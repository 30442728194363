// Here you can add other styles

body {
  .fullscreen {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .app-body {
    min-height: calc(100vh - 87px);
  }
}

.fw-semibold {
  font-weight: 600;
}

.right-0 {
  right: 0;
}

.gap-2 {
  gap: 5px;
}

.line-h-normal {
  line-height: normal;
}

.scroll-v1::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scroll-v1::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.scroll-v1::-webkit-scrollbar-thumb {
  background: rgb(192, 192, 192);
  border-radius: 5px;
}

.gap-y-2 {
  row-gap: 5px;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.scroll-v1::-webkit-scrollbar-thumb:hover {
  background: rgb(146, 145, 145);
}

// set the hight of the select dropdown
.s-ddl-style {
  .prefix-select__control {
    min-height: 34.8px !important;
    min-width: 150px !important;
  }

  .prefix-select__indicator {
    padding: 6.5px !important;
  }
}

.app-header .nav-item.head-msg-icon {
  padding-left: 10px;
  margin-left: 25px !important;
  border-left: 1px solid #ddd;
}

.no-cursor {
  cursor: not-allowed !important;
}

.stay-alive-icon {
  position: absolute;
  border-radius: 100%;
  background-color: #4dbd74;
  border: 1px solid #d4edda;
  left: 45px;
  height: 10px;
  width: 10px;
}

.q-count {
  position: relative;
  background-image: url("../img/question.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 2px 0px;
  height: 25px;
  line-height: 16px;
  font-size: 13px;
  color: #fff;
}

.r-count {
  position: relative;
  background-image: url("../img/response.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 2px 0px;
  height: 25px;
  line-height: 19px;
  font-size: 13px;
  color: #fff;
}

.ql-container .ql-editor {
  min-height: 150px;
}

.slick-slide,
.slick-slide * {
  outline: none !important;
}

/* input placeholder style start */
input::-webkit-input-placeholder {
  color: #aaa !important;
}

input::-moz-placeholder {
  color: #aaa !important;
}

input:-ms-input-placeholder {
  color: #aaa !important;
}

input:-moz-placeholder {
  color: #aaa !important;
}

/* input placeholder style end */

.form-control-label {
  margin-top: 6px;
}

input:focus,
.form-control:focus {
  outline: 0;
  box-shadow: none;
}

.email-link {
  color: #20a8d8 !important;
  text-decoration: none;
  cursor: pointer;

  .fa {
    font-size: 15px !important;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: hsl(0, 0%, 95%);
  border-color: hsl(0, 0%, 90%);
  color: #808080;
}

.select-control {
  height: 100%;
}

.email-link:hover {
  text-decoration: none;
  color: #167495 !important;
}

.time-link {
  background: #5cb85c !important;
  color: #fff !important;
  padding: 3px 8px;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
}

.time-link:hover {
  color: #fff !important;
  background: #5cb85c !important;
  text-decoration: none;
}

.time-grid-link {
  font-size: 13px;
}

.ph-link {
  background: #20a8d8 !important;
  color: #fff !important;
  padding: 3px 8px;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
}

.ph-link:hover {
  color: #fff !important;
  background: #167495 !important;
  text-decoration: none;
}

.ph-grid-link {
  font-size: 13px;

  .fa {
    font-size: 13px !important;
  }
}

.lead-status-style {
  padding: 3px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #999 !important;
  width: 85px;
  display: block;
  text-align: center;
}

/* Route loading indicator start */

.load-bar {
  position: fixed;
  width: 100%;
  height: 3px;
  background-color: #48a3cd;
  left: 0;
  top: 0;
  z-index: 999999999;
}

.bar {
  content: "";
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center;
}

.bar:nth-child(1) {
  background-color: #800e80;
  animation: loading 3s linear infinite;
}

.bar:nth-child(2) {
  background-color: #da4733;
  animation: loading 3s linear 1s infinite;
}

.bar:nth-child(3) {
  background-color: #48a3cd;
  animation: loading 3s linear 2s infinite;
}

@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index: 100;
  }

  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }

  to {
    left: 0;
    width: 100%;
  }
}

/* Route loading indicator end */

@keyframes blink {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.chat-wait {
  border: none;
  font-size: 14px;
  color: #8f8f8f;
}

.chat-wait span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  font-size: 25px;
  line-height: 13px;
  margin-left: 1px;
}

.chat-wait span:nth-child(2) {
  animation-delay: 0.2s;
}

.chat-wait span:nth-child(3) {
  animation-delay: 0.4s;
}

/* All table styles */
table.table {
  th {
    min-width: 80px;
  }

  .auto-width {
    min-width: auto;
  }

  .w60 {
    min-width: 60px;
  }

  .w100 {
    min-width: 100px;
  }

  .w110 {
    min-width: 110px;
  }

  .w120 {
    min-width: 120px;
  }

  .w130 {
    min-width: 130px;
  }

  .w140 {
    min-width: 140px;
  }

  .w150 {
    min-width: 150px;
  }

  .w160 {
    min-width: 160px;
  }

  .w170 {
    min-width: 170px;
  }

  .w180 {
    min-width: 180px;
  }

  .w190 {
    min-width: 190px;
  }

  .w200 {
    min-width: 200px;
  }
}

/* All table styles end */

/* More tab styles start */
.more-tabs {
  .dropdown {
    height: 100%;
  }

  .btn.dropdown-toggle,
  .btn.dropdown-toggle:active {
    background: transparent;
    border-color: #edf1f4;
    line-height: 25px;
    box-shadow: none !important;
    margin-bottom: -1px;
    border-bottom-color: transparent;
  }

  .btn.dropdown-toggle:hover {
    border: 1px solid;
    border-color: #c2cfd6 #c2cfd6 #a4b7c1;
  }

  button.dropdown-item {
    padding: 0;

    a.nav-link {
      border: 0;
    }

    a.nav-link.active {
      background: #edf1f4;
    }
  }
}

.more-tabs.active {

  .btn.dropdown-toggle,
  .btn.dropdown-toggle:active {
    color: #29363d;
    background: #fff;
    border: 1px solid #c2cfd6;
    border-bottom-color: #fff;
  }
}

/* More tab styles end */

.bee-template {
  width: 100%;
  height: 700px;

  iframe {
    min-width: 800px !important;
    border: 1px solid #ddd !important;
  }
}

.tab-head {
  padding: 0.5rem 1.25rem;

  h5 {
    float: left;
    line-height: 27px;
  }

  .btn-box {
    float: right;

    button {
      margin-top: 0;
    }
  }
}

.table-outer-box {
  position: relative;

  thead {
    a {
      color: black;
    }

    i {
      font-size: 13px !important;
      margin-top: 4px;
    }
  }

  .list-notfound {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-weight: 600;
    font-size: 14px;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;

    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
  }
}

.table-pagination {
  a {
    z-index: 0 !important;
  }

  .pagination {
    float: left;
    margin-right: 10px;
    margin-bottom: 0;
  }

  .total-records {
    float: right;
    line-height: 35px;
    color: #151b1e;
  }

  .list-notfound {
    line-height: 35px;
  }
}

.breadcrumb {
  margin: 0;
  border: 0;
}

.app-footer {
  flex: 0 0 30px;
  font-size: 13px;
}

/* react-data-grid style start */
.react-grid-Empty {
  .list-notfound {
    border: 0 !important;
  }
}

.react-grid-Main {
  outline: none !important;
}

.react-grid-Grid {
  border: 1px solid #a4b7c1 !important;
}

.react-grid-HeaderCell {
  border-right: 1px solid #a4b7c1 !important;
  border-bottom: 1px solid #a4b7c1 !important;
  background: #fff !important;
}

.react-grid-Cell {
  border-right: 1px solid #a4b7c1 !important;
  border-bottom: 1px solid #a4b7c1 !important;
  background-color: inherit !important;
}

.react-grid-Row--even {
  background-color: #f0f0f0 !important;

  .react-grid-Cell {
    background-color: #f0f0f0 !important;
  }
}

.react-grid-Row--odd {
  background-color: #fff !important;
}

.react-grid-Row.row-context-menu .react-grid-Cell,
.react-grid-Row:hover .react-grid-Cell {
  background-color: #dbdbdb !important;
}

.react-grid-Cell:focus {
  outline: none !important;
  outline-offset: 0 !important;
}

/* react-data-grid style end */

/* checkbox style */
.checkbox-style {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  // font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-style input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-style .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #c2cfd6;
}

/* On mouse-over, add a grey background color */
.checkbox-style:hover input~.checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-style input:checked~.checkmark {
  background-color: #20a8d8;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-style .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-style input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-style .checkmark:after {
  left: 9px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* checkbox style end */

/* radio button style */
.radio-style {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio */
.radio-style input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio */
.radio-style .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #c2cfd6;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-style:hover input~.checkmark {
  background-color: #fff;
}

/* When the radio is checked, add a blue background */
.radio-style input:checked~.checkmark {
  background-color: #20a8d8;
}

/* Create the checkmark/indicator (hidden when not checked) */
.radio-style .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.radio-style input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.radio-style .checkmark:after {
  left: 6px;
  top: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
}

/* radio button style end */

/* react-image-gallery style */
.image-gallery-slide-wrapper .image-gallery-left-nav,
.image-gallery-slide-wrapper .image-gallery-right-nav {
  outline: none;
}

.image-gallery {
  max-height: 500px;
  margin-bottom: 20px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.image-gallery-style img {
  max-height: 350px;
}

.image-gallery-thumb-style img {
  max-height: 75px;
}

.image-gallery-no-img {
  cursor: pointer;
  min-height: 380px;
  margin-bottom: 20px;
  border: 1px solid #eee;
  border-radius: 4px;
  text-align: center;
  line-height: 370px;
  font-size: 20px;
  color: #666;
}

.image-gallery-image,
.image-gallery-thumbnail-inner img {
  object-fit: contain;
  width: 100%;
  max-height: 350px !important;
}

/* react-image-gallery style end */

/* react-dates style */
.DateRangePicker {
  .DateInput {
    width: 100px;

    input {
      padding: 5px 12px;
      font-size: 14px;
    }
  }
}

/* react-dates style end */

// #unauthorized-box {
//   // display: none;
//   position: fixed;
//   z-index: 99999;
//   width: 100%;
//   height: 100%;
//   text-align: center;
//   background: rgba(0, 0, 0, 0.5);

//   .inner-box {
//     background: #fff;
//     width: 524px;
//     text-align: left;
//     padding: 30px;
//     margin: 0 auto;
//     margin-top: calc(25% - 80px);
//     border: 1px solid #a4b7c1;

//     .title {
//       font-size: 20px;
//       font-weight: 600;
//     }

//     .message {
//       margin: 10px 0 25px 0;
//     }

//     button {
//       float: right;
//       width: 130px;
//       color: #666;
//       background: #eee;
//       font-weight: 600;
//     }
//   }
// }

.Select.is-focused:not(.is-open)>.Select-control {
  border-color: #8ad4ee !important;
}

.prefix-select__menu {
  z-index: 1000 !important;
}

.error {
  color: red;
  margin-top: 5px;
  font-size: 13px;
}

.success {
  color: green;
  margin-top: 5px;
  font-size: 13px;
}

.record-notfound {
  background: #f2f2f3;

  div {
    font-size: 13px;
    line-height: 50px;
    text-align: center;
  }
}

.list-notfound {
  font-size: 13px;
  line-height: 50px;
}

.no-click {
  opacity: 0.6;
  pointer-events: none;
}

.no-click-v1 {
  opacity: 0.4;
  pointer-events: none;
}

.ftw600 {
  font-weight: 600 !important;
}

.fs11 {
  font-size: 11px !important;
}

.fs13 {
  font-size: 13px !important;
}

.fs15 {
  font-size: 15px !important;
}

.fs17 {
  font-size: 17px !important;
}

.fs21 {
  font-size: 21px !important;
}

.p0 {
  padding: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pr35 {
  padding-right: 35px !important;
}

.pl0 {
  padding-left: 0 !important;
}

.m0 {
  margin: 0 !important;
}

.my-9 {
  margin: 9px 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml3 {
  margin-left: 3px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml-chat {
  margin-left: 33px !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.b0 {
  border: 0 !important;
}

.br4 {
  border-radius: 4px !important;
}

.cursor-pointer {
  cursor: pointer;
}

/* required field style */
.req-msg {
  color: #dc3545 !important;
  font-size: 13px !important;
}

.req-lbl {
  position: relative;
}

label.req-lbl {
  margin: 0.5rem 0;
}

.req-lbl::after {
  content: " *";
  color: #dc3545 !important;
  font-size: 14px !important;
  position: absolute;
  top: -5px;
  margin-left: 2px;
}

/* required field style end */

.btn-white {
  background: #fff;
  color: #444;
  border: 1px solid #999;
}

.btn-clone,
.btn-clone .fa {
  &:focus {
    outline: none;
  }
}

.btn-clone {
  i {
    font-size: 18px;
    font-weight: bold;
  }
}

.clone-text-msg {
  font-size: 14px;
  padding: 2px;
  color: #666;
}

.editor {
  margin: 0;
  padding: 0;
  font-size: 13px;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  li,
  b {
    margin: unset;
    font-weight: unset;
    line-height: unset;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  h4 {
    font-size: 1.12em;
  }

  h5 {
    font-size: 0.83em;
  }

  h6 {
    font-size: 0.75em;
  }
}

.chat-welcome-note {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  border-radius: 0 10px 10px 10px;
  color: #646464;
  font-size: 13px;
  margin: 0;
  padding: 5px 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  float: left;
  width: calc(100% - 40px);
}

.light-box {
  border: 1px solid #eee;
  border-radius: 3px;

  .gruop-input {
    max-width: 300px;
    display: inline-block;
    width: 300px;
  }

  .card-header {
    border-bottom: 1px solid #eee;
  }
}

.channel-image {
  max-width: 20px;
  max-height: 20px;
}

.info-icon {
  font-size: 17px !important;
  margin-right: 7px;
  font-style: italic;
}

.Appbarstyle {
  text-align: "center";
  width: 100%;
}

.app-bar-title {
  font-size: "700px !important";
}

.appointmentMainDivStyle {
  align-items: "center";
  font-size: 16pt;
}

.appointmentSpanStyle {
  color: rgb(0, 188, 212);
}

.product {
  padding: 5px;
  border-bottom: 1px solid gray;
  margin-bottom: 5px;
}

.mainProductDes {
  margin-top: 20px;
}

.productBtn {
  height: 35px;
  font-size: 19px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: white !important;
  border-radius: 5px;
  width: 100%;
  background: #20a8d8 !important;
  float: none;
}

// #root h1 {
//   font-weight: bold !important;
// }

.main-class {
  margin: 0 auto;
  max-width: 400px;
}

@media (max-width: 767px) {
  .product img {
    width: 100%;
  }
}

.channels {
  .channel-list {

    .navTabs .card-body,
    .card-block {
      padding: 0;
    }
  }
}

.navTabs.card {
  border: none !important;
  background: transparent;
}

.main {
  padding-bottom: 10px;
  margin-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
}

.nav-item {
  background: transparent;
  position: relative;
}

.nav-item:hover {
  cursor: pointer;
}

// .serviceImg{
//   width: 100%;
//   height: 100px;
//   object-fit: cover;
// }

// .priceDiv{
//   height:25px;
//   position: absolute !important;
//   bottom: 46px;
//   width: 100%;
//   background: #c2cfd6;
//   opacity: 0.6;
//   color: black;
//   font-weight: bold;
// }

// .productsService .card {
//   border:none;
// }

// .productimg {
//   margin-top: 8px;
//   text-align: center
// }

.fbImg {
  height: 25px;
  width: 25px;
}

.status {
  height: 20px;
  width: 20px;
}

.socialIcons {
  height: 30px;
  width: 30px;
}

// .productimg{
//   padding:0 25px;
// }

//   .productimg:nth-child(4n-3) {
//     padding-left: 10px
//  }

//  .productimg:nth-child(4n+4) {
//     padding-right: 10px
//  }

.CircularProgressbar .CircularProgressbar-text {
  fill: black; // !important;
  font-size: 28px; // !important;
}

svg.CircularProgressbar:not(:root) {
  height: 35px; // !important;
}

.progress {
  border: 1px solid #b5a7a73d; // !important;
  height: 7px; // !important;
}

.percentage {
  padding-right: 27px;
}

.usageInfo {
  font-size: 11px;
  color: #908b8b;
}

.userName {
  font-size: 15px;
}

.user {
  text-align: left;
}

.lastLogin {
  text-align: left;
}

.social-icon {
  border: 2px solid gray;
  margin: 6px 0;
  padding: 10px 15px;
  font-size: 16px;
}

.social-icon .Img {
  width: 30px;
  margin: 0 7px;
}

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  h5 {
    margin-bottom: 0;
  }
}

.custom_btn_add_remove button {
  background: transparent;
  border: none;
}

.custom_btn_add_remove {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}

.custom_btn_add_remove img {
  display: block;
  margin: 0 auto;
}

.custom_btn_add_remove .btn {
  display: inline-block;
  text-align: center;
}

.social_custom .social-icon {
  padding: 8px 5px 8px 48px;
  position: relative;
}

.social_custom {
  float: left;
  padding-left: 6px;
  padding-right: 6px;
  width: 20%;
}

.social_custom :hover {
  cursor: pointer;
}

.custom_right_side h3 {
  font-size: 18px;
  font-weight: bold;
}

.custom_right_side ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom_right_side ul li {
  list-style: none;
  margin-bottom: 6px;
}

.custom_right_side ul li a {
  color: gray;
  font-size: 12px;
}

.custom_right_side ul li a.active {
  color: blue;
}

.custom_right_side ul li span {
  background: burlywood;
  color: white;
  padding: 3px 7px;
  display: inline-block;
  margin-left: 5px;
  text-align: center;
  border-radius: 5px;
  margin-top: -3px;
  line-height: normal;
  font-size: 12px;
  font-weight: bold;
}

.custom_right_side ul li span.yellow-bg {
  background: #f3d817;
}

.custom_right_side ul li span.orange-bg {
  background: #f38717;
}

.custom_right_side ul li span.bg {
  background: brown !important;
}

.custom_right_side {
  margin-top: 30px;
}

/* Added by dixit.pandya */
.custom_btn_add_remove {
  z-index: 9;
}

img {
  border: none;
  max-width: 100%;
  vertical-align: middle;
}

.social-box {
  position: relative;
  border: 1px solid white;
  background: rgba(220, 229, 234, 0.52);
}

.social-box .img-holder {
  position: absolute;
  width: 55px;
  height: 55px;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}

.hero-social-list {
  padding: 6px;
}

.hero-social-list .social-icon .badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #519612;
  color: #ffffff;
  font-size: 12px;
  border-radius: 3px;
  line-height: 1;
}

.hero-social-list .social-icon .icon-holder {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.hero-social-list .social-icon .name {
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 1199px) {
  .social-box .img-holder {
    height: 52px;
    width: 52px;
  }

  .hero-social-list .social_custom {
    width: 33.33%;
  }
}

@media screen and (max-width: 991px) {
  .custom_right_side {
    margin: 15px 15px 15px;
  }
}

@media screen and (max-width: 767px) {
  .social-box {
    min-height: 90px;
  }

  .social-box ul {
    margin-top: 20px;
  }

  .hero-social-list .social_custom {
    width: 50%;
  }
}

@media screen and (max-width: 481px) {
  .hero-social-list .social_custom {
    width: 100%;
  }

  footer.app-footer>span.ml-auto {
    margin-left: 0px !important;
  }

  .custom_btn_add_remove .btn {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.period {
  border: 1px solid white;
  height: 35px;
  padding: 0 15px;
}

.colors {
  height: 140px;
  border-radius: 8px;
}

.ProfileIcon {
  height: 45px;
  width: 14%;
}

.cardTitle {
  color: rgba(38, 51, 60, 0.83);
  font-size: 9px;
  font-weight: bold;
}

.dataSize {
  font-size: 10px;
  color: rgba(38, 51, 60, 0.76);
}

.cardUsage {
  font-size: 18px;
  color: red;
}

table {
  border: 1px solid darkgray;

  thead tr {
    border: 1px solid darkgray;
  }
}

.chartsHeading {
  margin-top: 6px;
  margin-left: 15px;
}

.fb2Img {
  // height: 30px;
  // max-width: 30px;
  // margin-left: 5px;
  // margin-top: -15px;
  // margin-left: 13px;
  // margin-top: -17px;
  height: 25px;
  max-width: 25px;
  margin-top: -12px;
}

.twitter2 {
  height: 30px;
  border-color: 1px solid #fff;
  max-width: 30px;
  margin-left: 13px;
  margin-top: -17px;
}

.detail {
  font-size: 13px;
  color: rgb(255, 255, 255);
  margin-left: 5px;
  font-weight: bold;
}

.rtl {
  direction: rtl;
}

.active-success {
  margin-top: 15px;
  font-weight: bold;
}

.active-not {
  margin-top: 15px;
  font-weight: bold;

  margin-left: -5px;
}

@media (min-width: 768px) {
  .userStatus {
    flex: 0 0 33.33333%;
    max-width: 32.33333%;
  }
}

.purple {
  background-color: #2c194e !important;
}

.cardSize {
  padding-left: 0px;
}

.detailData {
  margin-left: -50px;
}

.contentDetail {
  margin-top: 5px;
  padding-left: 10px;
}

@media (max-width: 768.99px) {
  .chartLength {
    padding-left: 15px;
  }

  .cardSize {
    padding-left: 15px;
  }

  .contentDetail {
    padding-right: 15px;
  }

  .fb2Img {
    float: right;
    margin-right: -27px;
  }

  .detailData {
    margin-top: -80px;
    margin-left: 200px;
  }
}

@media (min-width: 590.99px) and (max-width: 768.99px) {
  .detail {
    margin-left: 100px;
    margin-top: -80px;
  }
}

@media (max-width: 590.99px) {
  .detail {
    margin-left: 0px;
    margin-top: -80px;
  }
}

.chartLength {
  padding-left: 0px;
}

.orderSettings {
  right: -35px !important;
}

.search {
  border: 1px solid white;
  background: white;
}

.slick-slide {
  padding-right: 8px;
}

@media (max-width: 617px) {
  .Timeformat {
    margin-bottom: 35px;
  }

  .BotsHeader {
    margin-bottom: 56px !important;
  }

  .BotsMenu {
    float: left !important;
  }

  .ProfileIcon {
    height: 35px !important;
    float: left !important;
    width: 35px !important;
  }

  .active-success {
    font-size: 12px !important;
  }

  // .BotDescription {
  //   font-size : 11px !important;
  // }
  // .activeImage {
  //   height : 25px !important;
  //   float : right !important;
  //   width : 25px !important;
  // }
}

.activeImage {
  // top:15px;
  // right: 15px;
  top: 10px;
  right: -2px;
}

.slick-prev {
  left: -18px !important;
  // background: black !important;
  border: none;
}

.slick-next {
  right: -15px !important;
  // background: black !important;
  border: none;
}

.BotDescription {
  color: white;
  font-weight: bold;
  font-size: 13px;
}

@media (max-width: 769px) {
  .activeImage {
    top: 10px !important;
    right: 25px !important;
  }

  .BotDescription {
    padding-left: 0px;
  }
}

.menuicons {
  padding-right: 0px;
}

//chat box custom style css

//body {    background: #e8eef2;  font-family: 'Conv_Nexa Bold'; font-size:14px; }

@font-face {
  font-family: "Conv_Nexa Bold";
  src: url("../fonts/Nexa Bold.eot");
  src:
    local("☺"),
    url("../fonts/Nexa Bold.woff") format("woff"),
    url("../fonts/Nexa Bold.ttf") format("truetype"),
    url("../fonts/Nexa Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Conv_Nexa Light";
  src: url("../fonts/Nexa Light.eot");
  src:
    local("☺"),
    url("../fonts/Nexa Light.woff") format("woff"),
    url("../fonts/Nexa Light.ttf") format("truetype"),
    url("../fonts/Nexa Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

.cp-pen-styles {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #e8eef2;

  font-size: 1em;
  letter-spacing: 0.1px;
  color: #32465a;
  text-rendering: optimizeLegibility;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-font-smoothing: antialiased;
}

#frame {
  //margin-top:20px;
  //width: 100%;
  //min-width: 360px;
  // max-width: 1080px;
  // height: 92vh;
  // min-height: 300px;
  // max-height: 730px;
  // background: none;

  #sidepanel {
    float: left;
    min-width: 280px;
    max-width: 340px;
    width: 40%;
    height: 100%;
    background: #2c3e50;
    color: #f5f5f5;
    overflow: hidden;
    position: relative;
  }
}

.img_box {
  float: left;
}

.left_title {
  position: relative;
  float: left;
}

.left_title .img_box h3 {
  font-size: 16px;
  // color: #5c5c5c;
  font-weight: normal;
  // margin-top: 12px;
  // font-family: Conv_Nexa Bold;
  margin-bottom: 2px;
}

.left_title .img_box p {
  font-size: 13px;
  color: #9e9e9e;
  font-weight: 100;
  font-family: Conv_Nexa Light;
}

@media screen and (max-width: 360px) {
  #frame {
    width: 100%;
    height: 100vh;
  }
}

@media screen and (max-width: 768px) {
  #frame .content .messages ul li>p {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 25px;
  }

  // #frame .messages ul {
  //   width: 100%;
  //     float: left;
  //     margin-left: auto;
  //     padding-left: 0;
  // }
  #frame .content {
    float: right;
    //width: 100% !important ;
  }

  // #frame .content {
  //   //width: 100%;
  //   min-width: 300px !important;

  // }

  #frame .content .messages ul li>p {
    border-radius: 25px !important;
  }

  // #frame .content .messages { padding:15px 0px 32px 0px !important ;}
}

@media screen and (max-width: 735px) {
  #frame #sidepanel {
    width: 58px;
    min-width: 58px;
  }
}

#frame #sidepanel #profile {
  width: 80%;
  margin: 25px auto;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile {
    width: 100%;
    margin: 0 auto;
    padding: 5px 0 0 0;
    background: #32465a;
  }
}

#frame #sidepanel #profile.expanded .wrap {
  height: 210px;
  line-height: initial;
}

#frame #sidepanel #profile.expanded .wrap p {
  margin-top: 20px;
}

#frame #sidepanel #profile.expanded .wrap i.expand-button {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

#frame #sidepanel #profile .wrap {
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  -moz-transition: 0.3s height ease;
  -o-transition: 0.3s height ease;
  -webkit-transition: 0.3s height ease;
  transition: 0.3s height ease;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap {
    height: 55px;
  }
}

#frame #sidepanel #profile .wrap img {
  width: 50px;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid #e74c3c;
  height: auto;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap img {
    width: 40px;
    margin-left: 4px;
  }
}

#frame #sidepanel #profile .wrap img.online {
  border: 2px solid #2ecc71;
}

#frame #sidepanel #profile .wrap img.away {
  border: 2px solid #f1c40f;
}

#frame #sidepanel #profile .wrap img.busy {
  border: 2px solid #e74c3c;
}

#frame #sidepanel #profile .wrap img.offline {
  border: 2px solid #95a5a6;
}

#frame #sidepanel #profile .wrap p {
  float: left;
  margin-left: 15px;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap p {
    display: none;
  }
}

#frame #sidepanel #profile .wrap i.expand-button {
  float: right;
  margin-top: 23px;
  font-size: 0.8em;
  cursor: pointer;
  color: #435f7a;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap i.expand-button {
    display: none;
  }
}

#frame #sidepanel #profile .wrap #status-options {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 150px;
  margin: 70px 0 0 0;
  border-radius: 6px;
  z-index: 99;
  line-height: initial;
  background: #435f7a;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options {
    width: 58px;
    margin-top: 57px;
  }
}

#frame #sidepanel #profile .wrap #status-options.active {
  opacity: 1;
  visibility: visible;
  margin: 75px 0 0 0;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options.active {
    margin-top: 62px;
  }
}

#frame #sidepanel #profile .wrap #status-options:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #435f7a;
  margin: -8px 0 0 24px;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options:before {
    margin-left: 23px;
  }
}

#frame #sidepanel #profile .wrap #status-options ul {
  overflow: hidden;
  border-radius: 6px;
}

#frame #sidepanel #profile .wrap #status-options ul li {
  padding: 15px 0 30px 18px;
  display: block;
  cursor: pointer;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li {
    padding: 15px 0 35px 22px;
  }
}

#frame #sidepanel #profile .wrap #status-options ul li:hover {
  background: #496886;
}

#frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px 0 0 0;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
    width: 14px;
    height: 14px;
  }
}

#frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  margin: -3px 0 0 -3px;
  background: transparent;
  border-radius: 50%;
  z-index: 0;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
    height: 18px;
    width: 18px;
  }
}

#frame #sidepanel #profile .wrap #status-options ul li p {
  padding-left: 12px;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li p {
    display: none;
  }
}

#frame #sidepanel #profile .wrap #status-options ul li#status-online span.status-circle {
  background: #2ecc71;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-online.active span.status-circle:before {
  border: 1px solid #2ecc71;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-away span.status-circle {
  background: #f1c40f;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-away.active span.status-circle:before {
  border: 1px solid #f1c40f;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-busy span.status-circle {
  background: #e74c3c;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-busy.active span.status-circle:before {
  border: 1px solid #e74c3c;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-offline span.status-circle {
  background: #95a5a6;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-offline.active span.status-circle:before {
  border: 1px solid #95a5a6;
}

#frame #sidepanel #profile .wrap #expanded {
  padding: 100px 0 0 0;
  display: block;
  line-height: initial !important;
}

#frame #sidepanel #profile .wrap #expanded label {
  float: left;
  clear: both;
  margin: 0 8px 5px 0;
  padding: 5px 0;
}

#frame #sidepanel #profile .wrap #expanded input {
  border: none;
  margin-bottom: 6px;
  background: #32465a;
  border-radius: 3px;
  color: #f5f5f5;
  padding: 7px;
  width: calc(100% - 43px);
}

#frame #sidepanel #profile .wrap #expanded input:focus {
  outline: none;
  background: #435f7a;
}

#frame #sidepanel #search {
  border-top: 1px solid #32465a;
  border-bottom: 1px solid #32465a;
  font-weight: 300;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #search {
    display: none;
  }
}

#frame #sidepanel #search label {
  position: absolute;
  margin: 10px 0 0 20px;
}

#frame #sidepanel #search input {
  font-family: "proxima-nova", "Source Sans Pro", sans-serif;
  padding: 10px 0 10px 46px;
  width: calc(100% - 25px);
  border: none;
  background: #32465a;
  color: #f5f5f5;
}

#frame #sidepanel #search input:focus {
  outline: none;
  background: #435f7a;
}

#frame #sidepanel #search input::-webkit-input-placeholder {
  color: #f5f5f5;
}

#frame #sidepanel #search input::-moz-placeholder {
  color: #f5f5f5;
}

#frame #sidepanel #search input:-ms-input-placeholder {
  color: #f5f5f5;
}

#frame #sidepanel #search input:-moz-placeholder {
  color: #f5f5f5;
}

#frame #sidepanel #contacts {
  height: calc(100% - 177px);
  overflow-y: scroll;
  overflow-x: hidden;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts {
    height: calc(100% - 149px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  #frame #sidepanel #contacts::-webkit-scrollbar {
    display: none;
  }
}

#frame #sidepanel #contacts.expanded {
  height: calc(100% - 334px);
}

#frame #sidepanel #contacts::-webkit-scrollbar {
  width: 8px;
  background: #2c3e50;
}

#frame #sidepanel #contacts::-webkit-scrollbar-thumb {
  background-color: #243140;
}

#frame #sidepanel #contacts ul li.contact {
  position: relative;
  padding: 10px 0 15px 0;
  font-size: 0.9em;
  cursor: pointer;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact {
    padding: 6px 0 46px 8px;
  }
}

#frame #sidepanel #contacts ul li.contact:hover {
  background: #32465a;
}

#frame #sidepanel #contacts ul li.contact.active {
  background: #32465a;
  border-right: 5px solid #435f7a;
}

#frame #sidepanel #contacts ul li.contact.active span.contact-status {
  border: 2px solid #32465a !important;
}

#frame #sidepanel #contacts ul li.contact .wrap {
  width: 88%;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap {
    width: 100%;
  }
}

#frame #sidepanel #contacts ul li.contact .wrap span {
  position: absolute;
  left: 0;
  margin: -2px 0 0 -2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #2c3e50;
  background: #95a5a6;
}

#frame #sidepanel #contacts ul li.contact .wrap span.online {
  background: #2ecc71;
}

#frame #sidepanel #contacts ul li.contact .wrap span.away {
  background: #f1c40f;
}

#frame #sidepanel #contacts ul li.contact .wrap span.busy {
  background: #e74c3c;
}

#frame #sidepanel #contacts ul li.contact .wrap img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap img {
    margin-right: 0px;
  }
}

#frame #sidepanel #contacts ul li.contact .wrap .meta {
  padding: 5px 0 0 0;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap .meta {
    display: none;
  }
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .name {
  font-weight: 600;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .preview {
  margin: 5px 0 0 0;
  padding: 0 0 1px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  -webkit-transition: 1s all ease;
  transition: 1s all ease;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .preview span {
  position: initial;
  border-radius: initial;
  background: none;
  border: none;
  padding: 0 2px 0 0;
  margin: 0 0 0 1px;
  opacity: 0.5;
}

#frame #sidepanel #bottom-bar {
  position: absolute;
  width: 100%;
  bottom: 0;
}

#frame #sidepanel #bottom-bar button {
  float: left;
  border: none;
  width: 50%;
  padding: 10px 0;
  background: #32465a;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 0.85em;
  font-family: "proxima-nova", "Source Sans Pro", sans-serif;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button {
    float: none;
    width: 100%;
    padding: 15px 0;
  }
}

#frame #sidepanel #bottom-bar button:focus {
  outline: none;
}

#frame #sidepanel #bottom-bar button:nth-child(1) {
  border-right: 1px solid #2c3e50;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button:nth-child(1) {
    border-right: none;
    border-bottom: 1px solid #2c3e50;
  }
}

#frame #sidepanel #bottom-bar button:hover {
  background: #435f7a;
}

#frame #sidepanel #bottom-bar button i {
  margin-right: 3px;
  font-size: 1em;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button i {
    font-size: 1.3em;
  }
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button span {
    display: none;
  }
}

#frame .content {
  float: right;
  height: calc(100% - 40px);
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

// @media screen and (max-width: 735px) {
//   #frame .content {
//     // width: calc(100% - 58px);
//     min-width: 300px !important;
//   }
// }

// @media screen and (min-width: 900px) {
//   #frame .content {
//     //width: 100%;
//   }
// }

#frame .content .ask-connect-box {
  width: 100%;
  background: #47a3ce;
  color: #fff;
  padding: 10px 10px;
  font-weight: 600;
  font-size: 13px;

  p {
    margin-bottom: 8px;
    margin-top: 20px;
  }

  button {
    border: 1px solid #fff;
    color: #ffffff;
    width: 60px;
    border-radius: 3px;
    margin-right: 5px;
    font-weight: 600;
    padding: 1px 5px 3px 5px;
    font-size: 13px;
    cursor: pointer;
  }

  button.green {
    background: #45a745;
  }

  button.red {
    background: #a74545;
  }

  button.green:hover {
    background: #337933;
  }

  button.red:hover {
    background: #712e2e;
  }
}

#frame {
  .init-msg {
    position: fixed;
    bottom: 105px;
    right: 45px;
    padding: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    word-wrap: break-word;
    border-radius: 7px;
    background: #fff;
    color: #1c73b9;
    line-height: normal;
    font-size: 13px;
    margin-left: 10px;
    max-width: 250px;
    transition-delay: 1s;

    .triangle {
      position: absolute;
      bottom: -15px;
      right: -2px;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      border-right: 1px solid rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      background: #fff;
    }

    .close-icon {
      transition-property: opacity;
      transition-delay: 1s;
      opacity: 0;
      cursor: pointer;
      background: #1c73b9;
      font-size: 16px;
      position: absolute;
      top: -29px;
      right: -10px;
      line-height: 13px;
      color: #fff;
      border-radius: 15px;
      border: 1px solid #fff;
      height: 15px;
      width: 15px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .init-msg:hover {
    .close-icon {
      opacity: 1;
      transition-property: opacity;
      transition-delay: 0s;
    }
  }

  .chatBubble {
    float: right;
    height: 60px;
    width: 60px;
    cursor: pointer;
    display: block;
    position: fixed;
    bottom: 32px;
    right: 30px;
    z-index: 9999993;
    background: white;
    border-radius: 32px;
    // border: 1px solid #fff;
    box-shadow: rgba(63, 81, 181, 0.93) 0px 0px 10px;
    animation: ripple 1s linear infinite;
  }

  .chatBubble.fa {
    font-size: 28px;
    padding: 15px;
    color: #fff;
    background: #3e515b;
  }

  .content {
    background: #fff;

    .messages {
      height: 100%;
      // min-height: calc(100% - 170px);
      // max-height: calc(100% - 170px);
      overflow-y: auto;
      overflow-x: hidden;
      float: left;
      width: 100%;
      padding: 5px 5px 5px 10px;

      .text-center {
        color: #949495;
        font-size: 14px;
      }

      ul li {
        /* .qreply {
          width: calc(100% - 40px);
          float: left;
          margin-left: 40px;
          .slick-slider{
            text-align: center;
            width: calc(100% - 20px);
            display:grid;
            .slick-list{
              margin: 0 0 0 7px !important;
            }
            .slick-prev {
              left: -17px !important;
              border : none;
            }
            .slick-next {
              right: -17px !important;
              border : none;
            }
            .slick-next:before, .slick-prev:before {
              font-size: 20px;
              line-height: 1;
              opacity: .75;
              color: #fff;
              background: white;
              color: darkgray;
            }
          }
          p.qlink{
            margin: 3px;
            color: #46a3cd!important;
            border: 1px solid #46a3cd!important;
            cursor: pointer;
            padding: 5px 10px;
            display: inline-block;
            border-radius: 15px;
            a{
              text-decoration: none;
            }
          }
        }*/
        /* .listType {
          width:100%;
          margin-top: 18%; 
          margin-left: 0px;
          .listElements{
            display: flex;
            border-bottom:1px solid rgba(0, 0, 0, 0.18);
            margin:10px 0px;
            .col{
              color:black;
              .subTitle{
                color:darkgray;
              }
              .viewbtn {
                margin:10px 0px;
                button {
                  border: 2px solid #46a3cd;
                  border-radius: 4px;
                  color: #46a3cd;
                  width:50%;
                  padding-top:5; 
                  background:white;
                  a{
                    text-decoration:none
                  }
                }
              }
            }
            .col-5 img{
              border-radius:5%; 
              margin-bottom:10;
              width:100%;
              height:75px; 
              object-fit: cover;
            }
          }
        }*/

        .loading-wgt {
          color: #777;
          margin-top: 6px;
        }
      }
    }
  }
}

$color: #999;

@keyframes ripple {
  0% {
    box-shadow:
      0 0 0 0 rgba($color, 0.2),
      0 0 0 10px rgba($color, 0.2);
  }

  100% {
    box-shadow:
      0 0 0 10px rgba($color, 0.2),
      0 0 0 20px rgba($color, 0);
  }
}

#frame .content .messages>ul>li {
  display: inline-block !important;
  clear: both !important;
  float: left !important;
  margin: 15px 0 5px 0 !important;
  width: calc(100% - 5px) !important;
  font-size: 13px !important;
  word-break: break-word;
  max-width: 360px;

  .search-high-light {
    background-color: #2b2c33 !important;
    color: #d4d4d5 !important;
  }

  .search-high-light.selected {
    background-color: yellow !important;
    color: black !important;
  }
}

#frame .content .messages.chat-widget-msg>ul>li:first-child {
  // margin-top: 30px !important;
}

#frame .content .messages.chat-widget-msg>ul>.loading-wgt:first-child {
  // margin-top: 30px !important;
}

#frame .content .messages>ul>li:nth-last-child(1) {
  margin-bottom: 20px;
}

#frame .content .messages ul li.sent {
  position: relative;

  .high-light {
    background-color: yellow;
    color: black;
  }

  .message-option {
    position: absolute;
    width: 120px;
    display: inline-flex;
    align-items: flex-start;

    .arrow {
      margin-top: 8px;
    }

    .fa {
      vertical-align: super;
      margin-left: 5px;
      color: #20a8d8;
    }

    .btn-box {
      display: inline-block;
      border: 1px solid #ddd;
      border-radius: 4px;
      margin: 5px 0 0 12px;
      padding: 5px;
      border-top-left-radius: 0;

      button {
        border-radius: 2px;
        font-size: 11px;
        background-color: #eee;
        border: 1px solid #ddd;
        color: #20a8d8;
      }

      button:hover {
        color: #167495;
      }
    }

    .btn-box::before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      left: 14px;
      top: 5px;
      border: 8px solid transparent;
      border-right: 8px solid #aaa;
    }
  }

  /*& > p {
    background: #fff;
    color: black;
    border:1px solid #e7e7e7;
    font-size:14px;
  }*/
  .form-group {
    background: #fff;
    color: #949495;
    font-size: 14px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
  }

  .media {
    img {
      width: 100%;
      border-radius: 0%;
      height: 200px;
      margin-bottom: 0px;
      border: 1px solid #e7e7e7;
    }
  }

  .audio {
    border: none;
  }

  .listElements:nth-last-child(1) {
    border: none !important;

    .col-5 img {
      // margin-bottom: 0px !important;
    }
  }
}

#frame .content .messages ul li.replies img {
  float: right;
  margin: 0px 0 0 8px;
}

#frame .content .messages ul li>img.img-lnk {
  border: solid 1px #e6e6e6;
  margin: 0;
  max-height: 200px;
  width: auto;
}

#frame .content .messages ul li.replies>img.img-lnk {
  border-radius: 10px 10px 0 10px !important;
}

#frame .content .messages ul li.sent>img.img-lnk {
  border-radius: 0 10px 10px 10px !important;
  max-width: calc(100% - 35px);
}

#frame .content .messages ul li.replies p {
  background: #48a3cd;
  border-radius: 10px 10px 0 10px !important;
  font-size: 13px;
  margin: 0;
  color: #fff;
  padding: 5px 10px;
  float: right;
  word-wrap: break-word;
}

#frame .content .messages ul li.replies .qlink {
  float: right;
  word-wrap: break-word;
}

#frame .content .messages ul li.sent>p {
  background: #fff none repeat scroll 0 0;
  border: solid 1px #e6e6e6;
  border-radius: 10px !important;
  border-top-left-radius: 0 !important;
  color: #646464;
  font-size: 13px;
  margin: 0;
  padding: 5px 10px;
  // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  max-width: calc(100% - 35px);
}

#frame .content .messages ul li.sent .slick-list p {
  background: #fff none repeat scroll 0 0;
  border-radius: 10px !important;
  font-size: 13px;
  margin: 3px 0 0 0;
  padding: 5px 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 3px;
}

#frame .content .messages>ul>li.replies {
  display: block !important;
  float: right !important;
}

@media screen and (min-width: 735px) {
  #frame .content .messages ul li>p {
    max-width: 100%;
  }

  // #frame .messages ul{ float: left;
  //   width: 100%;
  //   padding-left: 0;}
}

#frame .content .chat-offline {
  color: red;
  font-size: 13px;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
}

#frame .content .phrases {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 104px;
  background: #f2f5f9;
  border: 5px solid #fff;
  font-size: 13px;
  max-height: calc(100% - 175px);
  overflow-y: auto;

  .msg-category {
    padding: 5px 10px;
    color: #666;
    font-weight: 500;
    border-bottom: 1px solid #eee;
  }

  .msg-category:before {
    content: "\F138";
    font-family: FontAwesome;
    margin-right: 7px;
    cursor: pointer;
  }

  .msg-category:hover {
    color: #48a3cd;
  }

  .msg {
    padding: 5px 10px;
    color: #666;
    cursor: pointer;
    border-bottom: 1px solid #eee;
  }

  .msg-active:before {
    content: "\f13a";
    font-family: FontAwesome;
    margin-right: 7px;
    cursor: pointer;
  }

  .msg:before {
    content: "\f101"; //dots
    font-family: FontAwesome;
    margin-right: 7px;
  }

  .msg:hover {
    color: #48a3cd;
  }

  .msg:last-child {
    border: 0;
  }
}

#frame .content .phrases::-webkit-scrollbar {
  width: 5px;
}

#frame .content .phrases::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#frame .content .phrases::-webkit-scrollbar-thumb {
  background: rgb(192, 192, 192);
}

#frame .content .phrases::-webkit-scrollbar-thumb:hover {
  background: rgb(146, 145, 145);
}

#frame .content .message-input {
  // position: absolute;
  // bottom: 0;
  // width: 100%;
  // z-index: 99;
  // left: 0;
  // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  height: 65px;
  background: #f2f5f9;
  padding: 0 2%;
  margin: 5px;
  width: calc(100% - 10px) !important;
  // border-top: 1px solid #ddd;

  .select-lng {
    width: 200px;
    right: 10px;
    position: absolute;
    bottom: 45px;
  }

  .lng-text {
    font-size: 13px;
    margin-left: 10px;
    display: inline-block;
    margin-top: -1px;
    color: gray;
    text-transform: capitalize;
  }

  .tradeMark {
    // font-family: "Conv_Nexa bold";
    color: darkgray;
    line-height: 1.8;
    font-size: 11px;
    float: right;
  }

  input {
    width: 100%;
    font-size: 14px;
    border: 0;
    color: #8f8f8f;
    -webkit-appearance: none;
  }

  input:focus {
    outline: none;
  }

  .cm-icon {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #46a3cd;
    opacity: 1;
    cursor: pointer;
    font-size: 14px;
  }

  .cm-icon:hover {
    opacity: 0.8;
  }

  .mic {
    position: absolute;
    right: 45px;
    z-index: 4;
    color: #abb6c1;
    cursor: pointer;
    font-size: 18px;
    padding: 5px 8px;
    border-radius: 15px;
    margin: 4px 0;
    opacity: 1;
  }

  .mic:hover {
    opacity: 0.8;
  }

  .mic.on {
    background: #abb6c1;
    color: #fff;
    box-shadow: 0 0 0 rgba(67, 95, 122, 0.7);
    animation: pulse 2s infinite;
  }

  .recording {
    animation: ease recording-pulse 2s infinite;
  }

  @keyframes recording-pulse {
    0% {
      color: red;
    }

    50% {
      color: #eca8a8;
    }

    100% {
      color: red;
    }
  }

  button {
    // float: right;
    border: none;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    padding: 0;
    cursor: pointer;
    background: #fff;
    color: #46a3cd;
    opacity: 1;

    .fa-paper-plane {
      margin-bottom: 5px;
      margin-right: 3px;
    }
  }

  button:hover {
    opacity: 0.8;
    // background: #435f7a;
  }

  button:focus {
    outline: none;
  }

  .widget-wait {
    padding: 4px 0 0 6px;
    line-height: normal;
    // width: calc(100% - 70px);
  }

  .chat-wait {
    // margin-left: 35px;
  }
}

#frame .content .message-input.mode-3,
#frame .content .message-input.mode-2 {
  .wrap {

    input,
    .widget-wait {
      width: calc(100% - 40px);
      margin-left: 0;
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(67, 95, 122, 0.7);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(67, 95, 122, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(67, 95, 122, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(67, 95, 122, 0.7);
    box-shadow: 0 0 0 0 rgba(67, 95, 122, 0.7);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(67, 95, 122, 0);
    box-shadow: 0 0 0 10px rgba(67, 95, 122, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(67, 95, 122, 0);
    box-shadow: 0 0 0 0 rgba(67, 95, 122, 0);
  }
}

@media screen and (max-width: 500px) {
  #frame .content .contact-profile .social-media {
    float: none;
    position: absolute;
    right: -30px;
  }
}

.custom_main_box {
  width: 100%; // 400px;
}

#frame .content .messages ul li.replies .doc-container {
  background: #eef3f5;
  border-radius: 10px 10px 0 10px !important;
  overflow: hidden;
  border: 1px solid #a4b7c1;

  .img-lnk {
    margin: 0;
    border-radius: 10px 10px 0 0;
  }

  p {
    width: 100%;
    border-radius: unset !important;
  }

  .doc-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    a {
      text-decoration: none;
    }

    i {
      font-size: 45px;
      color: #464646;
    }

    p {
      background: #eef3f5;
      padding: 10px;
      font-size: 15px;
      width: unset;
      color: #464646;
      font-weight: bold;
      margin: 8px;
    }

    span {
      width: 140px;
      display: flex;
      justify-content: space-around;
      padding: 5px;
      background: #cedde5;
      border-radius: 25px;
      font-size: 12px;
      font-weight: bold;
      margin: 15px;
      margin-top: 24px;

      i {
        font-size: 20px;
      }
    }
  }
}

.doc-container {
  background: #eef3f5;
  border-radius: 10px 10px 0 10px !important;
  overflow: hidden;
  border: 1px solid #a4b7c1;

  .img-lnk {
    margin: 0;
    border-radius: 10px 10px 0 0;
  }

  p {
    width: 100%;
    border-radius: unset !important;
  }

  .doc-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 10px;

    .doc-title {
      display: flex;
      width: 100%;
      padding: 10px;

      .doc-filename {
        width: 100%;
        display: block;
        margin-left: 10px;
        text-overflow: ellipsis;
        line-height: normal;
      }
    }

    a {
      text-decoration: none !important;
    }

    i {
      font-size: 35px;
      color: #464646;
    }

    p {
      background: #eef3f5;
      padding: 5px;
      font-size: 15px;
      width: unset;
      color: #464646;
      font-weight: bold;
      margin: 8px;
    }

    span {
      width: 140px;
      display: flex;
      justify-content: space-around;
      padding: 5px;
      background: #cedde5;
      border-radius: 25px;
      font-size: 12px;
      font-weight: bold;
      margin: 10px;

      i {
        font-size: 20px;
      }
    }
  }
}

//chat box custom style css
// #frame .content .messages ul li.replies p {
//   background: #48a3cd;
//   border-radius: 50px 30px 0px 50px !important;
//   font-size: 14px;
//   margin: 0;
//   color: #fff;
//   padding: 5px 10px 5px 12px;
// }
#frame .content .messages ul li>p {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 15px;
  max-width: 100%;
  line-height: 130%;
}

@media screen and (min-width: 735px) {
  #frame .content .messages ul li>p {
    max-width: 100%;
  }
}

.search_main {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.search_main .form-control-feedback {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #d4d8db;
  font-size: 20px;
}

.search_main input {
  padding: 12px;
  background: white;
  width: 100%;
  font-size: 16px;
  color: #d4d8db;
  font-family: "Conv_Nexa Light";
  padding-right: 50px;
  box-shadow: 4px 5px 6px #dadfe3;
  border: none;
  border-radius: 5px;
}

.search_main input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #d4d8db;
}

.search_main input::-moz-placeholder {
  /* Firefox 19+ */
  color: #d4d8db;
}

.search_main input:-ms-input-placeholder {
  /* IE 10+ */
  color: #d4d8db;
}

.search_main input:-moz-placeholder {
  /* Firefox 18- */
  color: #d4d8db;
}

.webchat_main {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  background: white;
  box-shadow: 4px 5px 6px #dadfe3;
}

.webchat_main .middle .imgbox img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin: 10px;
}

.webchat_main .middle ul {
  display: inline-block;
  width: 100%;
  list-style: none;
}

.webchat_main .middle ul li {
  display: inline-block;
  border-bottom: 1px solid #eeeeee;
  position: relative;
  width: 100%;
}

.webchat_main .middle ul li:last-child {
  border-bottom: none;
}

.webchat_main .middle ul li span {
  font-size: 10px;
  color: #949495;
  font-family: "Conv_Nexa Light";
  position: absolute;
  right: 10px;
  top: 18px;
}

.webchat_main .middle .titlebox {
  float: left;
  margin-top: 4px;
  margin-left: 5px;
  width: 78%;
}

.webchat_main .middle .titlebox h3 {
  font-size: 18px;
  color: #949495;
  margin-top: 10px;
  margin-bottom: 5px;
  font-family: "Conv_Nexa Bold";
}

.webchat_main .middle .titlebox p {
  font-size: 13px;
  color: #9e9e9e;
  font-weight: 100;
  font-family: "Conv_Nexa Light";
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 10px;
  text-overflow: ellipsis;
  width: 100%;
  float: left;
}

.webchat_main .custom_pagination {
  box-shadow: 0px -4px 16px #f4f6f7;
  display: inline-block;
  float: left;
  width: 100%;
  border-top: 1px solid #d7dfe5;
}

.webchat_main .custom_pagination ul {
  list-style: none;
  display: block;
  text-align: right;
  padding: 12px;
}

.webchat_main .custom_pagination ul li {
  display: inline-block;
}

.webchat_main .custom_pagination ul li a {
  padding: 6px 8px;
  display: inline-block;
  text-align: center;
  color: #979797;
  font-family: "Conv_Nexa Light";
  font-size: 10px;
  text-decoration: none;
  background: #fff;
}

.webchat_main .custom_pagination ul li a:hover,
.webchat_main .custom_pagination ul li a.active {
  background: #3a9bc9;
  color: #fff;
  border-radius: 5px;
}

//live chat screen design ends

.chatWidgetCaraousel {
  width: 100%;
  max-width: 600px;

  // margin-top: 25px;
  .carousel-item {
    width: auto;

    .w-100 {
      border-radius: 15px 15px 0 0 !important;
      object-fit: cover;
      // width: 325px !important;
      height: 190px !important;
    }

    .d-block.img-fluid {
      display: none !important;
    }
  }

  .carousel-inner {
    border-radius: 15px;
    border: 1px solid hsla(0, 0%, 62%, 0.329412);
    overflow: hidden;
  }

  .carousel-control-prev,
  .carousel-control-next {
    height: 180px;
    opacity: 0.7;
    cursor: pointer;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: white;
    border-radius: 3px;
    display: inline-block;
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  }

  .carousel-control-prev:hover,
  .carousel-control-next:hover {
    opacity: 0.9;
  }
}

._4ida {

  // border: 1px solid rgba(158, 158, 158, 0.32941176470588235);
  // border-radius: 5%;
  ._3cne {
    padding: 12px 12px 0 12px;

    ._3cnf {
      ._3cng h6 {
        color: black;
        margin-bottom: 0;
        // color:#5c5c5c;
        // font-weight: bold;
      }

      ._3cnl {
        div {
          color: gray;
          margin-bottom: 5px;
        }
      }
    }
  }

  ._3cnr {
    text-align: center;
    line-height: 32px;

    div {
      border-top: 1px solid rgba(158, 158, 158, 0.32941176470588235);
    }

    ._3cnp {
      cursor: pointer;
      color: #46a3cd;
      font-weight: 500;
      padding: 0 10px;
      text-decoration: none;
    }
  }
}

.w3-animate-bottom {
  animation: animatebottom 0.4s;
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

.bot .arrow {
  float: right;
  margin: 15px 10px;

  .fa-angle-down {
    width: 25px;
    height: 25px;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    color: #adb5bd;
    background: #eee;
    border: 1px solid #ddd;
    border-radius: 50px;
    font-weight: 600;
    line-height: 25px;
  }
}

/* customized sider */
.navbar .sidebar .dropdown-toggle .badge,
.sidebar .nav-link .badge,
.sidebar .navbar .dropdown-toggle .badge {
  border-radius: 0.25rem;
}

.app-header.navbar .nav-item .dropdown-toggle .badge,
.app-header.navbar .nav-item .nav-link .badge {
  margin-top: -20px;
}

.sidebar {
  .nav-dropdown.open {
    background: none;

    .nav-link {
      color: black;
    }
  }

  #sidebarNav {
    border-right: 1px solid #a4b7c1;
  }

  // background: #f0f3f5;
  background: rgba(220, 229, 234, 0.52);
  color: black;

  .sidebar-nav::-webkit-scrollbar-track {
    background-color: white;
    border: none;
  }

  .sidebar-nav::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .sidebar-nav::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-clip: border-box;
    border-width: 9px 1px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .nav-title {
    color: #23282c;
    font-size: 80%;
    font-weight: 700;
  }

  .nav-link {
    color: #23282c;
    margin: 0 10px;

    .fa-chevron-right,
    .fa-chevron-down {
      margin: unset;
    }
  }

  .nav-link:hover,
  .nav-link:hover i {
    // background: #20a8d8; // #e7f5fb;
    // background: white; // #e7f5fb;
    background-color: #20a8d8;
    color: black;
    border-radius: 30px;
  }

  .nav-link.active,
  .nav-link.active i {
    background: white; // #e7f5fb;
    color: black;
    border-radius: 30px;
  }

  .nav {
    padding: 10px 0;

    .dropdown {
      border-radius: 30px;
      padding-bottom: 5px;
    }
  }
}

.submenu-nav {
  position: relative;
  max-height: calc(100vh - 72px); // Set maximum height to prevent overflow beyond the viewport
  overflow-y: auto; // Enable vertical scrolling if content exceeds maxHeight
}

input,
textarea {
  border-radius: 4px !important;
}

select {
  border-radius: 4px !important;
  font-size: 0.875rem !important;
}

.btn:focus {
  box-shadow: none;
}

/* page element styles*/
.settings,
.channels,
.products,
.profile,
.studio,
.knowledge,
.aiAgents,
.engagement .tab-content .tab-pane .card {
  .card-body .form-horizontal {
    // input, textarea {
    // border-radius: 4px;
    // }
    // select{
    //   border-radius: 4px;
    //   font-size: .875rem;
    // }
  }

  .card-footer {

    // padding: .75rem 15.25rem;
    .btn {
      margin: 0px 5px;
    }
  }
}

/*Table style*/
.knowledgeTable.table-sm td,
.aiAgentsTable.table-sm td,
.visitorsTable.table-sm td,
.engagementsTable.table-sm td,
.table-sm th {
  padding: 0.4rem;

  .memberInfo {
    height: 18px;
    padding: 1px;
    margin-top: -3px;
  }

  .badge {
    padding: 0.25em 0.6em;
    line-height: 2;
    border-radius: 3px;
  }

  .badge-info {
    color: white;
  }

  .fa {
    font-size: 19px;
  }

  .fa-facebook-square {
    color: #3b5998;
  }
}

.react-grid-Grid {
  .memberInfo {
    height: 18px;
    padding: 1px;
    margin-top: -3px;
  }

  .badge {
    padding: 0.25em 0.6em;
    line-height: 2;
    border-radius: 3px;
  }

  .badge-info {
    color: white;
  }

  .fa {
    font-size: 19px;
  }

  .fa-facebook-square {
    color: #3b5998;
  }
}

/* Image capture style */
.c-img-box {
  border: 1px solid #c2cfd6;
  width: 150px;
  height: 150px;
  border-radius: 4px;
  position: relative;
  padding: 2px;

  img {
    width: 100%;
    height: 100%;
  }

  .c-upload-alone {
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: rgba(255, 255, 255, 0.9);
    font-size: 24px;
    border: 0;
  }

  .c-action-btn-box {
    display: none;
    position: absolute;
    z-index: 1;
    margin: 8px 0 0 8px;

    button {
      margin: 0 7px;
      border-radius: 100px;
      width: 35px;
      height: 35px;
      background-color: white;
      border-color: #fff;
      opacity: 0.6;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .small {
      width: 25px;
      height: 25px;
      padding: 0;
      font-size: small !important;
    }

    button.upload {
      color: #000;
    }

    button.delete {
      font-size: 18px;
      color: #ff0000;
    }

    button:hover {
      opacity: 1;
    }
  }
}

.c-imge-box {
  border: 1px solid #c2cfd6;
  width: 180px !important;
  height: 255px !important;
  border-radius: 4px;
  position: relative;
  padding: 2px;

  img {
    width: 100%;
    height: 100%;
  }

  .c-upload-alone {
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: rgba(255, 255, 255, 0.9);
    font-size: 24px;
    border: 0;
  }

  .c-action-btn-box {
    display: none;
    position: absolute;
    z-index: 1;
    margin: 8px 0 0 8px;

    button {
      margin: 0 7px;
      border-radius: 100px;
      width: 35px;
      height: 35px;
      background-color: white;
      border-color: #fff;
      opacity: 0.6;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .small {
      width: 25px;
      height: 25px;
      padding: 0;
      font-size: small !important;
    }

    button.upload {
      color: #000;
    }

    button.delete {
      font-size: 18px;
      color: #ff0000;
    }

    button:hover {
      opacity: 1;
    }
  }
}

.c-img-box:hover {
  .c-action-btn-box {
    display: flex;
  }
}

/*login*/
.login {
  .mlogo {
    text-align: center;

    img {
      max-width: 170px;
      max-height: 60px;
      width: auto;
      height: auto;
      margin-top: 10px;
    }
  }

  .input {
    display: flex;
  }

  .form-group {
    width: 100%;
  }

  .content {
    .box {
      padding-top: calc((100vh - 450px) / 2);
      min-height: 450px;
    }
  }

  .input-password {
    input {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-width: 1px 0 1px 1px;
    }

    .input-group-append {
      .input-group-text {
        border-width: 1px 1px 1px 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.unsubscribe-input {
  width: calc(100% - 50px) !important;
  display: inline-block;

  input.form-control {
    border-radius: 4px !important;
  }
}

/* Studio */
.studio {
  .my-studio {
    // padding: 5px 15px 15px;
    padding-bottom: 15px;

    .create-studio-box {
      .studio-content {
        position: relative;

        .studio-type {
          color: #444;

          .head {
            margin-bottom: 20px;

            .title {
              font-size: 24px;
            }

            .desc {
              color: #999;
              font-size: 13px;

              i {
                color: #d8a200;
              }
            }
          }

          .box {
            cursor: pointer;
            border: 1px solid #ddd;
            padding: 10px;
            background: rgba(237, 241, 244, 0.5);
            height: 100%;
            border-radius: 3px;

            .img-box {
              width: 50px;
              margin: 10px 0 10px 10px;
            }

            .name {
              font-size: 22px;
            }

            .desc {
              color: #999;
              font-size: 13px;
              font-style: italic;
              line-height: normal;
            }

            .highlight {
              margin-top: 5px;
              font-size: 13px;
              color: #c19100;
              line-height: normal;
            }
          }

          .box:hover {
            background: #edf1f4;
          }

          .row-flex {
            display: flex;
            flex-wrap: wrap;
          }

          [class*="col-"] {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  .search-box {
    span.input-group-text {
      background: #fff;
      border-right: 0;
      padding: 6px;
      cursor: pointer;
    }

    .input-group-prepend.input-group-addon {
      min-width: auto;
    }

    input {
      border-left: 0;
      padding-left: 3px;
      padding-right: 3px;
    }

    input:focus {
      border: 1px solid #c2cfd6;
      border-left: 0;
      box-shadow: none;
    }
  }

  .botstore-box {
    height: 100%;
    border: 1px solid #eaeaea;

    .retail {
      font-size: 13px;
      font-weight: 600;
    }

    .card-body {
      padding: 7px 5px;
    }

    .img-box {
      padding-right: 5px;
    }

    .content-box {
      padding-left: 5px;

      .title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
        width: calc(100% - 15px);
      }

      .group {
        font-size: 12px;
        font-style: italic;
        color: #666;
      }

      .desc {
        color: #999;
        line-height: normal;
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 65px;
      }

      .tag-list {
        font-size: 10px;
        text-transform: uppercase;
        line-height: normal;
        position: absolute;
        bottom: 35px;

        i {
          margin: 0 3px;
          font-size: 20px;
        }
      }

      .btn-box {
        position: absolute;
        bottom: 0;

        button {
          font-weight: 600;
          font-size: 11px;
          margin-right: 5px;
        }

        span {
          color: #20a8d8;
        }

        .purchased {
          i {
            border: 1px solid;
            border-radius: 50%;
            font-size: 10px;
            padding: 1px;
            position: absolute;
            top: 3px;
          }

          span {
            margin: 0 0 0 18px;
            display: inline-block;
            font-size: 13px;
          }
        }
      }
    }
  }

  .my-bots {
    height: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #f1f1f1;
    background: -moz-linear-gradient(left, #f1f1f1 0, #fbfbfb 100%);
    background: -webkit-linear-gradient(left, #f1f1f1, #fbfbfb);
    background: linear-gradient(90deg, #f1f1f1 0, #fbfbfb);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f1f1f1", endColorstr="#fbfbfb", GradientType=1);

    .img-box {
      img {
        border: 1px solid #ddd;
        border-radius: 3px;
        height: 180px;
        object-fit: cover;
        background: #fff;
      }
    }

    .content-box {
      .desc {
        margin-bottom: 90px;
      }

      .dt {
        font-size: 10px;
        text-transform: uppercase;
        line-height: normal;
        position: absolute;
        bottom: 35px;
      }

      .tag-list {
        bottom: 55px;
      }

      .btn-box {
        width: calc(100% - 25px);

        button {
          // width: 100%;
          font-weight: normal;
          font-size: 13px;
        }

        i {
          font-size: 14px;
          margin-right: 5px;
        }
      }
    }
  }

  .bot-studio {
    .s-box {
      margin: -1px 10px 0 0;
      float: left;

      input {
        // height: 29px;
        // font-size: 13px;
        padding-right: 60px;
      }

      .fa {
        cursor: pointer;
        position: absolute;
        font-size: 13px;
        right: 10px;
        top: 10px;
        color: #999;
      }

      .s-count {
        position: absolute;
        font-size: 10px;
        right: 30px;
        top: 9px;
        color: #777;
        font-weight: 700;
        border: 1px solid #ddd;
        border-radius: 50%;
        padding: 0 3px 0px 3px;
        display: block;
        line-height: 15px;
        background: #f5f5f5;
      }
    }

    .fix-actions {
      right: 30px;
      background: #fff;
      top: 77px;
      border-radius: 10px;
    }

    .sticky-header {
      width: 100%;
      padding: 16px 20px;

      &.is-sticky {
        position: fixed;
        top: 55px;
        width: calc(100% - 240px);

        +.chat-bot-canvas {
          margin-top: 76px;
        }
      }
    }

    .bot-info-icon {
      color: #666;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
    }

    // .page-head-right {
    //   position: fixed;
    //   z-index: 999;
    //   padding: 10px;
    //   right: 30px;
    //   background: #fff;
    //   border-radius: 20px;
    //   margin-top: unset;
    // }

    .saving-icon {
      margin-left: 5px;
      color: #666;

      i {
        margin-right: 2px;
      }

      .fa-box {
        border-radius: 50%;
        border: 1px solid #666;
        font-size: 11px;
        height: 20px;
        width: 20px;
        line-height: 20px;
        text-align: center;
      }
    }

    .saving-icon::before {
      content: "";
    }

    .bot-studio-frame {
      width: 100%;
      min-height: 1000px;
      background: url(../img/bot-elements/grid.png);
      border: 1px solid #eff3e9;
      border-width: 0 1px 1px 0;
      // background: #fafafa;
    }

    .bot-studio-frame.v1 {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAD1BMVEUAAADY3OTY3OTY3OTY3OTFdWMRAAAABXRSTlMANhwzaLUVd4IAAAAlSURBVAjXYxARYGBgUFZgAAIXByAhaAAk4KLUBQgjoRYhRGkBAI9mAlul7olcAAAAAElFTkSuQmCC);
      border: 0;
      background-color: #f2f5f9;
    }

    .canvas-controls {
      position: fixed;
      right: 35px;
      bottom: 71px;
      width: 30px;

      .align-action {
        display: flex;
        position: relative;
        right: 30px;
        width: 60px;

        .ctrl-icon {
          background-color: #f5f5f5;
        }
      }

      .ctrl-icon {
        background-color: #fff;
        display: block;
        border-radius: 0;
        width: 30px;
        height: 30px;

        i {
          font-size: 16px;
          color: #20a8d8;
        }

        .success {
          color: green;
        }

        .error {
          color: red;
        }
      }
    }
  }

  .landing-page-type {
    display: table;

    // margin: 0 auto;
    .type-box {
      cursor: pointer;
      border: 1px solid #ddd;
      float: left;
      width: 300px;
      padding: 20px;
      border-radius: 3px;
      margin: 10px;

      .icon {
        font-size: 90px;
        text-align: center;
        color: #666;
      }

      .content {
        text-align: center;
        color: #666;

        .title {
          font-weight: 600;
        }

        .desc {
          font-size: 13px;
        }
      }
    }

    .type-box:hover {
      border-color: #999;
      background: #f5f5f5;
    }
  }

  .landing-page-list {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #f1f1f1;
    text-align: center;

    .page-content {
      padding-left: 5px;

      .title {
        margin-top: 3px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
      }
    }

    .page-img {
      position: relative;

      .icon-group {
        position: absolute;
        right: 5px;
        top: 5px;

        .fa {
          padding: 7px;
          margin-left: 5px;
          text-align: right;
          font-size: 18px;
          background-color: #f1f1f1;
          border-radius: 18px;
          border: 1px solid #ddd;
          cursor: pointer;
        }
      }

      img {
        display: block;
        width: -webkit-fill-available;
      }
    }

    .date {
      font-size: 11px;
      text-transform: uppercase;
      line-height: normal;
    }
  }

  .page-theme-list {
    margin-bottom: 20px;
    cursor: pointer;
  }

  .promotions {
    .c-body {
      padding: 0;
    }
  }

  .btn-select {
    width: 215px;
    text-align: center;
    font-size: 13px;
    margin: 5px 0px 5px 0px;
    cursor: pointer;
    font-weight: 600;
  }

  .channel-list {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden;

    .box {
      border: 1px solid #ddd;
      float: left;
      text-align: center;
      position: relative;
      border-radius: 5px;
      margin: 10px;

      .clone {
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 90px;
        padding: 7px;
        font-size: 15px;
        background-color: #f1f1f1;
        width: 30px;
        height: 30px;
        border-radius: 18px;
        cursor: pointer;
        border: 1px solid #ddd;
      }

      .edit {
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 50px;
        padding: 7px;
        font-size: 18px;
        background-color: #f1f1f1;
        width: 30px;
        height: 30px;
        border-radius: 18px;
        cursor: pointer;
        border: 1px solid #ddd;
      }

      .delete {
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 7px;
        font-size: 18px;
        background-color: #f1f1f1;
        width: 30px;
        height: 30px;
        border-radius: 18px;
        cursor: pointer;
        border: 1px solid #ddd;
      }

      img {
        height: 250px;
        max-height: 250px;
        width: 200px;
        object-fit: cover;
        object-position: top;
      }

      .title {
        margin: 5px 5px;
        font-size: 15px;
        font-weight: 600;
        color: #666;
      }
    }

    .icon-group {
      position: absolute;
      right: 5px;
      top: 5px;

      .fa {
        padding: 7px;
        margin-left: 5px;
        text-align: right;
        font-size: 18px;
        background-color: #f1f1f1;
        border-radius: 18px;
        border: 1px solid #ddd;
        cursor: pointer;
      }
    }

    .date {
      font-size: 11px;
      text-transform: uppercase;
      line-height: normal;
      margin-bottom: 5px;
    }
  }

  .channel-list.template {
    .box {
      width: calc(33% - 18px);

      .template-info {
        border-bottom: 1px solid #ddd;
        text-align: left;
        padding-left: 20px;
        padding: 5px 20px;
        line-height: 28px;

        .title {
          margin: 0;
        }
      }

      .icon-group {
        top: 10px;
        right: 10px;
      }

      .template-body {
        background: #f5f5f5;
        text-align: left;
        padding: 10px 20px;
        height: calc(100% - 53px);
        min-height: 80px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 13px;
        color: #666;
      }
    }
  }

  .emails {
    .c-body {
      padding: 0;
    }

    .email-template {
      margin-bottom: 20px;

      img {
        cursor: pointer;
      }
    }

    .email-page-list {
      padding: 5px;
      border: 1px solid #ddd;
      border-radius: 5px;
      background: #f1f1f1;
      font-style: italic;

      .page-content {
        margin-top: 5px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
        text-align: center;
      }

      .page-img {
        .edit {
          display: inline-block;
          position: absolute;
          top: 10px;
          right: 10px;
          padding: 7px;
          font-size: 18px;
          background-color: #f1f1f1;
          width: 30px;
          height: 30px;
          border-radius: 18px;
          cursor: pointer;
        }

        img {
          display: block;
          width: -webkit-fill-available;
        }
      }
    }
  }

  .template-name {
    font-size: 14px;
    margin-top: 10px;
  }

  .template-section-line {
    display: block;
    background-color: #ddd;
    height: 1px;
    width: inherit;
    margin-top: 60px;
  }

  .btn-screen-size {
    float: left;
    padding: 4.5px 7px 3px 7px;
    background: #a4b7c1;
    cursor: pointer;
  }

  .bot-preview {
    .bot-img-box {
      .bot-img {
        border-radius: 8px;
        border: solid 1px #cbd0d2;
        width: 180px;
        height: 180px;
      }
    }

    .bot-item-img-box {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .bot-img {
        max-height: 300px;
        max-width: 100%;
        width: auto;
        height: auto;
        border-radius: 8px;
      }
    }

    .line {
      margin-top: 10px;
      margin-bottom: 10px;
      display: block;
      height: 1px;
      background-color: #cbd0d2;
      width: 100%;
    }

    .catagory {
      margin-top: 30px;
      font-size: 17px;
      font-weight: 600;
    }

    .name {
      color: #666;
    }

    .bot-head {
      .title {
        font-size: 27px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-weight: 600;
      }

      .desc {
        float: left;
        font-size: 16px;
        font-weight: 500;
        color: #666;
        padding: 5px 0;
      }

      .btn {
        width: fit-content;
        padding: 5px 10px;
        float: right;
        top: 10px;
        border: solid 1px #cbd0d2;
        border-radius: 18px;
        background-color: #a4b7c1;
      }
    }

    .ratting-box {
      .fa-star {
        font-size: 17px;
        color: #999;
      }

      .btn-grp {
        float: right;
        margin-right: 20px;
      }
    }

    .bot-video {
      margin: 20px 0;

      .video-title {
        font-size: 17px;
        font-weight: 600;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
}

/* Studio element popup style */
.bot-popup {
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // background: rgba(0, 0, 0, 0.4);
  // z-index: 9999;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .bot-popup-inner {
    width: 100%;
    height: 554px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    .bt-title {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid #ddd;
      padding: 10px 15px;
      background: #f5f5f5;
      font-weight: 600;
      font-size: 16px;

      .close {
        cursor: pointer;
      }
    }

    .bt-left {
      float: left;
      width: 455px;
      border-right: 1px solid #ddd;
      height: calc(100% - 45px);
      position: relative;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      background: #f5f5f5;

      .cat-title {
        height: 42px;
        padding: 10px 35px 10px 15px;
        font-weight: 600;
        border-bottom: 1px solid #ddd;
        background: #fff;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row wrap;
        justify-content: space-around;
        -webkit-justify-content: space-around;
        flex-wrap: nowrap;
        -webkit-flex-wrap: nowrap;

        .action-icon {
          width: 17px;
          position: absolute;
          right: 10px;
          top: 12px;
        }

        span {
          cursor: pointer;

          img {
            height: 18px;
            margin-right: 5px;
          }
        }
      }

      .cat-title span.active,
      .cat-title span:hover {
        color: #1b8eb7;
      }

      .bt-menu-list {
        padding: 15px;

        .bt-msg-box {
          cursor: pointer;
          float: left;
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 4px;
          width: 120px;
          margin: 10px;
          padding: 10px;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;

          .item {
            text-align: center;

            img {
              height: 50px;
              width: 50px;
            }

            .name {
              font-size: 13px;
              line-height: normal;
              margin-top: 10px;
            }
          }
        }

        .bt-msg-box.active {
          border: 1px solid #999;
        }

        .bt-msg-box.disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        .bt-msg-box.in-progress {
          opacity: 0.7;
          pointer-events: none;
        }
      }
    }

    .bt-right {
      position: relative;
      // padding: 10px 30px;
      float: left;
      background: #fff;
      width: calc(100% - 455px);
      height: calc(100% - 45px);
      min-width: 455px;

      .verify-lbl {
        position: absolute;
        right: 40px;
        top: 15px;
        font-weight: 600;

        .fa {
          margin-right: 5px;
        }
      }

      .product-refresh {
        position: absolute;
        right: 30px;
        top: 15px;
        font-weight: 600;

        .bounce {
          animation: refresh-bounce 4s infinite;
        }

        .fa {
          margin-right: 5px;
        }
      }

      .bt-product {
        textarea {
          resize: none;
        }
      }

      .no-element {
        height: 100px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        color: #999;

        .icon {
          font-weight: 600;
          font-size: 50px;
        }

        .title {
          font-size: 24px;
          font-weight: 600;
          margin-top: 10px;
        }
      }

      .ele-title {
        margin: 10px 30px 0 30px;
        padding: 0 0 10px 0;
        font-weight: 600;
        border-bottom: 1px solid #ddd;
        background: #fff;
        width: 70%;

        input {
          border: 0;
          width: calc(100% - 50px);
          font-weight: 600;
        }

        fa {
          color: #666;
        }
      }

      .ele-content {
        margin: 20px 30px;

        .draggable {
          .c-box {
            cursor: move;
          }

          .non-draggable-item .c-box {
            cursor: auto;
          }
        }

        .input-box {
          .form-group {
            margin-bottom: 5px;
          }
        }

        .text-right {
          label {
            margin-top: 5px;
          }
        }

        .c-box {
          width: 100%;
          min-height: 260px;
          border: 1px solid #ddd;
          position: relative;
          border-radius: 5px;

          .plus {
            font-size: 70px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: #ddd;
          }

          .c-close {
            cursor: pointer;
            position: absolute;
            right: 6px;
            z-index: 1;
            font-size: 15px;
            color: #000;
            text-shadow: 0 1px 0 #fff;
            opacity: 0.5;
          }

          .c-close:hover {
            opacity: 0.7;
          }

          .textBox {
            color: #3e515b;
            border: none;
            border-bottom: solid #eeeeee 1px;
            padding: 6px 12px;
            width: 100%;
          }

          .textBox:focus {
            outline: none;
            border-bottom: 1px solid #8ad4ee;
          }

          .slider-img-box {
            margin: 0;
            border: none;
            position: relative;
            width: 100%;
            height: 110px;
            border-bottom: 1px solid #eee;
          }

          .btn-box {
            text-align: right;
            position: absolute;
            z-index: 99;
            right: 5px;
            top: 60px;
            width: auto;

            button {
              height: 25px;
              border-radius: 100px;
              background-color: #fff;
              border-color: #fff;
              padding: 0;
            }

            button.upload {
              color: black;
              opacity: 0.6;
              margin: 0;
            }

            button:hover {
              opacity: 1;
            }
          }

          .cb-submit {
            width: 100%;
            background-color: white;
            color: #1b8eb7;
            border: none;
            font-size: inherit;
          }

          .element-control {
            .select-attr-btn {
              width: auto;
              bottom: unset;
              top: 20px;
              background: #20a8d8;
              font-size: 14px;
              border-radius: 20px 0 0 20px;
              color: #fff !important;
              padding-left: 15px;
              padding-right: 10px;
              z-index: 100;
            }

            .select-attr {
              bottom: unset;
              top: 57px;
            }

            .select-control {
              .prefix-select__menu {
                .prefix-select__menu-list {
                  max-height: 158px !important;
                }
              }
            }
          }
        }

        .name {
          font-weight: 600;
        }

        .larger {
          transform: scale(1.5);
          padding: 10px;
        }

        .lbl {
          font-size: 110%;
          display: inline;
          margin-left: 7px;
        }

        .fileUpload {
          position: relative;
          overflow: hidden;

          input.upload {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            padding: 0;
            font-size: 20px;
            cursor: pointer;
            opacity: 0;
            filter: alpha(opacity=0);
          }
        }

        .btn-browse {
          border: 1px solid #c2cfd6;
          border-left: 0;
          border-radius: 0 4px 4px 0;
          background-color: #ccc;
          color: black;
          height: 35px;
          width: 72px;
          margin-left: -2px;
        }

        .a-box-outer {
          .a-box {
            min-width: 130px;
            display: inline-block;
          }
        }

        .input-box {
          width: calc(100% - 60px);
          display: inline-block;
          margin-right: 10px;
        }

        .btn-box {
          margin-top: 3px;
        }

        .select-attr-btn {
          position: absolute;
          width: 30px;
          right: 0;
          bottom: 0;
          font-size: 16px;
        }

        .select-attr {
          position: absolute;
          width: 200px;
          right: 0;
          bottom: -37px;
          margin: 0;
          z-index: 9;

          .select-control {
            font-size: 13px;
            line-height: 13px;

            .prefix-select__control {
              border-bottom: 0;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;

              .prefix-select__indicators {
                display: none;
              }
            }

            .prefix-select__menu {
              box-shadow: none;
              border: 1px solid #cccccc;
              border-top-right-radius: 0;
              border-top-left-radius: 0;
              margin-top: 0;

              .prefix-select__menu-list {
                max-height: 120px !important;
              }
            }
          }
        }

        .b-form {
          .bf-row {
            margin-bottom: 15px;
            display: flex;

            .fl {
              width: 180px;
              display: inline-block;
              text-align: right;
              margin-right: 20px;
              margin-top: 5px;
            }

            .fr {
              max-width: 470px;
              width: calc(100% - 200px);
              display: inline-block;

              .ml-box {
                border: 1px solid #ddd;
                border-radius: 4px;
                padding: 10px;
                line-height: 30px;

                .title {
                  font-weight: 600;
                  width: 110px;
                  text-align: right;
                  margin-right: 10px;
                  display: inline-block;
                }
              }
            }
          }

          .bf-row:after {
            clear: both;
          }

          .bf-logo {
            .select-attr-btn {
              right: 70px;
              top: 0;
            }

            .select-attr {
              top: 37px;
              right: 70px;
            }
          }
        }

        .int-box {
          width: 90px;
          height: 90px;
          border: 1px solid #ddd;
          border-radius: 10px;

          img {
            width: 100%;
            height: 100%;
            padding: 10px;
            object-fit: contain;
          }
        }

        .border-right-0 input[type="password"] {
          border-right: 0;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        .icon-pwd {
          border: 1px solid #c2cfd6;
          border-width: 1px 1px 1px 0;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          cursor: pointer;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          margin-left: -4px;
        }

        .isSelected {
          box-shadow: 0 0 0 2px #0075e3;
          border-radius: 4px;
        }
      }

      .ele-submit {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #f5f5f5;
        text-align: right;
        padding: 10px 15px;
        border-top: 1px solid #ddd;

        .lang-list {
          span {
            cursor: pointer;
            margin-right: 10px;
            padding-right: 10px;
            border-right: 1px solid #ccc;
            font-size: 13px;
            line-height: 15px;
            display: inline-block;
            height: 17px;
          }

          span:last-child {
            border-right: 0;
            padding-right: 0;
            margin-right: 0;
          }
        }
      }
    }

    .bt-left-sm {
      width: 70px;

      .cat-title {
        padding: 10px 15px;
      }

      .bt-menu-list .bt-msg-box {
        float: left;
        width: auto;
        height: auto;
        margin: 10px 0;
        padding: 8px;

        .item {
          .name {
            display: none;
          }

          img {
            height: 20px;
            width: 20px;
          }
        }
      }
    }

    .bt-right-lg {
      width: calc(100% - 70px);
    }
  }

  .integration-desc {
    color: #808080;
    font-size: 13px;
    margin-top: 2px;
  }

  .work-bench-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    .popup-content {
      background: #fff;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

      .popup-header {
        background-color: #eee;
        padding: 10px 20px;
        height: 65px;
      }

      .close-btn {
        cursor: pointer;
        color: #000;
      }

      .close-btn:hover {
        color: #ff0000;
      }

      .workbench-layout {
        display: flex;
        height: calc(100vh - 65px);
        overflow: hidden;

        .panel-left,
        .panel-right {
          overflow: hidden;
        }

        .right-content {
          flex: 1;
          display: flex;
          flex-direction: column;
          overflow: hidden;
        }

        .panel-rightBottom {
          flex: 1;
          overflow: hidden;
        }

        .panel-left-handle {
          width: 5px;
          background: #ccc;
          height: 100%;
          right: 0;
          position: absolute;
          top: 0;
          cursor: e-resize;
        }

        .panel-bottom-handle {
          height: 5px;
          background: #ccc;
          width: 100%;
          position: absolute;
          bottom: 0;
          cursor: s-resize;
        }

        .schema-loader {
          width: 20px;
          position: absolute;
          right: 97px;
          z-index: 1;
          margin-top: 10px;
        }

        .panel-disabled {

          .view-lines,
          .margin {
            background: #efefef;
          }
        }

        .editor-tag {
          z-index: 9;
          position: absolute;
          right: 22px;
          height: 20px;
          width: 70px;
          font-size: 11px;
          font-weight: 600;
          margin-top: 10px;
          background: #dfdfdf;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
        }
      }

      .monaco-editor .suggest-widget .monaco-list .monaco-list-row>.contents>.main {
        padding: 0;
        margin: 0;
      }
    }
  }
}

@keyframes refresh-bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}

/* Knowledge Base */
.knowledge {
  .download-info {
    font-style: italic;
    font-size: 13px;
    color: #666;
    line-height: 35px;
  }

  .status-switch {
    .switch {
      height: 19px;
      width: 36px;
      margin-bottom: 0;

      .switch-label {
        border: 1px solid #999;
      }

      .switch-handle {
        height: 15px;
        width: 15px;
        border: 1px solid #999;
      }
    }
  }

  .collapse-box {

    .phrases,
    .responses {
      margin-bottom: 10px;

      .help {
        margin-left: 10px;
        background: #999;
        color: #fff;
        border-radius: 50px;
        padding: 1px 5px;
      }

      .arrow {
        float: right;
        font-size: 16px;
        cursor: pointer;
        font-weight: 600;
      }
    }

    input {
      border-radius: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
      background: #fff;
    }

    input:focus {
      border: 1px solid #c2cfd6;
      box-shadow: none;
    }

    .input-group-text {
      background: #fff;
    }

    .phase-input {
      width: 100%;

      .form-control:disabled {
        border-top: 1px solid #c2cfd6 !important;
        border-bottom: 1px solid #c2cfd6 !important;
        color: #3e515b;
      }

      .disbled {
        border-color: #e6e6e6;
      }
    }

    .quote {
      background: #fff;
      border-right: 0;
      border-left: 2px solid #20a8d8;

      span {
        background: #fff;
      }
    }

    .plus-icon {
      border: 1px solid #c2cfd6;
      padding: 4px 7px;
      border-left: 0 !important;
    }

    button {
      margin-top: 15px;
    }

    .list-box {
      margin-top: 10px;

      input {
        border-left: 0 !important;
        border-right: 0 !important;
      }

      .delete {
        border-left: 0;
        cursor: pointer;
        width: 55px;
        text-align: right;
      }

      .quote {
        background: #fff;
        border-right: 0;
        border-left: 2px solid #20a8d8;

        span {
          background: #fff;
        }
      }
    }
  }

  .aiAgents {
    .agent-list {
      .active {
        border: 1px solid rgb(143, 142, 142) !important;
      }

      .agent-box {
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 10px;
        //background: #fbfbfb;
        text-align: center;
        margin-bottom: 20px;

        background: rgb(241, 241, 241);
        background: -moz-linear-gradient(left, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
        background: -webkit-linear-gradient(left, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
        background: linear-gradient(to right, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f1f1', endColorstr='#fbfbfb', GradientType=1);

        .img-box {
          border-right: 1px dotted #ccc;
          min-height: 80px;
          margin-right: 15px;
          margin-left: -10px;

          img {
            width: 70px;
            height: 70px;
            border: 1px solid #eee;
            border-radius: 150px;
            -webkit-box-shadow: 0px 3px 0px 4px #d4d4d4;
            -moz-box-shadow: 0px 3px 0px 4px #d4d4d4;
            box-shadow: 0px 3px 0px 4px #d4d4d4;
          }
        }

        .main-info-box {
          text-align: left;

          .action {
            text-align: right;

            i {
              padding: 2px 5px;
              cursor: pointer;
            }
          }

          .name {
            margin-top: 15px;
            font-size: 16px;
            font-weight: 600;
            color: #555555;
          }

          .channel {
            font-weight: 600;
            font-size: 13px;
            color: #666;
          }
        }

        .info-box {
          margin-top: 20px;
          text-align: left;
          font-size: 13px;

          .other {
            text-align: left;
            color: #555;

            b {
              margin-right: 10px;
              width: 95px;
              display: block;
              float: left;
            }

            span {
              display: block;
              float: left;
              width: calc(100% - 105px);
              font-size: 11px;

              i {
                float: right;
                margin-left: 5px;
                font-weight: bold;
                color: #333;
              }
            }
          }

          .footer {
            margin-top: 10px;
            border-top: 1px dotted #ccc;
            padding: 10px 0 5px 0;
            line-height: 40px;

            label.switch {
              margin: 10px 0 0 0;
            }

            .score-box {
              width: 40px;
              height: 40px;
              padding: 5px 0 0 0;
              font-size: 13px;
              font-weight: 600;
              display: inline-block;
              text-align: center;
              vertical-align: middle;
              background-color: #8ec553;
              border: 1px solid #589219;
              border-radius: 20px;
              line-height: normal;

              p {
                font-weight: 100;
                font-size: 8px;
              }
            }

            i.fa.fa-comments {
              font-size: 25px;
              cursor: pointer;
              margin-top: 5px;
              color: #444;
            }

            .proactive {
              line-height: normal;
              margin-top: 4px;
              font-size: 13px;
              color: #444;

              b {
                display: block;
              }
            }
          }
        }
      }

      .ai-agent-box {
        background: #fbfbfb;
        text-align: center;
        margin-bottom: 20px;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

        .info-box {
          background: #46a3cd;
          min-height: 150px;
          padding-top: 20px;
          color: #fff;

          .name {
            font-size: 22px;
            font-weight: 600;
          }
        }

        .img-box {
          margin-top: -52px;
          height: 104px;

          img {
            width: 100px;
            height: 100px;
            background: #fff;
            border: 2px solid #fff;
            border-radius: 50px;
          }
        }

        .other-info {
          padding: 20px;
          text-align: left;

          .title {
            font-weight: 600;
          }

          .desc {
            margin-bottom: 10px;
            color: #666;
          }

          .score-box {
            width: 47%;
            height: 40px;
            padding: 5px 0 0;
            font-size: 13px;
            font-weight: 600;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            background-color: #8ec553;
            border: 1px solid #589219;
            border-radius: 4px;
            line-height: normal;
          }

          i.fa.fa-comments {
            font-size: 25px;
            cursor: pointer;
            color: #444;
            background: #eee;
            width: 47%;
            float: right;
            text-align: center;
            border: 1px solid #999;
            border-radius: 4px;
            height: 40px;
            padding: 5px 0;
          }
        }
      }
    }
  }

  .business {
    .busTextArea {
      border: 1px solid #c2cfd6 !important;
    }
  }

  .skillManagement {
    .box {
      border: 1px solid #ddd;
      padding: 10px;
      background: rgba(237, 241, 244, 0.5);
      border-radius: 3px;
      height: 100%;

      .img-box {
        margin: 0 0 10px 0;

        img {
          border-radius: 3px;
        }
      }

      .detailBox {
        height: 40px;
      }

      .name {
        font-size: 14px;
        font-weight: 600;
      }

      .title {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: -5px;
      }

      .desc {
        color: #999;
        font-size: 13px;
        font-style: italic;
        line-height: normal;
      }

      .loadBtn {
        color: #20a8d8;
        cursor: pointer;
        font-size: 25px;
      }
    }

    .filterList:hover,
    .filterActiveSkill {
      color: #20a8d8;
      text-decoration: underline;
      background-color: transparent;
      cursor: pointer;
    }

    .box:hover {
      background: #edf1f4;
    }
  }
}

/*start Agent chat styles*/
.agentChat {
  height: 100%;

  .video-left-outer {
    .video-leftside {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #48a3cd;
      padding: 15px;
      border-bottom: 1px solid #eee;
      height: 120px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

      .ol-active {
        width: 10px;
        height: 10px;
        background: #1ad6c2;
        border-radius: 50px;
        display: inline-block;
        vertical-align: unset;
        margin-left: 5px;
      }

      .ol-active.ol-offline {
        background-color: #999;
      }

      a {
        padding: 0 3px 0 0;
        color: #fff;
      }

      .left-profile-userpic {
        img {
          width: 80px;
          height: 80px;
          border-radius: 50em;
        }

        .v-img {
          width: 80px;
          height: 80px;
          border-radius: 50em;
          text-align: center;
          vertical-align: middle;
          display: table-cell;
          font-size: 36px;
          border: 4px solid #cfe7f2;
          color: #cfe7f2;
        }
      }

      .video-social {
        margin-top: 15px;

        .call-on {
          background: #f8936e;
          border-color: #f8936e;
        }

        .btn-video-call {
          background: #fff;
          color: #20a8d8;
          border: 0;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .btn-video-call:hover {
          background: #f8fcfc;
        }
      }

      .chat-cb {
        margin-left: 10px;
        display: inline-block;
        top: 2px;
        font-size: 16px;
        margin-bottom: 0;
        padding-left: 25px;

        .checkmark {
          width: 20px;
          height: 20px;
          top: 3px;
        }

        .checkmark:after {
          left: 7px;
          top: 3px;
        }
      }
    }

    .profiletab {
      .nav-tabs {
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        border-bottom-width: 1px;
        justify-content: space-between;

        .nav-item {
          flex: 1 1 auto !important;

          .nav-link.active {
            color: #fff;
            background: #1b8eb7;
            height: 100%;
            border: 0;
          }
        }
      }
    }
  }

  .pv-outer {
    .video-leftside {
      background-color: #fff;
      height: auto;
    }
  }

  .video-subtab li a {
    padding: 0px 14px;
    text-decoration: none;
    // margin-right: 20px;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  .pv-icon {
    background: #f2f5f9;
    border: 1px solid #e2e2e3;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .small {
    height: 22px;
    width: 22px;
  }

  h5,
  .h5 {
    font-size: 16px;
    font-weight: 600;
  }

  // a {
  //   color: #777;
  //   text-decoration: none;
  // }

  .messages a {
    color: #0a2e3a;
    text-decoration: underline;
  }

  a:hover {
    color: #167495;
  }

  .profiletab {
    .nav-tabs {
      color: #000;
      background: #fff;
      border-bottom: 2px solid #a4b7c1;
      justify-content: space-around;

      .nav-link {
        border-bottom: 1px solid #fff;
        padding: 0.5rem 5px !important;
      }

      .nav-link.active {
        border-color: #fff;
        color: #48a3cd;
      }

      .nav-link:hover {
        color: #48a3cd;
        border-color: #fff;
        cursor: pointer;
      }

      .nav-item {
        margin-bottom: 0;
        text-align: center;
        font-size: 13px;
      }
    }

    .tab-box {
      position: relative;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .tab-content {
      margin-top: -1px;
      background: none;
      border: none;
      overflow-y: auto;

      .tab-pane {
        padding: 0px;
        border: none;

        .row {
          margin-right: 0px;

          .col {
            padding-right: 0px;

            // form {
            //   padding: 12px;
            // }
          }
        }

        .form-group {
          margin-bottom: 7px;

          label {
            display: inline-block;
            margin-bottom: 2px;
            font-size: 13px;
            word-break: break-all;
          }

          .form-control {
            display: block;
            width: 100%;
            padding: 3px 0;
            font-size: 13px;
            line-height: 1.5;
            color: #3e515b;
            background-color: #fff;
            background-clip: padding-box;
            // border-radius: 0;
            transition:
              border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;
            border: 0;
            border-bottom: 1px solid #c2cfd6;
          }

          .form-control:focus {
            border: 0;
            outline: 0;
            border-bottom: 1px solid #48a3cd;
            box-shadow: none;
          }

          .btn-submit {
            font-size: 13px;
            border-radius: 18px !important;
          }
        }

        .display-profile {
          .pr-separator {
            border-top: 1px solid #ddd;
            margin: 0 0 15px 0;
          }

          .curron-box {
            padding: 12px;

            .current-link {
              display: flex;
              justify-content: flex-start;
              margin: 5px 0 0 0;

              span {
                height: 30px;
                display: inline-block;
                width: 30px;
                text-align: center;
                padding-top: 3px;

                img {
                  width: 17px;
                }
              }

              span.input {
                height: unset;
                margin-left: 0px;
                margin-right: 0px;
                width: 85%;
                border: 0;
                text-indent: 3px;
                font-size: 13px;
                overflow: hidden !important;
                text-overflow: ellipsis;
                padding-top: 5px;
                word-wrap: break-word;
                text-align: unset;
              }
            }
          }
        }

        .deatils-box {
          padding: 10px 16px 10px 16px;
          background: #48a3cd;

          h5 {
            font-size: 14px;
            margin-bottom: 0;
            color: #fff;
          }
        }

        .browser-box {
          padding: 0;

          ul {
            padding-left: 0;

            li {
              margin-top: 5px;
              font-size: 13px;
              list-style-type: none;

              a {
                font-size: 13px;
              }

              img {
                margin-right: 10px;
              }

              p {
                vertical-align: middle;
                display: inline-block;
                width: 100%;
                overflow: hidden;
                margin-bottom: 0;
              }
            }
          }
        }

        .hist-box {
          margin: 0 0 10px 0;

          .hist-inbox {
            cursor: pointer;
            display: flex;
            align-items: center;
            border: 1px solid #ddd;
            padding: 5px 5px 5px 10px;
            // margin: 10px;
            border-radius: 20px;
            position: relative;

            p {
              margin-bottom: 0;
              max-width: calc(100% - 30px);
              display: inline;
              font-size: 13px;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
              color: #48a3cd;
            }

            span {
              background: #48a3cd;
              border-radius: 50px;
              min-width: 30px;
              height: 20px;
              text-align: center;
              color: #fff;
              display: block;
              font-size: 12px;
              position: absolute;
              right: 8px;
              padding: 0 3px;
            }
          }

          .hist-inbox:hover {
            background: #f5f5f5;
            border-color: #48a3cd;
          }
        }

        .hist-box.active {
          .hist-inbox {
            border-color: #48a3cd;
            background: #f5f5f5;
          }
        }

        .note-box {
          display: block;
          width: 100%;
          margin: 10px;
          padding: 10px 10px 5px 10px;
          -webkit-box-shadow: 1px 1px 4px 0px #ddd;
          -moz-box-shadow: 1px 1px 4px 0px #ddd;
          box-shadow: 1px 1px 4px 0px #ddd;

          .note {
            font-weight: 600;
            margin-bottom: 10px;
            color: #666;
          }

          .tag {
            font-size: 13px;
            color: #48a3cd;
          }

          .dt {
            text-align: right;
            font-size: 10px;
            font-style: italic;
            color: #999;
            line-height: 25px;
          }
        }
      }
    }
  }

  .white-bg {
    position: relative;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    height: 100%;

    #videoiFrame {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 999;
      border-radius: 3px;
      border: 1px solid #ccc;
      background: #222;
      overflow: hidden;
    }

    .dialer-window {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 999;
      border-radius: 10px;
      border: 1px solid #ccc;
      overflow: hidden;
    }

    #frame .content {
      background: none;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      -o-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }

    .msg-tag-bg {
      color: #fff;
      background: #48a3cd;
      border-radius: 5px 5px 0 0;
      border-bottom: solid 1px #eaebeb;
      padding: 15px 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .msg-user-dill {
        display: table;
        width: 100%;

        .msg-user-cell {
          display: table-cell;
          width: 40px;
          min-width: 40px;
          height: 40px;
          min-height: 40px;
          vertical-align: middle;

          img {
            min-width: 40px;
            height: 40px;
            border-radius: 100%;
          }
        }
      }

      .msg-user-d-cell {
        display: table-cell;
        vertical-align: middle;
        padding-left: 10px;

        h4 {
          color: #fff;
          font-size: 16px;
          margin: 0px;
          font-weight: 600;
        }

        a {
          display: block;
          text-decoration: underline;
          color: #aab3bb;
          font-size: 12px;
        }

        .sub-title {
          color: #fff;
          font-size: 13px;
        }
      }

      .chat-conversation-search-bg {
        width: 170px;
        display: inline-block;
        margin-right: 5px;
      }

      .chat-conversation-search-bg.sm {
        width: 110px;
      }

      .chat-stylish-input-group {
        position: relative;
      }

      .chat-stylish-input-group input {
        // border-bottom: 1px solid #cdcdcd !important;
        padding: 6px 42px 6px 22px;
        background: none;
        width: 100%;
        border: none;
        font-size: 13px;
        border-radius: 0 !important;
      }

      .chat-input-group-addon {
        position: absolute;
        top: 3px;
        left: -3px;
      }

      // .chat-input-group-addon-right {
      //   position: absolute;
      //   top: 3px;
      //   right: 3px;
      // }

      .chat-input-group-addon button {
        background: transparent;
        border: none;
        color: #707070;
      }

      .search-bar:focus {
        outline: 0;
        // border-bottom: 1px solid #48a3cd !important;
      }

      .user-connectivity {
        display: inline-block;
        list-style: none;
        padding: 0px;
        margin: 0px;

        .u-forms {
          position: relative;

          .dropdown-menu {
            right: unset !important;
            top: 30px !important;
            left: 30px !important;
            border-radius: 4px;
            overflow: hidden;
          }
        }

        li {
          margin: 5px 2px 0;
          display: inline-block;
          width: 28px;
          height: 28px;
          background: #47a2cc;
          border-radius: 100%;
          text-align: center;

          a {
            color: #fff;
            padding: 3px 0 0 0;
            display: block;
            cursor: pointer;
          }
        }
      }
    }

    .conversation-bg {
      background: #48a3cd;
      display: table;
      padding: 10px;
      width: 100%;

      .chat_filter {
        margin-left: 20px;
        background-color: rgba(41, 47, 43, 0.3);
        border-radius: 20px;
      }

      h3 {
        color: #fff;
        font-size: 16px;
        margin: 4px 0 0 0;
        float: left;
      }

      .search-conver {
        list-style: none;
        padding: 0px;
        margin: 0px;
        float: right;

        li {
          display: inline-block;

          a {
            color: #fff;
            font-size: 18px;
            padding: 0px 5px;
          }
        }
      }

      .dropdown-menu-right {
        position: relative;

        .dropdown-menu {
          -ms-transform: none !important;
          -webkit-transform: none !important;
          transform: none !important;
          right: 0;
          left: auto !important;
          top: 20px !important;
        }

        .dropdown-menu::before {
          content: "\A";
          border-style: solid;
          border-width: 0 5px 7px 5px;
          border-color: transparent transparent #fff transparent;
          position: absolute;
          top: -7px;
          right: 0px;
        }
      }
    }

    .conversation-search-bg {
      background: #eef3f5;
      padding: 10px;

      .stylish-input-group {
        position: relative;

        .input-group-addon {
          position: absolute;
          top: 5px;
          left: 5px;

          button {
            background: rgba(0, 0, 0, 0);
            border: medium none;
            padding: 0;
            color: #707070;
          }
        }

        input {
          padding: 6px 10px 6px 25px;
          background: none;
          width: 100%;
          border: 0;
          border-bottom: 1px solid #cdcdcd;
          font-size: 13px;
        }

        input:focus {
          outline: 0;
          border-bottom: 1px solid #48a3cd;
        }
      }
    }

    .visitor-tabs {
      background: #eef3f5;

      .v-tab {
        width: 50%;
        display: inline-block;
        text-align: center;
        color: #666;
        padding: 10px 10px 8px 10px;
        border-bottom: 2px solid #eef3f5;
        cursor: pointer;

        .cnt {
          margin-left: 5px;
        }
      }

      .v-tab.active {
        border-bottom: 2px solid #48a3cd;
        color: #48a3cd;
      }
    }

    .inbox_chat {
      position: relative;
      height: 100%;
      overflow-y: auto;
      min-height: 251px;

      .chat_list {
        margin: 0;
        padding: 10px;
        cursor: pointer;

        .channel-image {
          max-width: 18px;
          max-height: 18px;
          float: right;
          margin-top: 5px;
        }

        .agent-badge {
          margin: 6px 6px 0 0;
          background: #f8936e;
          color: #fff !important;
          padding: 2px 7px 4px;
          font-weight: normal;
          max-width: 92px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;

          img {
            height: 10px;
            margin: -1px 3px -1px -4px;
          }
        }

        .badge.assigned {
          background: green;
        }

        // .agent-image {
        //   width: 21px;
        //   height: 21px;
        //   float: right;
        //   margin-top: 5px;
        //   margin-right: 5px;
        //   border-radius: 50%;
        //   border: 1px solid #999;
        // }

        .chat_people {
          overflow: hidden;
          clear: both;
          display: flex;

          .chat_img {
            float: left;
            min-width: 35px;
            width: 8%;
            vertical-align: middle;

            img {
              width: 34px;
              height: 34px;
              border-radius: 50em;
            }

            .v-img {
              width: 34px;
              height: 34px;
              border-radius: 50em;
              text-align: center;
              vertical-align: middle;
              display: table-cell;
              font-size: 16px;
              border: 1px solid #999;
            }
          }

          .chat_ib {
            float: left;
            padding: 0 0 0 10px;
            width: 92%;
            vertical-align: middle;

            h5 {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #464646;
              margin: 0 0 4px 0;
              font-weight: 600;
              width: 100%;

              .v-title {
                max-width: calc(100% - 45px);
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              .v-unread-msg {
                margin: 0 0 0 4px;
                font-size: 10px;
                background: #dce7ed;
                line-height: 13px;
              }
            }

            .dt {
              display: block;
              font-size: 10px;
              font-weight: 400;
              color: #716f6f;
            }
          }
        }
      }
    }
  }

  .msg-user-cell {
    img {
      height: 40px;
      width: 40px;
    }
  }

  .browser-box {
    padding: 20px;

    .frame-header {
      font-size: 13px;

      span {
        font-weight: 600;
        margin-right: 5px;
        font-size: 14px;
      }

      input {
        width: 500px;
        padding-left: 3px;
        padding-right: 3px;
      }

      input:focus {
        outline: 0;
      }

      a.go {
        border: 1px solid #999;
        border-left: 0;
        background: #eee;
        display: inline-block;
        width: 40px;
        text-align: center;
        line-height: 23px;
      }

      button.btn-action {
        color: #167495;
        border: 1px solid #167495;
        padding: 2px 15px;
        margin-left: 10px;
        font-size: 13px;
        background: #fff;

        p {
          font-size: 13px;
          font-weight: normal;
          margin: 0;
        }
      }

      button.btn-action:hover {
        background: #20a8d8;
        color: #fff;
        border: 1px solid #167495;
      }

      button.btn-action:focus {
        outline: 0;
        box-shadow: none;
      }

      .visitor-box {
        border-right: 1px solid #999;
        margin-right: 10px;
        padding-right: 5px;
        float: right;

        img {
          height: 25px;
          margin-right: 5px;
          cursor: pointer;
        }

        .visitorname {
          border: 1px solid #ccc;
          padding: 7px 10px;
          border-radius: 0 0 3px 3px;
          position: absolute;
          right: 74px;
          top: 50px;
          font-size: 13px;
          font-weight: normal;
          background: #eee;
          display: none;
        }

        .visitorname:before {
          content: " \25B4";
          font-weight: 600;
          position: absolute;
          top: -14px;
          right: 0px;
          color: #aaa;
        }
      }

      .visitor-box:hover {
        .visitorname {
          display: block;
        }
      }

      button.selected {
        background: #20a8d8;
        color: #fff;
        border: 1px solid #167495;
      }
    }

    #browserFrame {
      margin-top: 15px;
      border: 1px solid #ddd;
      border-radius: 3px;
    }
  }

  .prescription {
    padding: 20px;

    .all-med {
      width: 100%;
      display: block;
      height: 150px;
    }

    .selected-med {
      border-top: 1px solid #ddd;
      margin: 20px 0;

      .med-box {
        margin-top: 10px;
        border: 1px solid #ddd;

        .title-box {
          background-color: #f5f5f5;
          padding: 10px 7px 15px 7px;

          label {
            font-weight: 600;
            margin-bottom: 0;
            width: calc(100% - 80px);
            padding-top: 3px;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
          }

          button {
            float: right;
            color: #48a3cd;
          }
        }

        .collapse {
          padding: 30px 10px 10px 10px;
        }

        .med-options {
          .opt-item {
            border: 1px solid #e5e5e5;
            border-radius: 4px;
            padding: 20px 10px;
            position: relative;
            margin-bottom: 20px;

            .select1 {
              width: 70px;
              float: left;
            }

            .select2 {
              width: calc(100% - 150px);
              float: left;
            }

            .select-title {
              float: left;
              margin-left: 10px;
              margin-right: 5px;
              font-size: 13px;
              padding-top: 4px;
              width: 60px;
              text-align: right;
            }

            .med-opt-title {
              position: absolute;
              top: -17px;
              background-color: #fff;
              padding: 5px;
              font-weight: 600;
            }
          }

          .t1 {
            .select1 {
              margin-right: 5px;
            }

            .dp-box {
              width: 108px;
              float: left;

              input {
                height: 31px;
              }
            }
          }
        }
      }
    }
  }

  .l-chat-no-visitor {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-size: 20px;
    color: #999;
  }

  .support-chat-bot {
    width: 100%;
    height: 100%;
    border: 0;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  }
}

/* popup to assign agent to visitor */
.chat-agent-list {
  color: #666;

  .agent {
    border-left: 2px solid #ddd;
    padding: 10px;
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }

  .agent:hover {
    border-left: 2px solid #48a3cd;
    color: #000;
  }

  .agent.active {
    border-left: 2px solid #48a3cd;
    color: #48a3cd;
  }

  .ol-active {
    width: 10px;
    height: 10px;
    background: #1ad6c2;
    border-radius: 50px;
    display: inline-block;
    vertical-align: unset;
    margin-left: 5px;
  }
}

.agentPreview {
  .video-left-outer {
    .video-leftside {
      justify-content: left;
      border-bottom: 2px solid #a4b7c1;

      h5 {
        margin-bottom: 0;
      }
    }

    .browser-box {
      ul {
        margin-top: 10px;

        li {
          padding-left: 10px;

          i {
            cursor: pointer;
            margin-right: 7px;
            color: #48a3cd;
          }
        }
      }
    }
  }

  .chat-preview {
    min-height: 450px;
    border: 0;
  }
}

.chat-widget {
  border: 0;
  background: #fff;
  position: fixed !important;
  margin: 20px;
  border-radius: 10px;
  width: calc(100% - 40px);

  #videoiFrame {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 0;
    background: #222;
  }

  #bookAppointment {
    position: relative;
    text-align: center;

    #appointmentiFrame {
      border: 1px solid #ccc;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.chat-box {
  #frame .content .messages {
    // min-height: calc(100% - 123px);
    // max-height: calc(100% - 123px);
  }

  .capture-play {
    position: absolute;
    top: 0;
    z-index: 99;
    // min-height: calc(100% - 131px);
    // max-height: calc(100% - 131px);
    border: 1px solid #ddd;
    border-radius: 3px;

    .slick-slide {
      padding-right: unset !important;
    }

    .close {
      position: absolute;
      z-index: 1000;
      right: 15px;
      top: 15px;
    }

    .title {
      position: absolute;
      z-index: 1;
      font-weight: 600;
      top: 15px;
      left: 15px;
    }
  }
}

.chat-dnd {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;

  .chat-dnd-header {
    background: #48a3cd;
    display: flex;
    justify-content: center;
    padding: 9px;

    span {
      font-size: 16px;
      color: #fff;
      margin-top: 5px;
    }

    i {
      position: absolute;
      right: 15px;
      padding: 5px;
      font-size: 20px;
      margin-top: 2px;
      color: #fff;
    }
  }

  .od-preview-box {
    height: calc(100% - 46px);
    padding: 10px;
    width: 100%;
    background: #ecf7fd;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .od-preview-status {
      width: 100%;
      text-align: center;
      top: 60px;
      left: 5px;
    }

    .dzu-dropzone {
      height: 100%;
      // min-height: 100% !important;
    }
  }

  .caption-box {
    background: transparent;
    border: none;
    border-bottom: 1px solid #000000;
    border-radius: unset !important;
    width: 100%;
    margin: 0 8px 0 20px;
  }

  .caption-wrapper {
    width: calc(100% - 50px);
    margin-top: -50px;
  }

  .dzu-submitButtonContainer {
    // display: flex;
    // justify-content: flex-end;
    // width: 40px !important;
    // height: 40px !important;
    // text-align: revert;
    // float: right;
    margin: 0;

    .dzu-submitButton {
      color: #48a3cd;
      border-radius: 25px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-top: 12px;
      background: transparent;
      font-size: 16px;
    }

    .dzu-submitButton:disabled {
      display: none;
    }

    .dzu-submitButton:focus {
      outline: none;
    }
  }

  .dzu-previewContainer {
    // height: calc(-250px + 100vh);
    height: 100%;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px;

    .dzu-previewFileName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: inherit;
      text-align: center;
    }

    .dzu-previewStatusContainer {
      margin-top: 20px;
    }

    .dzu-previewImage {
      max-height: 150px;
      max-width: 300px;
      margin-bottom: 20px;
    }
  }
}

/*end Agent chat styles*/
.products,
.profile,
.knowledge,
.engagement {
  .form-group {
    label {
      margin-top: 6px;
    }
  }
}

$engagementsUrl: url(../img/info/engagements.png) no-repeat center center / cover;
$scoringUrl: url(../img/info/scoring.png) no-repeat center center / cover;
$routingUrl: url(../img/info/routing.png) no-repeat center center / cover;
$segmentationUrl: url(../img/info/segmentation.png) no-repeat center center / cover;
$dataExtractionUrl: url(../img/info/data_extraction.png) no-repeat center center / cover;
$departmentUrl: url(../img/info/department.png) no-repeat center center / cover;

$engagementsClr: #e39794;
$scoringClr: #f5c244;
$routingClr: #9fcfe2;
$segmentationClr: #98dc6d;
$dataExtractionClr: #f5c244;
$departmentClr: #9fcfe2;

/* Engagement */
.engagement {
  .row-disable {
    .react-grid-Cell {
      background-color: #ddd !important;
    }

    .eng-link,
    .eng-graph {
      color: #000 !important;
      background-color: #c1c1c1 !important;
    }
  }

  .info-tab {
    .card {
      box-shadow: 0px 0px 70px 5px #ddd;
      border: none;
      min-height: 280px;
    }

    .card-body {
      padding: 0;
    }

    .box-bg {
      display: flex;
      align-items: center;
      padding: 40px;
      cursor: pointer;
    }

    .box-bg::before {
      content: "";
      position: absolute;
      left: 30%;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .box-bg.engagements:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $engagementsClr;
      -moz-box-shadow: 0px 0px 5px 1px $engagementsClr;
      box-shadow: 0px 0px 5px 1px $engagementsClr;
    }

    .box-bg.scoring:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $scoringClr;
      -moz-box-shadow: 0px 0px 5px 1px $scoringClr;
      box-shadow: 0px 0px 5px 1px $scoringClr;
    }

    .box-bg.routing:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $routingClr;
      -moz-box-shadow: 0px 0px 5px 1px $routingClr;
      box-shadow: 0px 0px 5px 1px $routingClr;
    }

    .box-bg.segmentation:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $segmentationClr;
      -moz-box-shadow: 0px 0px 5px 1px $segmentationClr;
      box-shadow: 0px 0px 5px 1px $segmentationClr;
    }

    .box-bg.dataextraction:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $dataExtractionClr;
      -moz-box-shadow: 0px 0px 5px 1px $dataExtractionClr;
      box-shadow: 0px 0px 5px 1px $dataExtractionClr;
    }

    .box-bg.department:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $departmentClr;
      -moz-box-shadow: 0px 0px 5px 1px $departmentClr;
      box-shadow: 0px 0px 5px 1px $departmentClr;
    }

    .box-bg.engagements::before {
      background: $engagementsUrl;
    }

    .box-bg.scoring::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $scoringUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $scoringUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $scoringUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $scoringUrl;
      /* IE6-9 */
    }

    .box-bg.routing::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $routingUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $routingUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $routingUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $routingUrl;
      /* IE6-9 */
    }

    .box-bg.segmentation::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $segmentationUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $segmentationUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $segmentationUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $segmentationUrl;
      /* IE6-9 */
    }

    .box-bg.dataextraction::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $dataExtractionUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $dataExtractionUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $dataExtractionUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $dataExtractionUrl;
      /* IE6-9 */
    }

    .box-bg.department::before {
      background: $departmentUrl;
    }

    .box-bg {
      h2 {
        font-size: 25px;
        margin: 10px auto;
      }

      p {
        width: 100%;
      }

      img {
        max-width: 70px;
        width: 100%;
      }
    }
  }

  .routing {
    .condition-box {
      padding: 20px 0 0;
      border: 0;
      margin-bottom: 0;

      .sec {
        float: left;
        margin: 0 2px;

        .css-16pqwjk-indicatorContainer {
          padding: 8px 2px;
        }

        .css-1thkkgx-indicatorContainer {
          padding: 8px 2px;
        }

        input {
          font-size: 13px;
          line-height: 1rem;
        }
      }

      .sec-1 {
        width: calc(15% - 4px);
      }

      .sec-2 {
        width: calc(15% - 4px);
      }

      .sec-3 {
        width: calc(33% - 4px);
      }

      .sec-4 {
        width: calc(15% - 4px);
      }

      .sec-5 {
        width: calc(15% - 4px);
      }

      // .sec-6 {
      //   width: calc(10% - 4px);

      //   input {
      //     background-color: rgba(106, 199, 138, 0.3);
      //   }
      // }

      .sec-7 {
        width: calc(7% - 4px);
        line-height: 27px;
      }

      .ddl-anyall {
        width: 65px;
        display: initial;
        margin: 0 10px;
      }

      .rule-box {
        margin-top: 10px;
      }

      .scoring-input {
        height: 35px;
      }

      .react-tagsinput-input {
        min-height: 28px;
      }
    }

    .rc-slider-handle {
      width: 20px;
      height: 25px;
      background-color: #fff;
      margin-top: -10px;
      cursor: pointer;
      margin-left: -13px;
      border-width: 1px;
      border-radius: 4px;
    }

    .rc-slider-handle::after {
      content: "\2016";
      margin-left: 5px;
    }

    .lead-status {
      width: 100%;
      display: flex;
      margin-top: 40px;

      .box {
        width: 25%;
        margin: 5px;
        height: fit-content;
        border: 1px solid #ddd;
        border-radius: 4px;

        .label {
          float: left;
          display: flex;
          line-height: 40px;

          .color-box {
            width: 12px;
            height: 12px;
            display: inline-block;
            margin: 14px 15px;
            border-radius: 2px;
          }
        }

        .range {
          float: right;
          padding: 9px;
          background: #f0f3f5;
          height: 40px;
          border-left: 1px solid #ddd;
        }
      }
    }
  }

  .data-extraction {
    .datepicker-box {
      span {
        margin-right: 10px;
      }

      .rdt {
        display: inline-block;

        input {
          width: auto;
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }

        input[readonly] {
          background: none;
        }

        input:focus {
          outline: none !important;
          outline-offset: 0 !important;
          border-color: #c2cfd6;
          box-shadow: none;
        }
      }

      .fa-calendar {
        padding: 9px 9px 10px 9px;
        border: 1px solid #c2cfd6;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        border-left: 0;
        background: #c2cfd6;
        cursor: pointer;
      }
    }

    .engagement-list {
      width: auto;
      display: inline-block;
      margin-left: 10px;
      min-width: 200px;
    }

    .dropdown {
      button {
        i {
          color: #151b1e;
        }
      }
    }

    .col-filterbox {
      padding: 0;

      label {
        display: block;
        margin: 0;
        padding: 10px;
        cursor: pointer;
      }
    }

    .dropdown-menu-right {
      position: relative;

      .dropdown-menu {
        -ms-transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;
        right: 0;
        left: auto !important;
        top: 35px !important;
      }
    }
  }

  .live-pages {
    .ql-container {
      .ql-editor {
        min-height: 270px;
        max-height: 500px;
      }

      .ql-tooltip.ql-editing {
        left: 0 !important;
      }
    }
  }

  .my-engagements {
    .create-eng-box {
      .eng-steps {
        border: 0;
        // border: 1px solid #ddd;
        padding: 0px;
        // background: #f5f5f5;
        border-radius: 0;
        list-style: none;
        overflow: hidden;

        // margin-bottom: 30px;
        ul {
          border: 0;
          padding: 0;
          // background: #f5f5f5;
          border-radius: 0;
          list-style: none;
          overflow: hidden;

          li {
            margin-bottom: -1px;
            color: #bbb;
            text-decoration: none;
            position: relative;
            border: 0 !important;
            border-radius: 0;
            outline-style: none;
            background: #666565;

            a {
              color: #bbb;
              text-decoration: none;
              padding: 10px 0 10px 45px;
              position: relative;
              display: block;
              border: 0 !important;
              border-radius: 0;
              outline-style: none;
              background: #f5f5f5;
              // width: 165px;
              cursor: auto;
            }

            a:before {
              content: " ";
              display: block;
              width: 0;
              height: 0;
              border-top: 50px solid transparent;
              border-bottom: 50px solid transparent;
              border-left: 30px solid #ddd;
              position: absolute;
              top: 50%;
              margin-top: -50px;
              left: 100%;
              z-index: 1;
              margin-left: 1px;
            }

            a:after {
              content: " ";
              display: block;
              width: 0;
              height: 0;
              border-top: 50px solid transparent;
              border-bottom: 50px solid transparent;
              border-left: 30px solid #f5f5f5;
              position: absolute;
              top: 50%;
              margin-top: -50px;
              left: 100%;
              z-index: 2;
            }
          }

          li.done {
            a {
              border-color: #20a8d8 !important;
              color: #fff !important;
              background: #20a8d8 !important;
            }

            a:after {
              border-left: 30px solid #20a8d8;
            }
          }

          li.active {
            a {
              border-color: #167495 !important;
              color: #fff !important;
              background: #167495 !important;
            }

            a:after {
              border-left: 30px solid #167495;
            }
          }
        }
      }

      .eng-content {
        position: relative;
        padding: 30px 30px 20px 30px;

        // padding: 0 30px;
        // margin-bottom: 20px;
        .eng-type {
          color: #444;

          .head {
            margin-bottom: 20px;

            .title {
              font-size: 24px;
            }

            .desc {
              color: #999;
              font-size: 13px;

              i {
                color: #d8a200;
              }
            }
          }

          .box {
            cursor: pointer;
            border: 1px solid #ddd;
            padding: 20px 15px;
            height: 100%;
            border-radius: 3px;
            display: flex;

            .img-box {
              width: 60px;
              margin: 10px 20px 10px 10px;
            }

            .content {
              width: calc(100% - 90px);

              .name {
                font-size: 22px;
              }

              .desc {
                color: #999;
                font-size: 13px;
                font-style: italic;
                line-height: normal;
              }

              .highlight {
                margin-top: 5px;
                font-size: 13px;
                color: #c19100;
                line-height: normal;
              }
            }
          }

          .box:hover {
            // background: #edf1f4;
            background: rgba(237, 241, 244, 0.5);
          }

          .listBox {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .row-flex {
            display: flex;
            flex-wrap: wrap;
          }

          [class*="col-"] {
            margin-bottom: 30px;
          }
        }

        .channel {
          .search-box {
            input {
              outline: 0;
              width: 270px;
              border-radius: 4px;
              border: 1px solid #ddd;
              padding: 5px 30px 5px 10px;
              color: #666;
              margin-left: 10px;
            }

            i {
              margin-left: -23px;
              color: #666;
            }

            .selected {
              margin-left: 25px;
              border-left: 2px dotted #ddd;
              padding: 4px 0 4px 10px;
              display: inline-block;
              font-weight: 600;
              color: #666;

              b {
                color: #4aa8d8;
              }

              img {
                max-width: 20px;
                max-height: 20px;
                margin: -3px 3px 0 5px;
              }
            }
          }

          .channel-list {
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            overflow: hidden;

            .box {
              width: 165px;
              border: 1px solid #ddd;
              float: left;
              text-align: center;
              padding: 20px 0;
              border-radius: 5px;
              margin: 10px;
              overflow: hidden;

              img {
                min-width: 60px;
                min-height: 60px;
                max-height: 60px;
              }

              .title {
                max-width: 250px;
                margin: 15px 5px;
                font-size: 15px;
                font-weight: 600;
                color: #666;
              }

              button {
                background: #fff;
                border-radius: 3px;
                padding: 5px 30px;
                font-size: 13px;
              }

              button.active {
                background: #20a8d8;
                color: #fff;
              }

              button:hover {
                background: #20a8d8;
                color: #fff;
              }

              button:focus {
                outline: 0;
                box-shadow: none;
              }
            }
          }

          .list-notfound {
            padding-left: 10px;
          }
        }

        .details {
          .channel {
            width: 165px;
            border: 1px solid #ddd;
            text-align: center;
            padding-bottom: 10px;
            border-radius: 5px;
            margin: 0 auto;

            .head {
              background: #f5f5f5;
              border-bottom: 1px solid #ddd;
              padding: 5px;
              margin-bottom: 25px;
              color: #4aa8d8;
              font-weight: 600;
            }

            img {
              width: 60px;
            }

            .title {
              margin: 15px 5px 0 5px;
              font-size: 15px;
              font-weight: 600;
              color: #666;
            }

            .cat {
              margin-bottom: 15px;
              font-size: 13px;
              font-style: italic;
              color: #999;
            }
          }

          .page-title {
            font-size: 20px;
          }

          .page-subtitle {
            color: #666;
            line-height: 25px;
            margin-bottom: 20px;
          }
        }

        .options {
          .input-error {
            margin-bottom: 0px !important;

            input {
              border-color: red;
            }

            .css-vj8t7z {
              border-color: red;
            }
          }

          .err-text {
            color: red;
            font-size: 12px;
          }

          .title {
            font-size: 20px;
          }

          .page-subtitle {
            color: #666;
            line-height: 25px;
            margin-bottom: 20px;
          }

          .opt-list {
            padding: 0 20px;

            .checkbox-style {
              font-size: 14px;
              padding-left: 30px;

              .checkmark {
                height: 20px;
                width: 20px;
              }

              .checkmark:after {
                left: 7px;
                top: 3px;
              }
            }
          }

          .landing-url {
            padding: 0 20px;

            p {
              margin-bottom: 3px;
              font-weight: 600;
            }

            input {
              border-radius: 4px;
            }

            .btn-browse {
              border: 1px solid #c2cfd6;
              border-left: 0;
              border-radius: 0 4px 4px 0;
              background-color: #ccc;
              color: black;
              height: 35px;
              width: 72px;
              margin-left: -2px;
            }
          }

          .eng-livepage-preview {
            .title {
              font-weight: 600;
              margin-bottom: 5px;
            }

            .editor {
              border: 1px solid #ddd;
              border-radius: 3px;
              padding: 10px;
              -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
              -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
              box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
            }
          }

          .landing-content {
            padding: 0 20px;

            .ql-container {
              .ql-editor {
                min-height: 270px;
                max-height: 500px;
              }

              .ql-tooltip.ql-editing {
                left: 0 !important;
              }
            }
          }

          .option-box {
            padding: 0 20px;
            margin-top: 5px;

            .url {
              padding: 0 20px;

              p {
                margin-bottom: 3px;
                font-weight: 600;
              }

              input {
                border-radius: 4px;
              }

              .input-box {
                float: left;
                width: calc(100% - 60px);
              }

              .btn-box {
                float: left;
                width: 50px;
                margin-left: 10px;
                line-height: 30px;
              }
            }

            .preview {
              font-size: 14px;
              cursor: pointer;
            }
          }

          .web-config-box {
            .lbl {
              text-align: right;
              margin-top: 5px;
            }

            .btn-browse {
              border: 1px solid #c2cfd6;
              border-left: 0;
              border-radius: 0 4px 4px 0;
              background-color: #ccc;
              color: black;
              height: 35px;
              width: 72px;
              margin-left: -2px;
            }
          }

          .option-box-add {
            cursor: pointer;
            padding: 15px;
            font-size: 15px;
            border-radius: 4px;
            margin-bottom: 20px;
            background-color: #f5f5f5;
            border: 1px solid #f5f5f5;
            -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
          }

          .option-box-add.no-record {
            text-align: center;
            padding: 70px;
            font-size: 18px;
          }

          .option-box-add:hover {
            color: #167495;
            border-color: #ddd;
          }

          .option-header {
            font-weight: bold;
            text-transform: uppercase;
            padding: 15px 15px 10px 15px;
            font-size: 15px;
            color: grey;
          }

          .option-box-view-outer {
            // padding: 20px;
            // border-radius: 4px;
            padding: 15px 15px 5px 15px;
            border: 1px solid #ddd;
            // background-color: #f5f5f5;
            // -webkit-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.25);
            // -moz-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.25);
            // box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.25);

            .option-box-view {
              // margin-bottom: 20px;
              margin-bottom: 15px;
              border-bottom: 1px solid #ddd;
              padding-bottom: 10px;

              .box {
                .item-lbl {
                  margin-bottom: 5px;
                }

                .item {
                  color: #666;
                  margin-bottom: 0;

                  img {
                    margin-right: 5px;
                    border-radius: 2px;
                    margin-top: -3px;
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }

            .option-box-view:last-child {
              border-bottom: 0;
              margin-bottom: 0;
              // padding-bottom: 0;
            }
          }
        }

        .email-content {
          iframe {
            width: 100%;
            border: 1px solid #ddd;
            min-height: 600px;
          }
        }

        .setup {
          max-width: 872px;
          margin: 0 auto;
          width: 75%;
          margin-top: 5px;
          min-height: calc(100vh - 230px);

          .info {
            background: #f9f9f9;
            padding: 20px;
            margin-bottom: 30px;
            border: 1px dotted #999;

            .success {
              font-size: 16px;
              font-weight: 600;
              margin: 0 0 10px;
            }

            .desc {
              color: #666;
              margin-bottom: 20px;
            }

            .url-box {
              b {
                color: #444;
              }

              input {
                width: calc(100% - 160px);
                float: left;
                border: 1px solid #ddd;
                height: 35px;
                background: #eee;
                outline: none;
                box-shadow: none;
                color: #666;
              }

              button {
                width: 160px;
                background: #167495;
                color: #fff;
                outline: none;
                box-shadow: none;
              }

              button:hover {
                background: #20a8d8;
              }
            }
          }

          .sideButtons {
            font-size: 16px;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            color: white;
          }

          .headingSetup {
            font-size: 25px;
            color: rgb(55, 64, 103);
          }

          // img {
          //   border: 1px solid #ddd;
          //   -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
          //   -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
          //   box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
          // }
        }
      }

      .eng-content.first-tab {
        padding: 0 15px 20px 15px;
        // padding: 0px;
      }

      .bots {
        .head {
          margin-bottom: 20px;

          .title {
            font-weight: 600;
          }
        }

        .bot-list {
          padding: 0px;

          .bot-box {
            border: 1px solid #c2cfd6;
            margin: 10px;
            padding: 10px;
            height: 100%;
            border-radius: 3px;
            cursor: pointer;

            .img-box {
              width: 100px;
              float: left;
            }

            .bot-content {
              width: calc(100% - 120px);
              float: left;
              margin-left: 10px;
              color: #444;
              font-size: 13px;

              .title {
                font-weight: 600;
              }

              .desc {
                font-size: 11px;
                margin-top: 5px;
                margin-bottom: 5px;
              }

              .dt {
                font-style: italic;
                margin-bottom: 5px;
                font-size: 11px;
                color: #666;
              }

              .tag-list {
                i {
                  margin: 0 3px;
                  font-size: 20px;
                }
              }
            }
          }

          .active {
            border: 1px solid black;
          }
        }
      }

      .template-list {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        overflow: hidden;

        .active {
          border: 1px solid rgb(75, 168, 216) !important;
        }

        .template-box {
          border: 1px solid #ddd;
          float: left;
          text-align: center;
          position: relative;
          border-radius: 5px;
          margin: 10px;
          overflow: hidden;
          max-width: 200px;
          min-height: 284px;

          img {
            height: 250px;
            max-height: 250px;
            width: 200px;
            object-fit: cover;
            object-position: top;
          }

          .title {
            margin: 5px 5px;
            font-size: 15px;
            font-weight: 600;
            color: #666;
          }

          .fa-code {
            font-size: 70px;
            margin: 40px 0 10px;
          }

          .desc {
            color: #666;
            margin-top: 10px;
          }
        }

        .template-box-active:hover {
          cursor: pointer;
          border-color: rgb(75, 168, 216);
          background: #f5f5f5;
        }
      }

      .eng-contact {
        .head {
          margin-bottom: 20px;

          .title {
            font-weight: 600;
          }
        }

        .react-grid-Grid {
          background-color: transparent;
          border: none !important;
          width: 100%;

          .react-grid-HeaderCell {
            border: none !important;
            background-color: rgb(215, 224, 230) !important;
          }

          .react-grid-Cell {
            border: none !important;
          }

          .react-grid-Row:nth-child(odd) {
            background-color: rgb(242, 242, 242) !important;
          }
        }

        .react-tagsinput-input {
          width: 250px !important;
        }

        .schedule-date {
          .fa-calendar {
            position: absolute;
            top: 10px;
            right: 15px;
            cursor: pointer;
            padding: 10px;
          }

          .rdtPicker {
            padding-bottom: 25px;
          }
        }
      }

      .tab1-select {
        .prefix-select__menu-list {
          // max-height: 150px;
        }
      }
    }

    .nav {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
    }

    .nav-item:hover {
      cursor: pointer;
    }

    .tab-content {
      border: 0;
      border-top: 1px solid #ddd;
      margin-top: -1px;
      background: #fff;
    }

    .nav-tab .active {
      border-bottom: 3px solid #20a8d8;
      font-weight: 700;
    }
  }

  .agent-thumb {
    border-radius: 100%;
    border: 1px solid #ddd;
    width: 26px;
    height: 26px;
    margin-right: 5px;
  }

  .eng-graph {
    color: #fff !important;
    background: #4ba8d8;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    line-height: 22px;
    display: inline-block;
    padding: 1px 3px;
    font-size: 13px;
    margin: 0 auto !important;
  }

  .eng-link {
    background: #b5dfc4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    line-height: 22px;
    display: inline-block;
    padding: 0;
    font-size: 13px;
    margin: 0 auto !important;

    a {
      color: #151b1e;
      text-decoration: none;
      width: 25px;
      height: 25px;
      display: block;
      margin: 0;
      padding: 1px 0;
    }
  }

  .eng-link:hover {
    background: #85ca9d;
  }

  .react-tagsinput {
    background-color: #fff;
    border: 1px solid #c2cfd6;
    overflow: hidden;
    border-radius: 4px;
    padding-left: 5px;
  }

  .react-tagsinput--focused {
    border-color: none;
  }

  .react-tagsinput-tag {
    background-color: #edf1f4;
    border-radius: 2px;
    display: inline-block;
    margin: 5px 5px 5px 0;
    padding: 2px 5px;
    font-size: 12px;
    border: 1px solid #a4b7c1;
  }

  .react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
  }

  .react-tagsinput-tag a::before {
    content: " ×";
  }

  .react-tagsinput-input {
    background: transparent;
    border: 0px;
    // height: 22px;
    padding-top: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
    padding-bottom: 0px;
    margin-top: 1px;
    outline: none;
    width: 150px;
  }

  .score-box {
    width: 40px;
    height: 40px;
    padding: 5px 0 0 0;
    font-size: 13px;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    background-color: #8ec553;
    border: 1px solid #589219;
    border-radius: 20px;
    line-height: normal;

    p {
      font-weight: 100;
      font-size: 8px;
    }
  }

  .detailHead {
    width: 70% !important;
  }
}

.livepage-popup {
  max-width: 90%;
}

/* Automation */
.rules {
  .rulesForm {
    p {
      color: #888;
    }

    .rule-box {
      margin-top: 10px;
    }

    .s-ddl-style .prefix-select__control {
      min-width: auto !important;
    }
  }
}

/* Profile */
.profile {
  .profile-img-box {
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px solid #f0f3f5;

    .upload {
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      font-size: 24px;
      background: rgba(255, 255, 255, 0.9);
      border-width: 0px;
      border-style: initial;
      border-color: initial;
      border-image: initial;
    }
  }

  .profile-img {
    width: 150px;
    height: 150px;
    padding: 5px;
    border: 1px solid #f0f3f5;
    border-radius: 5px;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    line-height: 25px;
    word-wrap: break-word;
  }

  .role {
    color: #999;
  }

  .createdon {
    margin-top: 10px;
    padding-top: 5px;
    color: #666;
    border-top: 1px solid #f0f3f5;
    font-size: 13px;
  }

  .profile-btn {
    margin-top: 10px;
    width: 100%;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
  }

  .input-lbl {
    text-align: right;
    font-weight: 600;
  }

  .merchantid-box {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .login-sessions {
    .session-header {
      background: #f0f3f5;
      border: 1px solid #a4b7c1;
      padding: 10px 15px;
      border-bottom: 0;

      .btn-white {
        margin-top: -15px;
        border-radius: 4px;
      }
    }

    table {
      thead {
        background: rgba(164, 183, 193, 0.3);
      }
    }
  }

  .agentprofile-list {
    border: 0 !important;

    .profile-box {
      border-radius: 3px;
      padding: 10px;
      text-align: center;

      background: rgb(241, 241, 241);
      /* Old browsers */
      background: -moz-linear-gradient(left, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f1f1', endColorstr='#fbfbfb', GradientType=1);
      /* IE6-9 */

      .agent-img {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-height: 120px;
        }
      }

      .agentimg-box {
        border-right: 1px dotted #ccc;
        min-height: 80px;
        margin-right: 15px;
        margin-left: -10px;

        img {
          width: 70px;
          height: 70px;
          border: 1px solid #eee;
          border-radius: 150px;
          -webkit-box-shadow: 0px 3px 0px 4px #d4d4d4;
          -moz-box-shadow: 0px 3px 0px 4px #d4d4d4;
          box-shadow: 0px 3px 0px 4px #d4d4d4;
        }
      }

      .agent-info-box {
        text-align: left;

        .action {
          text-align: right;

          i {
            padding: 2px 5px;
            cursor: pointer;
            font-size: 16px;
          }
        }

        .name {
          padding-left: 25px;
          border-left: 1px dotted #ddd;
          color: #555555;

          label {
            font-weight: 600;
            margin-right: 5px;
          }
        }

        .channel {
          font-weight: 600;
          font-size: 13px;
          color: #666;
        }
      }

      .info-box {
        margin-top: 20px;
        text-align: left;
        font-size: 13px;

        .other {
          text-align: left;
          color: #555;

          b {
            margin-right: 10px;
            width: 85px;
            display: inline-block;
          }
        }

        .footer {
          margin-top: 10px;
          border-top: 1px dotted #ccc;
          padding: 10px 0 5px 0;
          line-height: 40px;

          label.switch {
            margin: 10px 0 0 0;
          }

          .score-box {
            width: 40px;
            height: 40px;
            padding: 5px 0 0 0;
            font-size: 13px;
            font-weight: 600;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            background-color: #8ec553;
            border: 1px solid #589219;
            border-radius: 20px;
            line-height: normal;

            p {
              font-weight: 100;
              font-size: 8px;
            }
          }

          i.fa.fa-comments {
            font-size: 25px;
            cursor: pointer;
            margin-top: 5px;
            color: #444;
          }

          .proactive {
            line-height: normal;
            margin-top: 4px;
            font-size: 13px;
            color: #444;

            b {
              display: block;
            }
          }
        }
      }
    }
  }

  .payment {
    .payment-initbox {
      border: 1px solid #ddd;
      text-align: center;
      padding: 30px 70px;
      width: 500px;
      margin: 40px auto;
      background: #f7f7f7;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);

      .title {
        margin: 15px;
      }

      .desc {
        margin: 15px auto 25px auto;
        width: 250px;
        color: #666;
      }

      .option {
        margin: 15px auto;
        width: 190px;
      }

      button {
        margin: 10px auto;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
      }
    }

    .payment-rightbox {
      border-right: 1px dotted #ddd;
      padding: 15px 30px 15px 15px;
      min-height: 300px;

      h5 {
        font-weight: bold;
        border-bottom: 1px dotted #ddd;
        padding-bottom: 10px;
        font-size: 15px;
      }
    }

    .payment-leftbox {
      padding: 15px;

      .info {
        font-size: 13px;
        font-style: italic;
        margin-bottom: 10px;
        line-height: normal;
        color: #666;
      }

      button {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 10px;
      }
    }
  }

  .feature-box {
    margin-top: 10px;
  }

  .action-btn-box {
    text-align: right;
    display: inline-block;
    position: absolute;
    z-index: 99;
    left: 20px;
    top: 10px;

    button {
      margin: 0 5px;
      border-radius: 100px;
      width: 35px;
      height: 35px;
      background-color: rgba(255, 255, 255, 1);
      border-color: #fff;
      opacity: 0.6;
      font-size: 16px;
      float: left;
    }

    button.upload {
      color: #000;
    }

    button.delete {
      font-size: 18px;
      color: #ff0000;
    }

    button:hover {
      opacity: 1;
    }
  }

  .new-pass-strength {
    margin-left: 10px;
    border-radius: 20px;
  }

  .too-weak {
    background-color: antiquewhite;
  }

  .weak {
    background-color: #ffdd57;
  }

  .medium {
    color: white;
    background-color: #3298dc;
  }

  .strong {
    color: white;
    background-color: #48c774;
  }
}

$visitorsUrl: url(../img/info/visitors.png) no-repeat center center / cover;
$agentsUrl: url(../img/info/agents.png) no-repeat center center / cover;
$channelsUrl: url(../img/info/channels.png) no-repeat center center / cover;
$msgCenterUrl: url(../img/info/msgcenter.png) no-repeat center center / cover;

$brandingUrl: url(../img/info/branding.png) no-repeat center center / cover;
$calendarUrl: url(../img/info/calendar.png) no-repeat center center / cover;
$departmentUrl: url(../img/info/department.png) no-repeat center center / cover;
$rtmemberUrl: url(../img/info/rtmember.png) no-repeat center center / cover;

$paymenttaxUrl: url(../img/info/paymenttax.png) no-repeat center center / cover;
$billingUrl: url(../img/info/billing.png) no-repeat center center / cover;
$botsettingsUrl: url(../img/info/botsettings.png) no-repeat center center / cover;
$promoUrl: url(../img/info/promo.png) no-repeat center center / cover;
$intgUrl: url(../img/info/intg.png) no-repeat center center / cover;
$securityUrl: url(../img/info/security.png) no-repeat center center / cover;

$visitorsClr: #f5c244;
$agentsClr: #98dc6d;
$channelsClr: #9fcfe2;
$msgcenterClr: #e39794;

/* Visitors */
.visitors {
  .visit-history-gird {
    .react-grid-Grid {
      background-color: transparent;
      border: none !important;
      width: 100%;

      .react-grid-HeaderCell {
        border: none !important;
        background-color: rgb(215, 224, 230) !important;
      }

      .react-grid-Cell {
        border: none !important;
      }

      .react-grid-Row:nth-child(odd) {
        background-color: rgb(242, 242, 242) !important;
      }
    }
  }

  .info-tab {
    .card {
      box-shadow: 0px 0px 70px 5px #ddd;
      border: none;
      min-height: 280px;
    }

    .card-body {
      padding: 0;
    }

    .box-bg {
      display: flex;
      align-items: center;
      padding: 40px;
      cursor: pointer;
    }

    .box-bg.visitors:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $visitorsClr;
      -moz-box-shadow: 0px 0px 5px 1px $visitorsClr;
      box-shadow: 0px 0px 5px 1px $visitorsClr;
    }

    .box-bg.agents:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $agentsClr;
      -moz-box-shadow: 0px 0px 5px 1px $agentsClr;
      box-shadow: 0px 0px 5px 1px $agentsClr;
    }

    .box-bg.channels:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $channelsClr;
      -moz-box-shadow: 0px 0px 5px 1px $channelsClr;
      box-shadow: 0px 0px 5px 1px $channelsClr;
    }

    .box-bg.msgcenter:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $msgcenterClr;
      -moz-box-shadow: 0px 0px 5px 1px $msgcenterClr;
      box-shadow: 0px 0px 5px 1px $msgcenterClr;
    }

    .box-bg::before {
      content: "";
      position: absolute;
      left: 30%;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .box-bg.visitors::before {
      background: $visitorsUrl;
    }

    .box-bg.agents::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $agentsUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $agentsUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $agentsUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $agentsUrl;
      /* IE6-9 */
    }

    .box-bg.channels::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $channelsUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $channelsUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $channelsUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $channelsUrl;
      /* IE6-9 */
    }

    .box-bg.msgcenter::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $msgCenterUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $msgCenterUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $msgCenterUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $msgCenterUrl;
      /* IE6-9 */
    }

    .box-bg {
      h2 {
        font-size: 25px;
        margin: 10px auto;
      }

      p {
        width: 100%;
      }

      img {
        max-width: 70px;
        width: 100%;
      }
    }
  }

  .visitor-link {
    white-space: nowrap;
    width: 12em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .agent-icon {
    height: 20px;
    margin-right: 5px;
    margin-top: -5px;
  }

  .agent-thumb {
    border-radius: 100%;
    border: 1px solid #ddd;
    width: 26px;
    height: 26px;
    margin-right: 5px;
  }

  .intent-popup {
    display: block;
    position: fixed;
    z-index: 1040;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;

    .intent-box {
      background: #fff;
      width: 60%;
      height: calc(100vh - 20%);
      text-align: left;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
      margin: auto;
      margin-top: 5%;

      .card-header {
        height: 60px;
      }

      .card-body {
        overflow-x: hidden;
        overflow-y: scroll;
        height: calc(100vh - 230px);
        padding: 30px;

        .collapse-box {

          .phrases,
          .responses {
            margin-bottom: 10px;

            .help {
              margin-left: 10px;
              background: #999;
              color: #fff;
              border-radius: 50px;
              padding: 1px 5px;
            }

            .arrow {
              float: right;
              font-size: 16px;
              cursor: pointer;
              font-weight: 600;
            }
          }

          input {
            border-radius: 0 !important;
            border-left: 0 !important;
            background: #fff;
          }

          input:focus {
            border: 1px solid #c2cfd6;
            box-shadow: none;
          }

          .input-group-text {
            background: #fff;
          }

          .quote {
            background: #fff;
            border-right: 0;
            border-left: 2px solid #20a8d8;

            span {
              background: #fff;
            }
          }

          button {
            margin-top: 15px;
          }

          .list-box {
            margin-top: 10px;

            input {
              border-left: 0 !important;
              border-right: 0 !important;
            }

            .delete {
              border-left: 0;
              cursor: pointer;
              width: 55px;
              text-align: right;
            }

            .quote {
              background: #fff;
              border-right: 0;
              border-left: 2px solid #20a8d8;

              span {
                background: #fff;
              }
            }
          }
        }
      }
    }
  }

  .message-center {
    .white-bg {
      .email-box {
        padding: 0 !important;
        background: none !important;
        border-radius: unset !important;
        border-bottom: none !important;
      }

      height: unset;
    }

    .profiletab .tab-content {
      overflow-y: unset;
      padding: 20px;
    }

    .selection-box {
      width: calc(33% - 60px);
      border: 1px solid #ddd;
      border-radius: 4px;
      margin: 30px;
      float: left;
      padding: 30px 15px;
      text-align: center;
      background: #f9f9f9;
      cursor: pointer;
      color: #666;

      img {
        width: 70px;
        height: 70px;
      }

      .title {
        font-size: 18px;
        margin: 15px 0 0;
      }

      .desc {
        span {
          color: #20a8d8;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    .selection-box:hover {
      background: #edf2f4;
      border: 1px solid #999;
    }

    .sec-style {
      border: 1px solid #ddd;
      min-height: 410px;
      height: calc(100vh - 241px);
      border-radius: 0 0 10px 10px;
    }

    .sec-1 {
      background-color: #f5f5f5;
      padding: 10px;
      line-height: 28px;
      font-size: 16px;

      a {
        display: block;
      }

      a:hover {
        text-decoration: none;
      }

      i {
        margin-right: 3px;
        color: #444;
      }

      .count {
        font-size: 13px;
        color: #444;
      }

      .active {
        color: #167495;
        font-weight: 600;
      }
    }

    .sec-2 {
      background-color: #f5f5f5;
      overflow: hidden;
      overflow-y: auto;

      .list-notfound {
        text-align: center;
        color: #999;
        margin-top: 15px;
      }

      .m-box {
        border-bottom: 1px dotted #ddd;
        padding: 10px;
        cursor: pointer;

        .visitor {
          .icon {
            background: #fff;
            border: 1px solid #aaa;
            border-radius: 50%;
            margin-right: 5px;
            width: 25px;
            height: 25px;
            display: inline-block;
            text-align: center;
            line-height: 23px;

            img {
              border-radius: 50%;
            }
          }

          .title {
            display: inline-block;
            font-weight: 600;
          }

          .date {
            font-size: 10px;
            font-style: italic;
            color: #666;
            text-align: right;
            float: right;
            margin-top: 3px;
          }

          .que {
            margin-left: 32px;
            font-size: 13px;
          }
        }

        .visitor-res {
          border: 1px solid #e5e5e5;
          margin-top: 10px;
          padding: 10px;
          margin-left: 30px;
          background: #eee;
          vertical-align: top;
          display: flex;

          img {
            width: 27px;
            height: 27px;
            border: 1px solid #aaa;
            border-radius: 50%;
            padding: 3px;
            background: #fff;
            margin-right: 5px;
          }

          .res {
            display: inline-block;
            font-size: 13px;
            width: calc(100% - 40px);
          }
        }

        .um-profile {
          position: relative;
          display: inline-block;
          width: 35px;
          margin-right: 10px;
          vertical-align: top;

          .icon {
            border-radius: 50%;
            overflow: hidden;
            display: block;
            height: 35px;
            width: 35px;
            text-align: center;
            font-size: 20px;
            line-height: 32px;

            .status {
              position: absolute;
              right: -6px;
              bottom: -6px;
              font-size: 13px;
            }

            .online {
              color: #3cc33c;
            }

            .offline {
              color: #ff0000;
            }
          }
        }

        .um-content {
          display: inline-block;
          width: calc(100% - 45px);

          .t-box {
            .title {
              display: inline-block;
              font-weight: 600;
            }

            .date {
              font-size: 10px;
              font-style: italic;
              color: #666;
              text-align: right;
              float: right;
              margin-top: 3px;
            }
          }

          .um-messages {
            color: #666;
            line-height: 16px;
            margin: 4px 0 0 0;
            font-size: 11px;
          }

          .more-info {
            margin-top: 10px;
            border-top: 1px dotted #ddd;
            padding-top: 10px;
            font-size: 13px;
            line-height: 20px;

            .ib {
              display: inline-block;
              margin-right: 10px;
              border-right: 1px dotted #ddd;
              padding-right: 5px;

              .ib-t {
                font-weight: 600;
                margin-right: 3px;
              }

              .ib-v {
                display: inline-block;
              }
            }

            .ib:last-child {
              border-right: 0;
            }
          }
        }
      }

      .m-box.even {
        background: #efefef;
      }

      .m-box.active {
        background: rgba(194, 207, 214, 0.5);
      }
    }

    .sec-3 {
      .info-section {
        padding: 10px;
        font-size: 13px;
        color: #444;
        background-color: #f5f5f5;
        border-bottom: 1px solid #ddd;

        // max-height: 85px;
        // overflow: hidden;
        // overflow-y: auto;
        .messages {
          margin-bottom: 5px;

          b {
            float: left;
          }

          span {
            width: calc(100% - 72px);
            display: block;
            float: left;
            margin-left: 5px;
            word-break: break-all;
          }
        }

        .i-box {
          margin-bottom: 3px;

          .status {
            width: auto;
            height: 10px;
            font-size: 11px !important;
            border-radius: 15px;
            display: inline-block;
            margin-right: 3px;
          }

          .online {
            color: #3cc33c;
          }

          .offline {
            color: #ff0000;
          }

          .title {
            width: 150px;
            display: inline-block;
            font-weight: 600;
            font-size: 15px;
          }

          .unansweredDate {
            font-size: 10px !important;
            text-align: left !important;
            float: left !important;
          }

          .date {
            font-size: 12px;
            font-style: italic;
            color: #666;
            text-align: right;
            float: right;
            margin-top: 3px;
          }

          .action {
            // float: right;
            // margin-left: 10px;

            .search-conver {
              list-style: none;
              padding: 0px;
              margin: 0px;
              float: right;

              li {
                display: inline-block;

                a {
                  color: #fff;
                  font-size: 18px;
                  padding: 0px 5px;
                }
              }
            }

            .dropdown-menu-right {
              position: relative;

              .btn-video-call {
                color: #fff;
                background: #20a8d8;
                border: 0;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                padding: 0;
              }

              .btn-video-call:hover {
                background: #1985ac;
              }

              .dropdown-menu {
                -ms-transform: none !important;
                -webkit-transform: none !important;
                transform: none !important;
                right: 0;
                left: auto !important;
                top: 25px !important;
                border-radius: 6px;
              }

              // .dropdown-menu::before {
              //   content: "\A";
              //   border-style: solid;
              //   border-width: 0 5px 7px 5px;
              //   border-color: transparent transparent #fff transparent;
              //   position: absolute;
              //   top: -7px;
              //   right: 0px;
              // }
            }

            a {
              font-size: 14px;
              color: #444;
              margin-left: 5px;
            }

            .fa-trash {
              font-size: 16px;
            }
          }
        }

        .lead-status-style {
          display: inline;
          padding: 3px 3px 2px 3px;
          font-size: 9px;
        }

        .pagination {
          display: inline-flex;
          margin: 0;

          .em-pagination {
            a {
              border-radius: 50%;
              height: 28px;
              width: 28px;
              line-height: 24px;
              margin: 0 3px;
              padding: 0;
              text-align: center;
              border-color: #ddd !important;

              &:focus {
                box-shadow: none;
              }
            }
          }

          .em-prev {
            a {
              background: unset !important;
              border: 0;
              font-weight: bold;
              margin-right: 0;
            }
          }

          .em-next {
            a {
              background: unset !important;
              border: 0;
              font-weight: bold;
              margin-left: 0;
            }
          }

          .disabled {
            a {
              color: #ccc;
            }
          }
        }
      }

      .inbox-ddl {
        position: relative;

        li {
          display: inline-block;

          a {
            color: #fff;
            font-size: 18px;
            padding: 0px 5px;
          }
        }

        .btn-video-call {
          color: #fff;
          background: #20a8d8;
          border: 0;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          padding: 0;
        }

        .btn-video-call:hover {
          background: #1985ac;
        }

        .dropdown-menu {
          -ms-transform: none !important;
          -webkit-transform: none !important;
          transform: none !important;
          right: 0;
          left: auto !important;
          top: 25px !important;
          border-radius: 6px;
          margin-top: 5px;
        }
      }

      .chat-box {
        min-height: 335px;

        #frame {
          .content {
            box-shadow: none;
            border-radius: 6px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            // .messages {
            //   min-height: calc(100% - 62px);
            //   max-height: calc(100% - 62px);
            // }

            .message-input {
              .msg-status {
                min-height: 48px;
                padding-top: 12px;
                color: #666;
                padding-left: 15px;
              }
            }
          }
        }

        .offline-visitor {
          text-align: center;
          margin-top: 20px;
          color: #999;
          font-style: italic;
        }

        .em-box {
          margin: 15px 15px 0 15px;

          .list-notfound {
            text-align: center;
            margin-top: 5px;
          }

          .item {
            margin-bottom: 10px;

            span {
              font-weight: 600;
              width: 55px;
              display: inline-block;
              text-align: right;
              margin-right: 6px;
            }
          }

          .body {
            margin: -30px 0 0 60px;
            line-height: 25px;
            border: 1px solid #ddd;
            border-radius: 5px;
            padding: 5px 10px;
            height: calc(100vh - 472px);
            overflow: scroll;
            width: calc(100% - 70px);
            white-space: nowrap;
            background: #f5f5f5;
          }

          .input-text {
            cursor: text;
            border-top: 1px solid #ddd;
            margin: 20px -15px 0 -14px;
            padding: 20px;
            color: #777;
          }

          .em-editor {
            flex-direction: column;
            position: absolute !important;
            bottom: 0;
            background: #fff;
            left: 0;
            width: 100%;
            padding-top: 5px;
            border: 1px solid #ddd;
            border-radius: 10px;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            z-index: 99;

            .ql-toolbar,
            .ql-container {
              border: 0;
            }

            .ql-container {
              border-top: 1px solid #ddd;
              border-bottom: 1px solid #ddd;
            }
          }

          .em-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            left: 0;
            top: 0;
            z-index: 9;
          }
        }
      }
    }

    .broadcast-box {
      .contact-msg-box:before {
        content: "";
        position: absolute;
        height: 0;
        width: 0;
        left: -20px;
        top: 5px;
        border: 10px solid transparent;
        border-right: 10px solid #aaaaaa;
      }

      .contact-msg-box {
        border: 1px solid #aaa;
        width: 600px;
        padding: 15px;
        border-radius: 4px;
        position: absolute;
        z-index: 9;
        background: #f0f3f5;
        top: -15px;
        left: 15px;

        .close-icon {
          position: absolute;
          right: 15px;
          top: 15px;
          text-decoration: none;
          font-weight: 600;
          color: #999;
        }

        .ct-profile {
          width: calc(100% - 20px);

          img {
            width: 60px;
            height: 60px;
            // border: 1px solid #999;
            border-radius: 4px;
            float: left;
            margin-right: 10px;
          }

          .name {
            display: block;
            font-weight: 600;
          }

          .desc {
            font-size: 12px;
            display: block;
          }
        }

        .ct-msg-input {
          width: 100%;
          min-height: 300px;
          border: 1px solid #ddd;
          padding: 10px;
        }

        .ct-btn-submit {
          float: right;
          border-radius: 3px;
        }
      }

      .details {
        padding: 10px;
        display: flex;
        height: 50px;
        align-items: center;
        font-weight: 600;
        background: #efefef;

        .desc {
          margin-left: 10px;
          font-weight: 200;
          color: #777;
        }
      }

      .contact-list {
        display: block;
        width: 100%;
        height: calc(100% - 50px);

        .tab-grid {
          .react-grid-Empty {
            border: 0 !important;
          }
        }
      }
    }

    .que,
    .ans {
      line-height: 25px;

      img {
        height: 15px;
        float: left;
        margin: 6px 5px 0 0;
      }

      .content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 30px);
        float: left;
      }
    }

    .live-chat {
      #messageInp {
        outline: none;
      }

      .user-connectivity {
        li {
          margin: 4px 2px 0;
          width: 22px;
          height: 22px;
        }

        #btnVideo {
          width: 20px;
          height: 20px;
        }
      }
    }

    // #frame .content .messages ul li.sent {
    #frame .content .messages ul li.load-more {
      cursor: pointer;
      text-align: center;
      width: 100% !important;
      max-width: 100%;

      p {
        border-radius: 10px !important;
      }
    }

    #frame .content .messages ul li.load-more:hover {
      p {
        background: #edf2f4;
      }
    }

    .schedule-date {
      .rdtPicker {
        bottom: 40px;
      }
    }
  }

  .online-visitors {
    .ob-first {
      min-height: 230px;
    }

    .ob-sec {
      min-height: 140px;
    }
  }

  .inbox {
    .head {
      background: #f8fcfc;
      padding: 15px;
      border-bottom: 1px solid #eee;
      text-align: left;
      height: 52px;
      font-weight: 600;

      span {
        font-size: 13px;
      }
    }

    .box-style {
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid #f5f5f5;
    }

    .ifilter-box {
      width: 200px;
      float: left;

      .icontent {
        padding: 10px;

        .filter-cb-outer {
          line-height: 25px;

          label {
            cursor: pointer;
          }

          .filter-box {
            margin: 3px 0 10px 12px;

            .value {
              height: 30px;
              margin-top: 5px;
            }
          }
        }
      }

      .foot {
        padding: 10px;
        background: #ddd;
      }
    }

    .iconversation-box {
      width: 280px;
      float: left;
      margin: 0 10px;

      .head {
        background: #48a3cd;
        color: #fff;
      }

      .search-box {
        padding: 10px;
        position: relative;
        background: #f0f3f5;

        .fa-search {
          position: absolute;
          top: 20px;
          left: 20px;
        }

        input {
          padding-left: 28px;
          border: 0;
          border-bottom: 1px solid #c2cfd6;
          border-radius: 0 !important;
          background: transparent;
          box-shadow: none;
        }
      }

      .icontent {
        min-height: 196px;

        .chat_list {
          margin: 0;
          padding: 10px;
          cursor: pointer;
          border-bottom: 1px solid #eee;

          .chat_people {
            overflow: hidden;
            clear: both;
            display: flex;

            .chat_img {
              float: left;
              min-width: 35px;
              width: 8%;
              vertical-align: middle;
              position: relative;

              img {
                width: 34px;
                height: 34px;
                border-radius: 100%;
              }

              .status {
                font-size: 8px;
                position: absolute;
                top: 24px;
                left: 23px;

                i {
                  border: 1px solid #127712;
                  border-radius: 100%;
                  color: #3cc33c;
                  background: #3cc33c;
                }
              }
            }

            .chat_ib {
              float: left;
              padding: 0 0 0 10px;
              width: 82%;
              vertical-align: middle;

              h5 {
                font-size: 14px;
                color: #464646;
                margin: 0;
                font-weight: 600;
                width: 100%;

                span {
                  font-size: 9px;
                  float: right;
                  font-weight: normal;
                  color: #716f6f;
                  margin-top: 2px;
                }
              }
            }
          }
        }

        .chat_list.active {
          background: rgba(81, 134, 162, 0.28);
        }
      }
    }

    .ichat-box {
      float: left;
      width: calc(100% - 500px);

      .head {
        padding: 10px 15px;

        .msg-user-cell {
          display: table-cell;
          width: 30px;
          min-width: 30px;
          height: 30px;
          min-height: 30px;
          vertical-align: middle;

          img {
            min-width: 30px;
            height: 30px;
            border-radius: 100%;
          }
        }

        .msg-user-d-cell {
          width: 100%;
          display: table-cell;
          vertical-align: middle;
          padding-left: 10px;

          h4 {
            color: #37383b;
            font-size: 16px;
            margin: 0;
            font-weight: 600;
            cursor: pointer;
          }

          h4:hover {
            color: #48a3cd;
          }
        }
      }

      .icontent {
        #frame {
          min-height: 250px;

          .content {
            box-shadow: none;

            // .messages {
            //   min-height: calc(100% - 68px);
            //   max-height: calc(100% - 68px);
            // }
          }

          .message-input {
            .wrap {
              min-height: 48px;
              padding-top: 5px;
              color: #666;
              padding-left: 15px;
            }

            .msg-status {
              padding-top: 12px;
            }
          }
        }

        .offline-visitor {
          padding: 0 10px;
        }
      }
    }

    .icontent {
      position: relative;
      min-height: 250px;
    }

    .search-box-outer {
      border: 1px solid #c2cfd6;
      border-radius: 4px;
      display: flex;
      padding: 0.375rem 0.475rem;
      line-height: 1.5;

      .search-icon {
        float: left;
        cursor: pointer;
        font-weight: 400;
        color: #3e515b;
        line-height: inherit;
      }

      .ctColSearch {
        width: 40%;
        border: 0;
        height: inherit;
        padding: 0 0 0 5px;
        height: inherit;
      }

      .search-input {
        padding: 0 0 0 7px;
        margin-left: 4px;
        border: 1px solid #c2cfd6;
        border-width: 0 0 0 1px;
        border-radius: 0px !important;
      }

      .ctColSearch:focus {
        outline: 0;
        box-shadow: none;
        border-color: #c2cfd6;
      }

      .search-input:focus {
        outline: 0;
        box-shadow: none;
        border-color: #c2cfd6;
      }
    }

    .filter-box {
      float: right;
      display: inline-flex;
      border: 1px solid #c2cfd6;
      border-radius: 4px;

      .dp-box {
        border: 0;

        input {
          border-right: 1px solid #c2cfd6;
          padding: 0 32px 0 12px;
          margin: 6px 0;
          border-radius: 0 !important;
          width: 160px;
        }

        .fa-calendar {
          right: 13px;
        }
      }

      .btn-action {
        cursor: pointer;
        color: #3e515b;
        padding: 0 10px;
        background: #edf1f4;

        .fa {
          line-height: 32px;
        }
      }

      .apply {
        border: 1px solid #c2cfd6;
        border-width: 0 1px;
        margin-left: -1px;
        border-right: 1px solid #ddd;
      }

      .clear {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .ai-agent-tab {
    .score-box {
      width: 40px;
      height: 40px;
      padding: 5px 0 0 0;
      font-size: 13px;
      font-weight: 600;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      background-color: #8ec553;
      border: 1px solid #589219;
      border-radius: 20px;
      line-height: normal;

      p {
        font-weight: 100;
        font-size: 8px;
      }
    }
  }
}

.whatsapp-preview {
  height: 100%;
  background: url(../img/broadcast/whatsapp_bg.png) repeat;
  background-origin: content-box;
  background-size: contain;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);

  .whatsapp-preview-header {
    padding-left: 1em;
    position: relative;
    font-family: Roboto, Arial, sans-serif;
    background: #f0f2f5;
    z-index: 1000;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;

    .icon {
      width: 35px;
      height: 35px;
      background: #ddd;
      border-radius: 50%;
      margin-right: 10px;
    }

    h3 {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .whatsapp-message-inner-container {
    padding: 1em;
    max-width: 80%;
    min-width: 30%;
    display: inline-block;
    clear: both;
    font-size: 15px;
    margin: 12px 8px;
    margin-top: 10px;

    .whatsapp-message {
      color: #000;
      line-height: 18px;
      position: relative;
      margin: 0px 0 2px 0.5em !important;
      word-wrap: break-word;
      z-index: 2;
      box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
      padding: 3px;

      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
      }

      .text-header {
        font-family: Helvetica, Arial, sans-serif !important;
        color: rgba(0, 0, 0, 0.76);
        font-size: 15px;
        font-weight: bold;
        padding: 6px 7px 5px 5px;
      }

      .header {
        background-color: #ccd0d5;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 80px 80px;
        border-radius: 4px;
        box-sizing: border-box;
        min-width: 240px;
        min-height: 125px;
        box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
        position: relative;
        word-wrap: break-word;
        margin-bottom: 4px;
      }

      .footer {
        color: rgba(0, 0, 0, 0.45);
        font-size: 13px;
        line-height: 17px;
        font-family: Helvetica, Arial, sans-serif !important;
        padding: 7px 4px 0px 2px;
      }

      .body {
        line-height: 19px;
        padding: 2px 4px 0px 4px !important;
      }

      .metadata {
        display: block;
        text-align: right;
        padding: 0 0 0 40px !important;

        .time {
          color: rgba(0, 0, 0, 0.4);
          font-size: 11px;
          font-weight: normal;
          display: inline-block;
        }
      }
    }

    .whatsapp-message.received {
      background: #fff;
      border-radius: 0px 7.5px 7.5px 7.5px;

      &:after {
        border-width: 0px 10px 10px 0;
        border-color: transparent #fff transparent transparent;
        top: 0;
        left: -10px;
      }

      pre {
        font-family: sans-serif !important;
        letter-spacing: 0.2px;
        margin: 0.1em;
        font-size: 0.85rem;
        white-space: pre-wrap;
      }
    }
  }
}

.sms-preview {
  height: 100%;
  border: 1px solid #1b8eb7;
  background-origin: content-box;
  background-size: contain;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);

  .sms-preview-header {
    padding-left: 1em;
    position: relative;
    font-family: Roboto, Arial, sans-serif;
    background: #f0f2f5;
    z-index: 1000;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;

    .icon {
      width: 35px;
      height: 35px;
      background: #ddd;
      border-radius: 50%;
      margin-right: 10px;
    }

    h3 {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .sms-message-inner-container {
    padding: 1em;
    max-width: 80%;
    min-width: 30%;
    display: inline-block;
    clear: both;
    font-size: 15px;
    margin: 12px 8px;
    margin-top: 10px;

    .sms-message {
      color: #000;
      line-height: 18px;
      position: relative;
      margin: 0px 0 2px 0.5em !important;
      word-wrap: break-word;
      z-index: 2;
      box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
      padding: 3px;

      .text-header {
        font-family: Helvetica, Arial, sans-serif !important;
        color: rgba(0, 0, 0, 0.76);
        font-size: 15px;
        font-weight: bold;
        padding: 6px 7px 5px 5px;
      }

      .header {
        background-color: #ccd0d5;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 80px 80px;
        border-radius: 4px;
        box-sizing: border-box;
        min-width: 240px;
        min-height: 125px;
        box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
        position: relative;
        word-wrap: break-word;
        margin-bottom: 4px;
      }

      .footer {
        color: rgba(0, 0, 0, 0.45);
        font-size: 13px;
        line-height: 17px;
        font-family: Helvetica, Arial, sans-serif !important;
        padding: 7px 4px 0px 2px;
      }

      .body {
        line-height: 19px;
        padding: 2px 4px 0px 4px !important;
      }

      .metadata {
        display: block;
        text-align: right;
        padding: 0 0 0 40px !important;

        .time {
          color: rgba(0, 0, 0, 0.4);
          font-size: 11px;
          font-weight: normal;
          display: inline-block;
        }
      }
    }

    .sms-message.received {
      background: #fff;
      border-radius: 7.5px;
      border: 1px solid #1b8eb7;

      &:after {
        border-width: 0px 10px 10px 0;
        border-color: transparent #fff transparent transparent;
        top: 0;
        left: -10px;
      }

      pre {
        font-family: sans-serif !important;
        letter-spacing: 0.2px;
        margin: 0.1em;
        font-size: 0.85rem;
        white-space: pre-wrap;
      }
    }
  }
}

.basic-preview {
  height: 100%;
  border: 1px solid #1b8eb7;
  background-origin: content-box;
  background-size: contain;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);

  .basic-preview-header {
    padding-left: 1em;
    position: relative;
    font-family: Roboto, Arial, sans-serif;
    background: #f0f2f5;
    z-index: 1000;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;

    .icon {
      width: 35px;
      height: 35px;
      background: #ddd;
      border-radius: 50%;
      margin-right: 10px;
    }

    h3 {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .basic-message-inner-container {
    padding: 1em;
    max-width: 80%;
    min-width: 30%;
    display: inline-block;
    clear: both;
    font-size: 15px;
    margin: 12px 8px;
    margin-top: 10px;

    .basic-message {
      color: #000;
      line-height: 18px;
      position: relative;
      margin: 0px 0 2px 0.5em;
      word-wrap: break-word;
      z-index: 2;
      box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
      padding: 3px;

      .basic-header {
        font-family: Helvetica, Arial, sans-serif !important;
        color: rgba(0, 0, 0, 0.76);
        font-size: 15px;
        font-weight: bold;
        padding: 6px 7px 5px 5px;
      }

      .header {
        background-color: #ccd0d5;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 80px 80px;
        border-radius: 4px;
        box-sizing: border-box;
        min-width: 240px;
        min-height: 125px;
        box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
        position: relative;
        word-wrap: break-word;
        margin-bottom: 4px;
      }

      .footer {
        color: rgba(0, 0, 0, 0.45);
        font-size: 13px;
        line-height: 17px;
        font-family: Helvetica, Arial, sans-serif !important;
        padding: 7px 4px 0px 2px;
      }

      .body {
        line-height: 19px;
        padding: 2px 4px 0px 4px;
      }

      .metadata {
        display: block;
        text-align: right;
        padding: 0 0 0 40px;

        .time {
          color: rgba(0, 0, 0, 0.4);
          font-size: 11px;
          font-weight: normal;
          display: inline-block;
        }
      }
    }

    .basic-message.received {
      background: #fff;
      border-radius: 7.5px;
      border: 1px solid #1b8eb7;

      &:after {
        border-width: 0px 10px 10px 0;
        border-color: transparent #fff transparent transparent;
        top: 0;
        left: -10px;
      }

      pre {
        font-family: sans-serif !important;
        letter-spacing: 0.2px;
        margin: 0.1em;
        font-size: 0.85rem;
        white-space: pre-wrap;
      }

      img.media {
        object-fit: contain;
        width: 100%;
      }
    }
  }
}

/* AI Agents */
.aiAgents {
  .grid-style {
    padding-right: 50px;
  }

  .training-status {
    border-left: 3px dotted #ddd;
    padding-left: 50px;
    padding-right: 50px;

    h5 {
      font-weight: bold;
      margin-left: -12px;
    }

    .timeline {
      position: relative;
      margin-top: 20px;

      .timeline-box {
        border-left: 1px solid #ddd;
        padding-left: 25px;
        padding-bottom: 20px;

        span {
          position: absolute;
          left: -12px;
          width: 25px;
          height: 25px;
          border-radius: 30px;
          margin-top: -2px;
          background: #ccc;
          text-align: center;
          padding-top: 2px;
        }

        span.active {
          background: #800e80;

          i {
            color: #fff;
          }
        }

        .desc {
          color: #666;
          font-size: 13px;
          line-height: normal;

          .not-config {
            color: #800f80;
            padding: 10px 0 0 0;
          }
        }

        .danger {
          margin-right: 5px;
          color: red;
        }
      }

      .timeline-box.last {
        border: 0;
      }
    }
  }

  .agent-icon {
    height: 20px;
    margin-right: 5px;
    margin-top: -5px;
  }

  .create-agent {
    .agent-form {
      width: calc(100% - 350px);
      float: left;

      .agent-left {
        float: left;
        width: 35%;
        text-align: center;

        .c-action-btn-box {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .agent-chat-box {
          border: 1px solid #ddd;
          margin: 100px 50px 0 50px;
          border-radius: 15px;
          overflow: hidden;
          // background: rgba(255, 255, 255, 1);
          // background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);

          .sec1 {
            // background: #b1abe1;
          }

          .sec2 {
            // background: #b1abe1;
            // opacity: 0.7;
            background: hsla(0, 0%, 100%, 0.2);

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              border: 1px solid #ddd;
              background: #fff;
            }

            .online-icon {
              display: inline-block;
              background: #04e204;
              border: 1px solid #35a435;
              border-radius: 50%;
              width: 9px;
              height: 9px;
              font-size: 10px;
              margin-right: 4px;
            }
          }

          .sec3 {
            height: 356px;
            overflow-y: auto;

            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              border: 1px solid #ddd;
              background: #fff;
            }

            .msg-box {
              border: 1px solid #ddd;
              margin-right: 38px;
              margin-left: 38px;
              background-color: #fff;
              border-radius: 10px;

              .dot {
                width: 5px;
                height: 5px;
                background-color: #333;
                border-radius: 50%;
                margin: 0 4px;
                opacity: 0;
                animation: loading-dots 1s infinite;
              }

              .dot1 {
                animation-delay: 0s;
              }

              .dot2 {
                animation-delay: 0.2s;
              }

              .dot3 {
                animation-delay: 0.4s;
              }

              @keyframes loading-dots {
                0% {
                  opacity: 0;
                }

                50% {
                  opacity: 1;
                }

                100% {
                  opacity: 0;
                }
              }
            }
          }

          .chat-sec {
            display: flex;
            padding: 0 15px 15px 15px;
          }
        }
      }

      .agent-right {
        float: right;
        width: 65%;
        border-right: 1px solid #c2cfd6;
        padding-right: 20px;

        .chat-icon {
          float: left;
          width: 120px;
        }

        .texture-img {
          float: left;
          margin-left: 15px;
          width: calc(100% - 135px);
        }

        .add-element {
          padding: 10px;
          text-align: center;

          p {
            margin: 0px auto 15px auto;
            width: 250px;
            color: #666;
          }
        }

        .rc-slider {
          width: calc(100% - 10px);
          margin: 0 5px;
        }
      }
    }

    .agent-chat {
      width: 350px;
      float: left;
    }

    .card-body {
      .btn-box {
        display: inline-block;

        button {
          width: 100px;
        }
      }
    }

    .ql-container {
      .ql-editor {
        min-height: 150px;
      }
    }

    .agent-img-box {
      .c-img-box {
        margin: 0 auto;
        width: 280px;
        height: 280px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background: #eeaeca;
        background: radial-gradient(circle, #eeaeca 0%, #94bbe9 100%);
        border: 5px solid #fff;
        padding: 0;

        .c-upload-alone {
          width: 100%;
          height: 100%;
        }
      }

      .agent-img-title {
        font-weight: 600;
        border: 3px solid #fff;
        background: red;
        position: absolute;
        position: absolute;
        bottom: -30px;
        right: 50%;
        transform: translate(50%, -50%);
        padding: 5px 15px;
        border-radius: 15px;
        background: #f2f5f9;
        min-width: 150px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background: #eeaeca;
        background: radial-gradient(circle, #eeaeca 0%, #94bbe9 100%);
      }
    }
  }

  .agent-list {
    .agent-box {
      border: 1px solid #ddd;
      border-radius: 3px;
      padding: 10px;
      //background: #fbfbfb;
      text-align: center;
      margin-bottom: 20px;

      background: rgb(241, 241, 241);
      /* Old browsers */
      background: -moz-linear-gradient(left, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f1f1', endColorstr='#fbfbfb', GradientType=1);
      /* IE6-9 */

      .channel-image {
        max-width: 15px;
        max-height: 15px;
      }

      .img-box {
        width: 100px;
        border-right: 1px dotted #ccc;
        max-height: 80px;
        margin: 20px 15px 0 -10px;

        img {
          width: 70px;
          height: 70px;
          border: 1px solid #eee;
          border-radius: 150px;
          -webkit-box-shadow: 0px 3px 0px 4px #d4d4d4;
          -moz-box-shadow: 0px 3px 0px 4px #d4d4d4;
          box-shadow: 0px 3px 0px 4px #d4d4d4;
        }
      }

      .main-info-box {
        text-align: left;
        width: calc(100% - 105px);
        line-height: 25px;

        .action {
          text-align: right;

          i {
            padding: 2px 5px;
            cursor: pointer;
          }
        }

        .name {
          font-size: 16px;
          font-weight: 600;
          color: #555555;
        }

        .channel {
          font-weight: 600;
          font-size: 13px;
          color: #666;
        }
      }

      .info-box {
        margin-top: 20px;
        text-align: left;
        font-size: 13px;

        .other {
          text-align: left;
          color: #555;

          b {
            margin-right: 10px;
            width: 95px;
            display: block;
            float: left;
          }

          span {
            display: block;
            float: left;
            width: calc(100% - 105px);
            font-size: 11px;
          }
        }

        .footer {
          margin-top: 10px;
          border-top: 1px dotted #ccc;
          padding: 10px 0 5px 0;
          line-height: 40px;

          label.switch {
            margin: 10px 0 0 0;
          }

          .score-box {
            width: 40px;
            height: 40px;
            padding: 5px 0 0 0;
            font-size: 13px;
            font-weight: 600;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            background-color: #8ec553;
            border: 1px solid #589219;
            border-radius: 20px;
            line-height: normal;

            p {
              font-weight: 100;
              font-size: 8px;
            }
          }

          i.fa.fa-comments {
            font-size: 25px;
            cursor: pointer;
            margin-top: 5px;
            color: #444;
          }

          .proactive {
            line-height: normal;
            margin-top: 4px;
            font-size: 13px;
            color: #444;

            b {
              display: block;
            }
          }
        }
      }
    }

    .ai-agent-box {
      background: #fbfbfb;
      text-align: center;
      margin-bottom: 20px;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

      .info-box {
        background: #46a3cd;
        min-height: 150px;
        padding-top: 20px;
        color: #fff;

        .name {
          font-size: 22px;
          font-weight: 600;
        }
      }

      .img-box {
        margin-top: -52px;
        height: 104px;

        img {
          width: 100px;
          height: 100px;
          background: #fff;
          border: 2px solid #fff;
          border-radius: 50px;
        }
      }

      .other-info {
        padding: 20px;
        text-align: left;

        .title {
          font-weight: 600;
        }

        .desc {
          margin-bottom: 10px;
          color: #666;
        }

        .score-box {
          width: 47%;
          height: 40px;
          padding: 5px 0 0;
          font-size: 13px;
          font-weight: 600;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          background-color: #8ec553;
          border: 1px solid #589219;
          border-radius: 4px;
          line-height: normal;
        }

        i.fa.fa-comments {
          font-size: 25px;
          cursor: pointer;
          color: #444;
          background: #eee;
          width: 47%;
          float: right;
          text-align: center;
          border: 1px solid #999;
          border-radius: 4px;
          height: 40px;
          padding: 5px 0;
        }
      }
    }
  }
}

/* Products */
.products {
  .product-catalog {
    .list {
      .card {
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 4px;
        position: relative;
        margin-bottom: 1rem;
        cursor: pointer;
      }

      .card-body {
        padding: 3px;
        text-align: center;
        position: relative;
        border-radius: 7px 7px 0 0;

        img {
          border-radius: 3px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          max-height: 200px;
        }

        .info-box {
          background: #edf1f4d9;
          text-align: center;
          z-index: 99;
          position: absolute;
          left: 3px;
          bottom: 3px;
          width: calc(100% - 6px);
          line-height: 30px;

          .price {}

          .switch {
            position: absolute;
            right: 5px;
            top: 6px;
            height: 19px;
            width: 36px;

            .switch-label {
              border: 1px solid #999;
            }

            .switch-handle {
              height: 15px;
              width: 15px;
              border: 1px solid #999;
            }
          }

          .loader-active {
            position: absolute;
            top: 2px;
            right: 1px;
          }

          .loader-inactive {
            position: absolute;
            top: 2px;
            right: 16px;
          }
        }

        .icon-box {
          position: absolute;
          right: 10px;
          top: 10px;
          display: flex;

          // remove after this is moved to v1
          .btn-action {
            background: #f2f5f9;
            border: 1px solid #e2e2e3;
            border-radius: 50%;
            height: 26px;
            width: 26px;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .card-footer {
        text-align: center;
        line-height: normal;
        padding: 5px;
        background: #fff;
        border-radius: 0 0 7px 7px;
        border-top: none;

        span {
          display: block;
          color: #999;
          font-size: 13px;
          font-style: italic;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .arrow {
      float: right;
      font-size: 16px;
      cursor: pointer;
      font-weight: 600;
      margin-right: 0;
      border-radius: 20px;
      padding: 3px 2px 3px 2px;
      height: 25px;
      width: 25px;
      line-height: normal;
      background: #fff;
    }
  }

  .product-detail {
    .action-btn-box {
      text-align: right;
      display: inline-block;
      position: absolute;
      z-index: 99;
      right: 40px;
      top: 30px;

      button {
        margin: 0 5px;
        border-radius: 100px;
        width: 35px;
        height: 35px;
        background-color: rgba(255, 255, 255, 1);
        border-color: #fff;
        opacity: 0.6;
        font-size: 16px;
      }

      button.upload {
        color: #000;
      }

      button.delete {
        font-size: 18px;
        color: #ff0000;
      }

      button:hover {
        opacity: 1;
      }
    }

    .detail-form {
      .form-group {
        margin-bottom: 5px;
      }

      .input-lbl {
        text-align: right;
        font-weight: 600;
      }

      .btn-discount {
        height: 34px;
        width: 70px;
        border-radius: 4px;
        color: #444;
        background: #f0f3f5;
      }

      .list-box {
        .input-group-text {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        input {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      .input-group-prepend.input-group-addon {
        min-width: auto;
      }
    }

    .feature-box {
      margin-top: 10px;
    }

    .feature-action {
      border-top: 1px solid #c2cfd6;
      padding: 20px;
      text-align: center;
      margin: 20px 0;

      p {
        margin-bottom: 15px;
        color: #666;
      }

      button {
        border: 1px solid #888;
        border-radius: 4px;
        padding: 7px 20px;
        font-size: 14px;
        color: #666;
        background: #f5f5f5;
      }
    }

    .Select-control {
      height: 28px;
      border-radius: 0;
      font-size: 0.76562rem;

      .Select-placeholder {
        line-height: 28px;
      }

      .Select-value {
        line-height: 28px !important;

        .Select-value-label {
          line-height: 28px;
        }
      }

      .Select-input {
        height: 28px;
      }

      .Select-clear {
        margin-top: 4px;
      }
    }

    .Select-menu-outer {
      .Select-option {
        font-size: 0.76562rem !important;
      }
    }

    .feature-box {
      .btn-action {
        float: right;
        margin-left: 10px;
        margin-top: 0;
        background: #ffffff;
        border-radius: 4px;
        color: #666;
      }
    }
  }

  .bulk-upload {
    .upload-box {
      label {
        width: 115px;
      }

      input {
        max-width: 350px;
        font-size: 13px;
        margin-top: 3px;
      }

      button {
        margin-left: 115px;
        margin-top: 10px;
        width: 130px;
      }
    }

    .download-box {
      border-left: 3px dotted #ddd;
      padding: 20px 0 20px 50px;
      font-style: italic;
      font-size: 13px;
      color: #666;

      button {
        margin-top: 10px;
        font-size: 12px;
        text-transform: uppercase;
        color: #444;
        background: #f0f3f5;
      }
    }

    .grid-img {
      border: 1px solid #999;
      border-radius: 3px;
      padding: 2px;
      width: 30px;
      height: 30px;
    }
  }
}

.product-loading {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

/* Fulfilment */
.fulfilment {
  .btn-search-order {
    border: 1px solid #cacccd;
    border-left: 0;
    padding: 5px 12px 7px 12px;
    font-size: 16px;
    background: #eee;
    outline: none;
  }

  .input-lbl {
    text-align: right;
    font-weight: 600;
  }

  .order-tbl {
    font-size: 13px;
    margin-bottom: 0;

    thead {
      tr {
        border: 0;
        border-bottom: 1px solid #ddd;

        .th-product {
          width: 455px;
        }

        th {
          border: 0;
        }
      }
    }

    tbody {
      border-bottom: 1px solid #ddd;

      tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.03);
      }

      tr:hover {
        background-color: rgba(0, 0, 0, 0.075);
      }

      td {
        border: 0;
      }
    }

    tfoot {
      border-bottom: 0;

      tr {
        line-height: 22px;

        td {
          border: 0;
        }
      }
    }

    .order-product {
      width: 350px;
    }

    .order-description {
      width: 400px;
    }

    .order-description-display {
      display: flex;
      width: 400px;
    }

    .order-specialInstructions {
      width: 350px;
    }

    .order-specialInstructions-display {
      width: 400px;
    }

    .order-contact {
      max-width: 280px !important;
    }

    .order-quantity {
      width: 100px;
    }

    .order-price {
      width: 100px;
    }

    .contact-label-input {
      width: 260px;
    }

    .order-currency {
      float: left;
      margin-top: 6px;
      margin-right: 5px;
    }
  }

  .search-order {
    margin-top: 20px;
    margin-bottom: 20px;

    .search-input {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      margin-top: 20px;
    }

    .form-group {
      margin-bottom: 0px;

      input {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-width: 1px 0 1px 1px;
      }
    }

    .search-button {
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
      border: 1px solid #c2cfd6;
      border-width: 1px 1px 1px 0;
      color: inherit;
    }
  }

  .calendly-frame {
    width: 100%;
    height: 100%;
    border: 0;
  }
}

/* Settings */
.settings {
  .form-group {
    margin-bottom: 5px;

    label {
      line-height: 25px;
      margin: 0;
    }

    .input-lbl {
      text-align: right;
      font-weight: 600;
    }

    .border-right-0 {
      input {
        border-right: 0;
        margin-right: -3px;
      }
    }

    .prefix-select__option {
      text-align: left;
    }
  }

  .row {
    label {
      line-height: 25px;
      margin: 0;
    }
  }

  .card-body {
    .input-lbl {
      text-align: right;
      font-weight: 600;
    }

    .row {
      margin-bottom: 5px !important;
    }
  }

  .ai-individuals {
    flex-basis: calc(33.33% - 20px);
    margin: 10px;
    background-color: #f2f2f2;
    box-sizing: border-box;
    background: #f1f1f1;
    background: -moz-linear-gradient(left, #f1f1f1 0%, #fbfbfb 100%);
    background: -webkit-linear-gradient(left, #f1f1f1 0%, #fbfbfb 100%);
    background: linear-gradient(to right, #f1f1f1 0%, #fbfbfb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f1f1', endColorstr='#fbfbfb', GradientType=1);
    border: solid 1px #ccc;
    border-radius: 7px;

    .aiAgents,
    .profile-box,
    .agent-profile-box {
      height: 100%;
    }

    .aiAgents {
      .profile-box {
        label {
          margin-bottom: 0;
        }

        .agent-img {
          background: #fff;
          border-radius: 7px;

          img {
            max-height: 120px;
            border-radius: 7px;
          }
        }
      }
    }
  }

  .agentprofile-list {
    border: 0 !important;

    .profile-box {
      border-radius: 3px;
      padding: 10px;
      text-align: center;

      background: rgb(241, 241, 241);
      /* Old browsers */
      background: -moz-linear-gradient(left, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f1f1', endColorstr='#fbfbfb', GradientType=1);
      /* IE6-9 */

      .agent-img {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-height: 70px;
        }
      }

      .agentimg-box {
        border-right: 1px dotted #ccc;
        min-height: 80px;
        margin-right: 15px;
        margin-left: -10px;

        img {
          width: 70px;
          height: 70px;
          border: 1px solid #eee;
          border-radius: 150px;
          -webkit-box-shadow: 0px 3px 0px 4px #d4d4d4;
          -moz-box-shadow: 0px 3px 0px 4px #d4d4d4;
          box-shadow: 0px 3px 0px 4px #d4d4d4;
        }
      }

      .agent-info-box {
        text-align: left;
        font-size: 13px;
        word-break: break-word;

        .agent-info-details {
          margin-bottom: 0.5rem;
        }

        .action {
          text-align: right;

          i {
            padding: 2px 5px;
            cursor: pointer;
            font-size: 16px;
          }
        }

        .name {
          padding-left: 25px;
          border-left: 1px dotted #ddd;
          color: #555555;

          label {
            font-weight: 600;
            margin-right: 5px;
          }
        }

        .channel {
          font-weight: 600;
          font-size: 13px;
          color: #666;
        }
      }

      .info-box {
        margin-top: 20px;
        text-align: left;
        font-size: 13px;

        .other {
          text-align: left;
          color: #555;

          b {
            margin-right: 10px;
            width: 85px;
            display: inline-block;
          }
        }

        .footer {
          margin-top: 10px;
          border-top: 1px dotted #ccc;
          padding: 10px 0 5px 0;
          line-height: 40px;

          label.switch {
            margin: 10px 0 0 0;
          }

          .score-box {
            width: 40px;
            height: 40px;
            padding: 5px 0 0 0;
            font-size: 13px;
            font-weight: 600;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            background-color: #8ec553;
            border: 1px solid #589219;
            border-radius: 20px;
            line-height: normal;

            p {
              font-weight: 100;
              font-size: 8px;
            }
          }

          i.fa.fa-comments {
            font-size: 25px;
            cursor: pointer;
            margin-top: 5px;
            color: #444;
          }

          .proactive {
            line-height: normal;
            margin-top: 4px;
            font-size: 13px;
            color: #444;

            b {
              display: block;
            }
          }
        }
      }
    }
  }

  .widget {
    .display-box {
      width: 50px;
      display: inline-flex;
      margin-left: 10px;
      border: 1px solid #fff;
      -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
    }

    .chrome-picker {
      font-family:
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        sans-serif !important;
    }
  }

  .info-tab {
    .card {
      box-shadow: 0px 0px 70px 5px #ddd;
      border-radius: 6px;
      border: 1px solid #ddd;
      overflow: hidden;
    }

    .card-body {
      padding: 0;
    }

    .box-bg {
      display: flex;
      padding: 10px;
      cursor: pointer;
      min-height: 100px;

      h4 {
        font-size: 15px;
        font-weight: normal;
        margin-bottom: 5px;
        color: #20a8d8;
      }

      p {
        font-size: 13px;
        margin-bottom: 0;
      }
    }

    .box-bg:hover {
      h4 {
        color: #167495;
        text-decoration: underline;
      }
    }

    .box-bg.visitors:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $visitorsClr;
      -moz-box-shadow: 0px 0px 5px 1px $visitorsClr;
      box-shadow: 0px 0px 5px 1px $visitorsClr;
    }

    .box-bg.agents:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $agentsClr;
      -moz-box-shadow: 0px 0px 5px 1px $agentsClr;
      box-shadow: 0px 0px 5px 1px $agentsClr;
    }

    .box-bg.channels:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $channelsClr;
      -moz-box-shadow: 0px 0px 5px 1px $channelsClr;
      box-shadow: 0px 0px 5px 1px $channelsClr;
    }

    .box-bg.msgcenter:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $msgcenterClr;
      -moz-box-shadow: 0px 0px 5px 1px $msgcenterClr;
      box-shadow: 0px 0px 5px 1px $msgcenterClr;
    }

    .box-bg.branding:hover {
      -webkit-box-shadow: 0px 0px 5px 1px $msgcenterClr;
      -moz-box-shadow: 0px 0px 5px 1px $msgcenterClr;
      box-shadow: 0px 0px 5px 1px $msgcenterClr;
    }

    .box-bg::before {
      content: "";
      position: absolute;
      left: 30%;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.4;
    }

    .box-bg.visitors::before {
      background: $visitorsUrl;
    }

    .box-bg.aiconfig::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $agentsUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $agentsUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $agentsUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $agentsUrl;
      /* IE6-9 */
    }

    .box-bg.channels::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $channelsUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $channelsUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $channelsUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $channelsUrl;
      /* IE6-9 */
    }

    .box-bg.accountinfo::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $msgCenterUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $msgCenterUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $msgCenterUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $msgCenterUrl;
      /* IE6-9 */
    }

    .box-bg.branding::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $brandingUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $brandingUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $brandingUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $brandingUrl;
      /* IE6-9 */
    }

    .box-bg.calendar::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $calendarUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $calendarUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $calendarUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $calendarUrl;
      /* IE6-9 */
    }

    .box-bg.department::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $departmentUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $departmentUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0.49) 82%,
          rgba(255, 255, 255, 0) 100%),
        $departmentUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $departmentUrl;
      /* IE6-9 */
    }

    .box-bg.rtmember::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $rtmemberUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $rtmemberUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $rtmemberUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $rtmemberUrl;
      /* IE6-9 */
    }

    .box-bg.paymenttax::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $paymenttaxUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $paymenttaxUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $paymenttaxUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $paymenttaxUrl;
      /* IE6-9 */
    }

    .box-bg.billing::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $billingUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $billingUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $billingUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $billingUrl;
      /* IE6-9 */
    }

    .box-bg.botsettings::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $botsettingsUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $botsettingsUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $botsettingsUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $botsettingsUrl;
      /* IE6-9 */
    }

    .box-bg.promo::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $promoUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $promoUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $promoUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $promoUrl;
      /* IE6-9 */
    }

    .box-bg.intg::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $intgUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $intgUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $intgUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $intgUrl;
      /* IE6-9 */
    }

    .box-bg.security::before {
      background: -moz-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $securityUrl;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $securityUrl;
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0.49) 50%,
          rgba(255, 255, 255, 0) 100%),
        $securityUrl;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1), $securityUrl;
      /* IE6-9 */
    }

    .box-bg {
      h2 {
        font-size: 25px;
        margin: 10px auto;
      }

      p {
        width: 100%;
      }

      img {
        max-width: 70px;
        width: 100%;
      }
    }
  }

  .payment {
    .payment-initbox {
      border: 1px solid #ddd;
      text-align: center;
      padding: 30px 70px;
      width: 500px;
      margin: 40px auto;
      background: #f7f7f7;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);

      .title {
        margin: 15px;
        font-size: 24px;
        font-weight: 600;
        color: #333;
        line-height: 25px;
        word-wrap: break-word;
      }

      .desc {
        margin: 15px auto 25px auto;
        width: 250px;
        color: #666;
      }

      .option {
        margin: 15px auto;
        width: 190px;
      }

      button {
        margin: 10px auto;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
      }
    }

    .payment-rightbox {
      border-right: 1px dotted #ddd;
      padding: 15px 30px 15px 15px;
      min-height: 300px;

      h5 {
        font-weight: bold;
        border-bottom: 1px dotted #ddd;
        padding-bottom: 10px;
        font-size: 15px;
      }
    }

    .payment-leftbox {
      padding: 15px;

      .info {
        font-size: 13px;
        font-style: italic;
        margin-bottom: 10px;
        line-height: normal;
        color: #666;
      }

      button {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 10px;
      }
    }

    .pay-icon {
      padding: 0 30px;
      border-right: 1px dotted #ddd;
      height: 200px;
      width: 100%;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }

    .detail-box {
      background: #f9f9f9;
      border: 1px solid #eee;
      padding: 10px;
      border-radius: 4px;
      color: #444;

      h5 {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .payment-detail-box {
      background: #fff;
      border: 0;
      padding: 0;

      .profile-box {
        height: 100%;
      }

      .row-flex {
        display: flex;
        flex-wrap: wrap;

        .inner-box {
          border: 0;
          height: 100%;
          margin-bottom: 0;

          .aiAgents {
            height: 100%;
          }
        }
      }

      [class*="col-"] {
        margin-bottom: 30px;
      }
    }
  }

  .branding {
    .wgt-position {
      .box {
        position: relative;
        width: 150px;
        height: 117px;
        margin-right: 20px;
        display: inline-block;

        span.icon {
          width: 20px;
          height: 20px;
          border-radius: 15px;
          background: green;
          position: absolute;
        }

        img.icon {
          width: 22px;
          position: absolute;
        }

        .br {
          bottom: 7px;
          right: 7px;
        }

        .bl {
          bottom: 7px;
          left: 7px;
        }

        .tr {
          top: 19px;
          right: 7px;
        }

        .tl {
          top: 19px;
          left: 7px;
        }

        .active {
          border: 1px solid #a7a7a7;
          border-radius: 10px;
          overflow: hidden;
          background: rgba(240, 243, 245, 0.6);
          position: absolute;
          height: 100%;
          width: 100%;
          color: #000;
          text-align: center;
          padding-top: 28%;
          font-weight: bold;
        }
      }
    }

    .action-btn-box {
      text-align: right;
      display: inline-block;
      position: absolute;
      z-index: 99;
      left: 15px;
      top: 15px;

      button {
        margin: 0 5px;
        border-radius: 100px;
        width: 35px;
        height: 35px;
        background-color: rgba(255, 255, 255, 1);
        border-color: #fff;
        opacity: 0.6;
        font-size: 16px;
        float: left;
      }

      button.upload {
        color: #000;
      }

      button.delete {
        font-size: 18px;
        color: #ff0000;
      }

      button:hover {
        opacity: 1;
      }
    }

    .wgt-size {
      .box {
        position: relative;
        width: 150px;
        height: 117px;
        margin-right: 20px;
        display: inline-block;

        .wgt-box {
          position: absolute;
          width: 30px;
          background: #ffffff;
          height: 40px;
          right: 7px;
          bottom: 7px;
          border-radius: 4px;
          border: 1px solid #1c73b9;
          border-top: 10px solid #1c73b9;

          .wgt-icon {
            width: 7px;
            height: 7px;
            background: #ffdc69;
            display: inline-block;
            position: absolute;
            top: -4px;
            left: 2px;
            border: 1px solid #000;
          }
        }

        .sm {
          height: 40px;
        }

        .md {
          height: 60px;
        }

        .lg {
          height: 80px;
        }

        .active {
          border: 1px solid #a7a7a7;
          border-radius: 10px;
          overflow: hidden;
          background: rgba(240, 243, 245, 0.6);
          position: absolute;
          height: 100%;
          width: 100%;
          color: #000;
          text-align: center;
          padding-top: 28%;
          font-weight: bold;
        }
      }
    }

    .input-lbl {
      font-weight: 600;
    }
  }

  .bot-settings {
    .feature-box {
      margin-top: 10px;
    }

    .btn-action {
      float: right;
      margin-left: 10px;
      margin-top: 0;
      background: #ffffff;
      border-radius: 4px;
      color: #666;
    }
  }

  .icon-pwd {
    border: 1px solid #c2cfd6;
    border-width: 1px 1px 1px 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-left: -4px;
  }

  .role-team-members {
    .department {
      .page-head-right {
        margin-top: -60px;
      }
    }

    .appointment {
      .page-head-right {
        margin-top: -60px;
      }
    }
  }

  .integrations {
    .feature-box {
      .input-box {
        float: left;
        width: calc(100% - 60px);
      }

      .btn-box {
        float: left;
        width: 50px;
        margin-left: 10px;
        line-height: 30px;
      }
    }
  }

  .security {
    .security-box {
      margin-bottom: 30px;

      .input-key {
        width: calc(100% - 150px);
        float: left;

        input {
          border-radius: 4px 0 0 4px !important;
        }
      }

      .generate-btn {
        width: 150px;
        border-radius: 0 4px 4px 0;
      }
    }

    .auth-box {
      border: 1px solid #eee;
      padding-top: 30px;
      border-width: 1px 0;

      .box {
        margin-bottom: 30px;

        .left {
          width: calc(100% - 250px);
          float: left;

          .title {
            font-weight: 600;
            margin-bottom: 10px;
          }

          .desc {
            color: #666;
          }
        }

        .right {
          float: right;
          padding-top: 25px;
        }
      }
    }

    .auth-box {
      .switch.switch-default {
        height: 20px;
        margin: 2px 20px 0;
      }

      .switch-handle {
        height: 16px;
      }
    }

    .data-box {
      padding-top: 30px;

      .title {
        font-weight: 600;
        margin-bottom: 10px;
      }

      .desc {
        color: #666;
        margin-bottom: 20px;
      }

      .link {
        height: 30px;

        &>span {
          width: 300px;
          display: inline-block;
        }

        .switch.switch-default {
          height: 20px;
          margin: 2px 20px 0;
        }

        .switch-handle {
          height: 16px;
        }
      }
    }
  }

  .access-management {
    .am-list {
      p.title {
        color: #777;
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      a.link {
        line-height: 25px;
        display: block;
        padding: 5px 10px;
        border-bottom: 1px dotted #eee;
        border-left: 2px solid #fff;
        color: #20a8d8;
        cursor: pointer;

        .name {
          margin-right: 20px;
          width: calc(100% - 130px);
          display: inline-block;
        }

        .switch.switch-default {
          height: 20px;
          margin: 2px 20px 0;
        }

        .switch-handle {
          height: 16px;
        }

        .type {
          color: #999;
        }
      }

      a.link:hover {
        background-color: #f5f5f5;
        text-decoration: none;
        border-left: 2px solid;
      }

      a.link.active {
        background-color: #edf2f4;
        border-left: 2px solid #20a8d8;
      }
    }

    .am-content {
      p.title {
        color: #777;
        padding-bottom: 5px;
        margin-bottom: 0;
        text-transform: uppercase;
        border-bottom: 1px solid #ddd;
      }

      .disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .list {
        padding-top: 3px;

        .item {
          padding: 6px 10px;
          border-bottom: 1px dotted #eee;

          .name {
            margin-right: 20px;
            width: calc(100% - 130px);
            display: inline-block;
          }

          .switch.switch-default {
            height: 20px;
            margin: 2px 20px 0;
          }

          .switch-handle {
            height: 16px;
          }

          .type {
            color: #999;
          }
        }

        .item:hover {
          background: #f5f5f5;
        }
      }
    }

    .report-access-link {
      color: #20a8d8;
      cursor: pointer;
    }
  }

  .unsubscribe-account {
    .sec-1 {
      .title {
        font-size: 20px;
      }

      .radio {
        margin: 22px 0 25px 0;
        padding-left: 10px;

        .radio-style {
          float: left;
          width: 35px;
        }

        .radio-style .checkmark {
          width: 20px;
          height: 20px;
        }

        .radio-style .checkmark:after {
          left: 5px;
          top: 5px;
        }

        .content {
          float: left;
          font-size: 16px;

          .ct {
            font-size: 18px;
          }

          a.lnk {
            display: inline-block;
            border-bottom: 1px solid;
          }

          .cd {
            color: #777;
          }
        }
      }
    }

    .sec-2 {
      .qa-title {
        font-size: 18px;
        margin-bottom: 5px;
      }

      .qa-desc {
        font-size: 16px;
        margin-bottom: 20px;
        color: #777;
      }

      .qa {
        margin-bottom: 15px;
        line-height: 22px;

        .que {
          font-weight: 600;
        }

        .input-style {
          max-width: 500px;
        }
      }

      .r-button {
        display: block;

        .form-group {
          display: inline-block;
          width: 30px;
        }
      }
    }
  }

  .channels {
    .channel-list {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      overflow: hidden;

      .box {
        width: 165px;
        border: 1px solid #ddd;
        float: left;
        text-align: center;
        padding: 20px 0;
        border-radius: 5px;
        margin: 10px;
        overflow: hidden;

        .img-box {
          height: 50px;
          position: relative;

          img {
            max-height: 80%;
            max-width: 60%;
            width: auto;
            height: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
        }

        .title {
          margin: 15px 5px 5px 5px;
        }

        .desc {
          color: #999;
          font-size: 13px;
          margin-bottom: 20px;
        }

        .switch.switch-default {
          height: 20px;
          margin: 2px 20px 0;
        }

        .switch-handle {
          height: 16px;
        }
      }
    }

    .channel-desc {
      color: #666;
      margin-bottom: 30px;
      line-height: 25px;
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;

      p {
        margin-bottom: 10px;
      }
    }

    .channelForm {
      p {
        color: #888;
      }
    }
  }

  .department {
    .dep-table {
      margin-top: 10px;
      width: 100%;
      border: 0;

      .form-group {
        margin-bottom: 0;
      }
    }

    .rule-box {
      display: flex;
      justify-content: space-between;

      .rule-sec {
        width: calc(100% - 60px);
      }

      .rule-btn {
        width: 50px;
        padding-top: 5px;
      }
    }
  }

  .sdk-integration {
    .box-link {
      color: inherit;

      &:hover {
        box-shadow: 0 0 6px #ccc;
        border-radius: 6px;
        cursor: pointer;

        a {
          text-decoration: none;
        }
      }
    }

    .sdk-integration-box {
      border-radius: 3px;
      padding: 26px;
      background: #f1f1f1;
    }

    .sdk-integration-title {
      color: #20a8d8;
      margin-bottom: 10px;
    }

    .sdk-integration-desc {
      color: #808080;
    }
  }

  .app-config {
    .attr-container {
      width: calc(100% - 100px);
    }

    .attr-button {
      .group-button {
        cursor: pointer;
        transition: all 0.25s;

        &:hover {
          transform: scale(1.2);
        }

        &.disabled {
          opacity: 0.25;
          pointer-events: none;
        }
      }
    }

    .attr-close-button {
      width: 33px;
    }

    .attr-move-button {
      width: 66px;
    }

    .creatable-select {
      width: 25%;

      .selectable__control {
        border-radius: 4px 0 0 4px;
      }

      .selectable__indicator-separator {
        background-color: transparent;
      }
    }

    .key-input {
      width: 25%;

      .form-control {
        border-radius: 4px 0 0 4px !important;
        min-height: 38px;
      }
    }

    .value-input {
      width: 75%;

      .form-control {
        border-radius: 0 4px 4px 0 !important;
        border-left: none;
        min-height: 38px;
      }
    }
  }
}

/* All Contacts */
.all-cnt {
  .list-notfound {
    border-top: 0;
    text-align: center;
    background: #f2f2f3;
    border: 1px solid #a4b7c1;
  }
}

.all-contacts {
  .dropdown {
    button {
      i {
        color: #151b1e;
      }
    }
  }

  .col-filterbox {
    padding: 0;

    label {
      display: block;
      margin: 0;
      padding: 10px;
      cursor: pointer;
    }
  }

  .dropdown-menu-right {
    position: relative;

    .dropdown-menu {
      -ms-transform: none !important;
      -webkit-transform: none !important;
      transform: none !important;
      right: 0;
      left: auto !important;
      top: 35px !important;
    }
  }

  .search-box-outer {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;

    .engagement {
      display: flex;
      position: absolute;
      right: 2%;

      .engagementOption {
        min-width: 150px;
        margin-left: 10px;
      }

      .drop {
        margin-left: 40px;
      }
    }

    .ctColSearch {
      width: 15%;
      float: left;
      border-radius: 4px 0 0 4px !important;
    }

    .ctColSearch:focus {
      outline: 0;
      box-shadow: none;
      border-color: #c2cfd6;
    }

    .search-box {
      width: 250px;
      float: left;

      .input-group-addon {
        cursor: pointer;
        margin-right: -3px;

        .input-group-text {
          background: transparent;
          border-right: 0;
          border-left: 0;
        }
      }

      input {
        border-left: 0;
        padding-left: 0;
        border-radius: 0 4px 4px 0 !important;
      }

      input:focus {
        outline: 0;
        box-shadow: none;
        border-color: #c2cfd6;
      }
    }
  }

  .datepicker-box {
    span {
      margin-right: 10px;
    }

    .rdt {
      display: inline-block;

      input {
        width: 120px;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }

      input[readonly] {
        background: none;
      }

      input:focus {
        outline: none !important;
        outline-offset: 0 !important;
        border-color: #c2cfd6;
        box-shadow: none;
      }
    }

    .fa-calendar {
      padding: 9px 9px 10px 9px;
      border: 1px solid #c2cfd6;
      border-left: 0;
      background: #c2cfd6;
      cursor: pointer;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .ag-react-container {
    width: 40px;
  }
}

/* regex sytle(REFACTOR)*/
.valid-regex-icon {
  position: absolute;
  right: 375px;
  margin-top: 8px;
}

.reg-test {
  display: flex;
}

.reg-test-input {
  width: 100%;
}

/*regex end*/

/* import contact style */
.import-contacts {
  .type-selection {
    label {
      cursor: pointer;
    }

    b {
      font-weight: 600;
    }

    p {
      color: #666;
    }
  }
}

.contactList:hover {
  border: 1px solid #ddd;
  background: #f6f8f9;
  background: rgba(237, 241, 244, 0.5);
}

/* contact service style */
.conatct-service {
  .box {
    position: relative;
    cursor: pointer;
    border: 1px solid #ddd;
    padding: 10px;
    background: rgba(237, 241, 244, 0.5);
    border-radius: 4px;
    height: 180px;
    display: flex;
    justify-content: center;

    .img-box {
      width: 160px;

      img {
        object-fit: contain;
        height: 40px;
        width: 40px;
      }
    }
  }

  .box.active:before {
    content: "\f00c";
    font-family: FontAwesome;
    position: absolute;
    right: 5px;
    top: 5px;
    border: 1px solid #56636c;
    width: 20px;
    border-radius: 50%;
    background: #56636c;
    color: #fff;
    height: 20px;
    text-align: center;
    font-size: 12px;
    line-height: 19px;
  }

  .list-box {
    position: relative;
    cursor: pointer;
    border: 1px solid #ddd;
    padding: 10px;
    height: 100%;
    border-radius: 4px;
    width: 100%;
    line-height: 25px;

    span {
      font-weight: 600;
      margin-right: 5px;
    }
  }

  .list-box.active {
    border: 1px solid #ddd;
    background: #f6f8f9;
    background: rgba(237, 241, 244, 0.5);
  }

  .list-box.active:before {
    content: "\f00c";
    font-family: FontAwesome;
    position: absolute;
    right: 5px;
    top: 5px;
    border: 1px solid #56636c;
    width: 20px;
    border-radius: 50%;
    background: #56636c;
    color: #fff;
    height: 20px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
  }
}

/* Bulk upload contacts */
.contact-bulk-upload {
  .upload-box {
    label {
      width: 115px;
    }

    input {
      max-width: 350px;
      font-size: 13px;
      margin-top: 3px;
    }

    button {
      margin-left: 115px;
      margin-top: 10px;
      width: 130px;
    }
  }

  .download-box {
    border-left: 3px dotted #ddd;
    padding: 20px 0 20px 50px;
    font-style: italic;
    font-size: 13px;
    color: #666;

    button {
      margin-top: 10px;
      font-size: 12px;
      text-transform: uppercase;
      color: #444;
      background: #f0f3f5;
    }
  }
}

/* Appointment Screen */
.appointment-style {
  &>div:first-child {
    z-index: inherit !important;
  }

  section {
    margin: 0 auto !important;
    max-width: calc(100% - 2px) !important;
  }

  h1 {
    font-size: 18px !important;
    line-height: normal !important;
    height: auto !important;
    padding: 10px 0 !important;
  }

  h2 {
    font-size: 14px !important;
    padding: 10px !important;
    margin-bottom: 0;
  }

  .appointment {
    text-align: left;
  }

  .appointment-date {
    margin-left: 0 !important;
  }

  .appointment-date>div {
    font-size: 13px !important;
  }

  .sloat-btn {
    margin-left: 0 !important;
    font-size: 13px;

    label {
      width: calc(100% - 40px) !important;
    }
  }

  .sloat-btn>div {
    margin-bottom: 10px !important;
  }

  .sloat-btn>div>div>div {
    margin-right: 5px !important;
  }

  .form-content {
    padding-right: 0 !important;

    .form-field {
      width: auto !important;
      font-size: 13px !important;

      button {
        display: inline-block !important;
        line-height: normal !important;
        padding: 5px 2px !important;
        height: auto !important;

        div {
          height: auto !important;

          span {
            font-size: 13px !important;
            padding: 5px !important;
            display: inline-block !important;
          }
        }
      }
    }
  }
}

.confirm-appointment {
  h3 {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  section {
    font-size: 14px !important;
  }
}

.appointmentTime {
  .rc-time-picker {
    .rc-time-picker-input {
      height: calc(1.5em + 0.75rem + 2px) !important;
      padding: 0.375rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      color: #3e515b;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #c2cfd6;
      border-radius: 0;
    }
  }
}

.file-upload-box {
  cursor: pointer;
  border: 1px dotted #999;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  padding: 25px 10px;
  color: #663d7c;
  font-weight: 600;
  background: #f2f5f9;
  height: 140px;

  .fa {
    font-size: 40px;
    color: #663d7c;
    margin-bottom: 10px;
  }
}

.file-upload-box.done {
  cursor: auto;
}

.yellow-star {
  color: #663d7c;
}

/* Dashboard style */
.dashboard {
  .time-format-btn {
    h4 {
      display: inline-block;
    }

    button.btn {
      border: 1px solid #999;
      background: #fff;
      font-size: 13px;
    }

    button.btn.active {
      background-color: #20a8d8;
      color: #fff;
    }

    button:focus {
      box-shadow: none;
    }

    .desc {
      margin-right: 10px;
      color: rgb(119, 119, 119);
      font-size: 12px;
      font-weight: 400;
      line-height: 35px;
    }
  }

  .wgt-box {
    .traffic-loading-box {
      min-height: 113px;
      width: calc(20% - 10px);
      margin: 0 5px;
      float: left;
    }

    .loading-box {
      min-height: 140px;
      margin-bottom: 20px;
    }

    .info-box {
      cursor: pointer;
      min-height: 100px;
      background: #fff;
      width: 100%;
      box-shadow: 0 5px 5px rgba(132, 132, 132, 0.1);
      -webkit-box-shadow: 0 5px 5px rgba(132, 132, 132, 0.1);
      margin-bottom: 20px;
      padding: 15px;

      .channel-tick {
        position: absolute;
        right: 25px;
        top: 10px;
        border-radius: 15px;
        background: #666;
        padding: 4px;
        border: 1px solid #fff;
        color: #fff;
      }

      .info-box-icon {
        float: left;
        height: 70px;
        width: 70px;
        text-align: center;
        font-size: 30px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 100%;
        padding: 7px;

        img {
          height: 100%;
          width: 100%;
        }

        .icon-title {
          font-size: 14px;
          margin: 10px 0 0 0;
        }
      }

      .info-box-content {
        padding: 10px 10px 10px 0;
        margin-left: 90px;

        .info-box-text,
        .progress-description {
          display: block;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
          margin-right: -10px;
        }

        .info-box-text {
          text-transform: uppercase;
          display: inline;
          margin-right: 10px;
        }

        .info-box-number {
          font-weight: 300;
          font-size: 14px;
          background: #e5e5e5;
          color: #000;
          border-radius: 25px;
          padding: 0px 8px 1px 7px;
        }

        .progress {
          border: none;
          background: rgba(0, 0, 0, 0.2);
          margin: 5px -10px 5px 0;
          height: 2px;

          .progress-bar {
            background: #fff;
          }
        }

        .info-box-inner {
          line-height: 30px;
        }
      }
    }

    .bg-blue {
      background-color: #45aef1 !important;
    }

    .bg-blue-light {
      background-color: rgba(69, 174, 241, 0.3) !important;
    }

    .bg-blue-light-more {
      background-color: rgba(69, 174, 241, 0.1) !important;
    }

    .bg-green {
      background-color: #46be8a !important;
    }

    .bg-green-light {
      background-color: rgba(70, 190, 138, 0.3) !important;
    }

    .bg-green-light-more {
      background-color: rgba(70, 190, 138, 0.1) !important;
    }

    .bg-purple {
      background-color: #926dde !important;
    }

    .bg-purple-light {
      background-color: rgba(146, 109, 222, 0.3) !important;
    }

    .bg-purple-light-more {
      background-color: rgba(146, 109, 222, 0.1) !important;
    }

    .bg-red {
      background-color: #f96868 !important;
    }

    .bg-red-light {
      background-color: rgba(249, 104, 104, 0.3) !important;
    }

    .bg-red-light-more {
      background-color: rgba(249, 104, 104, 0.1) !important;
    }

    .sale-box {
      background-color: #fff;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

      .sale-info-main {
        color: #333;
        padding: 15px;

        .info-main {
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 5px;
        }

        .info-sub {
          text-transform: uppercase;
          font-size: 13px;
        }

        .info-value {
          float: right;
        }
      }

      .sale-graph {
        padding: 15px;

        img {
          height: 100px;
        }
      }

      .deal-income {
        padding: 15px;

        .info {
          width: 50%;
          display: inline-block;
          font-size: 15px;
          text-align: center;

          b {
            font-size: 18px;
            display: block;
          }
        }

        .info:first-child {
          border-right: 1px solid #ddd;
        }
      }
    }
  }

  .traffic-box {
    padding: 15px;
    margin: 20px 0;
    background-color: #fff;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

    .traffic-box-detail {
      float: right;
      margin-top: -30px;
      color: #777;
      font-size: 11px;
    }

    .progress-group {
      .progress-text {
        font-weight: 600;
      }

      .progress-number {
        float: right;
      }

      .progress {
        height: 10px;
        margin-bottom: 20px;

        .progress-bar {
          border-radius: 1px;
        }

        .progress-bar-aqua {
          background-color: #48b0f7;
        }

        .progress-bar-red {
          background-color: #f96868;
        }

        .progress-bar-green {
          background-color: #46be8a;
        }

        .progress-bar-yellow {
          background-color: #f2a654;
        }

        .progress-bar-purple {
          background-color: #926dde;
        }
      }
    }
  }

  .tbl-user-order {
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

    table {
      margin-top: 10px;
      border: 0;

      thead {
        tr {
          border: 0;

          th {
            border-top: 1px solid #ddd;
            border-bottom-width: 1px;
          }
        }
      }

      tbody {
        td {
          border-color: #f5f5f5;
        }
      }
    }

    .lnk {
      text-align: right;
      display: block;
    }
  }

  .arrow-btn {
    position: relative;

    .slider-arrow {
      cursor: pointer;
      position: absolute;
      width: 100%;
      z-index: 1;
      top: 65px;
      -webkit-text-fill-color: #f0f3f4;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #20a8d8;

      .left {
        position: absolute;
        left: 0;
      }

      .right {
        position: absolute;
        right: 0;
      }
    }
  }

  .slider {
    margin: 0 15px;
  }

  .traffic-info {
    .i-box {
      background: #fff;
      width: calc(20% - 10px);
      float: left;
      margin: 0 5px;
      padding: 10px;
      border: 1px solid #ddd;
      border-top: 5px solid #6abef4;
      box-shadow: 0 5px 5px rgba(132, 132, 132, 0.1);
      -webkit-box-shadow: 0 5px 5px rgba(132, 132, 132, 0.1);

      p {
        margin-bottom: 0;
      }

      .i-title {
        color: #666;
        font-size: 16px;
        // font-weight: 600;

        .i-icon {
          margin-left: 7px;
          font-size: 18px;
          color: #999;
          font-style: italic;
          cursor: pointer;
        }
      }

      .i-number {
        font-size: 24px;
        font-weight: 600;
        margin-top: 10px;
      }

      .i-desc {
        font-size: 13px;
        color: #777;
      }
    }

    .i-box:hover {
      .i-title {
        color: #6abef4;
      }
    }
  }
}

.analytics {
  .time-format-btn {
    .nav-bar {
      float: left;
    }

    button.btn {
      border: 1px solid #999;
      background: #fff;
      font-size: 13px;
    }

    button.btn.active {
      background-color: #20a8d8;
      color: #fff;
    }

    button:focus {
      box-shadow: none;
    }
  }

  .analytics-tab {
    .analytics-arrow {
      cursor: pointer;
      position: absolute;
      width: 100%;
      z-index: 1;
      top: 35px;
      -webkit-text-fill-color: #f0f3f4;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #20a8d8;

      .left {
        position: absolute;
        left: 0;
      }

      .right {
        position: absolute;
        right: 0;
      }
    }

    .alice-carousel {
      padding: 0 35px !important;
    }

    .channel-list-placeHolder {
      .channel-placeHolder {
        padding: 10px 20px;
        height: 90px;
      }
    }

    .channel-list {
      .channel {
        background: #f5f5f5;
        border: 1px solid #ddd;
        padding: 10px 20px;
        border-radius: 2px;
        cursor: pointer;

        img {
          width: 20%;
          float: left;
          margin-top: 4px;
        }

        .content {
          float: right;
          text-align: right;

          .title {
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 600;
            color: #666;
          }

          .rate {
            font-size: 22px;
            font-weight: 700;
            margin-top: 10px;
          }

          .rate-title {
            color: #666;
          }
        }
      }

      .channel.active {
        border: 1px solid #c2cfd6;
        background: rgba(249, 103, 103, 0.1);
      }
    }

    .channel-analytics {
      color: #666;
      font-size: 13px;

      .c1 {
        background-color: #55b7f7;
      }

      .c2 {
        background-color: #56a0d6;
      }

      .c3 {
        background-color: #2d71a2;
      }

      .c4 {
        background-color: #1d4f71;
      }

      .c5 {
        background-color: #85c1e2;
      }

      .c6 {
        background-color: #78c9fa;
      }

      .counter-placeHolder {
        color: #fff;
        padding: 10px;
        height: 74px;
      }

      .counter {
        color: #fff;
        padding: 10px;

        .content {
          .count {
            font-size: 22px;
            font-weight: 600;
          }

          .count-title {
            text-transform: uppercase;
            font-size: 13px;
            letter-spacing: 1px;
          }
        }

        i {
          float: right;
          position: absolute;
          top: 19px;
          right: 30px;
          font-size: 35px;
          color: rgba(255, 255, 255, 0.4);
        }
      }

      h4 {
        font-size: 18px;
        margin-bottom: 0;
      }

      .conversion {
        background: #f0f3f4;
        padding: 15px;
        height: 100%;

        .group {
          margin-top: 15px;
          width: 100%;

          .title {
            text-transform: uppercase;
            font-weight: 600;
            width: 60%;
          }

          .content {
            width: 100%;
            border: 0;
            margin: 10px 0 30px 10px;

            .item {
              margin-bottom: 5px;

              .name {
                font-weight: 600;
                text-decoration: none;
                font-size: 12px;
                width: 60%;
              }

              .name:not(a) {
                color: #888;
              }

              .line {
                width: 100%;
                line-height: 15px;

                .line-progress {
                  display: inline-block;
                  background: #01719c;
                  height: 10px;
                }

                span {
                  width: 35px;
                  display: inline-block;
                  text-align: right;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }

      .group-placeholder {
        margin-top: 15px;
        width: 100%;
        height: 158px;
      }

      .sessions {
        background: #f0f3f4;
        padding: 15px;

        img {
          margin: 10px 0;
          height: 190px;
        }
      }

      .traffic {
        background: #f0f3f4;
        padding: 15px;
        min-height: 261px;

        img {
          margin: 30px 0;
        }

        .item {
          margin: 13.5px 0;

          .indicator {
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-right: 5px;
          }

          .rate {
            font-weight: 600;
            width: 35px;
            display: inline-block;
          }

          .name {}
        }
      }

      .visitors {
        background: #f0f3f4;
        padding: 15px;

        .item {
          margin-top: 20px;

          .box {
            float: left;
            display: inline-block;
            width: calc(25% - 6px);
            height: 219px;
            margin: 0 3px;
            position: relative;
            font-size: 11px;
            font-weight: 600;

            .rate {
              position: absolute;
              text-align: center;
              width: 100%;
            }

            .indicator {
              display: block;
              position: absolute;
              background-color: #2d71a2;
              width: 100%;
              bottom: 25px;
            }

            .name {
              position: absolute;
              bottom: 0;
              text-align: center;
              width: 100%;
            }
          }
        }
      }

      .engagement-rate {
        background: #f0f3f4;
        padding: 15px;

        img {
          margin: 10px 0;
          height: 219px;
        }
      }
    }
  }

  .engagement-tab {
    .page-head-right {
      width: 300px;
      margin-left: 30px;
      float: right;

      .ddl {
        width: calc(100% - 85px);
        display: inline-block;
      }
    }

    .eng-graph {
      // width: calc(100% - 280px);
      // display: inline-block;
      // float: left;
      margin-bottom: 20px;

      .g-box-loading {
        width: 100%;
        min-height: 120px;
        padding: 10px;
        box-shadow: 0 5px 5px hsla(0, 0%, 52%, 0.1);
        -webkit-box-shadow: 0 5px 5px hsla(0, 0%, 52%, 0.1);
      }

      .g-box {
        width: 100%;
        min-height: 120px;
        background: #f5f5f5;
        padding: 10px;
        border: 1px solid #ddd;
        border-top: 5px solid #6abef4;
        box-shadow: 0 5px 5px hsla(0, 0%, 52%, 0.1);
        -webkit-box-shadow: 0 5px 5px hsla(0, 0%, 52%, 0.1);

        .g-title {
          color: #666;
          font-weight: 600;
        }

        .g-left {
          float: left;
          width: 90px;

          .g-title {
            color: #666;
            font-size: 15px;
            font-weight: 600;
          }

          .g-percent {
            font-size: 24px;
            font-weight: 600;
            margin-top: 10px;
          }
        }

        .g-right {
          float: left;
          width: calc(100% - 90px);
        }
      }

      .gc1 {
        border-top-color: rgba(70, 190, 138, 0.8);
        color: rgba(70, 190, 138, 0.8);
      }

      .gc2 {
        border-top-color: rgba(146, 109, 222, 0.8);
        color: rgba(146, 109, 222, 0.8);
      }

      .gc3 {
        border-top-color: rgba(249, 104, 104, 0.8);
        color: rgba(249, 104, 104, 0.8);
      }

      .gc4 {
        border-top-color: rgba(158, 118, 0, 0.8);
        color: rgba(158, 118, 0, 0.8);
      }

      .g-details {
        .detail-box {
          clear: both;
          line-height: 24px;

          .title {
            float: left;
            color: #666;
          }

          .amt {
            float: right;
          }
        }
      }
    }

    .wgt-box {
      .traffic-loading-box {
        min-height: 94px;
        width: calc(20% - 10px);
        margin: 0 5px;
        float: left;
      }

      .loading-box {
        min-height: 140px;
        margin-bottom: 20px;
      }
    }

    .traffic-info {
      .i-box {
        background: #fff;
        width: calc(20% - 10px);
        float: left;
        margin: 0 5px;
        padding: 10px;
        border: 1px solid #ddd;
        border-top: 5px solid #6abef4;
        box-shadow: 0 5px 5px rgba(132, 132, 132, 0.1);
        -webkit-box-shadow: 0 5px 5px rgba(132, 132, 132, 0.1);

        p {
          margin-bottom: 0;
        }

        .i-title {
          color: #666;
          font-size: 15px;
          font-weight: 600;

          .i-icon {
            margin-left: 7px;
            font-size: 18px;
            color: #999;
            font-style: italic;
            cursor: pointer;
          }
        }

        .i-number {
          font-size: 24px;
          font-weight: 600;
          margin-top: 10px;
        }

        .i-desc {
          font-size: 13px;
          color: #777;
        }
      }
    }

    .traffic-box {
      padding: 15px;
      margin: 20px 0;
      background-color: #fff;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

      .traffic-box-detail {
        float: right;
        margin-top: -30px;
        color: #777;
        font-size: 11px;
      }

      .progress-group {
        .progress-text {
          font-weight: 600;
        }

        .progress-number {
          float: right;
        }

        .progress {
          height: 10px;
          margin-bottom: 20px;

          .progress-bar {
            border-radius: 1px;
          }

          .progress-bar-aqua {
            background-color: #48b0f7;
          }

          .progress-bar-red {
            background-color: #f96868;
          }

          .progress-bar-green {
            background-color: #46be8a;
          }

          .progress-bar-yellow {
            background-color: #f2a654;
          }

          .progress-bar-purple {
            background-color: #926dde;
          }
        }
      }
    }
  }

  .c-list {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .c-box {
      cursor: pointer;
      border: 1px solid #ddd;
      position: relative;
      border-radius: 5px;
      margin: 10px;
      width: 300px;
      padding: 20px;

      img {
        width: 90px;
      }

      .title {
        margin: 20px 0 5px 0;
        font-weight: 600;
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 7px;
      }

      .desc {
        font-style: italic;
        color: #777;
      }

      .dt {
        position: absolute;
        top: 20px;
        right: 20px;
        color: #777;
      }
    }

    .c-box:hover {
      background: rgba(237, 241, 244, 0.5);
    }
  }
}

.custom-analytics {
  height: calc(100vh - 180px);
  border: 1px solid #ddd;
  width: 100%;
}

.box-shadow {
  box-shadow: 0 5px 5px rgba(132, 132, 132, 0.1);
  -webkit-box-shadow: 0 2px 10px rgba(132, 132, 132, 0.1);
  border: 1px solid #ddd;
}

.loads {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeload;
  animation-name: placeload;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eae4e4;
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(8%, #eae4e4),
      color-stop(18%, #dddddd),
      color-stop(33%, #eae4e4));
  background: -webkit-linear-gradient(left, #eae4e4 8%, #dddddd 18%, #eae4e4 33%);
  background: linear-gradient(to right, #eae4e4 8%, #dddddd 18%, #eae4e4 33%);
  -webkit-background-size: 800px 104px;
  background-size: 1200px 104px;
  position: relative;
}

@-webkit-keyframes placeload {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes placeload {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@media (max-width: 767px) {

  .products,
  .profile,
  .fulfilment {
    .input-lbl {
      text-align: left;
    }
  }

  .aiAgents {
    .grid-style {
      padding-right: 0;
    }

    .training-status {
      border-left: 0;
    }
  }
}

/* togetherjs design changes */
#togetherjs-dock,
#togetherjs-intro,
#togetherjs-modal-background,
#togetherjs-walkthrough {
  display: none !important;
}

.togetherjs-cursor .togetherjs-cursor-container {
  display: inline-block;
}

.stop-screenshare {
  position: fixed;
  z-index: 999999999;
  right: 0;
  top: 0;
  padding: 3px 10px 5px;
  text-transform: uppercase;
  border: 1px solid #fff;
  color: #ffffff;
  border-radius: 0 0 0 3px;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
  background: #a74545;
}

// #togetherjs-container{
//   display: none
// }
/* togetherjs design changes end */

.preview-page {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  background: #fff;
  width: 100%;
  height: 100%;

  .preview-title {
    background: #ddd;
    border-bottom: 1px solid #aaa;
    padding: 5px 10px;
    font-weight: bold;

    a {
      float: right;
      color: #000;
      font-weight: bold;
    }
  }
}

.formchat-style {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  color: #646464;
  font-size: 13px;
  margin: 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  word-wrap: break-word;

  h5 {
    background-color: rgb(0, 188, 212);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-shadow:
      rgba(0, 0, 0, 0.12) 0px 1px 6px,
      rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 0px;
    position: relative;
    z-index: 1100;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    color: rgb(255, 255, 255);
    font-size: 18px;
    line-height: normal;
    padding: 10px 0;
    font-weight: 700;
  }

  .form-fields {
    padding: 5px 10px 10px 10px;

    label.form-control-label {
      margin: 10px 0 3px;
      color: #52bcd5;
    }

    input.form-control,
    textarea.form-control {
      border: 0;
      border-bottom: 1px solid #ddd;
      padding-left: 0;
    }

    button {
      margin-top: 10px;
      border: 10px;
      box-sizing: border-box;
      font-family: Roboto, sans-serif;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      text-decoration: none;
      padding: 0px;
      outline: none;
      font-weight: inherit;
      height: 36px;
      line-height: 36px;
      width: 100%;
      border-radius: 2px;
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      background-color: rgb(0, 188, 212);
      text-align: center;
      text-transform: uppercase;
    }

    input:focus,
    textarea:focus {
      outline: 0;
      box-shadow: none;
      border-color: #52bcd5;
    }

    button:focus,
    button:hover {
      outline: 0;
      box-shadow: none;
      background: rgba(0, 188, 212, 0.7);
    }
  }
}

// widget action menu
.wgt-action-menu {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-width: 1px 0;
  position: absolute;
  bottom: 60px;
  width: 100%;
  height: 180px;

  .action {
    display: inline-block;
    float: left;
    text-align: center;
    padding: 15px;
    cursor: pointer;
    width: 33.33%;

    img {
      width: 25px;
      height: 25px;
      margin: auto;
    }

    span {
      display: block;
      margin-top: 10px;
      color: #666;
      font-size: 13px;
    }
  }
}

.core-comp.autocomplete {
  position: relative;

  input {
    width: 100%;
  }

  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }

  .suggestions {
    border: 1px solid #ddd;
    border-top-width: 0;
    list-style: none;
    max-height: 143px;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    margin: 0;
    width: 100%;
    background: #fff;
  }

  .suggestions li {
    padding: 0.5rem;
  }

  .suggestion-active,
  .suggestions li:hover {
    background: #20a8d8;
    color: #fff;
    cursor: pointer;
  }

  // .suggestions li:not(:last-of-type) {
  //   border-bottom: 1px solid #999;
  // }
}

.core-comp.autocomplete.btt {
  .suggestions {
    bottom: 100% !important;
    border-bottom-width: 1px;
    border-top-width: 1px;
  }
}

.core-comp.autocomplete.chat-auto-complete {
  ul.suggestions {
    margin-bottom: 6px;
    width: calc(100% + 12px);
    margin-left: -6px;
  }
}

#frame {
  .content {
    .messages {
      ul {
        width: 100%;
        float: left;
        margin-left: auto;
        padding-left: 0;
        margin-bottom: 0;

        li {
          .chat-time {
            text-align: right;
            display: block;
            font-size: 10px;
            font-style: italic;
            color: #999;
            line-height: 25px;

            .chat-dot {
              background-color: #48a3cd;
              display: inline-block;
              height: 4px;
              width: 4px;
              border-radius: 50%;
              margin: 12px 6px 2px 6px;
            }

            .chat-agentname {
              color: #5f6368;
            }
          }

          .chat-thumb {
            width: 28px;
            height: 28px;
            margin-bottom: 0;
            border: 1px solid #ddd;
            float: left;
            margin: 0 5px 0 0;
            border-radius: 50%;
          }

          .listType {
            color: #949495;
            font-size: 14px;
            border: 1px solid #e7e7e7;
            border-radius: 10px;

            .listElements {
              display: flex;
              border-bottom: 1px solid rgba(0, 0, 0, 0.18);
              margin: 10px 0px;

              .col {
                color: black;
                font-size: 13px;

                .subTitle {
                  color: darkgray;
                  font-size: 12px;
                }

                .viewbtn {
                  margin: 10px 0px;

                  a {
                    cursor: pointer;
                    text-decoration: none;
                    border: 1px solid #46a3cd;
                    border-radius: 4px;
                    color: #46a3cd;
                    background: #fff;
                    padding: 3px 15px;
                  }
                }
              }

              .col-5 img {
                border-radius: 5%;
                margin-bottom: 10px;
                width: 100%;
                height: 75px;
                object-fit: cover;
              }
            }

            .listElements:last-child {
              border-bottom: 0;
              margin-bottom: 0;
            }

            .list-head {
              position: relative;
              border-radius: 10px 10px 0 0;
              overflow: hidden;

              .overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
                background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
              }

              img {
                width: 100%;
                border-radius: 0;
              }

              p {
                position: absolute;
                bottom: 0;
                color: #fff;
                padding: 5px;
                font-size: 12px;
                left: 0;
                margin-bottom: 0;
              }
            }
          }

          .appointment-style {
            width: 100%;
          }

          .user-form {
            margin-left: 30px;
            border-radius: 10px 10px 0px 10px;
            border: 1px solid #e7e7e7;
            background-color: #fff;
            padding: 10px;

            .btn-sm {
              padding: 0.25rem 0.5rem;
              font-size: 0.875rem;
              line-height: 1.5;
              border-radius: 0.2rem;
            }

            .req-lbl {
              color: #dc3545;
              margin-left: 3px;

              &::after {
                display: none;
              }
            }
          }

          .qlink {
            color: #46a3cd;
            border: 1px solid #46a3cd;
            cursor: pointer;
            padding: 5px 10px;
            display: inline-block;
            border-radius: 15px !important;
            background: #fff;
            outline: 0;
            white-space: normal;
            text-align: left;

            &:disabled {
              cursor: auto;
              opacity: 0.7;
            }

            a {
              text-decoration: none;
              color: inherit;
            }
          }

          .qreply-flex-wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: end;
          }

          .qreply {
            width: calc(100% - 30px);
            float: left;
            margin-left: 30px;

            .slick-slider {
              text-align: center;
              width: calc(100% - 20px);
              display: grid;

              .slick-list {
                margin: 0 0 0 7px !important;
              }

              .slick-prev {
                left: -17px !important;
                border: none;
              }

              .slick-next {
                right: -17px !important;
                border: none;
              }

              .slick-next:before,
              .slick-prev:before {
                font-size: 20px;
                line-height: 1;
                opacity: 0.75;
                color: #fff;
                background: white;
                color: darkgray;
              }
            }

            .qlink {
              margin: 3px;
              color: #46a3cd;
              border: 1px solid #46a3cd;
              cursor: pointer;
              padding: 5px 10px;
              display: inline-block;
              border-radius: 15px !important;
              background: #fff;
              outline: 0;
              white-space: normal;
              text-align: left;

              a {
                text-decoration: none;
                color: inherit;
              }
            }

            .qlink:disabled {
              cursor: auto;
              opacity: 0.7;
            }
          }

          .mq-box {
            width: calc(100% - 33px);
            background: #f5f5f5;
            border: 1px solid #e7e7e7;
            border-radius: 10px;
            border-top-left-radius: 0;
            margin: 0 0 0 auto;
            padding: 5px 10px 0 10px;

            span.qreply-box {
              width: 13px;
              height: 11px;
              margin-right: 5px;
              border-radius: 2px;
            }

            span.qreply-box.unchecked {
              display: inline-block;
              background: #ddd;
            }

            .qreply {
              width: 100%;
              float: none;
            }

            .btn-confirm {
              cursor: pointer;
              margin: 10px 0 0 -10px;
              padding: 10px;
              width: calc(100% + 20px);
              border-radius: 0 0 10px 10px;
              text-align: center;
              background: #46a3cd;
              color: #fff;
            }
          }

          .img-style {
            width: 100%;
            align-self: center;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
          }

          .custom-component {
            margin-left: 30px;
          }

          .appointment-carousel {
            display: block !important;
            width: 100% !important;

            .slick-slider {
              margin-left: -10px;
              margin-right: -10px;

              .slick-next,
              .slick-prev {
                width: 30px;
                height: 30px;
                z-index: 9;
                background-color: rgba(255, 255, 255, 0.8);
                // box-shadow: rgba(0, 0, 0, 0.5) 2px 0px 3px;
                // margin-top: 40px;
                border: 0;
                padding: 5px 0;

                &:before {
                  color: #666;
                }
              }

              .slick-prev {
                left: 0px !important;
                border-radius: 0 4px 4px 0;
              }

              .slick-next {
                right: 0 !important;
                border-radius: 4px 0 0 4px;
              }

              .slick-next:before,
              .slick-prev:before {
                font-size: 25px;
              }
            }

            .appointment-box {
              position: relative;
              min-height: 150px;
              padding: 10px;
              border: 1px solid silver;
              border-radius: 8px;
              color: #444;

              .left-box {
                width: 80px;
                margin-right: 5px;
                float: left;

                .user-img {
                  width: 70px;
                  height: 70px;
                  border-radius: 100px;
                  margin-left: 5px;
                  border: 1px solid #ddd;
                  object-fit: cover;
                }

                .timing {
                  font-size: 10px;
                  text-align: center;

                  b {
                    display: block;
                    margin-top: 5px;
                  }
                }
              }

              .right-box {
                width: calc(100% - 85px);
                float: left;

                .title {
                  font-weight: 600;
                  font-size: 13px;
                  line-height: normal;
                  margin-bottom: 5px;
                }

                .sub-title {
                  font-size: 12px;
                  color: #666;
                  line-height: normal;
                }

                .appt-line {
                  background-color: #d2d2d2;
                  height: 1px;
                  margin-top: 5px;
                  margin-bottom: 5px;
                }
              }

              .info {
                font-size: 10px;
                vertical-align: top;

                b {
                  margin-right: 3px;
                  width: 70px;
                  display: inline-block;
                  vertical-align: top;
                }

                span {
                  width: calc(100% - 73px);
                  display: inline-block;
                }
              }

              .btn-box {
                position: absolute;
                bottom: 15px;
                right: 15px;

                .appt-btn {
                  cursor: pointer;
                  font-size: 10px;
                  margin: 0 0 0 5px;
                  padding: 3px 5px;
                  background-color: #663d7c;
                  border-radius: 4px;
                  color: #fff;
                  border: none;
                  float: right;
                  outline: 0;

                  a {
                    color: #fff;
                  }
                }
              }
            }

            .product-box {
              position: relative;
              min-height: 150px;
              border: 1px solid #e7e7e7;
              border-radius: 8px;
              color: #444;

              .img-box img {
                border-radius: 8px 8px 0 0 !important;
                object-fit: cover;
                height: 180px !important;
                width: 100% !important;
              }

              .content-box {
                padding: 10px;

                .title {
                  font-size: 0.875rem;
                  color: #000;
                  font-weight: 600;
                  margin-bottom: 5px;
                }

                .desc {
                  color: gray;
                  margin-bottom: 5px;
                }
              }

              .btn-box {
                border-radius: 0 0 8px 8px;

                a {
                  cursor: pointer;
                  color: #46a3cd;
                  font-weight: 500;
                  padding: 7px 10px;
                  text-decoration: none;
                  display: block;
                  text-align: center;
                  // line-height: 32px;
                  border-top: 1px solid #e7e7e7;
                }
              }
            }
          }
        }

        li.sent {
          &>p {
            background: #fff;
            color: black;
            border: 1px solid #e7e7e7;
            font-size: 14px;
          }

          .msg-content {
            width: calc(100% - 33px);
            display: flex;
            margin: 0 0 0 auto;

            .chat-audio-video {

              audio,
              video {
                min-height: 50px;
                outline: none;
                border-radius: 4px;
              }
            }
          }

          // .appointment-carousel {
          //   display: block !important;
          //   width: 100%;

          //   .slick-slider {
          //     margin-left: -10px;
          //     margin-right: -10px;

          //     .slick-next,
          //     .slick-prev {
          //       width: 30px;
          //       height: 30px;
          //       z-index: 9;
          //       background-color: rgba(255, 255, 255, 0.8);
          //       // box-shadow: rgba(0, 0, 0, 0.5) 2px 0px 3px;
          //       // margin-top: 40px;
          //       border: 0;
          //       padding: 5px 0;

          //       &:before {
          //         color: #666;
          //       }
          //     }

          //     .slick-prev {
          //       left: 0px !important;
          //       border-radius: 0 4px 4px 0;
          //     }

          //     .slick-next {
          //       right: 0 !important;
          //       border-radius: 4px 0 0 4px;
          //     }

          //     .slick-next:before,
          //     .slick-prev:before {
          //       font-size: 25px;
          //     }
          //   }

          //   .appointment-box {
          //     position: relative;
          //     min-height: 150px;
          //     padding: 10px;
          //     border: 1px solid silver;
          //     border-radius: 8px;
          //     color: #444;

          //     .left-box {
          //       width: 80px;
          //       margin-right: 5px;
          //       float: left;

          //       .user-img {
          //         width: 70px;
          //         height: 70px;
          //         border-radius: 100px;
          //         margin-left: 5px;
          //         border: 1px solid #ddd;
          //         object-fit: cover;
          //       }

          //       .timing {
          //         font-size: 10px;
          //         text-align: center;

          //         b {
          //           display: block;
          //           margin-top: 5px;
          //         }
          //       }
          //     }

          //     .right-box {
          //       width: calc(100% - 85px);
          //       float: left;

          //       .title {
          //         font-weight: 600;
          //         font-size: 13px;
          //         line-height: normal;
          //         margin-bottom: 5px;
          //       }

          //       .sub-title {
          //         font-size: 12px;
          //         color: #666;
          //         line-height: normal;
          //       }

          //       .appt-line {
          //         background-color: #d2d2d2;
          //         height: 1px;
          //         margin-top: 5px;
          //         margin-bottom: 5px;
          //       }
          //     }

          //     .info {
          //       font-size: 10px;
          //       vertical-align: top;

          //       b {
          //         margin-right: 3px;
          //         width: 64px;
          //         display: inline-block;
          //         vertical-align: top;
          //       }

          //       span {
          //         width: calc(100% - 67px);
          //         display: inline-block;
          //       }
          //     }

          //     .btn-box {
          //       position: absolute;
          //       bottom: 15px;
          //       right: 15px;

          //       .appt-btn {
          //         cursor: pointer;
          //         font-size: 10px;
          //         margin: 0 0 0 5px;
          //         padding: 3px 5px;
          //         background-color: #663d7c;
          //         border-radius: 4px;
          //         color: #fff;
          //         border: none;
          //         float: right;
          //         outline: 0;

          //         a {
          //           color: #fff;
          //         }
          //       }
          //     }
          //   }

          //   .product-box {
          //     position: relative;
          //     min-height: 150px;
          //     border: 1px solid #e7e7e7;
          //     border-radius: 8px;
          //     color: #444;

          //     .img-box img {
          //       border-radius: 8px 8px 0 0 !important;
          //       object-fit: cover;
          //       height: 180px!important;
          //       width: 100%!important;
          //     }

          //     .content-box {
          //       padding: 10px;

          //       .title {
          //         font-size: 0.875rem;
          //         color: #000;
          //         font-weight: 600;
          //         margin-bottom: 5px;
          //       }
          //       .desc {
          //         color: gray;
          //         margin-bottom: 5px;
          //       }
          //     }

          //     .btn-box {
          //       border-radius: 0 0 8px 8px;

          //       a {
          //         cursor: pointer;
          //         color: #46a3cd;
          //         font-weight: 500;
          //         padding: 0 10px;
          //         text-decoration: none;
          //         display: block;
          //         text-align: center;
          //         line-height: 32px;
          //         border-top: 1px solid #e7e7e7;
          //       }
          //     }

          //   }

          // }

          .chat-time {
            text-align: left;
            margin-left: 33px;
          }
        }
      }
    }
  }
}

#frame.live-chat {
  margin-top: 0;
  height: 100%;

  .content {
    height: 100%;
    width: 100%;
    display: "block";

    .va-offline {
      position: absolute;
      text-align: center;
      width: 250px;
      background: #ffeeed;
      border: 1px solid #ddd;
      border-radius: 20px;
      margin: 80px calc(50% - 125px);
      padding: 5px;
      font-size: 13px;
      z-index: 9;
    }

    .vo-fix {
      width: 100%;
      margin: 70px 0 0 0;
      border-radius: 0;
      border: 0;
      padding: 10px;
      font-weight: 600;
    }

    .messages {
      ul {
        li {
          .qreply {
            // float: right;
            // width: auto;

            .qlink {
              background: #fff;
              border-radius: 15px !important;
              // float: left;
            }
          }
        }

        li.sent {
          .chat-time {
            margin-left: 0;
          }

          .msg-content {
            width: 100%;
          }
        }

        li.replies {
          .rdt {
            margin-left: 150px;
          }
        }
      }
    }
  }
}

#frame .content .contact-profile {
  width: 100%;
  line-height: normal;
  background: #f9f9f9;
  border-bottom: 1px solid #e5e5e5;

  .arrow {
    margin: 10px 10px 0 0;

    i {
      color: #051827;
      background: #ccc;
      width: 20px;
      height: 20px;
      font-size: 13px;
      line-height: 20px;
    }
  }

  .welcome-note {
    color: #fff;
    padding: 10px 10px 5px;
    float: left;
    width: calc(100% - 65px);
    margin-left: 35px;
    text-align: center;

    span {
      font-weight: normal;
    }
  }

  .left_title {
    width: 100%;
    padding: 8px;
    margin-top: 6px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  p {
    padding: 0;
    margin: 0;
  }

  .img_box>* {
    color: #fff;
  }

  .img_box.info-box {
    margin-left: 57px;
    width: calc(100% - 57px);
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    float: left;
    margin: 0 10px -30px 4px;
    border: 1px solid #fff;
    -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
    background: #fff;
    position: absolute;
    min-width: 40px;
    z-index: 999;
  }

  .social-media {
    float: right;

    p {
      font-size: 16px;
      color: #9e9e9e;
      font-weight: 100;
      margin-right: 50px;
      margin-top: 20px;
      font-family: "Conv_Nexa Light";
    }

    i {
      margin-left: 14px;
      cursor: pointer;
    }

    i:nth-last-child(1) {
      margin-right: 20px;
    }

    i:hover {
      color: #435f7a;
    }
  }

  .profile-status {
    color: #fff;
    font-size: 11px;

    span {
      float: left;
      width: 10px;
      height: 10px;
      background: #04e204;
      display: block;
      border: 1px solid #106500;
      border-radius: 5px;
      margin: 3px 3px 0 0;
    }
  }

  .img_box {
    position: relative;
  }
}

.pro-elements {
  width: 270px;
  float: right;
  border: 1px solid #e7e7e7;
  padding: 10px;
  border-radius: 10px 10px 0 10px;

  .element-box {
    position: relative;
    min-height: 60px;

    .img-icon {
      width: 38px;
      height: 38px;
      border-radius: 25px;
      border: 1px solid #ddd;
      float: left !important;
      background: #f5f5f5;
      opacity: 0.8;
      margin: 7px 10px 0 0 !important;
    }

    .content-box {
      float: left;
      width: calc(100% - 50px);
      color: #666;
      padding: 5px;

      .title {
        font-weight: 600;
        font-size: 14px;
      }

      .desc {
        font-size: 13px;
        line-height: normal;
      }
    }

    .path {
      position: absolute;
      top: 47px;

      .line {
        height: 15px;
        width: 2px;
        display: inline-block;
        margin-bottom: 7px;
        margin-left: 19px;
        border-left: 2px dotted #ccc;
      }

      i {
        margin-left: -5px;
        color: #ccc;
      }
    }
  }

  .element-box:nth-last-child(1) {
    .path {
      display: none;
    }
  }

  // .element-box:hover {
  //   cursor: pointer;

  //   .content-box {
  //     background: #f5f5f5;
  //     border-radius: 7px;
  //   }
  // }
}

.chat-footer {
  font-size: 9px;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 67px;
  left: 0;
  background: #fff;
  padding: 5px 0;
  color: #999;

  a {
    opacity: 0.8;
  }
}

.chat-preview {
  max-width: 330px;
  width: 100%;
  float: right;
  position: relative;
  min-height: 650px;
  border: 1px solid rgb(170, 170, 170);
  background: white;
  border-radius: 10px;

  .contact-profile {
    width: 100%;
    line-height: normal;
    border-bottom: 1px solid #e5e5e5;
    border-radius: 10px 10px 0 0;

    // background: url("https://png.pngtree.com/thumb_back/fh260/back_pic/00/10/42/9256332e9470d7d.jpg");
    .welcome-note {
      padding: 10px 10px 5px;
      float: left;
      width: calc(100% - 45px);
      text-align: center;
    }

    .arrow {
      float: right;
      margin: 10px 10px 0 0;

      i {
        border: 1px solid #ddd;
        border-radius: 50px;
        font-weight: 600;
        cursor: pointer;
        text-align: center;
        color: #051827;
        background: #ccc;
        width: 20px;
        height: 20px;
        font-size: 13px;
        line-height: 20px;
      }
    }

    .left_title {
      float: left;
      width: 100%;
      padding: 0 10px;
      margin-top: 6px;

      .img_box {
        position: relative;
      }

      .img_box>* {
        color: #fff;
      }

      .img_box.info-box {
        margin-left: 57px;
        width: calc(100% - 57px);
      }

      .profile-status {
        float: right;
        color: #fff;
        font-size: 11px;

        span {
          width: 10px;
          height: 10px;
          background: #04e204;
          display: inline-block;
          border: 1px solid #106500;
          border-radius: 5px;
          margin-right: 2px;
        }
      }
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      float: left;
      margin: 0 10px -30px 4px;
      border: 1px solid #fff;
      -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
      background: #fff;
      position: absolute;
      min-width: 40px;
      z-index: 999;
    }

    p {
      padding: 0;
      margin: 0;
    }
  }

  .message-input {
    // position: absolute;
    // bottom: 0;
    // width: 96%;
    // z-index: 99;
    // left: 2%;
    background: #fff;

    .wrap {
      position: relative;
      border-top: 1px solid #ddd;
      float: left;
      width: 100%;
      padding: 6px;

      input {
        float: left;
        border: none;
        width: calc(100% - 90px);
        padding: 7px 32px 6px 8px;
        font-size: 14px;
        color: #8f8f8f;
      }

      input:focus {
        outline: none;
        box-shadow: none;
      }

      .attachment {
        position: absolute;
        width: 10px;
        right: 55px;
        z-index: 4;
        margin-top: 10px;
        color: #435f7a;
        opacity: 0.5;
        cursor: pointer;
        top: 5px;
        font-size: 20px;
      }

      button {
        float: right;
        border: none;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        padding: 0;
        cursor: pointer;
        background: #46a3cd;
        color: #fff;

        .fa-paper-plane {
          margin-bottom: 5px;
          margin-right: 3px;
        }
      }

      button:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .tradeMark {
      // font-family: Conv_Nexa bold;
      color: #a9a9a9;
      line-height: 1.8;
      font-size: 11px;
      float: right;
    }
  }

  .messages {
    padding: 15px;
  }
}

.channels {
  .agentprofile-list {
    border: 0 !important;

    .profile-box {
      font-size: 13px;
      border-radius: 3px;
      padding: 10px;
      text-align: center;
      border: 1px dotted #ddd;

      background: rgb(241, 241, 241);
      /* Old browsers */
      background: -moz-linear-gradient(left, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(241, 241, 241, 1) 0%, rgba(251, 251, 251, 1) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f1f1', endColorstr='#fbfbfb', GradientType=1);
      /* IE6-9 */

      .agent-info-box {
        text-align: left;

        .action {
          text-align: right;

          i {
            padding: 2px 5px;
            cursor: pointer;
            font-size: 16px;
          }
        }

        .name {
          color: #555555;

          label {
            font-weight: 600;
            margin-right: 5px;
          }
        }

        .channel {
          font-weight: 600;
          font-size: 13px;
          color: #666;
        }
      }
    }
  }

  .emptyChannel {
    margin: 0 auto;
    text-align: center;

    .card {
      margin: 0 150px;
    }

    img {
      height: 80px;
      max-width: 200px;
      width: auto;
      margin-bottom: 20px;
    }

    h1 {
      color: #444;
      margin: 10px;
    }

    h2 {
      color: #999;
      font-weight: bold;
    }

    .buttonRow {
      margin: 0 auto;

      .btn {
        margin: 0 auto;
      }
    }
  }
}

.reports {
  .dropdown {
    button {
      i {
        color: #151b1e;
      }
    }
  }

  .col-filterbox {
    padding: 0;

    label {
      display: block;
      margin: 0;
      padding: 10px;
      cursor: pointer;
    }
  }

  .dropdown-menu-right {
    position: relative;

    .dropdown-menu {
      -ms-transform: none !important;
      -webkit-transform: none !important;
      transform: none !important;
      right: 0;
      left: auto !important;
      top: 35px !important;
    }
  }

  .lead-reports {
    .search-outer-box {
      position: relative;
      min-width: 230px;

      .fa {
        cursor: pointer;
        position: absolute;
        top: 11px;
        left: 8px;
      }

      .search-box {
        margin-bottom: 0;

        input {
          line-height: 13px;
          font-size: 13px;
          padding-left: 25px;
          border-radius: 4px;
        }
      }
    }

    .datepicker-box {
      span {
        margin-right: 10px;
      }

      .rdt {
        display: inline-block;

        input {
          width: 120px;
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }

        input[readonly] {
          background: none;
        }

        input:focus {
          outline: none !important;
          outline-offset: 0 !important;
          border-color: #c2cfd6;
          box-shadow: none;
        }
      }

      .fa-calendar {
        padding: 9px 9px 10px 9px;
        border: 1px solid #c2cfd6;
        border-left: 0;
        background: #c2cfd6;
        cursor: pointer;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .select-input {
    width: 160px;
    display: inline-flex;
    margin-right: 5px;
  }

  .filter-col-box {
    margin-left: 5px;
    margin-top: 10px;

    .col {
      padding: 5px;
      margin: 5px;
      background-color: #f0f3f5;
      height: 20px;
      font-size: 11px;
    }

    i {
      cursor: pointer;
    }
  }

  tfoot {
    div {
      text-align: left;
      padding-left: 10px;
    }
  }
}

/* Report edit column style */
.column-left {
  float: left;
  padding: 30px;
  border: 2px ridge;
  height: 400px;
}

.column-right {
  padding: 30px;
  border: 2px ridge;
  height: 400px;
}

.button {
  text-align: center;
  margin-top: 50px;
}

.fa-icons {
  display: table-cell;
  width: 50%;
  margin-top: 15px;
}

.buttons {
  margin-top: -280px;
}

.selctedValue {
  color: blue;
}

/* add new user popup style */
.add-new-user {
  background: rgba(133, 135, 136, 0.52);
  padding: 5px;

  .card {
    margin-bottom: 0px;
    /* border-radius: 4px; */
  }

  .card-body {
    padding: 0 20px;
  }

  .role {
    width: 100%;
    display: grid;
    align-content: center;
    background: rgba(220, 229, 234, 0.52);
    border-top: 1px solid #a4b7c1;
    border-bottom: 1px solid #a4b7c1;
    height: 60px;
    cursor: pointer;

    .radio-button {
      display: grid;
      align-content: center;

      .form-group {
        margin-bottom: 0px;
      }
    }

    .tooltip {
      opacity: 1;

      .tooltipRole {
        margin-left: 10px;
        padding: 1px 5px;
        cursor: pointer;
      }
    }
  }

  .role:hover {
    background-color: #dbdbdb !important;
  }
}

.register {
  .input {
    display: flex;
  }

  .form-group {
    width: 100%;
  }

  .form-control {
    border-radius: 0 4px 4px 0 !important;
  }

  .content {
    .box {
      padding-top: calc((100vh - 600px) / 2);
      min-height: 560px;
    }
  }

  .input-password {
    input {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-width: 1px 0 1px 1px;
    }

    .input-group-append {
      .input-group-text {
        border-width: 1px 1px 1px 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.forgot-password {
  .input {
    display: flex;
  }

  .form-group {
    width: 100%;
  }

  .form-control {
    border-radius: 0 4px 4px 0 !important;
  }

  .content {
    .box {
      padding-top: calc((100vh - 350px) / 2);
      min-height: 350px;
    }
  }
}

.page-layout {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;

  .layout {
    background-image: url(../img/bg_layout.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: fixed;
    height: 100%;
    width: 40%;
    float: left;
    border-right: 1px solid #c2cfd6;

    img {
      width: 170px;
    }

    .info {
      color: #801083;
      font-size: 28px;
      line-height: 38px;
      text-align: center;
      margin-top: 90px;

      span {
        font-size: 18px;
      }
    }
  }

  .content {
    width: 60%;
    float: left;
    margin-left: 40%;

    .c-box {
      min-height: 484px;
      padding-top: calc((100vh - 548px) / 2);
    }

    .box {
      max-width: 450px;
      margin: 0 auto;
      display: block;
      height: calc(100vh - 65px);

      .info {
        text-align: center;
        color: #801083;
        font-size: 25px;
        margin-top: 40px;
        line-height: 28px;
      }

      .register-info {
        font-size: 20px;
        margin-top: 20px;
        line-height: 25px;
      }

      .link-box {
        color: #536c79;
        line-height: 35px;

        .link {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .footer {
      text-align: center;
      color: #666;
      line-height: 18px;
      font-size: 13px;

      span {
        font-size: 11px;
      }
    }
  }
}

.lead-info {
  font-size: 13px;
  color: #444;

  .l-title {
    color: #167495;
    font-weight: 600;
  }

  .l-border {
    background: -moz-linear-gradient(-45deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d000000', endColorstr='#00000000', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    height: 1px;
    margin-bottom: 10px;
  }

  .leadInfo-head {
    margin-bottom: 30px;

    .info {
      float: left;

      .img-box {
        width: 35px;
        display: inline-block;
        height: 35px;
        border-radius: 27px;
        line-height: 35px;
        padding-left: 10px;
        border: 1px solid #aaa;
        margin-right: 5px;
        font-size: 18px;
      }

      .title {
        font-size: 22px;
      }
    }

    .channel {
      font-size: 13px;
      color: #777;
      float: left;
      margin: 0 20px;
      border-left: 1px solid #ddd;
      padding: 7px 20px;
      border-right: 1px solid #ddd;
      width: 70px;

      img {
        opacity: 0.7;
        margin-top: -5px;
      }
    }

    .score {
      width: 40px;
      float: left;

      .CircularProgressbar-text {
        font-size: 40px !important;
        dominant-baseline: central;
        text-anchor: middle;
      }
    }
  }

  .lead-left {
    float: left;
    width: calc(100% - 330px);
    padding-right: 15px;

    .box {
      line-height: 25px;

      .box-left {
        display: inline-block;
        width: 25%;
        text-align: right;
        margin-right: 15px;
        font-weight: 600;
      }

      .box-right {
        display: inline-block;
        width: calc(75% - 15px);
        line-height: normal;
      }
    }

    .steps {
      border: 0;
      // border: 1px solid #ddd;
      padding: 0px;
      // background: #f5f5f5;
      border-radius: 0;
      list-style: none;
      overflow: hidden;
      margin-bottom: 15px;

      ul {
        border: 0;
        padding: 0;
        // background: #f5f5f5;
        border-radius: 0;
        list-style: none;
        overflow: hidden;

        li {
          margin-bottom: -1px;
          color: #bbb;
          text-decoration: none;
          position: relative;
          border: 0 !important;
          border-radius: 0;
          outline-style: none;
          background: #666565;

          a {
            color: #bbb;
            text-decoration: none;
            padding: 10px 0 10px 13px;
            position: relative;
            display: block;
            border: 0 !important;
            border-radius: 0;
            outline-style: none;
            background: #f5f5f5;
            width: 80px;
            cursor: auto;
            text-align: center;
          }

          a:before {
            content: " ";
            display: block;
            width: 0;
            height: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left: 10px solid #ddd;
            position: absolute;
            top: 50%;
            margin-top: -20px;
            left: 100%;
            z-index: 1;
            margin-left: 1px;
          }

          a:after {
            content: " ";
            display: block;
            width: 0;
            height: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left: 10px solid #f5f5f5;
            position: absolute;
            top: 50%;
            margin-top: -20px;
            left: 100%;
            z-index: 2;
          }
        }

        li.done {
          a {
            border-color: #20a8d8 !important;
            color: #fff !important;
            background: #20a8d8 !important;
          }

          a:after {
            border-left: 10px solid #20a8d8;
          }
        }

        li.active {
          a {
            border-color: #167495 !important;
            color: #fff !important;
            background: #167495 !important;
          }

          a:after {
            border-left: 10px solid #167495;
          }
        }
      }
    }

    .steps.edit {
      li>a {
        cursor: pointer;
      }
    }

    .mv-items {
      .summary {
        border: 1px solid #ddd;
        background: #f9fdfd;
        padding: 15px;
        border-radius: 3px;
        font-size: 14px;

        .cat {
          line-height: 25px;
          margin-bottom: 20px;

          .title {
            font-weight: 600;
            margin-bottom: 10px;
          }

          .item {
            background-color: #fff;
            border: 1px solid #48a3cd;
            line-height: 15px;
            display: inline-block;
            padding: 10px;
            margin-right: 10px;
            border-radius: 20px;
          }
        }

        .prod {
          line-height: 25px;

          .title {
            font-weight: 600;
            margin-bottom: 10px;
          }

          .item-list {
            overflow-x: auto;
            overflow-y: hidden;
            height: 250px;
            white-space: nowrap;

            .item {
              width: 200px;
              height: 240px;
              display: inline-block;
              border: 1px solid #ddd;
              border-radius: 3px;
              overflow: hidden;
              margin-right: 15px;

              img {
                height: 150px;
                width: 100%;
                object-fit: cover;
                object-position: center;
                border-top: 13px;
                border-bottom: 1px solid #ddd;
              }

              div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 10px;
              }

              .item-title {
                font-weight: 600;
                padding-top: 5px;
                text-overflow: unset;

                span:first-child {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 155px;
                  display: inline-block;
                }

                span.badge {
                  margin-top: 7px;
                  display: block;
                  float: right;
                }
              }

              .item-cat {
                font-size: 13px;
              }

              .item-subCat {
                font-size: 13px;
                margin-bottom: 5px;
              }
            }

            .item:last-child {
              margin-right: 0;
            }
          }

          .item-list::-webkit-scrollbar {
            width: 5px;
            height: 5px;
          }

          .item-list::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          .item-list::-webkit-scrollbar-thumb {
            background: rgb(192, 192, 192);
          }

          .item-list::-webkit-scrollbar-thumb:hover {
            background: rgb(146, 145, 145);
          }
        }
      }

      .l-products {
        // max-width: 330px !important;
        // height: 430px !important;
        // overflow-y: scroll;
        // overflow-x: hidden;

        max-width: 600px;
        margin-top: 30px;
        margin-left: 120px;

        .now {
          border: 1px solid #20a8d8;
          display: inline-block;
          padding: 3px 12px;
          border-radius: 3px;
          background: #20a8d8;
          color: #fff;
          margin-left: 40px;
        }

        .time {
          position: absolute;
          left: -210px;
          top: -11px;
          z-index: 9;
          width: 135px;
          font-size: 11px;
          text-align: center;
          color: #444;
          border: 1px solid #999;
          display: inline-block;
          padding: 3px 12px;
          border-radius: 3px;
          background: #eee;
        }

        .time::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 45px;
          top: -12px;
          left: 156px;
          display: block;
          border-left: 1px solid #aaa;
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          transform: rotate(90deg);
        }

        .p-box {
          position: relative;
          margin: 20px 15px 20px 90px;
          border: 1px solid #c2cfd6;
          background: #f7fbfd;
          padding: 10px;
          border-radius: 10px;
          border-top-left-radius: 0;

          .img-style {
            border-radius: 3px;
            border: 1px solid #ddd;
            width: 150px;
            height: 100px;
            overflow: hidden;
            float: left;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          .info {
            width: calc(100% - 160px);
            float: left;
            margin-left: 10px;
            font-size: 11px;
            line-height: 20px;

            .head {
              font-size: 13px;
              font-weight: 600;
              margin-bottom: 10px;
            }

            .channel {
              color: #777;
              font-style: italic;

              span {
                margin-right: 3px;
                font-weight: 600;
              }
            }
          }

          .details {
            color: #777;
            font-style: italic;
            font-size: 11px;
            margin-top: 7px;
            line-height: 13px;

            span {
              margin-right: 3px;
              font-weight: 600;
              display: block;
              font-style: normal;
            }
          }

          .circle {
            width: 12px;
            height: 12px;
            background: #fff;
            border-radius: 10px;
            border: 3px solid #167495;
            position: absolute;
            left: -30px;
            top: -6px;
            z-index: 9;
          }
        }

        .p-box:first-child {
          margin-top: 20px;
        }

        .p-box:after {
          content: "";
          position: absolute;
          width: 1px;
          height: calc(100% + 20px);
          top: -20px;
          left: -25px;
          display: block;
          border-left: 1px dashed #666;
        }

        .p-box:first-of-type:after {
          height: calc(100% + 35px);
          top: -25px;
        }

        .p-box:last-child:after {
          height: 15px;
        }

        .p-box:before {
          position: absolute;
          top: -1px;
          content: "\A";
          left: -8px;
          border-top: 7px solid #c2cfd6;
          border-left: 7px solid transparent;
        }

        .c-box {
          width: 40%;

          .info {
            margin-left: 0;

            .head {
              margin-bottom: 0;
              color: #20a8d8;
            }
          }
        }
      }

      .l-products::-webkit-scrollbar {
        width: 5px;
      }

      .l-products::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      .l-products::-webkit-scrollbar-thumb {
        background: rgb(192, 192, 192);
      }

      .l-products::-webkit-scrollbar-thumb:hover {
        background: rgb(146, 145, 145);
      }
    }
  }

  .activity-box-outer {
    white-space: nowrap;
    overflow-x: auto;
    padding: 10px 30px 10px 0;

    .activity-line {
      position: relative;
      width: calc(100% - 87px);
      height: 1px;
      border-top: 1px dotted #999;
      margin: 10px 0 -7px 87px;
    }

    .activity-line::after {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      left: 100%;
      top: -11px;
      border: 10px solid transparent;
      border-left: 10px solid #c2cfd6;
    }

    .activity-box {
      color: #444;
      display: inline-block;
      position: relative;

      .line {
        height: 1px;
        position: absolute;
        width: 100%;
        top: 6px;
        border-top: 1px dotted #999;
      }

      .round {
        width: 15px;
        height: 15px;
        background: #20a8d8;
        border: 1px solid #167495;
        text-align: center;
        position: absolute;
        left: calc(50% - 5px);
        z-index: 9;
        border-radius: 10px;
      }

      .round:after {
        position: absolute;
        top: 7px;
        content: "\A";
        border-style: solid;
        border-width: 9px;
        border-color: transparent transparent #c2cfd6;
        left: -3px;
      }

      .content {
        cursor: pointer;
        margin: 25px 10px 10px;
        text-align: center;
        border: 1px solid #c2cfd6;
        padding: 5px 10px;
        border-radius: 4px;

        .time {
          font-weight: 600;
        }
      }

      .content:before {
        // position: absolute;
        // top: 5px;
        // content: "\A";
        // border-style: solid;
        // border-width: 10px 10px 10px 0;
        // border-color: transparent #c2cfd6 transparent transparent;
        // left: -11px;
      }
    }

    .activity-box.active {
      color: #167495;

      // .round {
      //   background: transparent;
      //   border: 0;
      // }

      .round:after {
        position: absolute;
        top: 7px;
        content: "\A";
        border-style: solid;
        border-width: 9px;
        border-color: transparent transparent #20a8d8;
        left: -3px;
      }

      .content {
        background: #f7fbfd;
        border: 1px solid #20a8d8;
      }
    }

    .activity-box:hover {
      .content {
        background: #f7fbfd;
      }
    }

    .activity-box:first-child {
      .line {
        width: 50%;
        right: 0;
      }
    }

    .activity-box:last-child {
      .line {
        width: 110%;
      }

      .line::after {
        content: "";
        position: absolute;
        height: 0;
        width: 0;
        left: 100%;
        top: -11px;
        border: 10px solid transparent;
        border-left: 10px solid #c2cfd6;
      }
    }
  }

  .lead-right {
    float: left;
    width: 330px;

    .tb-title {
      span {
        padding: 5px 7px;
        display: inline-block;
        cursor: pointer;
      }

      span:first-child {
        padding-left: 10px;
      }

      span.active {
        border-bottom: 1px solid #167495;
        color: #167495;
      }
    }

    .tb-content {
      padding: 10px 0 10px 10px;
      height: 450px !important;
    }

    .lead-chat {
      max-width: 330px !important;
      height: 430px !important;
      overflow: hidden;
      border-radius: 4px !important;
      border-color: #ddd;

      .contact-profile {
        border-radius: 0;
        background: #f8fcfd !important;
        border-bottom: 1px solid #eaebeb !important;
        padding: 7px 0px;

        img {
          margin-top: 10px !important;
          width: 35px !important;
          height: 35px !important;
          min-width: 35px !important;
          background: transparent !important;
          box-shadow: none !important;
          top: -15px !important;
        }

        .img_box>* {
          color: #444 !important;
        }

        .info-box {
          margin-left: 50px !important;
          width: calc(100% - 50px) !important;

          h3 {
            margin-top: 5px;
            font-size: 15px;
          }
        }
      }

      .messages {
        // min-height: calc(100% - 130px) !important;
        // max-height: calc(100% - 130px) !important;

        ul li.replies img {
          float: none !important;
          margin: auto !important;
        }

        ul li.replies img.img-icon {
          float: left !important;
          margin: 7px 10px 0 0 !important;
        }
      }

      .content .message-input {
        box-shadow: none !important;

        .wrap {
          border: 0;
        }
      }
    }

    .l-score {
      .box {
        float: left;
        margin: 5px;
        border: 1px solid #ddd;
        padding: 3px 6px;
        border-radius: 3px;
        font-size: 10px;

        .icon {
          width: 10px;
          height: 10px;
          display: inline-block;
          margin-right: 5px;
        }

        .item {
          color: #666;
        }
      }
    }
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
  }

  .nav-item:hover {
    cursor: pointer;
  }

  // .tab-grid{
  //   .react-grid-Row--even {
  //     background-color: #fff !important;
  //     .react-grid-Cell {
  //       background-color: #fff !important;
  //     }
  //   }
  // }

  .tab-content {
    border: 0;
    border-top: 1px solid #ddd;
    margin-top: -1px;
    background: #fff;
  }

  .nav-tab .active {
    border-bottom: 3px solid #20a8d8;
    font-weight: 700;
  }
}

.temp-box {
  border: 1px solid #a4b7c1;

  .btn-select {
    background: #f0f3f5;
    line-height: 35px;
    text-align: center;
    border-top: 1px solid #a4b7c1;
    color: #151b1e;
    cursor: pointer;
    margin-top: -6px;
  }
}

.progress-wizard-highlight {
  margin: 0 auto;
  font-size: 13px;
  cursor: pointer;

  img {
    width: 40px;
    margin-top: -25px;
    margin-right: 2px;
  }

  .title {
    text-decoration: underline;
    border: 1px solid #999;
    padding: 5px 20px;
    border-radius: 0 20px 20px 20px;
    display: inline-block;
    position: relative;
    font-weight: 600;
    overflow: hidden;

    .counter {
      color: #800080;
      font-weight: 600;
    }

    .progress-bar {
      background: rgba(118, 183, 93, 0.4); //rgba(78, 216, 137, 0.3);
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

.nav-item-left {
  display: flex;
  margin-right: auto;
}

.progress-wizard-highlight:hover {
  .title {
    color: #4a9ad8;
    border-color: #4a9ad8;
  }
}

.modal-progress-wizard {
  .modal-content {
    border-radius: 5px;
  }

  .progress-wizard {

    .btn-close,
    .btn-close:focus,
    .btn-close:active {
      position: absolute;
      right: 10px;
      font-size: 20px;
      font-weight: 600;
      color: #999;
      cursor: pointer;
    }

    .btn-close:hover {
      color: #20a8d8;
    }

    .head {
      border-bottom: 1px solid #ddd;

      .progress-box {
        float: left;
        width: 100px;
        display: inline-flex;

        .CircularProgressbar {
          height: 60px;
          margin: 20px;
        }
      }

      .head-box {
        float: left;
        padding: 12px 0 0;

        .title {
          color: #76b75d;
          font-size: 25px;
        }

        .desc {
          color: #666;
          font-size: 20px;
        }
      }
    }

    .content-box {
      padding: 15px 20px 20px 20px;

      .wizard-box {
        width: 90%;
        margin: 0 auto;

        .w-box {
          position: relative;
          margin: 10px 0 0px 40px;
          padding: 10px 10px 10px 30px;

          .title {
            font-size: 18px;
            color: #444;
          }

          .title:hover {
            text-decoration: none;
          }

          .desc {
            color: #666;
          }

          .circle {
            width: 50px;
            height: 50px;
            background: #fff;
            border-radius: 50%;
            border: 2px solid #167495;
            position: absolute;
            left: -30px;
            top: 7px;
            z-index: 9;

            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -moz-flex;
            display: -ms-flexbox;
            display: flex;

            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            -moz-justify-content: center;
            justify-content: center;

            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            -moz-align-items: center;
            align-items: center;

            i {
              font-size: 22px;
              color: #666;
            }
          }
        }

        .w-box.done {
          .title {
            color: #76b75d;
          }

          .circle {
            border-color: #76b75d;
            background: #d5e9ce;

            i {
              color: #76b75d;
            }
          }
        }

        .w-box:first-child {
          margin-top: 0;
        }

        .w-box:after {
          content: "";
          position: absolute;
          width: 1px;
          height: calc(100% + 20px);
          top: -70px;
          left: -5px;
          display: block;
          border-left: 1px solid #167495;
        }

        .w-box.done:after {
          border-left: 1px solid #76b75d;
        }

        .w-box:first-of-type:after {
          height: calc(100% + 15px);
          top: 10px;
        }

        // .w-box:last-child:after {
        //   height: 15px;
        // }
      }
    }
  }
}

/*================ Something went Wrong page css start ================*/

.something-wrong-sec {
  min-height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 80px 0;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

.something-wrong-sec h2 {
  font-size: 100px;
}

.something-wrong-sec h3 {
  font-size: 38px;
}

.something-wrong-sec p {
  font-size: 20px;
  line-height: 28px;
}

.something-wrong-sec .btn-primary {
  border-color: #fff;
}

.something-wrong-sec .btn-primary:hover {
  border-color: #fff;
}

/* unauthorized-box style start */

.unauthorized-box {
  display: none;
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  top: 0;

  .inner-box {
    background: #fff;
    width: 524px;
    text-align: left;
    padding: 30px;
    margin: 0 auto;
    margin-top: calc(25% - 80px);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    max-width: calc(100% - 60px);

    .title {
      font-size: 20px;
      font-weight: 600;
    }

    .message {
      margin: 10px 0 25px 0;
    }
  }
}

/* knowledge-hub-box style start */

.knowledge-hub-box {
  // display: none;
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  top: 0;

  .inner-box {
    position: relative;
    background: #fff;
    text-align: left;
    padding: 15px 20px;
    margin: 0 auto;
    margin-top: calc(10% - 80px);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    max-width: 60%;
    max-height: 90%;

    .title {
      font-size: 15px;
      font-weight: 600;
      display: flex;
      height: auto;
      align-items: center;

      i {
        margin-left: auto;
      }
    }

    .play-textarea {
      background-color: #f9fbfc;
      min-height: calc(100vh - 280px);
      overflow-y: auto;

      mark {
        padding: 0.1em 0.2em 0.2em 0.2em;
        border-radius: 4px;
        background: #d2e8f3;
      }
    }

    .title-icon {
      width: 56px;
      margin-right: 5px;
      margin-top: -6px;
      margin-left: -11px;
    }

    .close-icon {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 15px;
      width: 10px;
    }

    .message {
      margin: 10px 0 25px 0;
    }
  }
}

/* chat widget mic effect */
#frame .content .messages ul li.chat-loader-outerbox {
  margin-bottom: 20px !important;

  .chat-loader {
    text-align: center;
    margin: 2px 0 0 2px;
    float: right;

    &>div {
      width: 5px;
      height: 5px;
      background-color: #fff;
      margin-right: 2px;

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }
}

@-webkit-keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.chat-loader span.dot {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  font-size: 25px;
  line-height: 0;
}

.chat-loader span.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.chat-loader span.dot:nth-child(3) {
  animation-delay: 0.4s;
}

/* chat widget mic effect end */

/* nothing to show on screen */
.no-data {
  margin: 70px auto;
  width: fit-content;
  width: -moz-fit-content;
  text-align: center;

  .icon {
    font-weight: 600;
    font-size: 120px;
    color: #999;
  }

  .title {
    font-size: 30px;
    font-weight: 600;
    color: #999;
  }

  .desc {
    font-size: 15px;
    font-weight: 400;
    width: 400px;
    color: #999;
  }
}

/* nothing to show on screen end */

/* page title start*/
// .head {
//   margin-bottom: 20px;

//   .title {
//     font-size: 24px;
//   }

//   .desc {
//     color: #999;
//     font-size: 13px;
//   }

//   i {
//     color: #d8a200;
//   }
// }

/* page title end */

.page-head {
  margin-bottom: 20px;

  .title {
    font-size: 22px;
    color: #444;

    span {
      font-size: 14px;
      margin-left: 10px;
    }

    span:before {
      font-family: FontAwesome;
      content: "\F101";
      margin-right: 5px;
      color: #7e99a7;
    }
  }

  .desc {
    color: #999;
    font-size: 13px;

    i {
      color: #d8a200;
      margin-right: 5px;
    }
  }
}

.page-head-right {
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
}

.page-border-head {
  border-bottom: 1px dotted #c2cfd6;
  margin-bottom: 20px;
}

/* grid style start */
.tab-grid {
  .react-grid-Main {
    // outline: 1px solid rgba(164, 183, 193, 0.5) !important;

    .react-grid-Grid {
      background-color: transparent;
      border: none !important;
      width: 100%;

      .react-grid-Empty {
        border: 1px solid rgba(164, 183, 193, 0.5) !important;
        border-top: 0;
      }

      // .react-grid-Header {
      //   background: transparent;
      // }

      .react-grid-HeaderCell {
        font-weight: 600;
        // border: none !important;
        background-color: #d7e0e6 !important;
        border-top: 1px solid rgba(164, 183, 193, 0.5) !important;
        border-right: 1px solid rgba(164, 183, 193, 0.5) !important;
        border-bottom: 1px solid rgba(164, 183, 193, 0.5) !important;
      }

      // .react-grid-HeaderCell:last-child,
      // .react-grid-Cell:last-child {
      //   border-right: 0 !important;
      // }

      .react-grid-HeaderCell:first-child,
      .react-grid-HeaderCell--frozen {
        border-left: 1px solid rgba(164, 183, 193, 0.5) !important;
        border-top: 1px solid rgba(164, 183, 193, 0.5) !important;
      }

      .react-grid-Cell:first-child,
      .react-grid-Cell--frozen {
        border-left: 1px solid rgba(164, 183, 193, 0.3) !important;
      }

      .react-grid-Cell {
        // border: none !important;
        border-right: 1px solid rgba(164, 183, 193, 0.3) !important;
        border-bottom: 1px solid rgba(164, 183, 193, 0.3) !important;
      }

      // .rdg-selected {
      //   border: 0;
      // }

      // scrollbar style
      .react-grid-Canvas {
        overflow: hidden !important;
      }

      .react-grid-Canvas:hover {
        overflow: auto !important;
      }

      .react-grid-Canvas::-webkit-scrollbar {
        background: #f0f0f0 !important;
        // border-radius: 7px;
        width: 7px;
        height: 7px;
      }

      .react-grid-Canvas::-webkit-scrollbar-thumb {
        height: 7px;
        border-radius: 7px;
        background: #888;
      }

      // sorting style
      .react-grid-HeaderCell-sortable {
        position: relative;
      }

      .react-grid-HeaderCell-sortable:after {
        font-family: FontAwesome;
        content: "\f0dc";
        position: absolute;
        right: 0;
      }

      .react-grid-HeaderCell-sortable--ascending,
      .react-grid-HeaderCell-sortable--descending {
        .pull-right {
          display: none;
        }
      }

      .react-grid-HeaderCell-sortable--ascending:after {
        content: "\f0de";
        top: 4px;
      }

      .react-grid-HeaderCell-sortable--descending:after {
        content: "\f0dd";
        top: -2px;
      }
    }
  }
}

.entityGrid {
  .react-grid-Row--even {
    background-color: #fff !important;

    .react-grid-Cell {
      background-color: #fff !important;
    }
  }

  div.react-grid-Row div.react-grid-Cell:nth-of-type(1) {
    background-color: #d7e0e6 !important;
  }
}

/* grid style end */

/* tab box style start */
.tab-box {
  border: 1px solid #c2cfd6;
  border-radius: 4px;
  color: #444;

  .tab-header {
    font-weight: 600;
    padding: 10px;
    background: #edf1f4;
    border-bottom: 1px solid #c2cfd6;
    border-radius: 4px 4px 0 0;
  }

  .tab-details {
    padding: 10px;
    border-radius: 0 0 4px 4px;
    font-size: 13px;

    .left-part {
      display: inline-block;
      width: 130px;
      text-align: right;
      margin-right: 10px;
      font-weight: 600;
      line-height: 30px;
      vertical-align: top;
    }

    .right-part {
      display: inline-block;
      line-height: 30px;
      width: calc(100% - 140px);
      word-break: break-word;
    }

    .no-location {
      height: 381px;
      padding-top: 20%;
      text-align: center;
      font-size: 13px;
      color: #999;
    }
  }
}

.task {
  .tab-box {
    border: unset;
    border-radius: unset;
    color: unset;

    .tab-details {
      font-size: inherit;

      .left-part {
        margin-right: 15px;
        line-height: 40px;
      }

      .right-part {
        line-height: 40px;
        width: calc(100% - 145px);
      }
    }
  }
}

/* tab box style end */

/* button style start */
.cb {
  border: 1px solid #ddd;
  border-radius: 2px;
}

.cb:focus {
  outline: 0;
}

.action-btn {
  background: transparent;
  border-radius: 15px;
  padding: 0px 5px;

  i {
    font-size: 10px;
  }
}

.cb-back {
  color: #444;
  background: #edf1f4;
  border-color: #a4b7c1;
}

.cb-back:hover {
  background: #c2cfd6;
}

.cb-submit {
  color: #fff;
  background-color: #20a8d8;
  border-color: #1b8eb7;
}

.cb-submit:hover {
  color: #fff;
  background: #1b8eb7;
  border-color: #187da0;
}

.cb-dark {
  color: #fff;
  background-color: #3e4142;
  border-color: #3e4142;
}

.cb-dark:hover {
  color: #fff;
  background: #141414;
  border-color: #141414;
}

.cb-cancel {
  color: #fff;
  background-color: #f86c6b;
  border-color: #f64846;
}

.cb-cancel:hover {
  color: #fff;
  background: #f64846;
  border-color: #bd6a6a;
}

.cb-edit {
  color: #fff;
  background-color: #20a8d8;
  border-color: #1b8eb7;
}

.cb-edit:hover {
  color: #fff;
  background: #1b8eb7;
  border-color: #187da0;
}

/* button style end */

/* Scroll style start */
.scroll-style::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

.scroll-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  display: none;
}

.scroll-style::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-clip: border-box;
  border-width: 9px 1px;
  background-color: rgba(0, 0, 0, 0.2);
}

.scroll-style::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Scroll style end */

// sorting style
.react-grid-HeaderCell-sortable {
  position: relative;
}

.react-grid-HeaderCell-sortable:after {
  font-family: FontAwesome;
  content: "\f0dc";
  position: absolute;
  right: 0;
}

.react-grid-HeaderCell-sortable--ascending,
.react-grid-HeaderCell-sortable--descending {
  .pull-right {
    display: none;
  }
}

.react-grid-HeaderCell-sortable--ascending:after {
  content: "\f0de";
  top: 4px;
  color: #20a8d8 !important;
}

.react-grid-HeaderCell-sortable--descending:after {
  content: "\f0dd";
  top: -2px;
  color: #20a8d8 !important;
}

/* datetime picker style start */

.dp-box {
  position: relative;
  border: 1px solid #c2cfd6;
  border-radius: 4px;

  input {
    border: 0;
  }

  input:focus {
    outline: 0;
    box-shadow: none;
  }

  .fa-calendar {
    color: #536c79;
    position: absolute;
    top: 10px;
    right: 7px;
  }
}

.rdtPicker {
  color: #444;

  table,
  table thead tr {
    border: 1px solid #c2cfd6;
  }

  td.rdtDay:hover,
  td.rdtHour:hover,
  td.rdtMinute:hover,
  td.rdtSecond:hover,
  .rdtTimeToggle:hover {
    background: #edf1f4;
    border-radius: 4px;
    -webkit-box-shadow: inset 0px 0px 1px 1px rgba(194, 207, 214, 1);
    -moz-box-shadow: inset 0px 0px 1px 1px rgba(194, 207, 214, 1);
    box-shadow: inset 0px 0px 1px 1px rgba(194, 207, 214, 1);
  }

  td.rdtActive,
  td.rdtActive:hover {
    background-color: #c2cfd6 !important;
    color: #444;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 1px 1px #999;
    -moz-box-shadow: inset 0 0 1px 1px #999;
    box-shadow: inset 0 0 1px 1px #999;
  }

  td.rdtActive.rdtToday:before {
    border-bottom-color: #7b8286;
  }

  td.rdtToday:before {
    border-bottom: 7px solid #7b8286;
  }
}

/* datetime picker style end */

/* webview style start */
.webview-container {
  position: absolute;
  top: 0;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);

  .webview-container-core {
    position: absolute;
    bottom: 0;
    border: 1px solid #cacccd;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    padding-top: calc(100% - 245px);
  }

  .t-small {
    padding-top: calc(100% - 100px);
  }

  .t-large {
    padding-top: calc(100% - 300px);
  }

  .webview-search-bar {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #cacccd;
    background: #fff;
    border-radius: 10px 10px 0 0;
  }

  .webview-search {
    display: flex;
    justify-content: center;

    .search-input {
      width: 250px;
      font-weight: bold;
      text-align: center;
    }

    .search-input-inner {
      border-radius: 25px 0 0 25px !important;
    }

    .search-button {
      border: 1px solid #cacccd;
      border-left: 0;
      border-radius: 0 25px 25px 0;
      padding: 5px 12px 7px 12px;
      font-size: 16px;
      background: #eee;
      height: 35px;
      outline: none;
    }
  }

  .webview-frame-wrap {
    height: calc(100% - 42px);
    width: 100%;
    background-color: white;
    border-radius: 0 0 10px 10px;
    background-repeat: no-repeat;
    background-image: url("../img/loader3.gif");
    background-size: 32px 32px;
    background-position: center;
    // .fa-spinner{
    //   position: relative;
    //   top: 50%;
    //   left: 50%;
    //   z-index: -1;
    // }
  }

  .webview-frame {
    width: 100%;
    height: 100%;
    border-style: unset;
    border-radius: 0 0 10px 10px;
  }

  .favicon {
    position: absolute;
    left: 15px;

    .fa-globe {
      font-size: 20px;
    }
  }

  .close-view {
    position: absolute;
    right: 15px;
    cursor: pointer;

    .fa-times {
      font-size: 20px;
    }
  }
}

/* webview style end */

/*product view style start*/
.product-view-container {
  display: flex;

  .product-view-box {
    border: 1px solid #ddd;
    width: 550px;
    position: relative;
    left: 200px;
    height: 100%;

    .product-img-box {
      width: 100%;
    }

    .product-button-box {
      margin-left: 20px;
    }
  }
}

/*product view style end*/

// Change badge poisition for minimized sidebar
@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav-link .badge {
    display: block;
    top: 3px;
    right: 8px;
  }

  .sidebar-minimized .sidebar {
    width: 60px !important;

    #sidebarNav {
      width: 60px;

      .nav-item {
        // margin-left: 5px;
        border-radius: 30px;
      }

      .dropdown {
        .nav-item {
          margin-left: unset;
        }

        a {
          width: 48px;
          margin-left: 5px;
          border-radius: 30px;
        }

        i {
          width: 48px;
        }
      }
    }

    .nav-link:hover,
    .nav-item:hover>.nav-link,
    .nav-link.nav-link:hover i,
    .nav-link:hover i {
      background-color: #20a8d8; // #e7f5fb;
      // background-color: white;

      width: 50px;
      color: black;
    }
  }
}

/* Search box style start */
.s-box-outer {
  position: relative;
  border: 1px solid #c2cfd6;
  border-radius: 4px;

  .s-icon {
    position: absolute;
    padding: 10px;
    color: #3e515b;
  }

  .s-input {
    padding-left: 30px;
    border: 0;
  }
}

.s1-box-outer {
  position: relative;
  border: 1px solid #c2cfd6;
  border-radius: 4px;
  padding-left: 10px;

  .select-box {
    display: inline-block;
    margin-bottom: 0;

    .select-control {
      display: inline-block;
      width: 150px;

      .prefix-select__control,
      .prefix-select__control--is-focused {
        border: 0;
        box-shadow: none;
      }

      .prefix-select__indicator-separator {
        display: none;
      }

      .prefix-select__indicator {
        padding: 0 4px 0 0;
      }

      .prefix-select__dropdown-indicator {
        padding: 0 4px 0 0;
        border-right: 1px solid;
      }
    }
  }

  .input-box {
    display: inline-block;
    margin-bottom: 0;
  }

  input {
    width: 250px;
    display: inline-block;
    border: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

/* Search box style end */

/* Toggle for engagement list */

.toggleWrapper {
  padding: 3px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  height: 21px;

  span {
    width: 50%;
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    height: 15px;
  }
}

.toggleWrapper.toggle-center {
  max-width: 50px;
  margin: 10px 0;
}

.toggleWrapper.Active {
  background-color: #65b317;

  span:nth-child(1) {
    background: #fff;
    color: #65b317;
  }
}

.toggleWrapper.Inactive {
  background-color: #d22624;

  span:nth-child(2) {
    background: #fff;
    color: #d22624;
  }
}

.toggleWrapper.Pending {
  background-color: #ffa500;

  span:nth-child(1) {
    background: #fff;
    color: #65b317;
  }
}

/* Toggle for engagement list end*/

.toast-style {
  color: #444;
  background-color: #ccebf8 !important;
  border: 1px solid rgba(72, 163, 205, 0.5);

  .fa {
    width: 40px;
    display: inline-block;
    vertical-align: top;
    margin-top: 7px;
    font-size: 28px;
  }

  .toast-content {
    width: calc(100% - 40px);
    display: inline-block;
  }

  .toast-body-style {
    padding: 10px;
  }

  .toast-progress-style {
    background-color: #48a3cd !important;
    background: #48a3cd !important;
  }

  button {
    color: #48a3cd !important;
  }

  .hl {
    color: #ff6a00;
  }
}

.toast-style-v1 {
  min-height: auto;
  background: #f2f5f9;
  color: #000;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 0;
  border: 1px solid #c6d1d7;
}

.dt-filter-box {
  float: right;
  display: inline-flex;
  border: 1px solid #c2cfd6;
  border-radius: 4px;

  .dp-box {
    border: 0;

    input {
      border-right: 1px solid #c2cfd6;
      padding: 0 32px 0 12px;
      // margin: 6px 0;
      border-radius: 0 !important;
      width: 160px;
    }

    .fa-calendar {
      right: 13px;
    }
  }

  .btn-action {
    cursor: pointer;
    color: #3e515b;
    padding: 0 10px;
    background: #edf1f4;

    .fa {
      line-height: 32px;
    }
  }

  .apply {
    border: 1px solid #c2cfd6;
    border-width: 0 1px;
    margin-left: -1px;
    border-right: 1px solid #ddd;
  }

  .clear {
    border-radius: 0 4px 4px 0;
  }
}

.tbl-style {
  border: 1px solid rgba(164, 183, 193, 0.5) !important;

  th {
    background: #d7e0e6;
    border-right: 1px solid rgba(164, 183, 193, 0.5) !important;
    padding: 5px;
  }

  td {
    vertical-align: top;
    border-right: 1px solid rgba(164, 183, 193, 0.5) !important;
    padding: 10px;
  }

  tr:nth-child(even) {
    background: #f0f0f0;
  }
}

.small-input {
  padding: 7px;
  font-size: 12px;
}

/* changes for disable branding in chat widget - START */

#frame {
  .content.chat-widget.db-chat {
    .wgt-action-menu {
      bottom: 56px;
    }

    .message-input {
      .wrap {
        padding-bottom: 10px;
      }
    }
  }
}

.full-chat {
  #frame {
    .content.chat-widget {
      display: block;
      max-width: 100%;
      max-height: 100%;
      right: 0px;
      bottom: 0px;
      margin: 0;
      height: 100%;
      width: 100%;
      border-radius: 0;
      border-width: 0 0 0 1px;
    }
  }
}

/* changes for disable branding in chat widget - END */

/* filter style - START */

.mf-box {
  .f-info {
    margin-bottom: 7px;
    min-height: 24px;

    .f-title {
      font-weight: 600;
    }

    .f-col-box {
      margin-left: 10px;

      .f-col {
        background: #f5f5f5;
        margin-right: 5px;
        font-size: 11px;
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        padding: 2px 7px 3px 7px;
        border: 1px solid #ddd;
        border-radius: 4px;

        .f-col-label {
          font-weight: 600;
          margin-right: 3px;
        }

        .fa-times {
          cursor: pointer;
        }
      }
    }
  }

  .f-filter-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .ff-left {
      display: flex;
      width: 100%;

      .form-group {
        margin-bottom: 0;
      }

      .dp-box {
        width: 200px;
        height: fit-content;
      }

      button {
        height: fit-content;
      }
    }

    .ff-right {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: fit-content;
    }

    .f-filter-select {
      min-width: 250px;
    }

    // .ff-select {
    //   float: left;
    //   margin-bottom: 0;
    //   width: 180px;
    //   .prefix-select__control {
    //     border: 0;
    //   }
    // }
    // .ff-search {
    //   display: inline-block;
    //   margin-bottom: 0;
    //   input {
    //     width: 250px;
    //     display: inline-block;
    //     border: 0;
    //   }
    // }
    // .dp-box {
    //   float: left;
    //   width: 160px;
    // }
  }
}

/* filter style - END */

/* style for arrow if welcome note is not there */
.arrow.down-arrow {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}

.bot-params {
  display: inline-block !important;
  clear: both !important;
  float: left !important;
  margin: 30px 0 5px !important;
  width: calc(100% - 5px) !important;
  font-size: 13px !important;
  word-break: break-word;
  max-width: 400px;

  .chat-thumb {
    width: 28px;
    height: 28px;
    margin-bottom: 0;
    border: 1px solid #ddd;
    float: left;
    margin: 0 5px 0 0;
    border-radius: 50%;
  }

  .bot-msg {
    display: inline-block;
    border-radius: 10px !important;
    border-top-left-radius: 0 !important;
    color: #646464;
    margin: 0 0 5px 0;
    max-width: calc(100% - 35px);
    padding: 5px 10px;
    word-wrap: break-word;
    background: #fff;
    color: #000;
    border: 1px solid #e7e7e7;
    font-size: 14px;
  }

  .qreply {
    width: calc(100% - 30px);
    float: left;
    margin-left: 30px;

    .qlink {
      background: #fff;
      margin: 3px;
      color: #46a3cd;
      border: 1px solid #46a3cd;
      cursor: pointer;
      padding: 5px 10px;
      display: inline-block;
      border-radius: 15px;
    }
  }

  .chat-time {
    text-align: left;
    margin-left: 33px;
    display: block;
    font-size: 10px;
    font-style: italic;
    color: #999;
    line-height: 25px;
  }
}

.user-input-submit {
  margin: 3px;
  color: #46a3cd;
  border: 1px solid #46a3cd;
  cursor: pointer;
  padding: 5px 10px;
  display: inline-block;
  background: #fff;
  border-radius: 15px !important;
}

.business-logo-icon {
  position: relative;
  left: 55px;
}

.text-area-icon {
  position: relative;
  right: 13px;

  &.right-0 {
    right: 0;
  }
}

/* adv options common */
.adv-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 30px 20px 30px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  background: #fff;
  width: 70%;

  label,
  .fa {
    &:hover {
      color: #20a8d8;
    }
  }
}

.env-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 20px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  background: #fff;
  width: 100%;
}

/* Zoom meeting style start */
.meeting-client-inner {
  #wc-container-left {
    &>div {
      height: 100% !important;

      .main-layout {
        height: 100% !important;

        .active-main {
          width: 100% !important;
          height: 100% !important;

          #sv-active-video {
            width: 100% !important;
            height: calc(100% - 52px) !important;
            object-fit: cover;
          }

          #active-my-canvas {
            width: 100% !important;
            height: calc(100% - 52px) !important;
            object-fit: cover;
          }
        }
      }
    }
  }

  #dialog-join {
    display: none;
  }
}

.zoom-info {
  position: absolute;
  z-index: 9;
  background: #f8fcfc;
  left: calc(50% - 200px);
  width: 400px;
  height: 190px;
  top: calc(50% - 95px);
  text-align: center;
  border-radius: 15px;
  padding: 20px;
  color: #151b1e;

  .zoom-title {
    font-size: 25px;
  }

  .zoom-sub-title {
    font-size: 16px;
    color: #666;
    margin: 10px;
  }

  .zoom-btn {
    background: #48a3cd;
    color: #fff;
    padding: 7px 40px 5px 40px;
    border: 1px solid #999;
    margin-top: 10px;
  }

  .zoom-cls {
    cursor: pointer;
    position: absolute;
    top: -8px;
    right: -8px;
    background: #48a3cd;
    padding: 7px;
    border-radius: 50%;
    width: 26px;
    height: 27px;
    color: #fff;
  }
}

/* Zoom meeting style end */

/* stripe payment form style start */

.btn-pay {
  outline: 0 !important;
  margin: 0 3px;
  color: #46a3cd;
  background-color: transparent;
  border: 1px solid #46a3cd;
  cursor: pointer;
  padding: 5px 20px;
  display: inline-block;
  border-radius: 15px !important;
}

.lbl-payment-done {
  margin: 3px;
  padding: 5px 0;
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 15px;
}

.stripe-form {
  background: #f5f5f5;
  color: #000;
  border: 1px solid #e7e7e7;
  font-size: 14px;
  padding: 10px;
  border-radius: 10px !important;
  border-top-left-radius: 0 !important;

  label {
    margin-bottom: 5px;
    font-size: 13px;
  }

  .StripeElement {
    border: 1px solid #ddd;
    padding: 7px 10px 5px 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    background: #fff;
    -webkit-box-shadow: 1px 1px 0px 0px rgba(238, 238, 238, 1);
    -moz-box-shadow: 1px 1px 0px 0px rgba(238, 238, 238, 1);
    box-shadow: 1px 1px 0px 0px rgba(238, 238, 238, 1);
  }

  .StripeElement--focus {
    -webkit-box-shadow: 3px 3px 3px 0px rgba(238, 238, 238, 1);
    -moz-box-shadow: 3px 3px 3px 0px rgba(238, 238, 238, 1);
    box-shadow: 3px 3px 3px 0px rgba(238, 238, 238, 1);
  }
}

/* stripe payment form style end */

/* Razor pay checkout form style start */
.razorpay-container,
.razorpay-backdrop {
  width: calc(100% - 40px) !important;
  height: calc(100% - 40px) !important;
  min-height: calc(100% - 40px) !important;
  border-radius: 10px;
  overflow: hidden;
  left: 20px !important;
  top: 20px !important;
}

.full-chat {

  .razorpay-container,
  .razorpay-backdrop {
    width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
    border-radius: 0;
    overflow: hidden;
    left: 0 !important;
    top: 0 !important;
  }
}

/* Razor pay checkout form style end */

/* cloudinary popup style 
[data-test="uw-iframe"] {
  border: 1px solid #aaa!important;
  top: 20px !important;
  left: 20px !important;
  height: calc(100% - 40px) !important;
  width: calc(100% - 40px) !important;
  border-radius: 10px;
}*/

/* onedrive box style start */
.onedrive-box {
  border: 1px dotted #999;
  border-radius: 4px;
  overflow: auto;
  max-height: 400px;
}

.ms-Layer {
  .ms-Modal {
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
    top: 20px;
    left: 20px;
    border-radius: 10px;
    overflow: hidden;
  }
}

.od-preview-box {
  align-self: flex-start;
  padding: 10px 3%;
  display: block;
  width: 100%;
  background: #ecf7fd;
  border-bottom: 1px solid #ddd;

  .name {
    font-style: italic;
    color: #666;
  }

  .percent {
    font-weight: 600;
  }

  .status {
    font-weight: 600;
    text-transform: uppercase;
  }

  .od-preview-status {
    position: absolute;
    top: 54%;
    left: 41%;
    font-weight: bolder;
    animation-duration: 800ms;
    animation-name: blink;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}

.dzu-dropzone {
  border: 1px dotted #999 !important;
  background: #ecf7fd !important;
  overflow: auto !important;
  min-height: 140px !important;

  .dzu-inputLabel {
    color: #666 !important;
    font-weight: normal !important;
    font-family: inherit !important;

    .od-dropzone-lbl {
      color: #663d7c;
      text-align: center;
      font-size: 13px;
      font-weight: 600;
      margin: 25px 10px;

      .fa {
        font-size: 40px;
        margin-bottom: 10px;
      }
    }
  }

  .odbtn-cancel {
    background-color: #999;
  }

  .odbtn-item-cancel {
    border-radius: 4px;
    margin-left: 0.25rem;
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
  }
}

/* onedrive box style end */

// chat responsive css start
@mixin box-flex($flexSize) {
  flex: 0 0 $flexSize;
  max-width: $flexSize;
}

.left--box {
  @include box-flex(27%);
}

.middle--box {
  @include box-flex(46%);
}

.right--box {
  @include box-flex(27%);
}

.chat-form--box {
  @include box-flex(73%);

  .form-close {
    position: absolute;
    cursor: pointer;
    z-index: 9999;
    top: 0px;
    right: 15px;
    font-size: 26px;
    background: #fff;
    color: #444;
    border-radius: 20px;
    height: 20px;
    width: 22px;
    line-height: 23px;
  }

  .chat-form {
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 768px) {
  %three-box {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px !important;
  }

  .left--box {
    @extend %three-box;
  }

  .middle--box {
    @extend %three-box;
    display: none;
  }

  .right--box {
    @extend %three-box;
    display: none;
  }

  .visitor-btn-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: fixed;
    background: #48a3cd;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    z-index: 99;
    border-top: 1px solid #a4b7c1;
  }

  .visitor-left button,
  .visitor-right button,
  .visitor-middle button {
    background: #48a3cd;
    color: #eef3f5;
    border: 0;
    height: 40px;
    opacity: 0.7;

    & i {
      font-size: 14px;
    }

    &.active {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }

    img {
      height: 25px;
    }

    span {
      display: block;
      font-size: 13px;
    }
  }

  .main .mt10 {
    margin-top: 0 !important;
  }

  .agentChat .white-bg {
    border-radius: 0;
  }

  .show_box {
    display: block;
  }

  .hide_box {
    display: none;
  }

  footer.app-footer {
    display: none;
  }

  .agentChat #frame .content .message-input .wrap .attachment {
    top: 19px;
  }

  a.navbar-brand {
    border-bottom: none !important;
  }

  // header responsive
  .agentChat .white-bg .msg-tag-bg .chat-conversation-search-bg {
    width: 100%;
  }

  .app-header.navbar .navbar-toggler {
    min-width: 40px;
    z-index: 1;
  }

  .nav-item-left .breadcrumb {
    display: none;
  }

  .app-header.navbar .navbar-brand {
    background-size: 95px auto;
    padding: 0.5rem 0.5rem;
    left: 23%;
    background-color: transparent;
  }

  li.head-msg-icon.dropdown.nav-item span {
    display: none;
  }

  .app-header .nav-item.head-msg-icon {
    margin-left: 0 !important;
    padding-left: 0;
  }

  .navbar-nav>.nav-item>a>img {
    height: 18px;
  }

  .badge-pill {
    padding-right: 0.5em;
    padding-left: 0.5em;
  }

  .navbar-nav>.nav-item {
    padding: 0 !important;
    min-width: 40px !important;
  }

  .app-header.navbar .nav-item .nav-link>.img-avatar {
    height: 30px;
    width: 30px;
  }

  .agentChat .white-bg .msg-tag-bg .user-connectivity {
    display: none;
  }

  // login responsive start
  .login.page-layout {
    display: flex;
    flex-direction: column;
  }

  .layout.layout--custm {
    background: none;
    width: 100%;
    position: initial;
    float: none;
    height: auto;

    & .info {
      display: none;
    }

    &+.content {
      margin-left: 0;
      width: 100%;
      padding: 15px;
      background-image: url(../img/bg_layout.jpg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      height: 100%;
      flex: 1;
      position: relative;
      z-index: 1;

      &>.box {
        padding-top: 0;
        height: auto;
      }

      &:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #ffffff78;
        z-index: -1;
      }

      & .footer {
        color: #000;
      }
    }
  }

  .forgot-password.page-layout {
    display: flex;
    flex-direction: column;
  }

  // login responsive end
}

// chat responsive css end

/* video consulting popup style start */

.vc-wrapper-outer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .vc-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: white;
    border-radius: 0.3125rem;
    // box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.5);
    transition: transform 0.25s;
    transition-delay: 0.15s;
    width: calc(80% - 402px);
    margin-left: -382px;

    .vc-img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      border: 1px solid #ccc;
      margin-bottom: 20px;
      object-fit: cover;
    }

    .vc-header {
      text-align: center;
      font-size: 1.8rem;
      margin-bottom: 1rem;
      max-width: 500px;
      line-height: normal;
    }

    .vc-content {
      color: #666;
      font-size: 1rem;
      text-align: center;
    }

    .vc-content-loading {
      font-size: 1.5rem;
    }

    .vc-footer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0;
      padding: 1.875rem 0 0;

      .vc-action {
        position: relative;
        padding: 0.625rem 1.25rem;
        border: none;
        background-color: #2e85cc;
        border-radius: 0.25rem;
        color: white;
        font-size: 1.2rem;
        width: 180px;

        &:before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 0%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.2);
          transition: width 0.25s;
          z-index: 0;
        }

        &:hover:before {
          width: 100%;
        }
      }

      .vc-action-secondary {
        background-color: slategray;
      }
    }
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (max-width: 767px) {
  .vc-wrapper-outer {
    .vc-wrapper {
      width: 100%;
      margin: 0;
    }
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .vc-wrapper-outer {
    .vc-wrapper {
      width: calc(95% - 402px);
    }
  }
}

/* video consulting popup style end */

/* bot details style start */
.bot-details {
  .image {
    float: left;
  }

  .img-box {
    border: 1px solid #c2cfd6;
    width: 80px;
    height: 80px;
    // display: inline-block;
    border-radius: 4px;
    position: relative;
    padding: 2px;

    // float: left;
    button {
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background: rgba(255, 255, 255, 0.9);
      font-size: 24px;
      border: 0;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  .bd-left-container {
    border-right: 1px solid #ddd;
  }
}

/* bot details style end */

/* bot element popup style start */
.bt-json-api,
.bt-soap-api {
  .postman-head {
    border: 1px solid #ddd;
    padding: 0;
    border-radius: 4px;

    .nav-tab .nav-link.active {
      border-bottom: 3px solid #20a8d8;
      font-weight: bold;
    }

    .tab-content {
      border: 0;
      border-top: 1px solid #ddd;
      padding: 0 20px 20px 20px;
    }

    .tab-content .tab-pane {
      padding: 0;
      padding-top: 1rem;
    }
  }

  textarea {
    max-width: 100%;
  }
}

.bt-decision {
  .ddl-criteria {
    width: calc(100% - 70px);
    display: inline-block;
    margin-right: 20px;
  }

  .s-ddl-style .prefix-select__control {
    min-width: auto !important;
  }
}

.bt-javascript {
  .js-function {
    .fr {
      label {
        margin-top: 5px;
        font-weight: 600;
        color: #666;
        font-style: italic;

        span {
          font-weight: normal;
        }
      }

      textarea {
        background: #fcfcfc;
        border: 1px solid #f5f5f5;
        font-style: italic;
      }

      textarea:focus {
        border: 1px solid #ddd;
      }
    }
  }

  .display-head {
    padding: 0;
    border-radius: 4px;
    margin: 20px 20px 0 0;

    .nav-tab .nav-link.active {
      border-bottom: 3px solid #20a8d8;
      font-weight: bold;
    }

    .tab-content {
      border: 0;
      border-top: 1px solid #ddd;
    }

    .tab-content .tab-pane {
      padding: 0;
      padding-top: 1rem;
    }
  }
}

/* bot element popup style end */
.random-icon {
  position: absolute;
  right: 50px;
  top: 14px;
}

/* join meeting page style start */
.join-box {
  text-align: center;
  margin-top: 50px;

  .title {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .content {
    font-size: 18px;
  }
}

/* join meeting page style end */

/* paytabs payment popup style start */
// .hidden_iframe {
//   position: absolute;
//   width: calc(100% - 40px) !important;
//   height: calc(100vh - 40px) !important;
//   min-height: calc(100vh - 40px) !important;
//   border-radius: 10px;
//   overflow: hidden;
//   left: 20px !important;
//   top: 20px !important;
//   z-index: 9;
// }
// #PT_express_checkout_loader {
//   width: calc(100% - 40px) !important;
//   height: calc(100vh - 40px) !important;
//   min-height: calc(100vh - 40px) !important;
//   border-radius: 10px;
//   overflow: hidden;
//   left: 20px !important;
//   top: 20px !important;
// }
#paytabs-frame {
  position: absolute;
  border: 1px solid #ddd;
  width: calc(100% - 40px) !important;
  height: calc(100vh - 40px) !important;
  min-height: calc(100vh - 40px) !important;
  border-radius: 10px;
  overflow: hidden;
  left: 20px !important;
  top: 20px !important;
  z-index: 9;
  // background-image: url("/img/loader.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
  background-color: rgba(255, 255, 255, 0.5);
}

/* paytabs payment popup style end */

.appointment {
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
  }

  .nav-item:hover {
    cursor: pointer;
  }

  .tab-grid {
    .react-grid-Row--even {
      background-color: #fff !important;

      .react-grid-Cell {
        background-color: #fff !important;
      }
    }
  }

  .tab-content {
    border: 0;
    border-top: 1px solid #ddd;
    margin-top: -1px;
    background: #fff;
  }

  .nav-tab .active {
    border-bottom: 3px solid #20a8d8;
    font-weight: 700;
  }
}

.bs-tooltip-auto[x-placement^="right"] .arrow:before,
.bs-tooltip-right .arrow:before {
  // border-right-color: #f9f9f9;
  border-right-color: #20a8d8;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .tooltip-inner {
  top: -10px;
  position: relative;
  text-align: left;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
  background-color: rgba(220, 229, 234, 1.52);

  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 14px;
  }

  a:hover {
    background-color: #20a8d8;
    color: black;
  }
}

.more-info-icon {
  cursor: pointer;
  position: absolute;
  color: #fff;
  top: 5px;
  left: 10px;
  z-index: 9999;

  .ellipsis-icon {
    display: inline-block;
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    font-size: 2rem;
    user-select: none;
    fill: currentcolor;
  }

  li {
    list-style: none;

    a {
      color: #eee;
      padding: 0;
      font-size: 20px;
    }
  }

  .dropdown-menu {
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    // right: 0;
    // left: auto!important;
    top: 26px !important;
    min-width: 160px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 10px;
    border-color: #e3e3e3;
    border-radius: 8px;

    .dropdown-item {
      padding: 15px 20px 15px 38px;
      border-color: #e3e3e3;

      img {
        width: 20px !important;
        height: 20px !important;
        min-width: 20px !important;
        // top: 0 !important;
        left: 5px !important;
        border: 0 !important;
        box-shadow: none !important;
      }
    }

    .dropdown-item:active {
      color: #0b0e0f;
      text-decoration: none;
      background-color: #f0f3f5;
    }

    .dropdown-item:focus {
      outline: none;
    }

    .dropdown-item:first-child:hover {
      border-radius: 8px 8px 0 0;
    }

    .dropdown-item:last-child:hover {
      border-radius: 0 0 8px 8px;
    }
  }

  .dropdown-menu::before {
    content: "\A";
    border-style: solid;
    border-width: 0 5px 7px;
    border-color: transparent transparent #fff;
    position: absolute;
    top: -7px;
    left: 10px;
  }
}

.conversation {
  .eng-box {
    border: 1px solid #ddd;
    padding: 10px;
  }

  .graphTypes {
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }

  .graphTypes.inactive {
    color: #55b7f7 !important;
    text-decoration: underline;
  }

  .counter {
    background-color: rgb(255, 192, 25);

    .count {
      font-size: 16px !important;
    }

    .count-title {
      font-size: 28px !important;
    }
  }

  .highcharts-series .highcharts-point {
    width: 15px;
  }

  .sessions {
    background: #f0f3f4;
    padding: 15px;
  }

  .highcharts-background {
    background: #f0f3f4;
  }

  h4 {
    font-size: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }

  .input-range__track--active {
    background: #434348;
  }

  .input-range__slider {
    background: #434348;
    border: 1px solid #434348;
  }
}

/* AMP bot style start */
.amp-bot {
  .chat-widget {
    border-radius: 0 !important;
  }

  .w3-animate-bottom {
    animation: none !important;
  }

  .chatBubble {
    opacity: 0 !important;
    // display: none !important;
  }

  // .more-info-icon,
  // .welcome-note,
  .arrow {
    display: none !important;
  }

  // .left_title {
  //   margin-top: 0 !important;
  // }
}

/* AMP bot style end */

.ihelp {
  margin-top: 10px;
  width: 100% !important;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: flex-end;
  padding-left: 25px;
  justify-content: flex-start;

  .icon {
    font-size: 13px;
    color: #6f838f;
    cursor: pointer;
    border: 1px solid #6f838f;
    border-radius: 50%;
    width: 24px;
    height: 25px;
    text-align: center;
    line-height: 21px;
    margin: 5px 10px 8px 0px;
  }

  .icon:hover {
    color: #20a8d8;
    border-color: #20a8d8;
  }
}

.sidebar-minimized {
  .ihelp {
    justify-content: flex-end;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding-left: 0;

    .icon {
      margin: 5px 0 8px 0px;
    }
  }

  .bot-studio {
    .sticky-header {
      &.is-sticky {
        width: calc(100% - 90px);
      }
    }
  }

  .extensions {
    .extension-bot {
      .studio-fullscreen {
        .is-sticky {
          width: calc(100% - 90px) !important;
        }
      }

      .is-sticky {
        width: calc(58.33333% - 60px) !important;
      }
    }
  }
}

.sidebar-mobile-show {
  .ihelp {
    flex-direction: row;
    flex-grow: 1;
    align-items: flex-end;
    padding-left: 25px;
    justify-content: flex-start;
  }

  .icon {
    margin: 5px 10px 8px 0px;
  }
}

.tag-label {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin-right: 5px;
  }
}

// progress {
//   height: 10px;
//   border-radius: 10px;
//   background: #48a3cd;
// }

// progress::-webkit-progress-value {
//   background: #48a3cd;
//   border-radius: 10px;
// }

// progress::-webkit-progress-bar {
//   border-radius: 10px;
//   // background: blue;
// }

/* For Chrome or Safari */
progress::-webkit-progress-bar {
  background-color: #eeeeee;
  border-radius: 10px;
}

progress::-webkit-progress-value {
  background-color: #48a3cd !important;
  border-radius: 10px;
}

/* For Firefox */
progress {
  background-color: #eee;
  border-radius: 10px;
}

progress::-moz-progress-bar {
  background-color: #48a3cd !important;
  border-radius: 10px;
}

.M_USER {
  .nav-item-left {
    // display: none !important;
  }

  .navbar-toggler,
  .sidebar {
    display: none !important;
  }

  .navbar-brand {
    justify-content: flex-start !important;
  }

  .main {
    padding: 0 !important;
    margin: 0 !important;
  }

  .app-footer {
    margin-left: 0 !important;
  }

  .nav-item.head-msg-icon {
    border-left: 0 !important;
  }

  .conversation-bg {
    display: none !important;
  }
}

.ag-style {
  .ag-overlay-loading-center {
    border: 0 !important;
    box-shadow: none !important;
  }

  .cell-info-icon,
  .cell-action-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;

    & * {
      width: 16px;
    }
  }

  .cell-action-icon {
    justify-content: space-around;
  }

  .cell-channel {
    display: flex;
    align-items: center;

    img {
      height: 20px;
      margin-right: 5px;
    }
  }

  .cell-status {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    .lead-status-style {
      padding: 3px;
      font-size: 12px;
      border-radius: 4px;
      border: none !important;
      width: 85px;
      display: block;
      text-align: center;
      line-height: 25px;
    }
  }

  .ag-paging-panel {
    justify-content: flex-start;
  }

  .ag-side-buttons {
    padding-top: 0;
  }

  .ag-drag-handle {
    display: none;
  }

  .l-no-record {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 320px;
    color: #808080;

    .loader-text {
      color: #808080;
    }

    img {
      height: 60px;
    }

    .no-visitor {
      min-height: calc(100vh - 120px);
      align-self: center;

      img {
        width: 400px;
      }
    }
  }
}

.chat-close-icon {
  position: absolute;
  right: 10px;
  z-index: 9;
  top: -5px;
  background: #f8fcfd;
  height: 20px;
  width: 20px;
  border: 1px solid #ddd;
  text-align: center;
  padding-top: 2px;
  color: #48a3cd;
  cursor: pointer;
}

.page-tbl {
  .tab-pane {
    padding-left: 0;
    padding-right: 0;
  }
}

// popup design
.modal {
  .modal-content {
    border-radius: 10px;
  }

  .modal-footer {
    button {
      min-width: 60px;
      border-radius: 5px;
    }
  }

  .comment-clip-sendemail {
    position: absolute;
    right: 15px;
    top: -19px;
  }

  .sep-attachments-list {
    display: flex;
    margin-top: 15px;

    .al-item {
      background: #f2f5f9;
      padding: 4px 8px;
      margin-right: 10px;
      border: 1px solid #e2e2e3;
      border-radius: 7px;
      font-size: 11px;
      line-height: 15px;

      a:hover {
        text-decoration: none;
      }

      .fa-times {
        color: #151b1e;
        cursor: pointer;
        margin-left: 5px;
      }
    }

    .al-item:hover {
      border-color: #ccc;
    }
  }
}

// responsive for task page
.task-form {
  .task-preview-status {
    max-width: 75%;
    position: absolute;
    word-wrap: break-word;
    top: 40%;
    left: 15%;
    font-weight: bolder;
    animation-duration: unset;
    animation-name: unset;
    animation-iteration-count: unset;
    animation-direction: unset;
  }

  .od-preview-box {
    padding: unset;
    border-bottom: unset;

    .dzu-previewFileName {
      visibility: hidden;
    }
  }

  .task-attachment-preview {
    background: "#f5f5f5";
    padding: "5px 15px";
    border-top: 1px solid #c2cfd6;
    padding: 10px;

    .task-attachment-file {
      display: block;

      a {
        color: #6b6b6b;
        text-decoration: none;
        margin-left: 10px;
      }

      img {
        width: auto;
        max-height: 20px;
        max-width: 35px;
        border-radius: 4px;
      }

      .ta-delete-icon {
        position: absolute;
        right: 10px;
        cursor: pointer;
      }
    }
  }

  .task-attachment-preview:last-child {
    border-bottom: 1px solid #c2cfd6;
  }
}

@media (max-width: 768px) {
  .f-filter-search {
    flex-direction: column;

    .ff-left {
      flex-direction: column;
      margin-bottom: 20px;

      .form-group {
        margin: 0 0 10px 0 !important;
        width: 100% !important;
      }
    }

    .ff-right {

      button,
      a {
        width: 100%;
      }
    }
  }

  .page-head-right {
    margin-top: 0;
    display: flex;
    width: 100%;

    button {
      width: 100%;
    }
  }

  .task-form {
    .card-body {
      padding: 0 0 1.25rem 0;

      .text-right {
        text-align: unset !important;
      }

      .ti-m {
        width: 100% !important;
      }
    }
  }

  .task-chat {
    display: none;
    margin-bottom: 30px;
  }
}

.escalation {
  .page-head-right {
    margin-top: -60px;
  }

  .matrix {
    background: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px 10px 0 10px;
  }

  .rule-box {
    display: flex;
    margin-bottom: 15px;

    input,
    .prefix-select__placeholder {
      font-size: 13px;
    }

    .rule-sec {
      width: calc(100% - 64px);

      .form-group {
        margin-bottom: 0;
      }
    }

    .rule-btn {
      margin-left: 14px;
      align-items: center;
      display: flex;
    }
  }
}

.channel-analytics {
  padding: 20px;

  .ca-header {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .ca-footer {
    text-align: right;
    border-top: 1px solid #ddd;
    padding-top: 15px;
    margin-top: 15px;
  }

  .ca-content {
    .ca-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .cnt {
        display: flex;
        font-size: 20px;
        font-weight: 600;

        img {
          width: 14px;
          margin-right: 5px;
        }
      }

      .title {
        font-size: 13px;
        color: #777;
      }
    }

    .ca-box-link:hover {
      cursor: pointer;

      .title {
        color: #20a8d8;
      }
    }

    .ca-comments-box {
      font-size: 14px;

      .inner-head {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        align-items: center;

        .title {
          font-size: 14px;
        }

        .btn {
          font-size: 14px;
        }
      }

      .ca-citem {
        margin: 10px 10px 15px;

        .username {
          margin-right: 7px;
          font-weight: 600;
        }

        .time {
          font-size: 13px;
          color: #999;
          font-style: italic;
          margin-top: 3px;
        }
      }

      .scroll-bar {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 250px;
        white-space: nowrap;
      }
    }
  }
}

.row.sm {
  margin-right: -7px;
  margin-left: -7px;

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 7px;
    padding-left: 7px;
  }
}

.ReactModal__Overlay {
  z-index: 9999 !important;

  button {
    outline: none;
  }
}

.media-fileupload {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 20px;

  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;

    .nav-item:hover {
      cursor: pointer;
    }
  }

  .tab-content {
    border: 0;
    border-top: 1px solid #ddd;
    margin-top: -1px;
    background: #fff;
  }

  .nav-tab .active {
    border-bottom: 3px solid #20a8d8;
    font-weight: 700;
  }

  .fu-preview-box {
    display: flex;
    margin: 10px 0px;

    .preview-img-box {
      width: 72px;
      height: 72px;
      border-radius: 4px;
      border: 1px solid #a4b7c1;
      position: relative;
      margin-right: 5px;

      .fu-btn-close {
        right: 5px;
        top: 5px;
        background: #f86c6b;
        color: #fff;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        cursor: pointer;

        &:hover {
          background: #f64846;
        }
      }

      .fu-btn-download {
        right: 5px;
        top: 5px;
        background: #496886;
        color: #fff;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        cursor: pointer;

        &:hover {
          background: #000;
        }
      }

      img {
        border-radius: 4px;
        width: 70px;
        height: 70px;
      }
    }
  }
}

.http-request-head {
  border: 1px solid #ddd;
  padding: 0;
  border-radius: 4px;
}

.multitag-tag {
  white-space: nowrap;
  color: white;
  cursor: pointer;
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;

  .text {
    border-radius: 2px;
    color: hsl(0, 0%, 20%);
    font-size: 85%;
    overflow: hidden;
    padding: 3px;
    padding-left: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
  }

  .close {
    font-size: 1.1rem;
    font-weight: 700;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    border-radius: 2px;
    padding-left: 4px;
    padding-right: 4px;
    line-height: 20px;

    &:hover {
      background-color: #ffbdad;
      color: #de350b;
    }
  }
}

.intent-list {
  .item {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: flex-end;
    background: #f0f3f5;

    .title {
      font-weight: 600;
      width: 100%;
    }

    .desc {
      color: #777;
      width: 100%;
      margin-bottom: 0;
    }

    .info {
      font-size: 13px;
      text-align: right;
    }

    button {
      width: 80px;
      border-radius: 20px;
    }
  }
}

.ai-config {
  .equal-cols {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .rf-box {
    display: flex;
    border: solid 1px #ccc;
    padding: 20px;
    border-radius: 7px;
    cursor: pointer;

    .rf-img-box {
      display: flex;
      justify-content: center;
      width: 120px;
      height: 170px;
      padding: 20px;
      background: #f2f5f9;
      border-radius: 7px;
      overflow: hidden;
    }

    .badge {
      background-color: #765ca0;
    }

    .rf-info {
      width: calc(100% - 120px);
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .rf-title {
        word-break: break-all;
        color: #151b1e;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      }

      .rf-desc {
        color: #808080;
        margin-top: 5px;
        line-height: normal;
      }
    }
  }

  .ai-sample-box {
    max-height: 330px;
    max-width: 500px;
    margin-left: 20px;

    img {
      height: 100%;
      width: 100%;
      min-width: 500px;
      object-fit: contain;
      border: 1px solid #ddd;
      border-radius: 4px;
      padding: 10px;
    }
  }

  .ai-playground-result {
    display: flex;

    .ai-img {
      border: 1px solid #ddd;
      border-radius: 10px;
      padding: 10px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      background: #f9fbfc;
      width: 500px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .ai-data {
      width: 100%;
      border: 1px solid #ddd;
      border-radius: 10px;
      align-items: start;
      background: #f9fbfc;

      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
      column-gap: 20px;
      padding: 20px 20px 0 20px;

      .ai-data-item {
        display: inline-block;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 20px;
        margin-bottom: 20px;
        background: #fff;

        .title {
          font-size: 16px;
          font-weight: 600;
          border-bottom: 1px dotted #999;
          width: 100%;
          padding-bottom: 5px;
        }

        .box {
          .item {
            margin-bottom: 5px;

            .key {
              font-weight: 600;
              margin-right: 5px;
            }
          }
        }
      }

      .ai-data-item:nth-last-child(n + 2):nth-last-child(-n + 2):first-child~.ai-data-item {
        float: left;
      }
    }
  }

  .try-more {
    margin-top: 20px;

    .label {
      font-weight: 600;
      margin-bottom: 10px;
    }

    button {
      width: 70px;
    }
  }

  .gen-ai-playground {
    .play-textarea {
      background-color: #f9fbfc;
      height: 350px;
      overflow-y: auto;

      mark {
        padding: 0.1em 0.2em 0.2em 0.2em;
        border-radius: 4px;
        background: #d2e8f3;
      }
    }
  }

  .tran-ai-playground {
    .box {
      border: 1px solid #ddd;
      padding: 20px;
      border-radius: 10px;
      background: #f9fbfc;

      textarea {
        resize: none;
      }
    }
  }

  .agent-qr {
    .attr-container {
      width: calc(100% - 100px);
    }

    .attr-button {
      .group-button {
        cursor: pointer;
        transition: all 0.25s;

        &:hover {
          transform: scale(1.2);
        }

        &.disabled {
          opacity: 0.25;
          pointer-events: none;
        }
      }
    }

    .attr-close-button {
      width: 33px;
    }

    .attr-move-button {
      width: 66px;
    }

    .creatable-select {
      width: 25%;

      .selectable__control {
        border-radius: 4px 0 0 4px;
      }

      .selectable__indicator-separator {
        background-color: transparent;
      }
    }

    .key-input {
      width: 25%;

      .form-control {
        border-radius: 4px 0 0 4px !important;
        min-height: 38px;
      }
    }

    .value-input {
      width: 75%;

      .form-control {
        border-radius: 0 4px 4px 0 !important;
        border-left: none;
        min-height: 38px;
      }
    }
  }

  .responsive-ai {
    .switch-default {
      height: 20px;
    }

    .switch-handle {
      height: 16px;
    }
  }

  .ai-platform {
    .ai-platform-img {
      border-radius: 4px;
      padding: 5px;
      text-align: center;

      img {
        width: 150px;
      }
    }
  }
}

.quill-custom-footer {
  padding: 4px;
  position: relative;
  border: 1px solid #d1d1d1;
  border-top: none;
  background: #f8f8f8;
}

.int-config-box {
  display: flex;
  margin: 20px 0 30px 0;

  img {
    width: 80px;
    height: fit-content;
    margin-right: 20px;
  }

  .inti-config-info {
    // font-size: 13px;
    line-height: 25px;

    label {
      width: 80px;
    }

    span {
      font-weight: bold;
      margin-left: 10px;
    }

    .desc {
      line-height: normal;
    }

    button {
      width: 100px;
    }

    // img {
    //   object-fit: contain;
    //   width: 60px;
    //   margin: 0 15px 10px 10px;
    // }
  }
}

.access-report-list {
  .report-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .access-request-report-list {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    .report-title {
      font-size: 14px;
      font-weight: normal;
      margin-top: 0;
    }

    .switch.switch-default {
      height: 20px;
      margin: 2px 20px 0;
    }

    .switch-handle {
      height: 16px;
    }

    .type {
      color: #999;
    }
  }
}

.show-more {
  color: #20a8d8;
  cursor: pointer;
  padding-left: 5px;
}

.show-more:hover {
  text-decoration: underline;
}

.attribute-box {
  .attributes-actions {
    width: 70px;
    height: 34px;
  }

  .attr-btn-action {
    display: flex;
    position: absolute;
    right: 0;
    justify-content: center;
    align-items: center;
    height: 100%;

    .action-icon {
      position: relative !important;
      font-size: 15px !important;
      width: 25px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      &.icon-sm {
        font-size: 13px !important;
        width: 15px !important;
      }
    }
  }
}

.bot-details-preview {
  .bot-img-box {
    .bot-img {
      border-radius: 32px;
      border: solid 1px #cbd0d2;
      width: 180px;
      height: 180px;
    }
  }

  .attr-container {
    width: 100%;
  }

  .creatable-select {
    width: 25%;

    .selectable__control {
      border-radius: 4px 0 0 4px;
    }

    .selectable__indicator-separator {
      background-color: transparent;
    }
  }

  .attr-button {
    .group-button {
      cursor: pointer;
      transition: all 0.25s;

      &:hover {
        transform: scale(1.2);
      }

      &.disabled {
        opacity: 0.25;
        pointer-events: none;
      }
    }
  }

  .value-input {
    width: 75%;
  }

  .line {
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    height: 1px;
    background-color: #cbd0d2;
    width: 100%;
  }

  .catagory {
    margin-top: 30px;
    font-size: 17px;
    font-weight: 600;
  }

  .name {
    color: #666;
  }

  .bot-head {
    .title {
      font-size: 27px;
      margin-top: 5px;
      margin-bottom: 5px;
      font-weight: 600;
    }

    .desc {
      float: left;
      font-size: 16px;
      font-weight: 500;
      color: #666;
      padding: 5px 0;
    }

    .btn {
      width: fit-content;
      padding: 5px 10px;
      float: right;
      top: 10px;
      border: solid 1px #cbd0d2;
      border-radius: 18px;
      background-color: #a4b7c1;
    }
  }

  .ratting-box {
    .fa-star {
      font-size: 17px;
      color: #999;
    }

    .btn-grp {
      float: right;
      margin-right: 20px;
    }
  }

  .bot-video {
    margin: 20px 0;

    .video-title {
      font-size: 17px;
      font-weight: 600;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}

.form-box {
  display: flex;

  .form-preview {
    width: calc(100% - 360px);

    .form-preview-content {
      width: 500px;
      margin: 0 auto;
      background-color: #fff;
      padding: 5px 10px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

      input,
      select {
        background: transparent;
        cursor: inherit;
      }

      label {
        font-weight: 600;
        font-size: 13px;
        margin-bottom: 5px;
        cursor: inherit;
      }

      .s-ddl-style {
        .prefix-select__control {
          min-width: 10px !important;
        }
      }

      .draggable {
        div {
          cursor: move;
        }
      }
    }
  }

  .form-details-outer {
    width: 360px;
    padding-left: 20px;
    font-size: 13px;

    .form-details {
      position: fixed;
      width: 330px;
      margin-top: -50px;
      height: 380px;
      z-index: 99;
      padding-left: 10px;
      border-left: 1px solid #ddd;

      .form-title {
        background: #f5f5f5;
        padding: 10px;
        font-weight: 600;
      }
    }
  }
}

.ck-editor__editable_inline {
  height: 250px;
  overflow: auto;
}

.custom-input {
  padding: 8px;
  border: 1px solid #dbdbdb;
  border-radius: 0 !important;
}

.highcharts-legend-item text {
  font-weight: bold;
}

.highcharts-axis-labels text {
  color: #666666 !important;
  cursor: default !important;
  font-size: 11px !important;
  fill: #666666 !important;
}

.attachments-list {
  $darkBgTextColor: #f2f5f9;
  $borderColor: #e2e2e3;
  $radius: 7px;
  $primaryColor: #151b1e;
  $borderHoverColor: #ccc;

  display: flex;
  margin-top: 15px;

  .al-item {
    background: $darkBgTextColor;
    padding: 4px 8px;
    margin-right: 10px;
    border: 1px solid $borderColor;
    border-radius: $radius;
    font-size: 11px;
    line-height: 15px;

    a:hover {
      text-decoration: none;
    }

    .fa-times {
      color: $primaryColor;
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .al-item:hover {
    border-color: $borderHoverColor;
  }
}

.vertical_buttons {
  $radius: 4px;
  $borderColor: #e2e2e3;
  $buttonColor: #20a8d8;
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn {
    color: #3e515b;
    width: 100%;
    margin-bottom: 5px;
    border: 1px solid $borderColor;
    box-sizing: border-box;
    border-radius: $radius;
  }

  .btn:hover {
    text-decoration: none;
    background-color: hsl(0, 0%, 95%);
  }

  .btn:focus {
    text-decoration: none;
  }

  .btn.active {
    background-color: hsl(0, 0%, 90%);
  }
}