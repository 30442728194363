.v1-design {
  padding: 0;
  margin: 0;
  background-color: #f2f5f9;

  $radiusBtn: 5px;
  $radius: 7px;

  $shadow: rgba(0, 0, 0, 0.05) 0px 1px 4px;
  $shadow1: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  $lightBgColor: #f2f5f9;

  $darkBgColor: #142342;
  $darkBgTextColor: #f2f5f9;

  $darkBgText2Color: #e8843b;

  $primaryColor: #151b1e;
  $secondaryColor: #808080;
  $borderColor: #e2e2e3;
  $borderHoverColor: #ccc;

  $buttonColor: #20a8d8;
  $buttonHoverColor: #1b8eb7;

  $buttonDangerColor: #f86c6b;
  $buttonDangerHoverColor: #f64846;

  $buttonBannerColor: #266eb7;
  $buttonBannerHoverColor: #214090;

  $bannerColor: #31bafd;

  ::-webkit-input-placeholder {
    /* Edge */
    color: #a9a9a9;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #a9a9a9;
  }

  ::placeholder {
    color: #a9a9a9;
  }

  .vc-fw-600 {
    font-weight: 600 !important;
  }

  .vc-primary {
    color: $primaryColor !important;
  }

  .vc-secondary {
    color: $secondaryColor !important;
  }

  .vc-btn-color {
    color: $buttonColor !important;
  }

  .vc-border-color {
    border-color: $borderColor !important;
  }

  .vc-pwd-box {
    input {
      border-right: 0;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .icon-pwd {
      border: 1px solid #c2cfd6;
      border-width: 1px 1px 1px 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      cursor: pointer;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
    }
  }

  .vc-img-contain {
    object-fit: contain;
  }

  .line-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .line-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .line-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  .clearfix {
    clear: both;
  }

  .flex-col {
    flex-direction: column;
  }

  .container-fluid {
    padding: 15px;
  }

  .scroll-v1::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .scroll-v1::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  .scroll-v1::-webkit-scrollbar-thumb {
    background: rgb(192, 192, 192);
    border-radius: 5px;
  }

  .scroll-v1::-webkit-scrollbar-thumb:hover {
    background: rgb(146, 145, 145);
  }

  input[type="color"] {
    padding: 2px 4px;
  }

  input[type="color"]::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 4px;
  }

  input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
  }

  .row {
    margin-right: -10px;
    margin-left: -10px;

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  .row.equal-cols {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    &:before,
    &:after {
      display: block;
    }

    & > [class*="col-"] {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    & > [class*="col-"] > * {
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
    }
  }

  .m-box {
    background-color: #fff;
    padding: 20px;
    border-radius: $radius;
    box-shadow: $shadow;

    .m-box-title {
      color: $primaryColor;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .m-box-title-xl {
      color: $primaryColor;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .m-box-title-sm {
      color: $primaryColor;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .title-full {
      width: calc(100% + 40px);
      margin: -5px -20px 15px -20px;
      padding: 0 20px 10px 20px;
      border-bottom: 1px solid $borderColor;
    }

    .m-box-desc {
      color: $buttonColor;
      margin-bottom: 10px;
    }

    .m-box-info {
      color: $secondaryColor;
    }
  }

  .cp-action-box-small {
    background: $darkBgTextColor;
    border: 1px solid $borderColor;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    display: flex;
    text-align: center;
    padding: 5px;
    cursor: pointer;
  }

  .m-head-box {
    display: flex;
    justify-content: space-between;

    .m-box-title-xl {
      margin-bottom: 2px;
    }

    .m-box-desc {
      font-size: 13px;
      margin-bottom: 0;
      color: $secondaryColor;

      i {
        color: $buttonColor;
        margin-right: 5px;
      }
    }
  }

  .input-with-image {
    position: relative;
  }

  .input-image {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    margin-left: -18px;
    z-index: 9;
  }

  /** MBox design start **/
  .m1-box {
    background-color: #fff;
    border: 1px solid $borderColor;
    border-radius: $radius;
    padding: 5px;

    .m1-img-box {
      background-color: $darkBgTextColor;
      border-radius: 5px;
      overflow: hidden;
    }

    .m1-title {
      font-weight: 600;
      margin: 10px 0 5px 0;
      color: $primaryColor;
    }

    .m1-desc {
      font-size: 13px;
      color: $secondaryColor;
    }
  }

  .m1-box.click {
    cursor: pointer;
  }

  .m1-box.click:hover {
    border-color: $borderHoverColor;
  }

  /** MBox design end **/

  /** KBox design start **/
  .k-box {
    background-color: #fff;
    box-shadow: $shadow;
    border-radius: $radius;
    padding: 25px 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    align-items: center;
    position: relative;

    .k-img-box {
      width: 30px;
    }

    .k-info-box {
      margin-left: 10px;

      .k-title {
        font-weight: 600;
        color: $primaryColor;
      }

      .k-desc {
        font-size: 13px;
        color: $secondaryColor;
      }
    }

    .k-icon {
      position: absolute;
      right: 15px;
      bottom: 15px;
      box-shadow: $shadow1;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }

    .k-count {
      position: absolute;
      right: 25px;
      top: 22px;
      box-shadow: $shadow1;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }

  .k-box.click {
    cursor: pointer;
  }

  .k-box.click:hover {
    .k-icon {
      color: $buttonColor;
    }
  }

  /** KBox design end **/

  .banner {
    flex: unset !important;
    padding: 40px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-position: right 50px bottom 0px;
    min-height: 160px;
    color: #fff;
    background-size: auto calc(100% - 20px);

    .banner-title {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: 600;
    }

    .banner-desc {
      width: 50%;
    }

    .banner-action {
      button {
        background-color: $buttonBannerColor;
        color: $darkBgTextColor;
        border-radius: 2px;
        line-height: 30px;
        padding: 0 10px;
        border-radius: $radiusBtn;
        letter-spacing: 0.5px;

        &:hover {
          background-color: $buttonBannerHoverColor;
        }
      }
    }
  }

  .btn-action {
    background: $darkBgTextColor;
    border: 1px solid $borderColor;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border-color: $borderHoverColor;
    }
  }

  .outline-none,
  .outline-none:focus {
    outline: none;
  }

  .btn-v1 {
    background-color: $buttonColor;
    color: #fff;
    line-height: 30px;
    padding: 0 10px;
    border-radius: $radiusBtn;
    border: 1px solid $buttonColor;
  }

  .btn-v1:hover {
    background-color: $buttonHoverColor;
  }

  .btn-v2 {
    background-color: #fff;
    color: $buttonColor;
    line-height: 30px;
    padding: 0 10px;
    border-radius: $radiusBtn;
    border: 1px solid $buttonColor;
  }

  .btn-v2:hover {
    color: $buttonHoverColor;
    border-color: $buttonHoverColor;
  }

  .btn-transparent-grey {
    background-color: transparent;
    color: $secondaryColor;
    border: solid 1px $borderColor;
    border-radius: 6px;

    &:hover {
      background-color: $lightBgColor;
    }
  }

  .btn-danger {
    color: #fff;
    background-color: $buttonDangerColor;
    border: 1px solid $buttonDangerColor;
  }

  .btn-danger:hover {
    background-color: $buttonDangerHoverColor;
  }

  .btn-v1-orange {
    background-color: orange;
    color: #000;
    border: 1px solid darkorange;
  }

  .btn-v1-orange:hover {
    background-color: darkorange;
  }

  .btn-v3 {
    cursor: pointer;
    color: $buttonColor;
  }

  .btn-v3:hover {
    color: $buttonHoverColor;
    text-decoration: underline;
  }

  .btn-v1:focus,
  .btn-v2:focus,
  .btn-v3:focus {
    outline: none;
  }

  .ag-style {
    .ag-overlay-loading-center {
      border: 0 !important;
      box-shadow: none !important;
    }

    .cell-info-icon,
    .cell-action-icon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;

      & * {
        width: 16px;
      }
    }

    .cell-action-icon {
      justify-content: space-around;

      a {
        color: #181d1f;
        margin-top: 2px;
      }

      a:hover {
        text-decoration: none;
      }
    }

    .cell-channel {
      display: flex;
      align-items: center;

      img {
        height: 20px;
        margin-right: 5px;
      }
    }

    .cell-status {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;

      .lead-status-style {
        padding: 3px;
        font-size: 12px;
        border-radius: 4px;
        border: none !important;
        width: 85px;
        display: block;
        text-align: center;
        line-height: 25px;
      }
    }

    .ag-paging-panel {
      justify-content: flex-start;
    }

    .ag-side-buttons {
      padding-top: 0;
    }

    .ag-drag-handle {
      display: none;
    }
  }

  .ag-style-outer-v1 {
    .ag-root-wrapper {
      border-radius: 7px;
      border-color: $borderColor;

      .ag-header,
      .ag-side-bar-right,
      .ag-paging-panel {
        border-color: $borderColor;
      }
    }
  }

  .tab-style {
    .tab-content {
      border: 0;
      border-top: 1px solid $borderColor;
      margin-top: -1px;
      background: #fff;
    }
  }

  .tab-v1 {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    border-bottom: 1px solid $borderColor;

    .btn {
      color: $secondaryColor;
      border: 0;
    }

    .btn:hover {
      color: $primaryColor;
      text-decoration: none;
      background-color: transparent;
    }

    .btn:focus {
      text-decoration: none;
    }

    .btn.active {
      color: $buttonColor;
      border-bottom: 2px solid $buttonColor;
    }
  }

  .rb-no-record {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $secondaryColor;

    .loader-text {
      color: $secondaryColor;
    }

    img {
      height: 60px;
    }

    &.no-visitor {
      min-height: calc(100vh - 120px);
      align-self: center;

      img {
        width: 400px;
      }
    }
  }

  .w-190 {
    width: 190px;
  }

  /** tags design start **/

  .react-tagsinput {
    background-color: #fff;
    border: 1px solid #c2cfd6;
    overflow: hidden;
    border-radius: 4px;
    padding-left: 5px;
  }

  .react-tagsinput--focused {
    border-color: none;
  }

  .react-tagsinput-tag {
    background-color: #edf1f4;
    border-radius: 2px;
    display: inline-block;
    margin: 5px 5px 5px 0;
    padding: 2px 5px;
    font-size: 12px;
    border: 1px solid #a4b7c1;
  }

  .react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
  }

  .react-tagsinput-tag a::before {
    content: " ×";
  }

  .react-tagsinput-input {
    background: transparent;
    border: 0px;
    // height: 22px;
    padding-top: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
    padding-bottom: 0px;
    margin-top: 1px;
    outline: none;
    width: 100%;
  }

  /** tags design end **/

  // $engagementColor: #b5e8ef;
  // $automationColor: #91b3fa;
  // $bannerColor: #dae3fe;
  // $analyticsColor: #ef5454;
  // $knowledgeColor: #2edbad;

  // .engagement-color {
  //   background-color: $engagementColor;
  // }
  // .automation-color {
  //   background-color: $automationColor;
  // }
  // .report-color {
  //   background-color: $bannerColor;
  // }
  // .analytics-color {
  //   background-color: $analyticsColor;
  // }
  // .knowledge-color {
  //   background-color: $knowledgeColor;
  // }

  .dashboard {
    .dash-get-started {
      display: flex;
      justify-content: space-between;
      background-color: $darkBgColor;

      .dgs {
        width: 200px;

        .dg-title {
          color: $darkBgTextColor;
          font-weight: 600;
          font-size: 18px;
          margin-bottom: 5px;
        }

        .dg-desc {
          color: $darkBgTextColor;
        }

        .dg-progress {
          width: 90%;
          height: 7px;
          background: $darkBgTextColor;
          margin: 10px 0;
          border-radius: 5px;
        }

        .dg-load {
          display: block;
          background-color: $darkBgText2Color;
          width: 25%;
          height: 7px;
          border-radius: 5px;
          transition: all 3s;
        }

        .dg-status {
          color: $darkBgText2Color;
        }
      }

      .dgs-info {
        width: calc(100% - 203px);
        display: flex;

        .m1-box {
          margin: 0 7px;
          width: 100%;

          .m1-img-box {
            text-align: center;
            padding: 10px 20px;

            img {
              // width: 80px;
              height: 70px;
            }
          }
        }

        .m1-box:last-child {
          margin-right: 0px;
        }
      }
    }

    .dash-help {
      .help-box {
        .hb-link {
          line-height: 30px;

          a {
            color: $primaryColor;

            img {
              display: inline-block;
              margin-right: 10px;
              width: 15px;
              height: 17px;
              margin-top: -5px;
            }
          }
        }

        .hb-ask-que {
          width: 100%;
          margin: 20px 0 10px 0;
        }
      }

      .webinar {
        margin-top: 30px;
        border-top: 1px solid $borderColor;
        padding-top: 20px;

        img {
          display: block;
          max-width: 90px;
          margin: 20px auto;
        }

        ul {
          margin-left: -30px;
          margin-bottom: 0;
          line-height: 35px;
          list-style-type: none;
          color: $secondaryColor;
        }

        .webinar-signup {
          width: 100%;
          margin: 10px 0;
        }
      }
    }

    .to-do {
      .td-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        img {
          max-width: 50px;
          max-height: 35px;
          margin-bottom: 15px;
        }

        .dtm-title {
          color: $primaryColor;
          font-weight: 600;
          margin-bottom: 5px;
          font-size: 16px;
          line-height: normal;
        }

        .dtm-desc {
          color: $secondaryColor;
          margin-bottom: 30px;
        }

        .dti-cnt {
          font-size: 22px;
          font-weight: 600;
          margin: 9px 0;
        }

        a {
          display: block;
          font-weight: 600;

          i {
            float: right;
            border: 1px solid $borderColor;
            height: 20px;
            width: 20px;
            font-size: 12px;
            text-align: center;
            line-height: 17px;
            border-radius: 50%;
            background-color: $darkBgTextColor;
          }
        }
      }
    }

    .top-intents {
      .ti-title {
        float: left;
        color: $buttonColor;
        margin-bottom: 20px;
      }

      .ti-lbl {
        float: right;
        color: $secondaryColor;
      }
    }

    .top-intents.loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .top-intents.no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: auto;

      img {
        width: 170px;
        margin-bottom: 20px;
      }

      .tie-title {
        color: $primaryColor;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .tie-subtitle {
        color: $secondaryColor;
        width: 80%;
        margin-bottom: 15px;
      }
    }

    .dash-menu {
      display: flex;
      justify-content: space-between;

      .m1-box {
        width: calc(20% - 15px);

        .m1-img-box {
          height: 150px;
          padding: 10px 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100px;
            height: 100px;
          }
        }
      }
    }

    .info-link {
      .il-title {
        border-bottom: 1px solid $borderColor;
        padding-bottom: 15px;
        margin-bottom: 20px;
      }

      .ils-title {
        font-weight: 600;
        margin-bottom: 10px;

        i {
          margin-right: 8px;
        }
      }

      ul {
        list-style: none;
        padding-left: 22px;

        li {
          line-height: 30px;
          font-weight: 600;
        }
      }
    }

    .dash-channel {
      .dc-box {
        .info-box {
          margin: 0 2px;

          .dc-cl {
            width: calc(100% - 115px);
            float: left;

            .dc-bi {
              height: 70px;
              margin-bottom: 20px;

              img {
                height: 45px;
                width: 45px;
                margin-bottom: 10px;
              }

              .dc-it {
                color: $primaryColor;
                font-weight: 600;
              }
            }

            .dc-pr {
              color: $buttonColor;
              margin-bottom: 5px;
            }

            .progress {
              border-radius: $radius;
              border: none;
              background-color: $darkBgTextColor;

              .progress-bar {
                border-radius: $radius;
                background-color: $buttonColor;
              }
            }

            .dc-pd {
              margin-top: 3px;
              color: $secondaryColor;
            }
          }

          .dc-cr {
            width: 100px;
            float: right;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 140px;

            a:hover {
              text-decoration: none;

              .dc-text {
                text-decoration: underline;
              }
            }

            .dc-i {
              // background-color: $darkBgTextColor;
              border: 1px solid $borderColor;
              border-radius: $radius;
              padding: 7px;

              .dc-number {
                color: $primaryColor;
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 3px;
              }

              .dc-text {
                color: $buttonColor;
                font-weight: 600;
              }
            }
          }
        }
      }

      .dc-ac-btn {
        position: absolute;
        width: 100%;
        top: 80px;
        font-size: 25px;

        .fa.fa-angle-left {
          cursor: pointer;
          left: -40px;
          position: absolute;
          border: 1px solid $borderColor;
          border-radius: $radius;
          padding: 3px 10px 5px 8px;
          color: $primaryColor;
          background: #fff;
        }

        .fa.fa-angle-right {
          cursor: pointer;
          right: -40px;
          position: absolute;
          border: 1px solid $borderColor;
          border-radius: $radius;
          padding: 3px 8px 5px 10px;
          color: $primaryColor;
          background: #fff;
        }
      }
    }

    .show-btn {
      width: calc(100% - 80px);
      margin-left: 40px;
      position: relative;
    }
  }

  .reports {
    .banner {
      background-color: $bannerColor;
      background-image: url("../img/v1/reports/report-banner.svg");
    }

    .ri-box {
      border-left: 5px solid $bannerColor;

      .ri-icon {
        width: 32px;
        height: 32px;
        position: absolute;
      }

      .ri-title {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 10px;
        color: $primaryColor;
        margin-left: 42px;
      }

      .ri-desc {
        color: $secondaryColor;
        margin-left: 42px;
      }

      .ri-link-box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .ri-link {
          cursor: pointer;
          border: 1px solid $bannerColor;
          border-radius: 20px;
          padding: 7px 15px;
          display: inline-block;
          margin: 5px;
        }

        .ri-link:hover {
          background-color: #dae3fe;
          border-color: #31bafd !important;
        }
      }
    }
  }

  .reprot-item {
    .report-filter-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .form-group {
        margin-bottom: 0px !important;
      }

      .report-date {
        .DateRangePickerInput__withBorder {
          border-radius: 4px;

          .DateInput {
            border-radius: 4px;
          }
        }
      }

      .report-select {
        .prefix-select__control {
          height: 38px;
        }
      }

      .report-select-title {
        color: $primaryColor;
        font-weight: 500;
        font-size: 14px;
        line-height: 36px;
      }
    }

    .report-tab {
      // display: flex;
      background-color: #fff;
      border-bottom: 1px solid $borderColor;
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;

      .btn {
        color: $secondaryColor;
        font-weight: 600;
        border: 0;
      }

      .btn:hover {
        color: $primaryColor;
        text-decoration: none;
        background-color: transparent;
      }

      .btn:focus {
        text-decoration: none;
      }

      .btn.active {
        color: #fff;
        background-color: $buttonColor;
        border-radius: $radius;
      }
    }

    .report-select-option {
      color: $secondaryColor;
      font-size: 16px;
      height: calc(100vh - 210px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  // $knowledgeColor: #31bafd;
  .knowledge {
    .banner {
      background-color: $bannerColor;
      background-image: url("../img/v1/knowledge/knowledge-banner.svg");
      min-height: 210px;
    }

    .kb-status {
      .ks-box {
        display: flex;
        align-items: center;
        border-radius: $radius;
        background-color: #edf1f4;
        padding: 12px;
        padding-top: 10px;
        justify-content: space-between;

        .ksb-indi {
          display: flex;

          img {
            width: 20px;
            margin-right: 10px;
          }

          .ksb-title {
            color: $primaryColor;
            font-weight: 600;
          }

          .ksb-desc {
            color: $buttonColor;
          }

          iframe {
            margin-top: -10px;
            height: 175px;
          }
        }
      }

      .m-box-desc {
        color: $secondaryColor;
      }

      .m-box-title {
        position: absolute;
      }

      .m-box {
        padding: 18px;
        padding-bottom: 14px;
      }
    }

    .file-name-container {
      border: 1px solid #c2cfd6;
      border-radius: 4px;
    }

    .file-remove-icon {
      color: red;
      cursor: pointer;
    }

    .sliderContainer {
      padding: 0px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 20px;

      .slider {
        height: 30px;
        width: calc(100% - 50px);
        margin-top: 20px;
      }

      .slider-value {
        border: solid 1px #c2cfd6;
        color: #3e515b;
        border-radius: 50%;
        font-size: 12px;
        width: 30px;
        height: 30px;
        text-align: center;
        padding: 5px 0;
        font-weight: bold;
        margin-top: 4px;
      }
    }

    .business-faq-block {
      margin-top: 0.5rem;
      border: 1px solid rgb(204, 204, 204);
      padding: 20px;
      border-radius: 6px;
    }
  }

  .botstudio {
    .banner {
      background-color: #557898;
      background-image: url("../img/v1/robot/robot-banner.svg");
    }

    .eng-filter {
      width: 300px;

      label {
        margin-top: 7px;
      }
    }

    .rf-box {
      display: flex;

      .rf-img-box {
        display: flex;
        justify-content: center;
        width: 120px;
        height: 170px;
        padding: 20px;
        background: $darkBgTextColor;
        border-radius: $radius;
        overflow: hidden;

        img {
          object-fit: cover;
        }
      }

      .rf-info {
        width: calc(100% - 120px);
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .rf-title {
          word-break: break-all;
          color: $primaryColor;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        }

        .rf-desc {
          color: $secondaryColor;
          margin-top: 5px;
          line-height: normal;
        }

        .rf-icons {
          margin-top: 15px;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          i {
            font-size: 20px;
          }

          img {
            width: 20px;
            height: 20px;
          }

          .bot-btn-sm {
            font-size: 11px;
            padding: 0 7px;
            line-height: 25px;
          }
        }
      }
    }

    .bot-store-temp {
      .rf-icons {
        align-items: center !important;
      }

      .purchased {
        color: #20a8d8;
        line-height: 15px;

        i {
          border: 1px solid;
          border-radius: 50%;
          font-size: 10px !important;
          padding: 1px;
          position: absolute;
        }

        span {
          margin: 0 0 0 18px;
          display: inline-block;
          font-size: 13px;
        }
      }
    }

    .attr-container {
      // width: calc(100% - 100px);
      width: 100%;
    }

    .attr-button {
      .group-button {
        cursor: pointer;
        transition: all 0.25s;

        &:hover {
          transform: scale(1.2);
        }

        &.disabled {
          opacity: 0.25;
          pointer-events: none;
        }
      }
    }

    .attr-close-button {
      // width: 33px;
    }

    .attr-move-button {
      width: 66px;
    }

    .creatable-select {
      width: 25%;

      .selectable__control,
      .prefix-select__control {
        // border-radius: 4px;
        height: 38px;
      }

      .selectable__indicator-separator {
        background-color: transparent;
      }

      .selectable__menu {
        margin-top: -8px;
      }
    }

    .key-input {
      width: 25%;

      .form-control {
        // border-radius: 4px 0 0 4px !important;
        min-height: 38px;
      }
    }

    .value-input {
      width: 75%;

      .form-control {
        // border-radius: 0 4px 4px 0 !important;
        // border-left: none;
        min-height: 38px;
      }
    }
  }

  .studio-chatbot {
    .banner {
      background-color: #1e4266;
      background-image: url("../img/v1/studio/chatbot.png");
    }
  }

  .studio-flowbot {
    .banner {
      background-color: $bannerColor;
      background-image: url("../img/v1/studio/flowbot.png");
    }
  }

  .robotagents {
    .banner {
      background-color: $bannerColor;
      background-image: url("../img/v1/robot/robot-agent-banner.svg");
    }

    .ra-box {
      display: flex;

      .ra-img-box {
        display: flex;
        justify-content: center;
        width: 100px;
        height: 100px;
        background: $darkBgTextColor;
        border-radius: 50%;
      }

      .ra-info {
        width: calc(100% - 100px);
        padding-left: 20px;

        .ra-title {
          color: $primaryColor;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
        }

        .ra-desc {
          color: $secondaryColor;
          margin-top: 5px;
        }
      }
    }
  }

  .robotagentdetails {
    .ra-profile-box {
      display: flex;
      justify-content: center;

      img {
        max-width: 180px;
        max-height: 180px;
        border: 1px solid #ddd;
        border-radius: 50%;
        padding: 2px;
        width: 100%;
        height: 100%;
      }
    }

    .ra-img-box {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      // border-left: 1px solid $borderColor;

      & > img {
        min-height: 400px;
        max-height: 800px;
      }

      .temi-preview {
        background-size: 100% 100%;
        background-color: #ebf7ff;
        width: 280px;
        height: 170px;
        position: absolute;
        top: 34px;
        text-align: center;

        .logo {
          max-width: 100px;
          margin-top: 5px;
          max-height: 28px;
        }

        .overlay {
          background: rgba(113, 127, 148, 0.5);
          width: 90%;
          height: 125px;
          margin: 2px auto;
          padding: 10px;
          border-radius: 5px;

          .p-buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 8px;

            .p-btn {
              display: block;
              line-height: normal;
              padding: 1px;
              margin: 3px;
              background: #ddd;
              border-radius: 5px;
              font-size: 11px;
              width: 70px;
            }
          }
        }
      }
    }
  }

  .contactinfo {
    .c-profile {
      .c-profile-info {
        .cp-btn {
          float: right;

          .btn-back {
            background: #fff;
            color: #20a8d8;
            border: 0;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              height: 10px;
            }
          }
        }

        display: flex;
        align-items: center;
        justify-content: center;
        background: #48a3cd;
        padding: 15px;
        border-bottom: 1px solid #eee;
        height: 120px;
        border-radius: 10px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

        .c-address-container {
          min-height: 18px;
        }

        .c-profile-name {
          max-width: calc(100% - 60px);
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .c-profile-info-v1 {
        display: flex;
        align-items: center;
        flex-direction: column;
        border-bottom: 1px solid #eee;
        border-radius: 10px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        padding: 15px;

        .profile-bg {
          background: $buttonColor;
          height: 90px;
          width: calc(100% + 30px);
          text-align: right;
          margin-top: -15px;

          .back-btn {
            background: #fff;
            cursor: pointer;
            width: 20px;
            height: 20px;
            padding: 4px;
            margin: 8px;
            border-radius: 50%;
          }
        }

        .profile-icon {
          margin-top: -50px;
          background: #a7a7a7;
          border-radius: 50%;

          .v-img {
            width: 100px;
            height: 100px;
            border-radius: 50em;
            text-align: center;
            vertical-align: middle;
            display: table-cell;
            font-size: 36px;
            border: 4px solid #cfe7f2;
            color: #cfe7f2;
          }
        }

        .btn-block {
          cursor: pointer;
          position: absolute;
          top: 110px;
          right: calc(50% - 50px);
          width: 22px;
          background: #fff;
          padding: 2px;
          border-radius: 50%;
        }

        .c-profile-name {
          font-weight: 600;
          font-size: 18px;
          margin-top: 10px;

          .ol-active {
            margin-bottom: 2px !important;
          }
        }

        .c-info {
          width: 100%;
          border-top: 1px solid #ddd;
          margin-top: 15px;
          padding-top: 15px;
          line-height: 27px;
        }
      }

      .c-profile-info-v2 {
        display: flex;
        align-items: center;
        flex-direction: column;
        border-bottom: 1px solid #eee;
        border-radius: 10px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        padding: 15px;
        background-color: $buttonColor;
        color: #fff;

        .profile-main {
          width: 100%;
          display: flex;
          align-items: flex-start;
        }

        .profile-icon {
          background: #a7a7a7;
          border-radius: 50%;
          position: relative;

          .v-img {
            width: 80px;
            height: 80px;
            border-radius: 50em;
            text-align: center;
            vertical-align: middle;
            display: table-cell;
            font-size: 36px;
            border: 4px solid #cfe7f2;
            color: #cfe7f2;
          }

          .btn-block {
            cursor: pointer;
            position: absolute;
            width: 22px;
            background: #fff;
            padding: 2px;
            border-radius: 50%;
            bottom: 10px;
            right: 0;
          }
        }

        .profile-info {
          margin-left: 15px;
          flex-grow: 1;

          .c-profile-name-box {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .c-profile-name {
              font-weight: 600;
              font-size: 18px;

              .ol-active {
                margin-bottom: 2px !important;
              }
            }

            .back-btn {
              background: #fff;
              cursor: pointer;
              width: 20px;
              height: 20px;
              padding: 4px;
              border-radius: 50%;
            }
          }
        }

        .c-info {
          width: 100%;
          border-top: 1px solid #ddd;
          margin-top: 10px;
          padding-top: 10px;
          line-height: 22px;
          font-size: 13px;

          .link-text:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .ol-active {
        width: 10px;
        height: 10px;
        background: #1ad6c2;
        border-radius: 50px;
        display: inline-block;
        vertical-align: unset;
        margin-left: 5px;
        margin-bottom: 8px;
      }

      .ol-active.ol-offline {
        background-color: #999;
      }

      a {
        padding: 0 3px 0 0;
        color: #fff;
      }

      .left-profile-userpic {
        img {
          width: 80px;
          height: 80px;
          border-radius: 50em;
        }

        .v-img {
          width: 80px;
          height: 80px;
          border-radius: 50em;
          text-align: center;
          vertical-align: middle;
          display: table-cell;
          font-size: 36px;
          border: 4px solid #cfe7f2;
          color: #cfe7f2;
        }
      }

      .video-social {
        margin-top: 15px;

        .call-on {
          background: #f8936e;
          border-color: #f8936e;
        }

        .btn-video-call {
          background: #fff;
          color: #20a8d8;
          border: 0;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: none;
        }

        .btn-video-call:hover {
          background: #f8fcfc;
        }
      }

      .form-group {
        label {
          display: inline-block;
          margin-bottom: 2px;
          font-size: 13px;
          word-break: break-all;
        }
      }

      .c-profile-user {
        .cp-btn {
          button {
            display: flex;
            align-items: center;
          }
        }

        .cp-user {
          .cp-user-icon {
            .cp-user-online {
              width: 10px;
              height: 10px;
              display: block;
              position: absolute;
              right: 20px;
              border-radius: 50%;
              top: 60px;
              border: 1px solid #fff;
            }

            .img-avatar {
              width: 120px;
              height: 120px;
              border-radius: 50em;
              text-align: center;
              vertical-align: middle;
              display: table-cell;
              font-size: 36px;
              border: 1px solid #999;
            }
          }

          .cp-user-info {
            .cp-action {
              .cp-action-box {
                background: $darkBgTextColor;
                border: 1px solid $borderColor;
                padding: 3px;
                border-radius: 50%;
                height: 32px;
                width: 32px;
                margin-right: 10px;
                display: inline-block;
                text-align: center;
                cursor: pointer;
              }
            }
          }
        }

        .lp-channel-img {
          width: 20px;
          height: 20px;
        }

        .lp-tag-img {
          height: 18px;
        }

        .cp-form {
          .cp-form-box {
            margin-bottom: 10px;

            .cp-form-title {
              text-align: right;
              color: $secondaryColor;
            }

            .cp-form-val {
              color: $primaryColor;
            }
          }
        }
      }

      .c-profile-chunkScore {
        .CircularProgressbar-text {
          transform: translate(0, -10px);
        }
      }

      .cp-card-outer {
        // min-height: 320px;
        .cp-card-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;

          .cp-card-info {
            .cp-card-title {
              color: $primaryColor;
              font-weight: 600;

              span {
                font-weight: normal;
                margin-left: 7px;
                padding-bottom: 0.25rem;
              }
            }

            .cp-card-dt {
              font-size: 13px;
              color: $secondaryColor;
            }
          }
        }

        .cp-note-item {
          margin-top: 10px;
          background: $lightBgColor;
          border: 1px solid $lightBgColor;
          padding: 20px;
          border-radius: 7px;

          .cp-note-desc {
            color: $secondaryColor;
            font-weight: 600;
          }

          .cp-note-dt {
            flex-direction: column;
            align-items: flex-end;
            display: flex;
            font-size: 11px;
            font-style: italic;
            color: $secondaryColor;
            min-width: 140px;
            text-align: right;
          }
        }
      }
    }

    .c-details {
      .cd-tab {
        background-color: #fff;
        border-bottom: 1px solid $borderColor;
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;

        .btn {
          color: $secondaryColor;
          font-weight: 600;
          border: 0;
        }

        .btn:hover,
        .dropdown-item:hover {
          color: $primaryColor;
          text-decoration: none;
          background-color: transparent;
        }

        .btn:focus,
        .dropdown-item:focus {
          text-decoration: none;
        }

        .btn.active,
        .more-tabs.active {
          color: #fff;
          background-color: $buttonColor;
          border-radius: $radius;
        }

        .more-tabs {
          display: inline-block;

          .dropdown-menu {
            .btn {
              width: 100%;
              text-align: left;
            }
          }
        }
      }

      .cd-summary {
        .cd-summary-outer {
          .cd-summary-box {
            padding-top: 20px;
            padding-bottom: 20px;

            .cd-summary-title {
              font-size: 13px;
              color: $secondaryColor;
            }

            .cd-summary-info {
              margin-top: 5px;
              font-weight: 600;
              color: $primaryColor;
            }

            .progress.positive {
              .pb-success,
              .pb-warning,
              .pb-error {
                opacity: 1;
              }
            }

            .progress.negative {
              .pb-error {
                opacity: 1;
              }
            }

            .progress:not(.negative):not(.positive) {
              .pb-warning,
              .pb-error {
                opacity: 1;
              }
            }

            .progress {
              width: 65%;
              border: none;
              margin-top: 6px;

              .progress-bar {
                border-radius: 2px;
              }

              .pb-error {
                width: 20%;
                background: red;
                margin: 0 2px;
                opacity: 0.3;
              }

              .pb-warning {
                width: 35%;
                background: orange;
                margin: 0 2px;
                opacity: 0.3;
              }

              .pb-success {
                width: 45%;
                background: green;
                margin: 0 2px;
                opacity: 0.3;
              }
            }
          }
        }

        .cd-summary-outer:first-child {
          border-bottom: 1px solid $borderColor;
        }
      }

      .cd-activity {
        .cd-activity-sec {
          margin-top: 30px;

          .activity-month {
            font-weight: 600;
            font-size: 15px;
          }

          .activity-item {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            background: $darkBgTextColor;
            border: 1px solid $darkBgTextColor;
            padding: 20px;
            border-radius: 7px;

            .activity-info {
              margin-right: 10px;

              .activity-title {
                color: $secondaryColor;
                font-weight: bold;
                margin-bottom: 7px;
              }

              .activity-desc {
                color: $secondaryColor;
              }
            }

            .activity-dt {
              font-size: 13px;
              font-style: italic;
              color: $secondaryColor;
              min-width: 140px;
              text-align: right;

              .ol-active {
                display: inline-block;
                width: 9px;
                height: 9px;
                background: #1ad6c2;
                border-radius: 10px;
                margin-right: 3px;
              }
            }

            .activity-link {
              margin-top: 20px;
              font-size: 13px;
              color: $buttonColor;
            }
          }

          .activity-item:hover {
            border: 1px solid $borderHoverColor;

            .activity-link {
              color: $buttonHoverColor;
              text-decoration: underline;
            }
          }

          .activity-item.active {
            border: 1px solid $buttonColor;
          }
        }
      }

      .cd-score-graph {
        margin: 60px;
        width: 100%;
        display: flex;
        justify-content: center;

        .CircularProgressbar {
          height: 100%;
          width: 200px;
        }

        .CircularProgressbar-text {
          fill: #800080;
        }

        .CircularProgressbar-path {
          stroke: #e49542;
        }
      }

      .cd-score-desc {
        width: 100%;
        padding: 12px;
        padding-bottom: unset;
        text-align: center;

        .cd-score-box {
          border: 1px solid #f0e6f0;
          border-radius: 5px;

          span {
            text-align: center;
            margin-top: 5px;
          }

          .cd-score-num {
            font-size: 20px;
            font-weight: bold;
            color: #800080;
          }

          .cd-score-contact {
            border-right: 1px solid #f0e6f0;
            padding: 10px;
          }

          .cd-score-none {
            padding: 10px;
          }
        }
      }

      .cd-products {
        .cdp-cat {
          .cdp-cat-outer {
            span.btn-v2 {
              font-size: 13px;
              color: $primaryColor;
              line-height: 30px;
              border-radius: 5px;
              margin-right: 5px;
              border: 1px solid $borderColor;
              margin-bottom: 10px;
              display: inline-block;
            }
          }
        }

        .cdp-prod-outer {
          overflow-x: auto;
          overflow-y: hidden;
          height: 210px;
          white-space: nowrap;

          .cdp-prod-box {
            width: 200px;
            box-shadow: $shadow1;
            display: inline-block;
            margin: 5px 10px;
            border-radius: $radius;
            overflow: hidden;

            img {
              width: 100%;
              height: 150px;
              object-fit: contain;
              border-top-left-radius: $radius;
              border-top-right-radius: $radius;
            }

            .cdp-prod-body {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px;

              .cdp-prod-title {
                font-size: 13px;
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 155px;
                display: inline-block;
              }
            }
          }
        }
      }

      .cd-product-tree {
        max-width: 600px;
        margin-top: 30px;
        margin-left: 120px;

        .now {
          border: 1px solid #20a8d8;
          display: inline-block;
          padding: 3px 12px;
          border-radius: 3px;
          background: #20a8d8;
          color: #fff;
          margin-left: 40px;
        }

        .time {
          position: absolute;
          left: -210px;
          top: -11px;
          z-index: 9;
          width: 135px;
          font-size: 11px;
          text-align: center;
          color: $secondaryColor;
          border: 1px solid $borderColor;
          display: inline-block;
          padding: 3px 12px;
          border-radius: 3px;
          background: $darkBgTextColor;
        }

        .time::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 45px;
          top: -12px;
          left: 156px;
          display: block;
          border-left: 1px solid #aaa;
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          transform: rotate(90deg);
        }

        .p-box {
          position: relative;
          margin: 20px 15px 20px 90px;
          border: 1px solid $borderColor;
          padding: 10px;
          border-radius: $radius;
          border-top-left-radius: 0;

          .img-style {
            width: 150px;
            height: 100px;
            overflow: hidden;
            float: left;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          .info {
            width: calc(100% - 160px);
            float: left;
            margin-left: 10px;
            font-size: 11px;
            line-height: 20px;

            .head {
              font-size: 13px;
              font-weight: 600;
              margin-bottom: 10px;
            }

            .channel {
              color: $secondaryColor;
              font-style: italic;

              span {
                margin-right: 3px;
                font-weight: 600;
              }
            }
          }

          .details {
            color: $secondaryColor;
            font-style: italic;
            font-size: 11px;
            margin-top: 7px;
            line-height: 13px;

            span {
              margin-right: 3px;
              font-weight: 600;
              display: block;
              font-style: normal;
            }
          }

          .circle {
            width: 12px;
            height: 12px;
            background: #fff;
            border-radius: 10px;
            border: 3px solid $buttonColor;
            position: absolute;
            left: -30px;
            top: -6px;
            z-index: 9;
          }
        }

        .p-box:first-child {
          margin-top: 20px;
        }

        .p-box:after {
          content: "";
          position: absolute;
          width: 1px;
          height: calc(100% + 20px);
          top: -20px;
          left: -25px;
          display: block;
          border-left: 1px dashed #666;
        }

        .p-box:first-of-type:after {
          height: calc(100% + 35px);
          top: -25px;
        }

        .p-box:last-child:after {
          height: 15px;
        }

        .p-box:before {
          position: absolute;
          top: -1px;
          content: "\A";
          left: -8px;
          border-top: 7px solid #c2cfd6;
          border-left: 7px solid transparent;
        }

        .c-box {
          width: 40%;

          .info {
            margin-left: 0;
            width: 100%;

            .head {
              margin-bottom: 0;
              color: #20a8d8;
            }
          }
        }
      }

      .bubble-container {
        .bubble {
          border-radius: 30px;
          border: solid 1px #378ec4;
          font-size: 14px;
          padding: 2px 10px;
          margin: 4px;
          display: flex;
          display: inline-block;
          color: #666;
        }
      }
    }

    .l-chat {
      height: calc(100vh - 110px);

      .white-bg {
        box-shadow: $shadow;
      }

      #frame {
        .content {
          .messages {
            max-height: calc(100% - 123px);
          }

          .reply-text {
            display: none;
          }

          .lng-text {
            margin-left: 0;
          }

          .btn-foot-icon {
            width: 30px;
          }

          .connect-offline {
            background-color: $buttonColor;
            color: $darkBgTextColor;
          }
        }
      }
    }

    .l-chat.is-sticky {
      position: fixed;
      top: 55px;
      z-index: 999;
      animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }

    .cd-summary-assistBy {
      font-size: 11px;
      color: #888;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
    }

    .cd-summary-recent-title {
      display: flex;
      justify-content: space-between;

      .cell-info-icon {
        display: flex;
        align-items: center;

        img {
          width: 20px;
        }

        & * {
          padding: 0 3px;
        }
      }
    }

    .cd-summary-info-interest {
      width: 33.333333%;
      float: left;
    }

    .cd-last-visit-time {
      font-size: 12px;
    }

    .cd-last-visit-container {
      border-bottom: 1px solid $borderColor;
      padding: 10px 0;
      margin-bottom: 10px;

      &:last-child {
        border-bottom: none;
      }

      .cd-summary-info-icon {
        font-size: 18px;
        padding-right: 10px;
        align-items: baseline;
      }

      .cd-last-visit-heading {
        font-size: 18px;
        font-weight: 600;
        color: $secondaryColor;
      }

      .cd-last-visit-link {
        font-size: 14px;
        color: #888;
      }
    }

    .cd-pending-task-prority {
      background: #e8f9ff;
      padding: 0px 10px;
      width: max-content;
      font-size: 12px;
      border-radius: 20px;
      font-weight: 600;
      border: solid 1px #4d82a4;
      color: #4d82a4;
      margin: 6px 0;

      &.tag-pending {
        border: solid 1px #ff4a4a;
        color: #ff4a4a;
        background: #ffcbcb;
      }

      .tag-completed {
        border: solid 1px #46fc5f;
        color: #46fc5f;
        background: #d4ffd1;
      }

      &.tag-booked {
        border: solid 1px #4d82a4;
        color: #4d82a4;
        background: #e8f9ff;
      }

      .prority-normal {
        background: #add3eb;
      }
    }

    .cd-pending-task-container {
      border: solid 1px $borderColor;
      padding: 6px;
      border-radius: 2px;

      .cd-pending-task-title {
        font-weight: 600;
        font-size: 16px;
      }

      .cd-pending-task-date span {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 600;
      }

      .cd-pending-task-actions {
        .cd-pending-task-item {
          padding: 0 2px;

          span {
            padding: 0 3px;
            font-size: 14px;
          }
        }
      }
    }

    .cd-summary-product-info.cd-summary-info {
      display: flex;
      padding: 10px 0;
      border-bottom: solid 1px $borderColor;

      .cd-summary-info-img {
        width: 60px;
        float: left;

        img {
          width: 65px;
          height: 65px;
          border-radius: 7px;
        }
      }

      .cd-summary-info-content {
        &.full-width {
          width: 100%;
        }
      }

      .cd-summary-info-content {
        width: calc(100% - 65px);
        padding-left: 15px;

        .cd-summary-info-name {
          width: 100%;
          max-width: 100%;

          .cd-summary-info-time {
            font-size: 10px;
            color: #888;
          }
        }
      }
    }

    .contact-pending-appointments {
      .cd-summary-product-info {
        &:last-child {
          border: none;
        }

        .cd-summary-info-content {
          padding-left: 0;
          width: 100%;
        }
      }
    }

    .cd-summary-info {
      img {
        width: 65px;
        height: 65px;
        border-radius: 7px;
      }

      .cd-summary-info-img {
        width: 65px;
        float: left;
      }

      .activity-link {
        color: $buttonHoverColor;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .cd-summary-info-img.circular-img {
        width: 44px;

        img {
          width: 35px;
          height: 35px;
          border-radius: 50px;
        }
      }

      .cd-summary-info-text {
        margin-top: 5px;
        font-size: 14px;
        color: #888;
      }

      .cd-summary-info-content.circular-img-content {
        width: calc(100% - 44px);
        padding-left: 2px;
      }

      .cd-summary-info-content {
        width: calc(100% - 65px);
        float: left;
        padding-left: 15px;

        .cd-summary-info-name {
          font-size: 13px;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 90%;
          display: inline-block;
        }

        .cd-summary-info-designation {
          font-size: 12px;
          color: #666;
          display: block;
        }

        .cd-summary-info-view-profile {
          font-size: 11px;
          color: $buttonColor;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    // .react-tagsinput {
    //   background-color: #fff;
    //   overflow: hidden;
    //   border-radius: 4px;
    //   padding-left: 5px;
    // }

    // .react-tagsinput--focused {
    //   border-color: none;
    // }

    // .react-tagsinput-tag {
    //   background-color: #edf1f4;
    //   border-radius: 4px;
    //   display: inline-block;
    //   margin: 5px 5px 5px 0;
    //   padding: 4px 8px;
    //   font-size: 12px;
    // }

    // .react-tagsinput-remove {
    //   cursor: pointer;
    //   font-weight: bold;
    // }

    // .react-tagsinput-tag a::before {
    //   font: normal normal normal 12px/1 FontAwesome;
    //   content: "\f00d";
    //   padding-left: 5px;
    //   color: #888;
    // }

    // .react-tagsinput-input {
    //   background: transparent;
    //   border: 0px;
    //   // height: 22px;
    //   padding-top: 5px;
    //   padding-left: 5px;
    //   margin-bottom: 5px;
    //   padding-bottom: 0px;
    //   margin-top: 1px;
    //   outline: none;
    //   width: 150px;
    //   display: none;
    // }

    .col-half-offset {
      margin-left: 4.166666667%;
    }

    .c-profile-user svg.CircularProgressbar {
      height: 100%;
      width: 220px;
    }

    .churn-score-values {
      position: absolute;
      bottom: -10px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      span {
        width: 20px;
        display: block;
        text-align: center;
      }
    }
  }

  .leadinfo {
    .l-profile {
      .c-profile-info-v2 {
        display: flex;
        align-items: center;
        flex-direction: column;
        border-bottom: 1px solid #eee;
        border-radius: 10px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        padding: 15px;
        background-color: $buttonColor;
        color: #fff;

        .profile-main {
          width: 100%;
          display: flex;
          align-items: flex-start;
        }

        .profile-icon {
          background: #a7a7a7;
          border-radius: 50%;
          position: relative;

          .v-img {
            width: 80px;
            height: 80px;
            border-radius: 50em;
            text-align: center;
            vertical-align: middle;
            display: table-cell;
            font-size: 36px;
            border: 4px solid #cfe7f2;
            color: #cfe7f2;
          }

          .btn-block {
            cursor: pointer;
            position: absolute;
            width: 22px;
            background: #fff;
            padding: 2px;
            border-radius: 50%;
            bottom: 10px;
            right: 0;
          }
        }

        .profile-info {
          margin-left: 15px;
          flex-grow: 1;

          .c-profile-name-box {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .c-profile-name {
              font-weight: 600;
              font-size: 18px;

              .ol-active {
                margin-bottom: 2px !important;
              }
            }

            .back-btn {
              background: #fff;
              cursor: pointer;
              width: 20px;
              height: 20px;
              padding: 4px;
              border-radius: 50%;
            }
          }
        }

        .c-info {
          width: 100%;
          border-top: 1px solid #ddd;
          margin-top: 10px;
          padding-top: 10px;
          line-height: 22px;
          font-size: 13px;

          .link-text:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .l-profile-info {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #48a3cd;
        padding: 15px;
        border-bottom: 1px solid #eee;
        height: 120px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

        .lp-btn {
          float: right;

          .btn-back {
            background: #fff;
            color: $buttonColor;
            border: 0;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              height: 10px;
            }
          }
        }

        .ol-active {
          width: 10px;
          height: 10px;
          background: #1ad6c2;
          border-radius: 50px;
          display: inline-block;
          vertical-align: unset;
          margin-left: 5px;
          margin-bottom: 8px;
        }

        .ol-active.ol-offline {
          background-color: #999;
        }

        a {
          padding: 0 3px 0 0;
          color: #fff;
        }

        .left-profile-userpic {
          img {
            width: 80px;
            height: 80px;
            border-radius: 50em;
          }

          .v-img {
            width: 80px;
            height: 80px;
            border-radius: 50em;
            text-align: center;
            vertical-align: middle;
            display: table-cell;
            font-size: 36px;
            border: 4px solid #cfe7f2;
            color: #cfe7f2;
          }
        }

        .video-social {
          margin-top: 15px;

          .call-on {
            background: #f8936e;
            border-color: #f8936e;
          }

          .btn-video-call {
            background: #fff;
            color: #20a8d8;
            border: 0;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            outline: none;
          }

          .btn-video-call:hover {
            background: #f8fcfc;
          }
        }

        .l-address-container {
          min-height: 18px;
        }

        .l-profile-name {
          max-width: calc(100% - 60px);
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .display-profile {
        .form-group {
          label {
            display: inline-block;
            margin-bottom: 2px;
            font-size: 13px;
            word-break: break-all;
          }
        }

        // .react-tagsinput {
        //   background-color: #fff;
        //   overflow: hidden;
        //   border-radius: 4px;
        //   padding-left: 5px;
        // }

        // .react-tagsinput--focused {
        //   border-color: none;
        // }

        // .react-tagsinput-tag {
        //   background-color: #edf1f4;
        //   border-radius: 4px;
        //   display: inline-block;
        //   margin: 5px 5px 5px 0;
        //   padding: 4px 8px;
        //   font-size: 12px;
        // }

        // .react-tagsinput-remove {
        //   cursor: pointer;
        //   font-weight: bold;
        // }

        // .react-tagsinput-tag a::before {
        //   font: normal normal normal 12px/1 FontAwesome;
        //   content: "\f00d";
        //   padding-left: 5px;
        //   color: #888;
        // }

        // .react-tagsinput-input {
        //   background: transparent;
        //   border: 0px;
        //   padding-top: 5px;
        //   padding-left: 5px;
        //   margin-bottom: 5px;
        //   padding-bottom: 0px;
        //   margin-top: 1px;
        //   outline: none;
        //   width: 150px;
        //   display: none;
        // }
      }

      .lp-action-box-small {
        background: $darkBgTextColor;
        border: 1px solid $borderColor;
        border-radius: 50%;
        height: 26px;
        width: 26px;
        margin-right: 6px;
        display: flex;
        text-align: center;
        padding: 5px;
        cursor: pointer;
      }

      .l-profile-status {
        .steps {
          font-size: 12px;
          border: 0;
          padding: 0px;
          border-radius: 0;
          list-style: none;
          overflow: hidden;
          margin: 30px 0 15px 0;

          ul {
            border: 0;
            padding: 0;
            border-radius: 0;
            list-style: none;
            overflow: hidden;

            li {
              margin-bottom: -1px;
              color: #bbb;
              text-decoration: none;
              position: relative;
              border: 0 !important;
              border-radius: 0;
              outline-style: none;
              background: #666565;
              margin: 0 2px;

              a {
                color: $buttonColor;
                text-decoration: none;
                padding: 7px 18px;
                position: relative;
                display: block;
                border: solid 1px $buttonColor;
                border-radius: 0;
                outline-style: none;
                background: #f5f5f5;
                cursor: auto;
                text-align: center;
              }
            }

            li.active {
              a {
                border-color: $buttonColor !important;
                color: #fff !important;
                background: $buttonColor !important;
              }

              a:after {
                border-left: 10px solid $buttonColor;
              }
            }
          }
        }

        .lp-status-box:hover {
          color: $primaryColor !important;
          text-decoration: none;
        }
      }

      .l-profile-tags {
        .item {
          padding: 5px 10px;
          display: inline-block;
          margin-right: 5px;
          margin-top: 5px;
          border: 1px solid #e2e2e3;
          border-radius: 7px;
          font-size: 13px;
        }
      }

      .l-profile-info {
        .lp-info-head {
          display: flex;
          justify-content: space-evenly;
          background-color: #fff;
          border-bottom: 1px solid $borderColor;
          border-top-left-radius: $radius;
          border-top-right-radius: $radius;

          .btn {
            color: $secondaryColor;
            font-weight: 600;
          }

          .btn:hover {
            color: $primaryColor;
            text-decoration: none;
          }

          .btn:focus {
            text-decoration: none;
          }

          .btn.active {
            color: $primaryColor;
          }
        }

        .m-box {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          padding: 10px 20px;
        }
      }

      .lp-card-outer {
        .lp-card-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;

          .lp-card-info {
            .lp-card-title {
              color: $primaryColor;
              font-weight: 600;

              span {
                font-weight: normal;
                margin-left: 7px;
                padding-bottom: 0.25rem;
              }
            }

            .lp-card-dt {
              font-size: 13px;
              color: $secondaryColor;
            }
          }
        }

        .lp-note-item {
          margin-top: 10px;
          background: $lightBgColor;
          border: 1px solid $lightBgColor;
          padding: 20px;
          border-radius: 7px;

          .lp-note-desc {
            color: $secondaryColor;
            font-weight: 600;
          }

          .lp-note-dt {
            flex-direction: column;
            align-items: flex-end;
            display: flex;
            font-size: 11px;
            font-style: italic;
            color: $secondaryColor;
            min-width: 140px;
            text-align: right;
          }
        }
      }

      .lp-current-link {
        min-height: 300px;

        .current-link {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin: 5px 0 0 0;
        }

        img {
          width: 20px;
        }

        .input {
          height: 30px;
          margin-left: 0px;
          margin-right: 0px;
          width: 85%;
          border: 0;
          text-indent: 3px;
          font-size: 13px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          padding-top: 5px;
        }
      }
    }

    .l-details {
      .ld-tab {
        // display: flex;
        background-color: #fff;
        border-bottom: 1px solid $borderColor;
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;

        .btn {
          color: $secondaryColor;
          font-weight: 600;
          border: 0;
        }

        .btn:hover,
        .dropdown-item:hover {
          color: $primaryColor;
          text-decoration: none;
          background-color: transparent;
        }

        .btn:focus,
        .dropdown-item:focus {
          text-decoration: none;
        }

        .btn.active,
        .more-tabs.active {
          color: #fff;
          background-color: $buttonColor;
          border-radius: $radius;
        }

        .more-tabs {
          display: inline-block;

          .dropdown-menu {
            .btn {
              width: 100%;
              text-align: left;
            }
          }
        }
      }

      .ld-summary {
        .ld-summary-outer {
          .col-half-offset {
            margin-left: 4.166666667%;
          }

          .ld-summary-box {
            padding-top: 20px;
            padding-bottom: 20px;

            .ld-summary-title {
              font-size: 13px;
              color: $secondaryColor;
            }

            .ld-summary-info {
              margin-top: 5px;
              font-weight: 600;
              color: $primaryColor;
            }

            .ld-summary-info-interest {
              width: 33.333333%;
              float: left;

              .ld-summary-info-img {
                width: 60px;
                float: left;

                img {
                  width: 65px;
                  height: 65px;
                  border-radius: 7px;
                }
              }

              .ld-summary-info-content {
                width: calc(100% - 65px);
                float: left;
                padding-left: 15px;

                .ld-summary-info-name {
                  font-size: 13px;
                  font-weight: 600;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 90%;
                  display: inline-block;
                }

                .ld-summary-info-designation {
                  font-size: 12px;
                  color: #666;
                  display: block;
                }

                .ld-summary-info-view-profile {
                  font-size: 11px;
                  color: $buttonColor;
                  cursor: pointer;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }

            .progress.positive {
              .pb-success,
              .pb-warning,
              .pb-error {
                opacity: 1;
              }
            }

            .progress.negative {
              .pb-error {
                opacity: 1;
              }
            }

            .progress:not(.negative):not(.positive) {
              .pb-warning,
              .pb-error {
                opacity: 1;
              }
            }

            .progress {
              width: 65%;
              border: none;
              margin-top: 6px;

              .progress-bar {
                border-radius: 2px;
              }

              .pb-error {
                width: 20%;
                background: red;
                margin: 0 2px;
                opacity: 0.3;
              }

              .pb-warning {
                width: 35%;
                background: orange;
                margin: 0 2px;
                opacity: 0.3;
              }

              .pb-success {
                width: 45%;
                background: green;
                margin: 0 2px;
                opacity: 0.3;
              }
            }
          }

          .ld-assistBy {
            .cd-summary-title {
              font-size: 13px;
              color: $secondaryColor;
            }

            .ld-summary-info {
              margin-top: 5px;
              font-weight: 600;
              color: #151b1e;

              .circular-img {
                width: 44px;
                float: left;

                img {
                  width: 35px;
                  height: 35px;
                  border-radius: 50px;
                }
              }

              .circular-img-content {
                width: calc(100% - 44px);
                padding-left: 2px;
              }

              .ld-summary-info-name {
                font-size: 13px;
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 90%;
                display: inline-block;
              }
            }
          }
        }
      }

      .ld-products {
        .ldp-cat {
          .ldp-cat-outer {
            span.btn-v2 {
              font-size: 13px;
              color: $primaryColor;
              line-height: 30px;
              border-radius: 5px;
              margin-right: 5px;
              border: 1px solid $borderColor;
              margin-bottom: 10px;
              display: inline-block;
            }
          }
        }

        .ldp-prod-outer {
          overflow-x: auto;
          overflow-y: hidden;
          height: 210px;
          white-space: nowrap;

          .ldp-prod-box {
            width: 200px;
            box-shadow: $shadow1;
            display: inline-block;
            margin: 5px 10px;
            border-radius: $radius;
            overflow: hidden;

            img {
              width: 100%;
              height: 150px;
              object-fit: contain;
              border-top-left-radius: $radius;
              border-top-right-radius: $radius;
            }

            .ldp-prod-body {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px;

              .ldp-prod-title {
                font-size: 13px;
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 155px;
                display: inline-block;
              }
            }
          }
        }
      }

      .ld-product-tree {
        max-width: 600px;
        margin-top: 30px;
        margin-left: 120px;

        .now {
          border: 1px solid #20a8d8;
          display: inline-block;
          padding: 3px 12px;
          border-radius: 3px;
          background: #20a8d8;
          color: #fff;
          margin-left: 40px;
        }

        .time {
          position: absolute;
          left: -210px;
          top: -11px;
          z-index: 9;
          width: 135px;
          font-size: 11px;
          text-align: center;
          color: $secondaryColor;
          border: 1px solid $borderColor;
          display: inline-block;
          padding: 3px 12px;
          border-radius: 3px;
          background: $darkBgTextColor;
        }

        .time::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 45px;
          top: -12px;
          left: 156px;
          display: block;
          border-left: 1px solid #aaa;
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          transform: rotate(90deg);
        }

        .p-box {
          position: relative;
          margin: 20px 15px 20px 90px;
          border: 1px solid $borderColor;
          padding: 10px;
          border-radius: $radius;
          border-top-left-radius: 0;

          .img-style {
            width: 150px;
            height: 100px;
            overflow: hidden;
            float: left;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          .info {
            width: calc(100% - 160px);
            float: left;
            margin-left: 10px;
            font-size: 11px;
            line-height: 20px;

            .head {
              font-size: 13px;
              font-weight: 600;
              margin-bottom: 10px;
            }

            .channel {
              color: $secondaryColor;
              font-style: italic;

              span {
                margin-right: 3px;
                font-weight: 600;
              }
            }
          }

          .details {
            color: $secondaryColor;
            font-style: italic;
            font-size: 11px;
            margin-top: 7px;
            line-height: 13px;

            span {
              margin-right: 3px;
              font-weight: 600;
              display: block;
              font-style: normal;
            }
          }

          .circle {
            width: 12px;
            height: 12px;
            background: #fff;
            border-radius: 10px;
            border: 3px solid $buttonColor;
            position: absolute;
            left: -30px;
            top: -6px;
            z-index: 9;
          }
        }

        .p-box:first-child {
          margin-top: 20px;
        }

        .p-box:after {
          content: "";
          position: absolute;
          width: 1px;
          height: calc(100% + 20px);
          top: -20px;
          left: -25px;
          display: block;
          border-left: 1px dashed #666;
        }

        .p-box:first-of-type:after {
          height: calc(100% + 35px);
          top: -25px;
        }

        .p-box:last-child:after {
          height: 15px;
        }

        .p-box:before {
          position: absolute;
          top: -1px;
          content: "\A";
          left: -8px;
          border-top: 7px solid #c2cfd6;
          border-left: 7px solid transparent;
        }

        .c-box {
          width: 40%;

          .info {
            margin-left: 0;
            width: 100%;

            .head {
              margin-bottom: 0;
              color: #20a8d8;
            }
          }
        }
      }

      .ld-score-graph {
        width: 200px;
        margin: 60px;

        .CircularProgressbar {
          height: 100%;
        }

        .CircularProgressbar-text {
          fill: #800080;
        }

        .CircularProgressbar-path {
          stroke: #e49542;
        }
      }

      .ld-score-desc {
        width: 100%;
        padding: 12px;
        padding-bottom: unset;
        text-align: center;

        .ld-score-box {
          border: 1px solid #f0e6f0;
          border-radius: 5px;

          span {
            text-align: center;
            margin-top: 5px;
          }

          .ld-score-num {
            font-size: 20px;
            font-weight: bold;
            color: #800080;
          }

          .ld-score-contact {
            border-right: 1px solid #f0e6f0;
            padding: 10px;
          }

          .ld-score-none {
            padding: 10px;
          }
        }
      }

      .ld-prediction-outer {
        .ld-prediction-box {
          display: flex;
          align-items: center;
          border-bottom: 1px solid $borderColor;
          padding: 10px 0;

          &:last-child {
            border-bottom: none;
          }

          img {
            margin-right: 10px;
          }

          .ld-prediction-title {
            font-weight: 600;
            color: $primaryColor;
          }

          .ld-prediction-info {
            color: $secondaryColor;
            font-size: 11px;
          }
        }
      }

      .ld-activity {
        .ld-activity-sec {
          margin-top: 30px;

          .activity-month {
            font-weight: 600;
            font-size: 15px;
          }

          .activity-item {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            background: $darkBgTextColor;
            border: 1px solid $darkBgTextColor;
            padding: 20px;
            border-radius: 7px;

            .activity-info {
              margin-right: 10px;

              .activity-title {
                color: $secondaryColor;
                font-weight: bold;
                margin-bottom: 7px;
              }

              .activity-desc {
                color: $secondaryColor;
              }
            }

            .activity-dt {
              font-size: 13px;
              font-style: italic;
              color: $secondaryColor;
              min-width: 140px;
              text-align: right;

              .ol-active {
                display: inline-block;
                width: 9px;
                height: 9px;
                background: #1ad6c2;
                border-radius: 10px;
                margin-right: 3px;
              }
            }

            .activity-link {
              margin-top: 20px;
              font-size: 13px;
              color: $buttonColor;
            }
          }

          .activity-item:hover {
            border: 1px solid $borderHoverColor;

            .activity-link {
              color: $buttonHoverColor;
              text-decoration: underline;
            }
          }

          .activity-item.active {
            border: 1px solid $buttonColor;
          }
        }
      }

      .ld-recent-activity {
        .ld-summary-recent-title {
          display: flex;
          justify-content: space-between;

          .cell-info-icon {
            display: flex;
            align-items: center;

            img {
              width: 20px;
            }

            & * {
              padding: 0 3px;
            }
          }
        }

        .ld-summary-assistBy {
          font-size: 11px;
          color: $secondaryColor;
          margin-bottom: 5px;
          display: flex;
          justify-content: space-between;
        }

        .ld-summary-info {
          .ld-summary-info-img {
            width: 44px;
            float: left;

            img {
              width: 35px;
              height: 35px;
              border-radius: 50px;
            }
          }

          .circular-img-content {
            width: calc(100% - 44px);
            padding-left: 2px;
          }

          .ld-summary-info-name {
            font-size: 13px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 90%;
            display: inline-block;
          }

          .ld-summary-info-text {
            margin-top: 5px;
            font-size: 14px;
            color: $secondaryColor;
          }

          .activity-link {
            color: #1b8eb7;
            cursor: pointer;
          }
        }

        .ld-summary-product-info {
          display: flex;
          padding: 10px 0;
          border-bottom: solid 1px $borderColor;

          .ld-summary-info-img {
            width: 60px;
            float: left;

            img {
              width: 65px;
              height: 65px;
              border-radius: 7px;
            }
          }

          .ld-summary-info-content {
            width: calc(100% - 65px);
            padding-left: 15px;
            float: left;

            &.full-width {
              width: 100%;
            }

            .ld-summary-info-name {
              font-size: 13px;
              font-weight: 600;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              max-width: 100%;

              .ld-summary-info-time {
                font-size: 10px;
                color: $secondaryColor;
              }
            }

            .ld-summary-info-designation {
              font-size: 12px;
              color: $secondaryColor;
              display: block;
            }

            .ld-summary-info-view-profile {
              font-size: 11px;
              color: $buttonColor;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .trending-enquires {
        .bubble {
          padding: 2px 10px;
          font-size: 13px;
          border: 1px solid $buttonColor;
          border-radius: 15px;
          display: inline-block;
          margin: 0 5px 7px 0;
        }
      }

      .cd-last-visit-summary {
        .cd-last-visit-container {
          border-bottom: 1px solid $borderColor;
          padding: 10px 0;
          margin-bottom: 10px;

          &:last-child {
            border-bottom: none;
          }

          .cd-last-visit-time {
            font-size: 12px;
          }

          .cd-summary-info-icon {
            font-size: 18px;
            padding-right: 10px;
            align-items: baseline;
          }

          .cd-last-visit-content {
            .cd-last-visit-heading {
              font-size: 18px;
              font-weight: 600;
              color: $secondaryColor;
            }

            .cd-last-visit-link {
              font-size: 14px;
              color: $secondaryColor;
            }
          }
        }
      }
    }

    .l-chat {
      height: calc(100vh - 110px);

      .white-bg {
        box-shadow: $shadow;
      }

      #frame {
        .content {
          .messages {
            max-height: calc(100% - 123px);
          }

          .reply-text {
            display: none;
          }

          .lng-text {
            margin-left: 0;
          }

          .btn-foot-icon {
            width: 30px;
          }

          .connect-offline {
            background-color: $buttonColor;
            color: $darkBgTextColor;
          }
        }
      }
    }

    .l-chat.is-sticky {
      position: fixed;
      top: 55px;
      z-index: 999;
      animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
  }

  .ld-contacts {
    .c-box {
      background-color: $darkBgTextColor;
      padding: 20px;
      border-radius: $radius;

      .contact-item {
        label {
          color: $secondaryColor;
          margin-bottom: 0.25rem;
        }
      }
    }
  }

  .l-no-record {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 320px;
    color: $secondaryColor;

    .loader-text {
      color: $secondaryColor;
    }

    img {
      height: 60px;
    }

    &.no-visitor {
      min-height: calc(100vh - 120px);
      align-self: center;

      img {
        width: 400px;
      }
    }
  }

  .task-details {
    .td-list {
      display: flex;
      // border-bottom: 1px solid $borderColor;
      // padding-bottom: 30px;

      .td-icon {
        position: relative;
        width: 50px;
        height: 50px;
        margin-right: 20px;

        img {
          border-radius: 50%;
          width: 50px;
          height: 50px;
        }

        .online-icon {
          display: block;
          height: 12px;
          width: 12px;
          position: absolute;
          right: 2px;
          bottom: 0px;
          background: #29b955;
          line-height: 1;
          border-radius: 50%;
          border: 2px solid #fff;
        }
      }

      .td-details {
        width: calc(100% - 70px);

        .fa-paperclip {
          cursor: pointer;
          color: $secondaryColor;

          &:hover {
            color: $buttonColor;
          }
        }

        .ticket-action {
          position: absolute;
          right: 20px;
          top: -36px;
          display: flex;
          justify-content: flex-end;
          height: 12px;
          width: 60px;
        }

        .td-name {
          color: $primaryColor;
          font-weight: 600;
          font-size: 18px;
        }

        .td-cb {
          color: $buttonColor;
          margin-top: 5px;
          color: #000;
        }

        .td-dt {
          color: $secondaryColor;
          font-size: 11px;
        }

        .td-desc {
          margin-top: 20px;
          word-break: break-word;
        }

        .td-save {
          min-width: 100px;
        }

        .attachments-list {
          display: flex;
          margin-top: 15px;

          .al-item {
            background: $darkBgTextColor;
            padding: 4px 8px;
            margin-right: 10px;
            border: 1px solid $borderColor;
            border-radius: $radius;
            font-size: 11px;
            line-height: 15px;

            a:hover {
              text-decoration: none;
            }

            .fa-times {
              color: $primaryColor;
              cursor: pointer;
              margin-left: 5px;
            }
          }

          .al-item:hover {
            border-color: $borderHoverColor;
          }
        }
      }
    }

    .td-item {
      border-bottom: 1px solid $borderColor;
      margin-top: 20px;
      padding-bottom: 20px;
    }

    .select-template {
      position: absolute;
      right: 10px;
      bottom: 85px;
      width: 200px;
    }

    .agent-tree {
      max-width: 600px;

      .now {
        border: 1px solid #20a8d8;
        display: inline-block;
        padding: 3px 12px;
        border-radius: 3px;
        background: #20a8d8;
        color: #fff;
      }

      .time {
        position: absolute;
        left: -1px;
        top: -35px;
        z-index: 9;
        width: 165px;
        font-size: 11px;
        text-align: center;
        color: $secondaryColor;
        border: 1px solid $borderColor;
        display: inline-block;
        padding: 3px 12px;
        border-radius: 3px 3px 0 0;
        background: $darkBgTextColor;
      }

      .time::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 17px;
        top: 1px;
        left: -10px;
        display: block;
        border-left: 1px solid #aaa;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
      }

      .p-box {
        position: relative;
        margin: 60px 15px 20px 50px;
        border: 1px solid $borderColor;
        padding: 10px;
        border-radius: $radius;
        border-top-left-radius: 0;

        .img-style {
          width: 50px;
          height: 50px;
          overflow: hidden;
          float: left;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
          }
        }

        .info {
          width: calc(100% - 160px);
          float: left;
          margin-left: 10px;
          font-size: 13px;
          line-height: 20px;

          .head {
            font-size: 15px;
            font-weight: 600;
          }

          .item {
            color: $secondaryColor;

            span {
              margin-right: 3px;
              font-weight: 600;
            }
          }
        }

        .circle {
          width: 12px;
          height: 12px;
          background: #fff;
          border-radius: 10px;
          border: 3px solid $buttonColor;
          position: absolute;
          left: -30px;
          top: -31px;
          z-index: 9;
        }
      }

      .p-box:after {
        content: "";
        position: absolute;
        width: 1px;
        height: calc(100% + 60px);
        top: -60px;
        left: -24.5px;
        display: block;
        border-left: 1px dashed #666;
      }

      .p-box:last-child:after {
        height: 35px;
      }
    }

    .sla-tree {
      .p-box {
        .circle {
          top: 5px;
        }

        &:after {
          height: calc(100% + 20px);
          top: 10px;
        }

        &:last-child:after {
          height: 5px;
        }
      }
    }

    .i-assignTo {
      cursor: pointer;
      margin-left: 6px;

      :hover {
        color: $buttonHoverColor;
      }
    }
  }

  .ticket-v1 {
    .ag-style {
      .badge {
        padding: 0.25em 0.6em;
        line-height: 2;
        border-radius: 3px;
      }
    }

    .left-profile-userpic {
      img {
        width: 80px;
        height: 80px;
        border-radius: 50em;
      }

      .v-img {
        width: 50px;
        height: 50px;
        border-radius: 50em;
        text-align: center;
        vertical-align: middle;
        display: table-cell;
        font-size: 20px;
        border: 1px solid #999;
      }
    }

    .ticket-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .profile-view {
    .online-icon {
      display: block;
      height: 12px;
      width: 12px;
      position: absolute;
      right: 2px;
      bottom: 6px;
      background: #29b955;
      line-height: 1;
      border-radius: 50%;
      border: 2px solid #fff;
    }
  }

  .chat-history-v1 {
    height: 100vh;
    margin: 0 auto;
    padding: 20px;
    max-width: 800px;

    .messages {
      min-height: calc(100% - 70px) !important;
      max-height: calc(100% - 70px) !important;
    }
  }

  .livechat-v1 {
    height: 100vh;
    margin: 0 auto;
    // padding: 20px;
    max-width: 800px;

    .messages {
      min-height: calc(100% - 123px) !important;
      max-height: calc(100% - 123px) !important;
    }
  }

  .agentChat {
    .profiletab {
      .chat-summary {
        .visitor {
          .item {
            display: flex;
            border-radius: $radius;
            margin-bottom: 10px;
            padding: 2px 0;
            font-size: 13px;

            .title {
              min-width: 130px;

              .icon {
                height: 13px;
                margin-top: -2px;
                margin-right: 8px;
              }
            }

            .current-url {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              img {
                height: 16px;
              }

              span {
                width: 100%;
                margin: 0 5px;
              }
            }

            .value {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
              font-weight: 600;
            }
          }
        }

        .notes {
          .CircularProgressbar {
            height: 80px;
            width: 80px;
          }

          .item {
            background-color: $darkBgTextColor;
            border: 1px solid $borderColor;
            border-radius: $radius;
            margin-bottom: 10px;
            padding: 5px 10px;
            font-size: 13px;

            .value {
              text-align: right;
              font-size: 11px;
              font-style: italic;
              color: $secondaryColor;
              line-height: 25px;
            }
          }
        }

        .tickets {
          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $darkBgTextColor;
            border: 1px solid $borderColor;
            border-radius: $radius;
            margin-bottom: 10px;
            padding: 5px 10px;
            font-size: 13px;
            align-items: stretch;

            .title {
              .no {
                font-weight: 600;
              }

              .dt {
                font-size: 11px;
                font-style: italic;
                color: $secondaryColor;
                margin-bottom: 7px;
              }
            }

            .value {
              min-width: 110px;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: space-between;
            }
          }
        }

        .tag {
          .item {
            padding: 5px 10px;
            display: inline-block;
            margin-right: 5px;
            margin-top: 5px;
            border: 1px solid $borderColor;
            border-radius: $radius;
            font-size: 13px;
          }
        }

        .lead-status {
          .item {
            background: $buttonColor;
            color: $darkBgTextColor;
            border-radius: $radius;
            font-size: 13px;
            padding: 5px 10px;
            display: inline-block;
          }

          .cs-action-box-small {
            background: #f2f5f9;
            border: 1px solid #e2e2e3;
            border-radius: 50%;
            height: 26px;
            width: 26px;
            margin-right: 6px;
            display: flex;
            text-align: center;
            padding: 5px;
            cursor: pointer;
          }

          .cs-card-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
          }
        }

        .currently-on {
          .icon {
            height: 17px;
          }

          .link {
            white-space: nowrap;
            text-overflow: "ellipsis";
          }

          .page-img {
            display: block;
            width: 100%;
            max-width: 370px;
            max-height: 250px;
            object-fit: cover;
            object-position: top;
            border-radius: $radius;
            margin: 10px 0 5px 0;
          }
        }

        .item:last-child {
          margin-bottom: 0;
        }

        .chat-profile-status {
          .steps {
            font-size: 12px;
            border: 0;
            padding: 0px;
            border-radius: 0;
            list-style: none;
            overflow: hidden;
            margin: 30px 0 15px 0;

            ul {
              border: 0;
              padding: 0;
              border-radius: 0;
              list-style: none;
              overflow: hidden;

              li {
                margin-bottom: -1px;
                color: #bbb;
                text-decoration: none;
                position: relative;
                border: 0 !important;
                border-radius: 0;
                outline-style: none;
                background: #666565;
                margin: 0 2px;

                a {
                  color: $buttonColor;
                  text-decoration: none;
                  padding: 7px 18px;
                  position: relative;
                  display: block;
                  border: solid 1px $buttonColor;
                  border-radius: 0;
                  outline-style: none;
                  background: #f5f5f5;
                  cursor: auto;
                  text-align: center;
                }
              }

              li.active {
                a {
                  border-color: $buttonColor !important;
                  color: #fff !important;
                  background: $buttonColor !important;
                }

                a:after {
                  border-left: 10px solid $buttonColor;
                }
              }
            }
          }

          .lp-status-box:hover {
            color: $primaryColor !important;
            text-decoration: none;
          }
        }
      }

      .select-lng {
        .prefix-select__control {
          width: 100%;
          padding: 3px 0;
          font-size: 13px;
          line-height: 1.5;
          color: #3e515b;
          background-color: #fff;
          background-clip: padding-box;
          transition:
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          border: 0;
          border-bottom: 1px solid #c2cfd6;

          .prefix-select__value-container {
            padding: 0;
          }

          .prefix-select__indicator-separator {
            display: none;
          }
        }

        .prefix-select__control--is-focused {
          outline: none;
          box-shadow: none;
        }
      }

      .chat-ticket {
        padding: 12px;

        .s-ddl-style {
          .prefix-select__control {
            width: 100%;
            padding: 3px 0;
            font-size: 13px;
            line-height: 1.5;
            color: #3e515b;
            background-color: #fff;
            background-clip: padding-box;
            transition:
              border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;
            border: 0;
            border-bottom: 1px solid #c2cfd6;

            .prefix-select__value-container {
              padding: 0;
            }

            .prefix-select__indicator-separator {
              display: none;
            }
          }

          .prefix-select__control--is-focused {
            outline: none;
            box-shadow: none;
          }
        }

        .dp-box {
          border: 0;
        }

        .ed-picker {
          .rdtPicker {
            right: 0;
          }
        }
      }

      .chat-activity {
        .visitor {
          .item {
            display: flex;
            margin-bottom: 5px;
            padding: 2px 10px;
            font-size: 13px;

            .value {
              display: flex;
              align-items: center;

              &:before {
                content: "\f192";
                font-family: "FontAwesome";
                margin-right: 5px;
                margin-top: 2px;
                opacity: 0.8;
              }
            }
          }
        }

        .notes {
          .item {
            background-color: $darkBgTextColor;
            border: 1px solid $borderColor;
            border-radius: $radius;
            margin-bottom: 10px;
            padding: 5px 10px;
            font-size: 13px;

            .value {
              text-align: right;
              font-size: 11px;
              font-style: italic;
              color: $secondaryColor;
              line-height: 25px;
            }
          }
        }

        .item:last-child {
          margin-bottom: 0;
        }
      }

      .chat-insights {
        .context {
          .item {
            padding: 2px 10px;
            font-size: 13px;
            border: 1px solid $buttonColor;
            border-radius: 15px;
            display: inline-block;
            margin: 0 5px 7px 0;
          }
        }

        .products {
          .item {
            display: flex;
            margin-bottom: 5px;
            padding: 10px 0;
            font-size: 13px;
            border-bottom: 1px solid $borderColor;

            img {
              width: 80px;
              height: 80px;
              object-fit: cover;
              border-radius: 10px;
            }

            .score {
              line-height: 15px;
              margin-top: 5px;
            }

            .badge-darkgreen {
              color: #fff;
              background-color: #165e2f;
            }

            .title {
              min-width: 120px;
              font-weight: 600;
            }

            .value {
              text-align: right;
              font-size: 11px;
              font-style: italic;
              color: $secondaryColor;
              line-height: 20px;
            }
          }
        }
      }

      .history {
        .conv-hist-box {
          margin: 0 0 10px 0;

          .conv-hist-date {
            background: #f2f5f9;
            padding: 6px 12px;
            border-radius: 6px;
            font-size: 13px;
            font-weight: 600;
          }

          .conv-hist-description {
            font-size: 13px;
            line-height: 18px;
            font-weight: 600;
          }

          .conv-hist-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;

            .conv-hist-icon {
              padding: 5px 0px;

              img {
                width: 22px;
                border-radius: 50px;
              }

              .conv-hist-name {
                color: $buttonColor;
                padding-left: 6px;
                font-size: 12px;
              }
            }

            .conv-hist-datetime {
              font-size: 11px;
              display: flex;
              color: $secondaryColor;

              .icon {
                padding-right: 6px;
              }

              .conv-hist-details-link {
                text-decoration: underline;
                padding-left: 4px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .task-chat {
    #profileFrame {
      a {
        color: #20a8d8 !important;
      }
    }
  }

  .login-v1 {
    background-color: $darkBgTextColor;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 100vh;
    padding: 10px 0;

    .content {
      width: 440px;

      &.register {
        width: 575px;
      }

      .mlogo {
        text-align: center;

        img {
          margin: 20px 0;
          height: 50px;
        }
      }

      .input-password {
        .form-control {
          border-right: 0;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }

      .input-group-text {
        background-color: transparent;
        border-radius: 0 4px 4px 0;
        border-left: 0;
      }

      .footer {
        font-size: 12px;
        text-align: center;
      }

      .strike-around-text {
        max-width: 600px;
        position: relative;

        &:before {
          content: "";
          width: 40px;
          height: 1px;
          background: #eee;
          left: 60px;
          top: 50%;
          position: absolute;
        }

        &:after {
          content: "";
          display: block;
          width: 40px;
          height: 1px;
          background: #eee;
          right: 60px;
          top: 50%;
          position: absolute;
        }
      }
    }

    .resend-text {
      color: $buttonColor;
    }

    .edit-user-icon {
      margin-left: 15px;
      cursor: pointer;
    }

    .btn-link {
      cursor: pointer;
    }

    .otp-container {
      &.error {
        .otp-input {
          border-color: #dc3545 !important;
        }
      }

      div {
        display: flex;
        justify-content: space-between;
      }

      .otp-input {
        font-size: 24px;
        border: solid 2px #bbb;
        margin-right: 10px;
      }

      .otp-input:last-child {
        margin-right: 0;
      }

      .otp-input:focus {
        box-shadow: 0px 2px 4px 2px #ccc;
      }

      .otp-input[value]:not([value=""]) {
        border-color: #20a8d8;
      }
    }
  }

  .app-store {
    .banner {
      color: #000;
      background-color: #e2edff;
      background-image: url("../img/v1/app-store/app-store.svg");
      background-position: right 0px bottom 0px;
      background-size: contain;
    }

    .app-store-general {
      .model-catalog {
        background: linear-gradient(108deg, #f5f7fd 1.3%, #dfe7f9 44.41%, #edf1fa 97.55%);
      }

      .bots {
        background: linear-gradient(109deg, #bfffc2 -8.59%, #a3ffde 52.5%, #5df9c1 105.41%);
      }

      .data-source {
        background: linear-gradient(180deg, #2892df 0%, #3ccbf4 100%);
        color: #fff;
      }

      .integration {
        background: #3387f3;
        color: #fff;
      }

      .title-button {
        padding: 8px 10px;
        border-radius: 4px;
        border: 1px solid;
        background: transparent;
        color: inherit;
        font-size: 13px;
        line-height: 20px;
      }
    }

    .slider-value {
      border: solid 1px #c2cfd6;
      color: #3e515b;
      border-radius: 50%;
      font-size: 12px;
      width: 30px;
      height: 30px;
      text-align: center;
      padding: 5px 0;
      font-weight: bold;
      margin-top: 4px;
      margin-left: 20px;
    }

    .model-tag {
      border-radius: 4px;
      background: $borderColor;
      margin-top: 20px;
      padding: 5px 10px;
      font-size: 11px;
    }

    .inte-img {
      width: 120px;
      max-height: 120px;
    }

    .bot-img {
      width: 300px;
      max-height: 300px;
      height: auto;
      border: 1px solid $borderColor;
      border-radius: $radius;
    }

    .box-link {
      color: inherit;
      border: 1px solid transparent;
      border-radius: $radius;

      &.disabled {
        cursor: auto;

        .channel-box {
          opacity: 0.6;
        }
      }
    }

    .box-link:hover {
      text-decoration: none;
      border: 1px solid $borderColor;
      border-radius: $radius;
    }

    .app-bot {
      .bot-image {
        display: flex;
        justify-content: center;
        width: 120px;
        height: 170px;
        min-width: 120px;

        img {
          object-fit: cover;
        }
      }

      .bot-view-details {
        color: inherit;
        background-color: $borderColor;
        margin: 20px -20px -20px -20px;
        padding: 14px 28px;
        border-radius: 0 0 7px 7px;
      }
    }

    .integration-status {
      position: absolute;
      right: 0;
      margin-top: -45px;
    }

    .select-attr {
      position: absolute;
      width: 200px;
      right: 0;
      bottom: -37px;
      margin: 0;
      z-index: 9;

      .select-control {
        font-size: 13px;
        line-height: 13px;

        .prefix-select__control {
          border-bottom: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          .prefix-select__indicators {
            display: none;
          }
        }

        .prefix-select__menu {
          box-shadow: none;
          border: 1px solid #cccccc;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          margin-top: 0;

          .prefix-select__menu-list {
            max-height: 120px !important;
          }
        }
      }
    }

    .postman-head {
      border: 1px solid #ddd;
      padding: 0;
      border-radius: 4px;

      .nav-tab .nav-link.active {
        border-bottom: 3px solid #20a8d8;
        font-weight: bold;
      }

      .tab-content {
        border: 0;
        border-top: 1px solid #ddd;
        padding: 0 20px 20px 20px;
      }

      .tab-content .tab-pane {
        padding: 0;
        padding-top: 1rem;
      }
    }

    .icon-pwd {
      border: 1px solid #c2cfd6;
      border-width: 1px 1px 1px 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      cursor: pointer;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
    }

    .border-right-0 {
      input {
        border-right: 0;
        margin-right: -3px;
      }
    }

    .prompt-response {
      min-height: 332px;
      background-color: $lightBgColor;
      padding: 20px;
    }

    .skeleton-box {
      background: #fff;
      border-radius: 8px;
      padding: 20px;
      margin: 20px 0;
      box-shadow:
        0 1px 3px rgba(0, 0, 0, 0.12),
        0 1px 2px rgba(0, 0, 0, 0.24);
      width: 100%;

      .skeleton {
        background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
        background-size: 200% 100%;
        animation: skeleton-loading 1.5s infinite;
      }

      .skeleton-line {
        height: 20px;
        margin-bottom: 10px;
      }

      .skeleton.text {
        height: 16px;
        width: 70%;
        margin-bottom: 10px;
      }

      .skeleton.icon {
        width: 16px;
        height: 16px;
      }

      .skeleton.bot-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .skeleton.model-tag {
        width: 80px;
        height: 24px;
        border-radius: 12px;
        display: inline-block;
        margin-right: 5px;
      }

      .skeleton.sub-text {
        height: 16px;
        width: 40%;
      }

      .skeleton-input {
        height: 30px;
      }

      .skeleton-button {
        height: 35px;
      }

      .login-brand-img {
        width: 150px;
        height: 70px;
        min-width: 120px;
        align-self: center;
      }

      .bot-card-img {
        display: flex;
        justify-content: center;
        width: 120px;
        height: 170px;
        min-width: 120px;

        img {
          object-fit: cover;
        }
      }

      .model-name {
        width: 80px;
        height: 24px;
        border-radius: 12px;
        margin-right: 5px;
      }

      .model-description {
        width: 100%;
        height: 65px;
        border-radius: 12px;
      }

      .artifact-card-img {
        width: 40px;
        min-width: 40px;
        height: 40px;
      }

      @keyframes skeleton-loading {
        0% {
          background-position: 200% 0;
        }

        100% {
          background-position: -200% 0;
        }
      }
    }

    .rc-time-picker {
      width: 100%;
      input {
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #3e515b;
      }
      .rc-time-picker-clear {
        margin-top: 2px;
      }
    }
  }

  .analytics {
    .ed-picker {
      .rdtPicker {
        right: 0;
      }
    }
  }

  .aiAgents {
    .agent-list {
      .rf-box {
        display: flex;

        .rf-img-box {
          display: flex;
          justify-content: center;
          width: 100px;
          height: 100px;
          // background: $darkBgTextColor;
          border-radius: 50%;
          overflow: hidden;

          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          background: rgb(238, 174, 202);
          background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
          border: 3px solid #fff;

          img {
            object-fit: cover;
          }
        }

        .rf-info {
          width: calc(100% - 120px);
          padding-left: 20px;
          margin-left: 20px;
          border-left: 1px solid #ddd;

          .rf-info-inner {
            word-break: break-all;
            // border-left: 1px solid #ddd;
            // padding-left: 20px;
            font-size: 13px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .ai-playground {
    .ai-tabs {
      background-color: #fff;
      border-bottom: 1px solid $borderColor;
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;

      .btn {
        color: $secondaryColor;
        font-weight: 600;
        border: 0;
      }

      .btn:hover {
        color: $primaryColor;
        text-decoration: none;
        background-color: transparent;
      }

      .btn:focus {
        text-decoration: none;
      }

      .btn.active {
        color: #fff;
        background-color: $buttonColor;
        border-radius: $radius;
      }
    }

    .banner {
      background-color: #1e4266;
      background-image: url("../img/v1/model/ai-playground.png");
    }

    .ai-playground-result {
      .ai-data-item {
        display: inline-block;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 20px;
        margin-bottom: 20px;
        background: #f2f5f9;

        .title {
          font-size: 16px;
          font-weight: 600;
          border-bottom: 1px dotted #999;
          width: 100%;
          padding-bottom: 5px;
        }

        .box {
          .item {
            margin-bottom: 5px;

            .key {
              font-weight: 600;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .prompt-response {
      min-height: 332px;
      background-color: $lightBgColor;
      padding: 20px;
    }
  }

  .c-img-box {
    width: 100%;
    height: 300px;

    img {
      background: #f2f5f9;
    }
  }

  .prompt-lab {
    .prompt-items {
      .p-type-items {
        margin-bottom: 10px;
        .p-type {
          font-weight: 600;
          color: $buttonColor;
          background-color: $lightBgColor;
          padding: 5px 10px;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .badge {
          border-radius: 0.25rem;
        }

        .p-type-indicator {
          display: flex;
          align-items: center;
          gap: 5px;
        }

        .p-item {
          overflow-wrap: break-word;
          cursor: pointer;
          border-bottom: 1px solid $borderColor;
          padding: 15px;

          &:last-child {
            border: 0;
          }

          &:hover {
            background-color: $lightBgColor;
          }

          .p-title {
            font-weight: 600;
          }

          .p-desc {
            color: $secondaryColor;
          }
        }
      }
    }

    .prompt-response {
      min-height: 300px;
      background-color: $lightBgColor;
      padding: 20px;
    }

    .model-info {
      label {
        font-weight: 600;
        width: 100px;
        text-align: right;
        margin-right: 10px;
      }
    }
    .tab-scroll {
      max-height: 655px;
      overflow-y: auto;
    }
  }

  .extensions {
    .extension-bot {
      .extension-icon {
        position: absolute;
        left: -6px;
        font-size: 16px;
        cursor: pointer;
        background: #fff;
        padding: 5px 5px 5px 5px;
        border-radius: 6px 0 0 6px;
      }

      .is-sticky {
        width: calc(58.33333% - 148px);
      }

      .studio-fullscreen {
        .is-sticky {
          width: calc(100% - 240px);
        }
      }
    }

    .knowledgeGraph-icon {
      position: absolute;
      right: 30px;
      margin-top: -60px;
      cursor: pointer;

      img {
        width: 20px;
      }
    }

    .kg-frame {
      width: 100%;
      height: calc(100vh - 230px);
      border: 0;
      overflow: hidden;
      border-radius: 7px;
      box-shadow: $shadow;
    }

    .close-kg-frame {
      position: absolute;
      top: 4px;
      right: 20px;
      cursor: pointer;
    }

    .kg-frame-icon {
      position: absolute;
      right: -6px;
      margin-top: 45px;
      font-size: 16px;
      cursor: pointer;
      background: #fff;
      padding: 5px 5px 5px 5px;
      border-radius: 0 6px 6px 0;
    }
  }

  .bot-details-v2 {
    .tag-model {
      border-radius: 4px;
      background: $borderColor;
      margin-top: 20px;
      padding: 5px 10px;
      font-size: 11px;
    }

    .bot-imge {
      display: flex;
      justify-content: center;
      width: 180px;
      height: 255px;
      min-width: 180px;

      img {
        object-fit: cover;
      }
    }
  }

  .knowledge-graph {
    .kg-frame {
      width: 100%;
      height: calc(100vh - 235px);
      border: 0;
      padding: 0px;
      overflow: hidden;
      border-radius: 7px;
      box-shadow: $shadow;
    }
    .wrapper {
      height: 100%;
      width: 100%;
    }
    loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
    }

    .board-bg {
      background-color: #ffffff;
    }

    .handle {
      background-color: #aaa;
      width: 15px;
      height: 15px;
      position: absolute;
      top: calc(100% - 20px);
      left: calc(100% - 13px);
      border-radius: 5px;
      &.full {
        width: 150px;
        height: 150px;
        transform: translate(-50%, -45%);
        opacity: 0;
      }
    }

    .element-node {
      width: 250px;
      height: 150px;

      .node-outer {
        width: 100%;
        height: 100%;
        border: 1px solid #ddd;
        border-radius: 10px;
        background-color: #fbfcff;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

        &.selected {
          border-color: #1b8eb7;
        }

        .head {
          display: flex;
          padding: 10px;
          background-color: #e8f0fe;
          border-bottom: 1px solid #ddd;
          border-radius: 10px 10px 0 0;

          .title {
            color: #424242;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 30px;
          }
          .close {
            color: black !important;
            opacity: 1;
            border: 1px solid #ddd;
            background-color: #fff;
            width: 20px;
            height: 20px;
            border-radius: 7px;
            font-size: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
          }
        }

        &.ontology {
          background-color: #f9f9f9;

          .head {
            background-color: #e4e5e9;
          }
        }

        .info {
          padding: 10px;
          .desc {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .details-box {
      padding: 20px;

      .label-box {
        display: flex;
        flex-direction: column;
        .label {
          margin-top: 5px;
          min-width: 80px;
          vertical-align: top;
          text-align: left;
          padding-right: 10px;
        }
        .label-info {
          width: 100%;
          input[type="text"],
          select,
          textarea {
            border: solid 1px #ccc;
            border-radius: 6px;
            width: 100%;
            padding: 3px;
            margin: 4px 0;
          }
        }
      }
    }
    .sidebar-btn {
      font-weight: 600;
      position: absolute;
      right: 332px;
      z-index: 99999999;
      transform: rotate(270deg);
      background: #fbfcff;
      cursor: pointer;
      top: 45px;
      border: 1px solid #ddd;
      border-radius: 4px 4px 0 0;
      width: 70px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sidebar-box {
      position: absolute;
      z-index: 9;
      right: 0;
      top: 0;
      background: #fff;
      border: 1px solid #ddd;
      font-size: 13px;
      width: 350px;
      height: 100%;
      border-radius: 4px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      overflow: auto;
      padding-top: 0;

      .tab-header {
        display: flex;
        position: absolute;
        background: #fbfcff;
        width: 100%;
        border-bottom: 2px solid #ddd;

        .items {
          min-width: 100px;
          padding: 10px;
          cursor: pointer;
          text-align: center;
          font-weight: 600;

          &:hover {
            color: cornflowerblue;
          }
          &.active {
            border-bottom: solid 2px cornflowerblue;
            margin-bottom: -1px;
          }
        }
      }

      .tab-content {
        margin-top: 42px;

        .item {
          cursor: pointer;
          border-bottom: 1px solid #e2e2e3;
          padding: 10px;
        }
        .title {
          font-weight: 600;
        }

        .description {
          color: grey;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
    .react-flow__pane {
      cursor: auto;
    }
  }
}
