.bg-primary,
.bg-success,
.bg-info,
.bg-warning,
.bg-danger,
.bg-inverse {
  color: #fff;
}

.bg-info {
  color: #151b1e !important;
}
